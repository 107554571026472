import React from 'react'

interface Props {
  orderStatus: number
  paymentStatus: number
  isOfflineOrder?: boolean
}

const orderstatuses = [
  {
    id: 0,
    name: 'Incomplete order',
    color: '#EBB908',
  },
  {
    id: 1,
    name: 'Complete order',
    color: '#11A33A',
  },
]

const paymentstatuses = [
  {
    id: 0,
    name: 'In Cart',
    color: '#0066FF',
  },
  {
    id: 1,
    name: 'Successful Payment',
    color: '#11A33A',
  },
  {
    id: 2,
    name: 'Partially Paid',
    color: '#EBB908',
  },
  {
    id: 3,
    name: 'Refunded',
    color: '#F64B4B',
  },
]

export const Chips = ({
  orderStatus,
  paymentStatus,
  isOfflineOrder,
}: Props) => (
  <div className="flex flex-wrap">
    <div className="mr-2 flex items-center justify-center">
      <div
        style={{
          backgroundColor: orderstatuses[orderStatus].color,
        }}
        className="h-[15px] w-[15px] rounded-full"
      />
      <div
        style={{
          color: orderstatuses[orderStatus].color,
        }}
        className="ml-2  text-white"
      >
        {orderstatuses[orderStatus].name}
      </div>
    </div>
    <div className="flex items-center justify-center">
      <div
        style={{
          backgroundColor: paymentstatuses[paymentStatus].color,
        }}
        className="h-[15px] w-[15px] rounded-full"
      />
      <div
        style={{
          color: paymentstatuses[paymentStatus].color,
        }}
        className=" ml-2 text-white"
      >
        {paymentstatuses[paymentStatus].name}
      </div>
    </div>
    {isOfflineOrder && (
      <div className="ml-2 flex items-center justify-center">
        <div className="h-[15px] w-[15px] rounded-full bg-black" />
        <div className=" ml-2 text-black">Offline order</div>
      </div>
    )}
  </div>
)

export default Chips
