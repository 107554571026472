import { PlusIcon, XIcon } from '@heroicons/react/outline'
import { useFormikContext } from 'formik'
import { useOutsideClick } from 'hooks'
import React, { FC, useState } from 'react'
import { accessibleOnClick } from 'utils'

interface Props<Option> {
  name: string
  placeholder?: string
  options: Option[]
  Render: FC<Option>
  identifier: (option: Option) => any
  searcher: (search: string, option: Option) => boolean
}

export const DropMultiSelect = <Option, FormikShape>({
  name,
  placeholder,
  options,
  Render,
  identifier,
  searcher,
}: Props<Option>) => {
  const { values, setFieldValue } = useFormikContext<FormikShape>()
  const [search, setSearch] = useState('')
  const selectedOptions = (values[name as keyof FormikShape] || []) as Option[]
  const [isOpen, setIsOpen] = useState(false)

  const ref = useOutsideClick(() => {
    if (isOpen) setIsOpen(false)
  }, [isOpen])

  return (
    <div ref={ref} {...accessibleOnClick(() => setIsOpen(true))}>
      <div className="flex flex-wrap">
        {selectedOptions.map(option => (
          <div className="mr-2 mb-2 flex items-center rounded-lg border p-2">
            <Render {...option} />
            <XIcon
              onClick={() =>
                setFieldValue(
                  name,
                  selectedOptions.filter(
                    o => identifier(o) !== identifier(option),
                  ),
                )
              }
              className="ml-2 h-5 w-5 cursor-pointer"
            />
          </div>
        ))}
        <div className="flex h-10 items-center rounded-lg border">
          <input
            value={search}
            onChange={e => setSearch(e.target.value)}
            type="text"
            className="h-full flex-1 border-0 bg-transparent"
            placeholder={placeholder}
          />
          <PlusIcon className="mr-2 h-5 w-5" />
        </div>
      </div>
      {isOpen && (
        <div className="selectDropdown mt-2 max-h-[200px] overflow-y-auto rounded p-1">
          {options
            .filter(option => searcher(search, option))
            .map(option => {
              const existing = selectedOptions.find(
                o => identifier(o) === identifier(option),
              )
              return (
                <div
                  {...accessibleOnClick(() =>
                    setFieldValue(
                      name,
                      existing
                        ? selectedOptions.filter(
                            o => identifier(o) !== identifier(option),
                          )
                        : [...selectedOptions, option],
                    ),
                  )}
                  className={[
                    'mb-1 cursor-pointer rounded p-1 hover:bg-slate-100',
                    existing ? 'bg-blue-100' : '',
                  ].join(' ')}
                >
                  <Render {...option} />
                </div>
              )
            })}
        </div>
      )}
    </div>
  )
}
