import { FC } from 'react'
import {
  FilingReport,
  KnockoutDocument,
  OrderItemPaymentStatus,
  PriorityType,
  ProofType,
  TmOrder,
} from '../interfaces'

import { createFetcher, ROUTES } from '../api'
import { OrderItem } from './matters'

export const fetchIntFilingMoveStep = createFetcher<
  any,
  {
    tm_order_id: string
    next_step: number
  }
>({
  url: ROUTES.INT_FILING.MOVE_STEP,
  method: 'PATCH',
  withTokens: true,
})

// POSTPAYMENT
export const fetchIntFilingPostpayment = createFetcher<IntFilingPostPayment[]>({
  url: ROUTES.INT_FILING.POSTPAYMENT,
  withTokens: true,
})

// STEP 1
export const fetchIntFilingStep1 = createFetcher<Step1IntDataItem[]>({
  url: ROUTES.INT_FILING.STEP1.GET,
  method: 'GET',
  withTokens: true,
})
export const fetchIntFilingStep1AddClassification = createFetcher<
  any,
  {
    tm_order_id: string
    tm_order_classifications: {
      trademark_classification_class_id: string
      description: string
    }[]
    trademark_type: string
  }
>({
  url: ROUTES.INT_FILING.STEP1.ADD,
  method: 'POST',
  withTokens: true,
})
export const fetchIntFilingStep1RomoveClassification = createFetcher<
  any,
  {
    tm_order_id: string
    tm_order_classifications: string[]
  }
>({
  url: ROUTES.INT_FILING.STEP1.REMOVE,
  method: 'POST',
  withTokens: true,
})
export const fetchIntFilingStep1UpdateClassification = createFetcher<
  any,
  {
    tm_order_id: string
    tm_order_classifications: {
      id: string
      description: string
    }[]
  }
>({
  url: ROUTES.INT_FILING.STEP1.UDPATE,
  method: 'PATCH',
  withTokens: true,
})

// STEP 2
export const fetchIntFilingStep2 = createFetcher<Step2IntDataItem[]>({
  url: ROUTES.INT_FILING.STEP2.GET,
  method: 'GET',
  withTokens: true,
})
export const fetchIntFilingStep2Update = createFetcher<
  any,
  {
    tm_order_id: string
    current_step: 2
    is_knockout_searched?: boolean
    working_attorney_id: string
    knockout_document?: {
      breif_description: string
      search_urls: string[]
      design_code: string
      description_used: string
      knockout_document_list: {
        serial_number: string
        similarity_type: string
      }[]
    }
  }
>({
  url: ROUTES.INT_FILING.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

// STEP 3
export const fetchIntFilingStep3 = createFetcher<Step3IntDataItem[]>({
  url: ROUTES.INT_FILING.STEP3.GET,
  method: 'GET',
  withTokens: true,
})
export const fetchIntFilingStep3Update = createFetcher<
  any,
  {
    tm_order_id: string
    current_step: 3
    law_firm_matter_id: number
    working_attorney_id: string
  }
>({
  url: ROUTES.INT_FILING.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

// STEP 4
export const fetchIntFilingStep4 = createFetcher<Step4IntDataItem[]>({
  url: ROUTES.INT_FILING.STEP4.GET,
  method: 'GET',
  withTokens: true,
})
export const fetchIntFilingStep4Update = createFetcher<
  any,
  {
    tm_order_id: string
    current_step: 4
    step4_earn_status: 'RAPC Law Firm Earned' | 'TBD'
    filing_report: {
      classes: number
      mark_name: string
      prices_id: string
      good_description: string
      logo_description: string
      is_good_protected: boolean
      is_name_protected: boolean
      is_step_1_completed: boolean
      is_step_2_completed: boolean
      is_step_3_completed: boolean
      is_step_4_completed: boolean
      service_description: string
      is_service_protected: boolean
      tm_order_entity_info: any
      class_classifications: {
        id: string
        proof: {
          other: string
          specimen: string
          proof_type: number
          website_specimen: string
        }[]

        is_saved: boolean
        description: string
        date_of_filing: number
        filing_country: string
        application_number: string
        first_use_date_any: number
        first_use_date_com: number
        is_being_used_to_sell: boolean
        is_filed_in_any_country: boolean
        trademark_classification_class_id: string
      }[]

      is_trademark_already_using: boolean
      is_submitted_for_processing: boolean
      is_good_classifications_confirmed: boolean
      is_service_classifications_confirmed: boolean
    }
  }
>({
  url: ROUTES.INT_FILING.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

// STEP 5
export const fetchIntFilingStep5 = createFetcher<Step5IntDataItem[]>({
  url: ROUTES.INT_FILING.STEP5.GET,
  method: 'GET',
  withTokens: true,
})
export const fetchIntFilingStep5Update = createFetcher<
  any,
  {
    tm_order_id: string
    current_step: 5
    step5_approval_type: string
    step5_approval_status: 'Complete - Approve to file' | 'In Progress'
  }
>({
  url: ROUTES.INT_FILING.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

export const fetchIntFilingEarnAttorney = createFetcher<Step4IntDataItem[]>({
  url: ROUTES.INT_FILING.EARN_ATTORNEY.GET,
  method: 'GET',
  withTokens: true,
})

// STEP 6
export const fetchIntFilingStep6 = createFetcher<Step6IntDataItem[]>({
  url: ROUTES.INT_FILING.STEP6.GET,
  method: 'GET',
  withTokens: true,
})
export const fetchIntFilingStep6Update = createFetcher<
  any,
  {
    tm_order_id: string
    current_step: 6
    serial_number: string
  }
>({
  url: ROUTES.INT_FILING.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

export const fetchIntFilingApproveAttorney = createFetcher<Step5IntDataItem[]>({
  url: ROUTES.INT_FILING.APPROVE_ATTORNEY.GET,
  method: 'GET',
  withTokens: true,
})

export interface IntFilingPostPayment {
  order_item_id: string
  order_id: string
  created_at: number
  updated_on: number
  order_status: number
  classes: number
  prices_id: string
  is_name_protected: boolean
  mark_name: string
  is_slogan_protected: boolean
  slogan: string
  is_logo_protected: boolean
  logo_url: string
  is_colour_protected: boolean
  logo_description: string
  is_trademark_already_using: boolean
  is_good_protected: boolean
  is_service_protected: boolean
  good_description: string
  service_description: string
  is_step1_completed: boolean
  is_step2_completed: boolean
  is_good_classifications_confirmed: boolean
  is_service_classifications_confirmed: boolean
  is_attorney_review_requested: boolean
  is_step3_completed: boolean
  priority_type: PriorityType
  is_submitted_for_processing: boolean
  matter_id: number
  full_name: string
  email: string
  phone: string
  classifications?: any
  order_item: OrderItem
}

export interface Step1IntDataItem {
  order_item_id: string
  order_id: string
  created_at: number
  updated_on: number
  order_status: OrderItemPaymentStatus
  classes: number
  prices_id: string
  is_name_protected: boolean
  mark_name: string
  is_slogan_protected: boolean
  slogan: string
  is_logo_protected: boolean
  logo_url: string
  is_colour_protected: boolean
  logo_description: string
  is_trademark_already_using: boolean
  is_good_protected: boolean
  is_service_protected: boolean
  good_description: string
  service_description: string
  is_step1_completed: boolean
  is_step2_completed: boolean
  is_good_classifications_confirmed: boolean
  is_service_classifications_confirmed: boolean
  is_attorney_review_requested: boolean
  is_step3_completed: boolean
  priority_type: PriorityType
  is_submitted_for_processing: boolean
  matter_id: number
  full_name: string
  email: string
  phone: string
  classifications: {
    id: string
    trademark_classification_class_id: string
    description: string | null
  }[]
  order_item: OrderItem
}

export interface LawFirmMatter {
  id: number
  created_at: number
  updated_on: number
  order_item_id: string
  order_item_type: string
  is_assigned: boolean
  law_firm_id: string
  attorney_id: string
  admin_us_tm_processing_id: string
  assigned_name?: any
}

export interface StepIntDataItem {
  created_at: number
  order_date: number
  current_step: number
  matter_id: number
  order_item: OrderItem
  tm_order: TmOrder
  tm_order_id: string
  order_contributor: string
}

export interface ParsedStepIntDataItem extends StepIntDataItem {
  title: string
  Icon: FC
  Preview: FC
  trademarkType: string
}

// export interface Step2IntDataItem extends StepIntDataItem {
//   is_black_list_checked: boolean
//   is_slic_checked: boolean
//   is_unfiled_matters_checked: boolean
//   slic_document?: any
//   slic_status: number
// }

export interface Step2IntDataItem extends StepIntDataItem {
  is_knockout_searched: boolean
  working_attorney_id: string
  knockout_documents: KnockoutDocument
}

export interface Step3IntDataItem extends StepIntDataItem {
  attorney_name: string
  working_attorney_name: string
  assigned_attorney_id: string
  working_attorney_id: string
  law_firm_matter: LawFirmMatter | null
}

export interface Step4IntDataItem extends StepIntDataItem {
  attorney_name: string
  working_attorney_name: string
  assigned_attorney_id: string
  working_attorney_id: string
  is_knockout_searched: boolean
  knockout_document: KnockoutDocument
  filing_report: FilingReport
  step4_earn_status: string
  law_firm_matter: LawFirmMatter | null
}

export interface Step5IntDataItem extends StepIntDataItem {
  attorney_name: string
  working_attorney_name: string
  assigned_attorney_id: string
  working_attorney_id: string
  is_knockout_searched: boolean
  knockout_document: KnockoutDocument
  filing_report: FilingReport
  step5_approval_type: string
  step5_approval_status: 'Complete - Approve to file' | 'In Progress'
}

export interface Step6IntDataItem extends StepIntDataItem {
  attorney_name: string
  working_attorney_name: string
  assigned_attorney_id: string
  working_attorney_id: string
  is_knockout_searched: boolean
  knockout_document: KnockoutDocument
  filing_report: FilingReport
  serial_number: string
}
