import React from 'react'

const Restricted = () => (
  <div className="flex h-[calc(100vh-4rem)] items-center justify-center">
    <div className="m-auto w-full max-w-lg rounded-md bg-white p-8 shadow-md">
      <h1 className="text-center text-9xl font-bold text-red-600">401</h1>
      <div className="text-center">
        <svg
          className="mx-auto h-12 w-12 text-red-600"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>

        <h1 className="mt-4 text-2xl font-extrabold text-gray-900">
          Restricted Page
        </h1>
        <p className="mt-2 text-gray-500">
          Please contact the administrator to access this page or if you think
          this is a mistake.
        </p>
      </div>
    </div>
  </div>
)

export default Restricted
