import { createFetcher, PE_ROUTES } from '../../api'
import { OrderStatus } from '../../interfaces'

export const getOrders = createFetcher<{ orders: Order[] }>({
  url: PE_ROUTES.ORDERS.GET,
  withTokens: true,
})

export const fetchCountries = createFetcher<PePrice[]>({
  url: PE_ROUTES.CONSTANTS.COUNTRIES,
  withTokens: true,
})

export const fetchPeOrderItem = createFetcher<
  FetchPeCart,
  any,
  { order_id: string; order_item_id: string }
>({
  url: PE_ROUTES.ORDERS.GET_ORDER_ITEM,
  method: 'GET',
  withTokens: true,
})
export interface FetchPeCart {
  orders: OrderAndItem
}

export interface OrderAndItem {
  id: string
  matter_id: number
  user_id?: any
  created_at: number
  updated_at: number
  order_status: OrderStatus
  payment_status: number
  email: string
  phone: string
  full_name: string
  billable_amount: number
  billable_amount_paid: number
  billable_amount_refunded: number
  billable_govt_amount: number
  billable_govt_amount_paid: number
  billable_govt_amount_refunded: number
  billable_govt_amount_paid_direct: number
  is_current_cart: boolean
  order_items: OrderItem
  addons?: any
  address?: any
  transactions?: any
}

export interface Order {
  id: string
  matter_id: number
  user_id?: any
  created_at: number
  updated_at: number
  order_status: OrderStatus
  payment_status: number
  email: string
  phone: string
  full_name: string
  billable_amount: number
  billable_amount_paid: number
  billable_amount_refunded: number
  billable_govt_amount: number
  billable_govt_amount_paid: number
  billable_govt_amount_refunded: number
  billable_govt_amount_paid_direct: number
  is_current_cart: boolean
  order_items?: OrderItem[]
  addons?: any
  transactions?: any
}

export interface OrderItem {
  id: string
  user_id?: any
  order_id: string
  added_at: number
  billable_amount: number
  billable_amount_paid: number
  patent_type: string
  patent_application_type: string
  attorney_meet_slot: Date
  order_item_status: OrderStatus
  payment_status: number
  prices_id?: any
  metadata: Metadata
  pricing_ref?: any
  specimens?: Specimen[]
  assignees: Assignee[]
  order_countries: OrderCountry[]
  specimen_urls?: SpecimenUrl[]
  inventors?: Investor[]
}

export interface Specimen {
  id: string
  order_item_id: string
  name: string
  description: string
  specimen: string
}

export interface Assignee {
  id: string
  order_item_id: string
  name: string
  website: string
  address: string
  city: string
  state: string
  country: string
  zip: string
}

export interface SpecimenUrl {
  specimen_key: string
  cloudfront_url: string
}

export interface Investor {
  id: string
  name: string
  citizenship: string
  city: string
  state: string
  country: string
  address: string
  zip: string
}

export interface Metadata {
  order_item_id: string
  name: string
  invention_description: string
  patent_application_type: string
  tentative_filing_date?: any
  priority: boolean
  priority_application_numbers?: any
  offering_date: number
  application_date: number
  publication_date: number
  main_problem: string
  solution: string
  previous_solution: string
  invention_details: string
  entity_status: string
  order_id?: any
  assignee_id?: any
  attorney_meet_slot: Date
}

export interface OrderCountry {
  order_item_id: string
  prices_id: string
  order_id?: any
}

export interface PePrice {
  country_code: string
  country_name: string
  created_at: number
  design_patent_prices: {
    continuation_in_part_design_govt_fee: number
    continuation_in_part_design_service_fee: number
    design_govt_fee: number
    design_service_fee: number
    expedited_design_govt_fee: number
    expedited_design_service_fee: number
    international_design_govt_fee: number
    international_design_service_fee: number
    prices_id: string
  }
  id: string
  pct_govt_fee: number
  pct_service_fee: number
  region: string
  utility_patent_prices: {
    accelerated_govt_fee: number
    accelerated_service_fee: number
    continuation_govt_fee: number
    continuation_in_part_govt_fee: number
    continuation_in_part_service_fee: number
    continuation_service_fee: number
    prices_id: string
    provisional_govt_fee: number
    provisional_service_fee: number
    utility_govt_fee: number
    utility_service_fee: number
  }
}
