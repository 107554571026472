import { XIcon } from '@heroicons/react/solid'
import { Modal } from '@mui/material'
import { Timestamp } from 'firebase/firestore'

interface ChangelogData {
  name: string
  changes: {
    text: string
    description: string
  }[]
}

interface ChangelogSection {
  version: string
  date: Timestamp
  data: ChangelogData[]
}

interface Props {
  isOpen: boolean
  onClose: () => void
  changelogSections: ChangelogSection
  onSubmit: () => void
}

interface ModalContentProps {
  currentVersionSection: string
  onClose: () => void
  changelogSections: ChangelogSection
  onSubmit: () => void
}

export const VersionModalContent = ({
  currentVersionSection,
  changelogSections,
  onClose,
  onSubmit,
}: ModalContentProps) => (
  <>
    <div className="sticky top-0 z-10 flex items-center justify-between border-b border-gray-200 bg-white p-8">
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-2 text-4xl font-bold">
          <span className="text-gray-500">Changelog</span>
          <span className="text-primary">v{currentVersionSection}</span>
        </div>
        <span className="text-sm font-normal text-gray-500">
          {changelogSections.date.toDate().toDateString()}
        </span>
      </div>
      <button
        type="button"
        className="rounded-full p-2 text-xl font-bold text-red-500 hover:bg-red-100 hover:text-red-600 focus:outline-none"
        onClick={() => {
          onSubmit()
          onClose()
        }}
        aria-label="Close modal"
      >
        <XIcon className="h-6 w-6" />
      </button>
    </div>
    <div className="px-8 py-4">
      {changelogSections?.data.map(section => (
        <div key={section.name}>
          <h3 className="mb-4 text-3xl font-bold">{section.name}</h3>
          {section.changes.map((change, idx) => (
            <div key={change.text} className="mb-4 flex gap-4">
              <div className="flex h-10 w-10 flex-shrink-0 flex-grow-0 items-center justify-center rounded-full bg-gray-100">
                <span className="text-xl font-bold text-gray-500">
                  {idx + 1}
                </span>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-lg font-medium ">{change.text}</p>
                <p className="mb-2 leading-relaxed text-gray-500">
                  {change.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
    <div className="sticky bottom-0 z-10 mt-8 flex w-full flex-1 justify-between border-t border-gray-200 bg-white px-8 py-4 shadow-md">
      <button
        type="button"
        className="flex-1 rounded-lg bg-blue-600 py-2 px-6 text-lg font-medium text-white hover:bg-blue-700 focus:outline-none"
        onClick={() => {
          onSubmit()
        }}
      >
        Get Started
      </button>
    </div>
  </>
)

const modalOverlayClasses =
  'fixed inset-0 z-10 flex items-center justify-center bg-gray-800 bg-opacity-70'
const modalContentClasses =
  'bg-white w-full max-w-3xl max-h-screen h-auto  rounded-lg overflow-y-scroll text-gray-800 max-h-[calc(100vh-4rem)] relative'

const ChangelogModal: React.FC<Props> = ({
  isOpen,
  onClose,
  changelogSections,
  onSubmit,
}) => {
  const currentVersionSection = changelogSections.version

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={modalOverlayClasses}>
        <div className={modalContentClasses}>
          <VersionModalContent
            {...{
              currentVersionSection,
              changelogSections,
              onClose,
              onSubmit,
              isOpen,
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ChangelogModal
