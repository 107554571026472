import { useEffect, useState } from 'react'

const NetworkStatus = () => {
  // avoid error navigator is not defined
  const navigator = typeof window !== 'undefined' ? window.navigator : null
  const [online, setOnline] = useState(navigator?.onLine)
  const [showSnackbar, setShowSnackbar] = useState(false)

  const handleOnline = () => {
    setOnline(true)
  }

  const handleOffline = () => {
    setOnline(false)
  }

  useEffect(() => {
    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  useEffect(() => {
    if (online) {
      setShowSnackbar(true)
      setTimeout(() => {
        setShowSnackbar(false)
      }, 3000)
    } else {
      setShowSnackbar(true)
    }
  }, [online])

  return (
    <div className="fixed bottom-0 left-0 right-0">
      {showSnackbar && (
        <div
          className={`${
            online ? 'bg-green-500' : 'bg-red-500'
          } p-4 text-center`}
        >
          {online ? (
            <p className="text-white">You are connected</p>
          ) : (
            <p className="text-white">
              Please check your connection to use the admin portal
            </p>
          )}
        </div>
      )}
    </div>
  )
}

export default NetworkStatus
