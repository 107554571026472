/* eslint-disable react/destructuring-assignment */
import { Component } from 'react'

class ErrorBoundary extends Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI
    console.log('error', error)

    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log({ error, errorInfo })
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex  h-[calc(100vh-16rem)] flex-col items-center justify-center">
          <h1 className="text-2xl font-semibold">
            Something went wrong on client side, check logs for more info
          </h1>
          <button
            type="button"
            className="mt-4 rounded bg-blue-500 py-2 px-4 font-semibold text-white hover:bg-blue-700"
            onClick={() => {
              this.setState({ hasError: false })
            }}
          >
            Try again
          </button>
        </div>
      )
    }

    // Return children components in case of no error

    return this.props.children
  }
}

export default ErrorBoundary
