import { createFetcher, ROUTES } from '../api'

export const fetchAddonStats = createFetcher<AddOnStats, any>({
  url: ROUTES.ADDONS.STATS,
  withTokens: true,
})

export const fetchAddons = createFetcher<AddOnData[]>({
  url: ROUTES.ADDONS.GET_ALL,
  withTokens: true,
})

export const fetchLegalForms = createFetcher<
  LegalFormsData[],
  any,
  any,
  {
    state?: string
    processing_status?: 'to_be_processed' | 'processing' | 'completed' | string
    user_id?: string
  }
>({
  url: ROUTES.LEGAL_FORMS.GET_ALL,
  withTokens: true,
})

export const fetchProcessTmAddons = createFetcher<
  any,
  {
    order_item_ids: string[]
    status: string
  }
>({
  url: ROUTES.ADDONS.PROCESS,
  method: 'PATCH',
  withTokens: true,
})

export const fetchProcessLegalForms = createFetcher<
  any,
  {
    id: string
    processing_status: 'to_be_processed' | 'processing' | 'completed'
    document_link: string
  }
>({
  url: ROUTES.LEGAL_FORMS.PROCESS,
  method: 'PATCH',
  withTokens: true,
})

export const fetchGroupedLegalDocs = createFetcher<GroupedLegalForm[]>({
  url: ROUTES.LEGAL_FORMS.GET_GROUPED_DOCS,
  withTokens: true,
})

export interface GroupedLegalForm {
  user_id: string
  full_name: string
  email: string
  phone: string
  total_docs: number
  pending_docs: number
  last_doc_created_at: number
}

export interface AddOnStats {
  count: {
    total_count: number
    total_revenue: number
    free_trial_count: number
  }
}

export interface LegalFormsData {
  id: string
  user_id: string
  full_name: string
  email: string
  form_name: string
  document_link: string
  phone: string
  form_id: string
  state: string
  processing_status: string
  created_at: number
  updated_at: number
}

export interface AddOnData {
  id: string
  user_id: string
  order_id: string
  added_at: number
  quantity: number
  discount: number
  billable_amount: number
  billable_amount_paid: number
  billable_amount_refunded: number
  subscription_amount: number
  subscription_payment_period: number
  is_free_trial: boolean
  is_offline_order: boolean
  offline_order_rep: string
  offline_order_created_by: string
  item_type: string
  order_item_status: number
  payment_status: number
  prices_id: string
  refund_id: string
  govt_fee: number
  is_govt_fee_generated: boolean
  group_order_item_id: string
  metadata: any
  pricing_ref: AddonsPricingRef
  matter_id: number
  processing_status: string
  client_name: string
  subscription_status: string
  email: string
  phone: string
}

export interface AddonsPricingRef {
  base_fee: number
}
