import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { RefreshIcon } from '@heroicons/react/solid'
import { useBrand } from 'hooks'
import { DispatchSetQuery } from 'interfaces'
import { FC, useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'

import { Section } from './Section'

export interface PaginationProps {
  activePage: number
  totalPages: number
  jumpTo: DispatchSetQuery<number>
  refresh: () => Promise<void>
  loading: boolean
  refetch: () => Promise<void>
}

export const Pagination: FC<PaginationProps> = ({
  activePage,
  totalPages,
  jumpTo,
  loading,
  refresh,
}) => {
  const { colors, brand } = useBrand()
  const [input, setInput] = useState(activePage)
  useEffect(() => setInput(activePage), [activePage])
  return (
    <Section>
      {!loading &&
        (totalPages ? (
          <div className="float-right mt-[78px] flex items-center justify-end">
            <form
              onSubmit={e => {
                e.preventDefault()
                jumpTo(input)
              }}
            >
              <input
                value={input}
                onChange={e =>
                  setInput(
                    Number(e.target.value) > totalPages
                      ? totalPages
                      : Number(e.target.value),
                  )
                }
                type="number"
                className="mr-2 w-10 rounded-md border border-gray-300 p-1 text-center text-sm"
              />
            </form>
            <span className="mr-4 font-semibold leading-6">Page:</span>
            <ReactPaginate
              className="flex items-center"
              breakLabel="..."
              breakClassName="font-semibold leading-6 mr-4"
              pageClassName="font-semibold leading-6 mr-4"
              previousClassName="font-semibold leading-6 mr-4 text-2xl"
              previousLinkClassName="page-link"
              nextClassName="font-semibold leading-6 mr-4 text-adminBlue-400 text-2xl"
              nextLinkClassName="page-link"
              activeClassName={[
                'border-gray-300 px-2 py-1 rounded',
                brand === 'tm'
                  ? 'bg-adminBlue-100 text-adminBlue'
                  : 'bg-pe-primary-100 text-pe-primary',
              ].join(' ')}
              nextLabel={
                <ChevronRightIcon
                  style={{
                    color:
                      activePage === totalPages ? '#c1c1c1' : colors.primary,
                  }}
                  className="h-6 w-6"
                />
              }
              pageRangeDisplayed={5}
              marginPagesDisplayed={1}
              pageCount={
                totalPages > activePage + 10 ? activePage + 10 : totalPages
              }
              forcePage={activePage - 1}
              previousLabel={
                <ChevronLeftIcon
                  style={{
                    color: activePage <= 1 ? '#c1c1c1' : colors.primary,
                  }}
                  className="h-6 w-6"
                />
              }
              onPageChange={page => jumpTo(page.selected + 1)}
            />
            <button type="button" onClick={refresh}>
              <RefreshIcon
                // style={{ color: colors.primary }}
                className="text-adminBlue h-5 w-5"
              />
            </button>
          </div>
        ) : (
          <p className="p-5 text-right font-semibold">No Data</p>
        ))}
    </Section>
  )
}
