import { createFetcher, ROUTES } from '../api'

import { FC } from 'react'
import {
  Admin,
  ClassClassification,
  FilingReport,
  KnockoutDocument,
  OrderItemPaymentStatus,
  PriorityType,
  ProofType,
  SlicDocument,
  TmOrder,
} from '../interfaces'
import { OrderItem } from './matters'
import { LawFirmMatter } from './int_filing'

export const fetchUsFilingMoveStep = createFetcher<
  any,
  {
    tm_order_id: string
    next_step: number
  }
>({
  url: ROUTES.US_FILING.MOVE_STEP,
  method: 'PATCH',
  withTokens: true,
})

// POSTPAYMENT
export const fetchUsFilingPostpayment = createFetcher<UsFilingPostPayment[]>({
  url: ROUTES.US_FILING.POSTPAYMENT,
  withTokens: true,
})

// STEP 1
export const fetchUsFilingStep1 = createFetcher<Step1DataItem[]>({
  url: ROUTES.US_FILING.STEP1.GET,
  method: 'GET',
  withTokens: true,
})
export const fetchUsFilingStep1Update = createFetcher<
  any,
  {
    tm_order_id: string
    current_step: 4
    law_firm_matter_id: number
    working_attorney_id: string
  }
>({
  url: ROUTES.US_FILING.UPDATE,
  method: 'PATCH',
  withTokens: true,
})
export const fetchUsFilingStep1AddClassification = createFetcher<
  any,
  {
    tm_order_id: string
    tm_order_classifications: {
      trademark_classification_class_id: string
      description: string
    }[]
  }
>({
  url: ROUTES.US_FILING.STEP1.ADD,
  method: 'POST',
  withTokens: true,
})
export const fetchUsFilingStep1RomoveClassification = createFetcher<
  any,
  {
    tm_order_id: string
    tm_order_classifications: string[]
  }
>({
  url: ROUTES.US_FILING.STEP1.REMOVE,
  method: 'POST',
  withTokens: true,
})
export const fetchUsFilingStep1UpdateClassification = createFetcher<
  any,
  {
    tm_order_id: string
    tm_order_classifications: {
      id: string
      description: string
    }[]
  }
>({
  url: ROUTES.US_FILING.STEP1.UDPATE,
  method: 'PATCH',
  withTokens: true,
})

// STEP 2
export const fetchUsFilingStep2 = createFetcher<Step2DataItem[]>({
  url: ROUTES.US_FILING.STEP2.GET,
  method: 'GET',
  withTokens: true,
})
export const fetchUsFilingStep2Update = createFetcher<
  any,
  {
    tm_order_id: string
    current_step: 2
    is_black_list_checked?: boolean
    is_unfiled_matters_checked?: boolean
    is_slic_checked?: boolean
    slic_document?: {
      breif_description: string
      search_urls: string[]
      design_code: string
      description_used: string
      slic_document_list: {
        serial_number: string
        similarity_type: string
      }[]
    }
    working_attorney_id?: string
  }
>({
  url: ROUTES.US_FILING.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

// STEP 2.2  (Actually this is step 8 for backend)
export const fetchUsFilingStep2_2 = createFetcher<Step2_2DataItem[]>({
  url: ROUTES.US_FILING.STEP2_2.GET,
  method: 'GET',
  withTokens: true,
})

/* SlicStatus:
	1 for escalated
	2 for contacting client
	3 for refund
	*/

export const fetchUsFilingStep2_2Update = createFetcher<
  any,
  {
    tm_order_id: string
    current_step: 8
    slic_status: number //1 | 2 | 3
    slic_reason?: string
  }
>({
  url: ROUTES.US_FILING.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

// STEP 3
export const fetchUsFilingStep3 = createFetcher<Step3DataItem[]>({
  url: ROUTES.US_FILING.STEP3.GET,
  method: 'GET',
  withTokens: true,
})
export const fetchUsFilingStep3Update = createFetcher<
  any,
  {
    tm_order_id: string
    current_step: 3
    is_knockout_searched: boolean
    knockout_document: {
      breif_description: string
      search_urls: string[]
      design_code: string
      description_used: string
      knockout_document_list: {
        serial_number: string
        similarity_type: string
      }[]
    }
  }
>({
  url: ROUTES.US_FILING.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

// STEP 4
export const fetchUsFilingStep4 = createFetcher<Step4DataItem[]>({
  url: ROUTES.US_FILING.STEP4.GET,
  method: 'GET',
  withTokens: true,
})
export const fetchUsFilingStep4Update = createFetcher<
  any,
  {
    tm_order_id: string
    current_step: 4
    law_firm_matter_id: number
  }
>({
  url: ROUTES.US_FILING.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

// STEP 5
export const fetchUsFilingStep5 = createFetcher<Step5DataItem[]>({
  url: ROUTES.US_FILING.STEP5.GET,
  method: 'GET',
  withTokens: true,
})
export const fetchUsFilingStep5Update = createFetcher<
  any,
  {
    tm_order_id: string
    current_step: 5
    step5_earn_status: 'RAPC Law Firm Earned' | 'TBD'
    filing_report: {
      classes: number
      mark_name: string
      prices_id: string
      good_description: string
      logo_description: string
      is_good_protected: boolean
      is_name_protected: boolean
      is_step_1_completed: boolean
      is_step_2_completed: boolean
      is_step_3_completed: boolean
      is_step_4_completed: boolean
      service_description: string
      is_service_protected: boolean
      tm_order_entity_info: any
      class_classifications: {
        id: string
        proof: {
          other: string
          specimen: string
          proof_type: number
          website_specimen: string
        }[]

        is_saved: boolean
        description: string
        date_of_filing: number
        filing_country: string
        application_number: string
        first_use_date_any: number
        first_use_date_com: number
        is_being_used_to_sell: boolean
        is_filed_in_any_country: boolean
        trademark_classification_class_id: string
      }[]

      is_trademark_already_using: boolean
      is_submitted_for_processing: boolean
      is_good_classifications_confirmed: boolean
      is_service_classifications_confirmed: boolean
    }
  }
>({
  url: ROUTES.US_FILING.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

export const fetchUsFilingEarnAttorney = createFetcher<Step5DataItem[]>({
  url: ROUTES.US_FILING.EARN_ATTORNEY.GET,
  method: 'GET',
  withTokens: true,
})

// STEP 6
export const fetchUsFilingStep6 = createFetcher<Step6DataItem[]>({
  url: ROUTES.US_FILING.STEP6.GET,
  method: 'GET',
  withTokens: true,
})
export const fetchUsFilingStep6Update = createFetcher<
  any,
  {
    tm_order_id: string
    current_step: 6
    step6_approval_type: string
    step6_approval_status: 'Complete - Approve to file' | 'In Progress'
  }
>({
  url: ROUTES.US_FILING.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

export const fetchUsFilingApproveAttorney = createFetcher<Step6DataItem[]>({
  url: ROUTES.US_FILING.APPROVE_ATTORNEY.GET,
  method: 'GET',
  withTokens: true,
})

// STEP 7
export const fetchUsFilingStep7 = createFetcher<Step7DataItem[]>({
  url: ROUTES.US_FILING.STEP7.GET,
  method: 'GET',
  withTokens: true,
})
export const fetchUsFilingStep7Update = createFetcher<
  any,
  {
    tm_order_id: string
    current_step: 7
    serial_number: string
  }
>({
  url: ROUTES.US_FILING.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

export interface Step1DataItem {
  order_item_id: string
  order_id: string
  created_at: number
  updated_on: number
  order_status: OrderItemPaymentStatus
  classes: number
  prices_id: string
  is_name_protected: boolean
  mark_name: string
  is_slogan_protected: boolean
  slogan: string
  is_logo_protected: boolean
  logo_url: string
  is_colour_protected: boolean
  logo_description: string
  is_trademark_already_using: boolean
  is_good_protected: boolean
  is_service_protected: boolean
  good_description: string
  service_description: string
  is_step1_completed: boolean
  is_step2_completed: boolean
  is_good_classifications_confirmed: boolean
  is_service_classifications_confirmed: boolean
  is_attorney_review_requested: boolean
  is_step3_completed: boolean
  priority_type: PriorityType
  is_submitted_for_processing: boolean
  matter_id: number
  full_name: string
  email: string
  phone: string
  classifications: {
    id: string
    trademark_classification_class_id: string
    description: string | null
  }[]
  order_item: OrderItem
  law_firm_matter: LawFirmMatter
  order_contributor: string
  tm_order_id: string
}

export interface UsFilingPostPayment {
  order_item_id: string
  order_id: string
  created_at: number
  updated_on: number
  order_status: number
  classes: number
  prices_id: string
  is_name_protected: boolean
  mark_name: string
  is_slogan_protected: boolean
  slogan: string
  is_logo_protected: boolean
  logo_url: string
  is_colour_protected: boolean
  logo_description: string
  is_trademark_already_using: boolean
  is_good_protected: boolean
  is_service_protected: boolean
  good_description: string
  service_description: string
  is_step1_completed: boolean
  is_step2_completed: boolean
  is_good_classifications_confirmed: boolean
  is_service_classifications_confirmed: boolean
  is_attorney_review_requested: boolean
  is_step3_completed: boolean
  priority_type: PriorityType
  is_submitted_for_processing: boolean
  matter_id: number
  full_name: string
  email: string
  phone: string
  classifications?: any
  order_item: OrderItem
}
export interface StepDataItem {
  created_at: number
  order_date: number
  current_step: number
  matter_id: number
  order_item: OrderItem
  tm_order: TmOrder
  tm_order_id: string
  order_contributor: string
}

export interface ParsedStepDataItem extends StepDataItem {
  title: string
  Icon: FC
  Preview: FC<{ onlyContent?: boolean }>
  trademarkType: string
}

export interface Step2DataItem extends StepDataItem {
  is_black_list_checked: boolean
  is_slic_checked: boolean
  is_unfiled_matters_checked: boolean
  slic_document?: SlicDocument
  slic_status: number
  slic_reason: string
  working_attorney_id: string
}
export interface Step2_2DataItem extends StepDataItem {
  is_black_list_checked: boolean
  is_slic_checked: boolean
  is_unfiled_matters_checked: boolean
  slic_document?: SlicDocument
  slic_status: number
  slic_reason: string
  working_attorney_id: string
}

export interface Step3DataItem extends StepDataItem {
  is_knockout_searched: boolean
  knockout_documents: KnockoutDocument
}

export interface Step4DataItem extends StepDataItem {
  attorney_name: string
  working_attorney_name: string
  assigned_attorney_id: string
  working_attorney_id: string
  law_firm_matter: LawFirmMatter | null
}

export interface Step5DataItem extends StepDataItem {
  attorney_name: string
  working_attorney_name: string
  assigned_attorney_id: string
  working_attorney_id: string
  is_knockout_searched: boolean
  knockout_document: KnockoutDocument
  filing_report: FilingReport
  step5_earn_status: string
  law_firm_matter: LawFirmMatter | null
}

export interface Step6DataItem extends StepDataItem {
  attorney_name: string
  working_attorney_name: string
  assigned_attorney_id: string
  working_attorney_id: string
  is_knockout_searched: boolean
  knockout_document: KnockoutDocument
  filing_report: FilingReport
  step6_approval_type: string
  step6_approval_status: 'Complete - Approve to file' | 'In Progress'
}

export interface Step7DataItem extends StepDataItem {
  attorney_name: string
  working_attorney_name: string
  assigned_attorney_id: string
  working_attorney_id: string
  is_knockout_searched: boolean
  knockout_document: KnockoutDocument
  filing_report: FilingReport
  serial_number: string
}
