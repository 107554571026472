// Navigation drawers (or "sidebars") provide access to destinations and app functionality, such as switching accounts. They can either be permanently on-screen or controlled by a navigation menu icon.

import { XIcon } from '@heroicons/react/solid'

// Temporary drawer
// Temporary navigation drawers can toggle open or closed. Closed by default, the drawer opens temporarily above all other content until a section is selected.

interface DrawerProps {
  open: boolean
  onClose: () => void
  anchor?: 'left' | 'right'
  children: React.ReactNode
  className?: string
  title?: string
}

const Drawer = ({
  open,
  onClose,
  anchor = 'left',
  children,
  title,
  className,
}: DrawerProps) => (
  <div
    className={`fixed inset-0 z-50 bg-black bg-opacity-50 transition-opacity ${
      open ? 'opacity-100' : 'pointer-events-none opacity-0'
    }`}
    {...(open ? { onClick: onClose } : {})}
  >
    <div
      {...(open ? { onClick: e => e.stopPropagation() } : {})}
      className={`fixed inset-y-0 ${anchor === 'left' ? 'left-0' : 'right-0'}  
       w-full max-w-fit overflow-y-auto bg-white  shadow-md transition-all
       duration-300 ease-in-out
       ${
         open
           ? 'translate-x-0'
           : anchor === 'left'
           ? '-translate-x-full'
           : 'translate-x-full'
       }`}
    >
      {/* // button to close the drawer on the top right */}
      <div className="flex items-center justify-between  py-2 px-4">
        <h3 className="text-xl font-bold">{title}</h3>
        <button
          type="button"
          className="rounded-md p-2 hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-200 focus:ring-opacity-50"
          onClick={onClose}
        >
          <XIcon className="h-6 w-6" />
        </button>
      </div>
      <div className={className}>{children}</div>
    </div>
  </div>
)

export default Drawer
