/* eslint-disable react/no-unstable-nested-components */
import { Admin, Classification, fetchAdmins, Price } from 'api'
import { useAsyncMemo } from 'hooks'
import Image from 'next/image'
import { createContext, FC, useContext, useMemo } from 'react'
import classificationsJson from '../Data/classifications.json'
import pricesJson from '../Data/prices.json'

const prices = pricesJson as unknown as Price[]
const countries: Country[] = prices.map(c => ({
  code: c.country_code,
  name: c.country_name,
  image: `/Flags/${c.country_code?.toLowerCase()}.svg`,
  id: c.id,
  Flag: flagProps => (
    <Image
      unoptimized
      width={30}
      height={20}
      objectFit="contain"
      src={`/Flags/${c.country_code?.toLowerCase()}.svg`}
      {...flagProps}
    />
  ),
}))

const allClassifications = classificationsJson as unknown as Classification[]

interface Props {
  countries: Country[]
  prices?: Price[]
  allClassifications?: Classification[]
  allAdmins?: Admin[]
}

export interface Country {
  name: string
  code: string
  image: string
  id: string
  Flag: FC<{ width?: number; height?: number }>
}

export const ConstantsContext = createContext<Props>({
  countries: [],
})

export const useConstants = () => useContext(ConstantsContext)

export const ConstantsProvider: FC = props => {
  const [allAdmins] = useAsyncMemo(async () => {
    const { body, error } = await fetchAdmins()
    if (error || !body) return []
    return body
  }, [])

  const value = useMemo<Props>(
    () => ({ countries, prices, allClassifications, allAdmins }),
    [allAdmins],
  )
  return <ConstantsContext.Provider value={value} {...props} />
}
