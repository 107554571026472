import { createFetcher, ROUTES } from '../api'

export const fetchCreateSpreadsheet = createFetcher<any, Spreadsheet>({
  url: ROUTES.SPREADSHEETS.CREATE_SPREADSHEET,
  method: 'POST',
  withTokens: true,
})

export const fetchSpreadsheetByAdminId = createFetcher<
  Spreadsheet[],
  any,
  { admin_id: string }
>({
  url: ROUTES.SPREADSHEETS.GET_BY_ADMIN_ID,
  method: 'GET',
  withTokens: true,
})

export const fetchSpreadsheetByAdminIdAndType = createFetcher<
  Spreadsheet,
  any,
  { admin_id: string; spreadsheet_type: string }
>({
  url: ROUTES.SPREADSHEETS.GET_BY_ADMIN_ID_AND_TYPE,
  method: 'GET',
  withTokens: true,
})

export const fetchDeleteSpreadsheet = createFetcher<
  any,
  any,
  { admin_id: string; spreadsheet_type: string }
>({
  url: ROUTES.SPREADSHEETS.DELETE_SPREADSHEET,
  method: 'DELETE',
  withTokens: true,
})

export interface Spreadsheet {
  admin_id: string
  spreadsheet_type: string
  spreadsheet_id: string
}
