import { useBrand } from 'hooks'
import { useRouter } from 'next/router'
import { FC, useState } from 'react'
import { PeLogo, Logo as TmLogo } from 'ui/assets'
import { accessibleOnClick } from 'utils'

interface BrandProps {
  name: string
  Logo: FC
}

const brands: Record<string, BrandProps> = {
  pe: {
    name: 'PatentExpress',
    Logo: PeLogo,
  },
  tm: {
    name: 'TrademarkNow',
    Logo: TmLogo,
  },
}

const Sidebar = () => {
  const { push } = useRouter()
  const { brand } = useBrand()
  const [open, setOpen] = useState(false)

  return (
    <div
      className={`fixed left-0 z-10 h-fit w-screen rounded-md border-b border-gray-200 bg-white px-2 md:h-screen md:border-r ${
        open ? 'md:w-auto' : 'md:w-8'
      }`}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <div
        className={`flex items-center md:h-full md:flex-col ${
          open ? 'justify-start ' : 'justify-start px-2 md:justify-center'
        }`}
      >
        <div
          className={`flex h-10 items-center justify-center border-gray-200 transition-all duration-300 md:h-16 md:border-b ${
            open && 'rotate-[135deg] transform md:rotate-0'
          } `}
          {...accessibleOnClick((e: any) => {
            e.preventDefault()
            setOpen(!open)
          })}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.5 5L6.5 19"
              stroke="#292D32"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.23 5L20.5 5L20.5 15.27"
              stroke="#292D32"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        {open && (
          <div className="flex w-full items-center justify-around overflow-y-auto md:flex-col md:justify-start md:py-3">
            {Object.keys(brands).map(key => {
              const { Logo } = brands[key]
              return (
                <div
                  key={key}
                  className={`my-1 flex items-center rounded-md p-4 ${
                    brand === key ? `bg-sky-50` : 'hover:bg-gray-100'
                  }`}
                  {...accessibleOnClick((e: any) => {
                    e.preventDefault()
                    push(`/${key}`)
                  })}
                >
                  <div className="flex h-8 w-8 flex-shrink-0 items-center object-contain">
                    <Logo />
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default Sidebar
