import Link from 'next/link'
import React from 'react'

export const NotFound = () => (
  <div className="flex min-h-screen items-center justify-center px-4 sm:px-6 lg:px-8">
    <div className="w-full max-w-md space-y-8">
      <div className="rounded-lg bg-white p-8 shadow-lg">
        <div className="text-center">
          <div className="animate-pulse text-5xl font-semibold text-red-500 md:text-9xl">
            404
          </div>
          <h1 className="mt-4 text-2xl font-semibold md:text-3xl">
            Page Not Found
          </h1>
          <p className="mt-4 text-gray-600">
            The page you are looking for doesn&apos;t exist or has been moved.
          </p>
        </div>
        <div className="mt-8 flex justify-center">
          <Link href="/" passHref>
            <a className="bg-primary hover:bg-primary-dark rounded-md px-4 py-2 text-white">
              Go home
            </a>
          </Link>
        </div>
      </div>
    </div>
  </div>
)
