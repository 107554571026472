/* eslint-disable jsx-a11y/label-has-associated-control */
import { ErrorMessage, Field } from 'formik'
import React from 'react'

interface Props {
  name: string
  value: string | number
  label: string
  id?: string
  className?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  variant?: 1 | 2
}

export const Radio = ({
  name,
  value,
  label,
  id,
  className,
  onClick,
  variant = 1,
}: Props) => {
  if (name === 'Priority') {
    return (
      <div className={`flex w-[100%]  items-center ${className}`}>
        <Field
          type="radio"
          className="text-primary focus:!ring-primary-100 mr-3  rounded-full  focus:!ring-opacity-0 "
          name={name}
          id={id}
          value={value}
          onClick={onClick}
        />
        <label
          htmlFor={id}
          className="items-center rounded-lg stroke-[#000]  pr-14"
        >
          <div className="text-foot  mx-1  font-medium leading-5">{label}</div>
        </label>
        <ErrorMessage name="ClassificationBool" />
      </div>
    )
  }
  return (
    <div
      className={[
        `relative w-[100%]`,
        className,
        variant === 2 && 'flex-row-reverse',
      ].join(' ')}
    >
      <Field
        type="radio"
        className={[
          'text-primary peer focus:!ring-primary-100 absolute right-3 top-4 rounded-full shadow-none  !ring-offset-0 focus:!ring-opacity-0 ',
          variant === 2 && 'flex-row-reverse',
        ].join(' ')}
        name={name}
        id={id}
        value={value}
        onClick={onClick}
      />
      <label
        htmlFor={id}
        className="peer-checked:border-primary peer-checked:text-primary  peer-checked:!stroke-primary peer-checked:bg-primary-100 flex w-full items-center rounded-lg border stroke-[#000] px-3 py-3 pr-10"
      >
        <div className="text-foot  mx-1 text-center font-medium leading-5">
          {label}
        </div>
      </label>
      <ErrorMessage name="ClassificationBool" />
    </div>
  )
}
