import { createFetcher, ROUTES } from '../api'

interface RefundBody {
  max_amount: number
  message: string
  success: boolean
}

export const fetchAllRefundRequests = createFetcher<RefundRequest[], any>({
  url: ROUTES.REFUNDS.GET_ALL,
  withTokens: true,
})

export const fetchCreateRefundRequest = createFetcher<RefundBody, Refund>({
  url: ROUTES.REFUNDS.CREATE_REFUND,
  withTokens: true,
  method: 'POST',
})

export const fetchUpdateRefundRequest = createFetcher<
  {
    success: boolean
    message: string
    refund_ref_id: string
    max_amount: number
  },
  UpdateRefund
>({
  url: ROUTES.REFUNDS.PATCH_REFUND,
  withTokens: true,
  method: 'PATCH',
})

export interface Refund {
  user_id: string
  order_id: string
  order_item_id: string
  refund_request_by: string
  refund_type: number // 1 - original / braintree / online, 2 - offline / manual
  refund_status: number // 0 - pending, 1 - approved, 2 - rejected
  refund_requested_amount: number
  refund_reason: string
}

export interface UpdateRefund {
  id: string
  refund_status: number
  refund_amount: number
  refund_reason: string
  refund_type: number
  transaction_ref_id?: string
  is_partial_refund?: boolean
  partial_refund_amount?: number
}

export interface RefundRequest {
  id: string
  created_at: number
  closure_date: number
  updated_on: number
  user_id: string
  order_id: string
  order_item_id: string
  order: RefundOrder
  order_item: RefundOrderItem
  refund_request_by: string
  refund_request_by_admin_id: any
  refund_approved_by_admin_id: any
  refund_type: number
  refund_requested_amount: number
  refund_amount: number
  refund_status: number
  refund_reason: string
}

export interface RefundOrder {
  id: string
  matter_id: number
  user_id: string
  created_at: number
  updated_on: number
  payed_on: number
  order_status: number
  payment_status: number
  address_id: any
  email: string
  phone: string
  full_name: string
  billable_amount: number
  billable_amount_paid: number
  billable_amount_refunded: number
  coupon_id: any
  coupon_code: string
  total_discount: number
  is_current_cart: boolean
  transactions: any
  order_items: any
  IsFromOldDB: boolean
}

export interface RefundOrderItem {
  id: string
  user_id: string
  order_id: string
  added_at: number
  quantity: number
  billable_amount: number
  billable_amount_paid: number
  billable_amount_refunded: number
  subscription_amount: number
  subscription_payment_period: number
  discount: number
  item_type: string
  order_item_status: number
  payment_status: number
  prices_id: any
  group_order_item_id: string
  pricing_ref: RefundPricingRef
  is_offline_order: boolean
  offline_order_rep: any
  offline_order_created_by: any
  is_govt_fee_generated: boolean
  transaction_id: string
  refund_id: string
}

export interface RefundPricingRef {
  base_fee: number
}
