/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field } from 'formik'
import React from 'react'
import { isTMO } from 'config'

interface Props {
  name: string
  icon: any
  id?: string
  label?: string
  className?: string
  value?: string
}

export const Checkbox = ({
  name,
  icon: Icon,
  className,
  id,
  label,
  value,
}: Props) => (
  <div className={`peer relative ${className}`}>
    <Field
      type="checkbox"
      className={[
        'absolute right-3 top-4 rounded-full border-none shadow-none !ring-offset-0  focus:!ring-opacity-0 ',
        isTMO
          ? 'text-secondary peer focus:!ring-secondary-100'
          : 'text-primary peer focus:!ring-primary-100',
      ].join(' ')}
      name={name}
      value={value}
      id={id || name}
    />
    <label
      htmlFor={label || name}
      className={[
        'flex cursor-pointer items-center rounded-lg border stroke-[#000] px-3 py-3',
        isTMO
          ? 'peer-checked:border-secondary peer-checked:text-secondary peer-checked:!stroke-secondary  peer-checked:bg-secondary-100'
          : 'peer-checked:border-primary peer-checked:text-primary peer-checked:!stroke-primary  peer-checked:bg-primary-100',
      ].join(' ')}
    >
      <Icon
        className={`{${
          isTMO ? 'peer-checked:bg-secondary stroke-transparent' : ''
        }`}
      />
      <div className="text-foot mx-1 whitespace-nowrap text-center font-medium leading-5">
        {label || name}
      </div>
    </label>
  </div>
)
