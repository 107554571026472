import {
  ErrorMessage,
  Field,
  FieldAttributes,
  FormikTouched,
  useFormikContext,
} from 'formik'
import React from 'react'
import { Calendar } from '../assets'
import { Asterisk } from '../Asterisk'

interface Props<FormikShape = any> extends FieldAttributes<any> {
  name: keyof FormikShape | (string & { namish?: any })
  type?: string
  placeholder?: string
  inputClassName?: string
  label?: string
  required?: boolean
  ref?: any
  textarea?: boolean
  getValue?: (value: FormikShape) => string
  value?: string
  withCharCount?:
    | boolean
    | FormikTouched<any>
    | FormikTouched<any>[]
    | undefined
  hideIcon?: boolean
  className?: string
}

export const InputWithError = <FormikShape,>({
  name,
  placeholder,
  type = 'text',
  inputClassName = '',
  label,
  required,
  ref,
  textarea,
  getValue,
  value,
  withCharCount,
  hideIcon,
  className,
  ...inputProps
}: Props<FormikShape>) => {
  const { values } = useFormikContext<any>()

  const Component = textarea ? 'textarea' : 'input'
  const inputClass = `mt-2 text-h5 w-full rounded-lg p-4 leading-5 placeholder:text-[#7D7D7D] ${inputClassName}`
  return (
    <div className={['relative', className].join(' ')}>
      {label ? (
        <div className="flex">
          <label
            htmlFor={name.toString()}
            className="text-black-37 text-foot mb-1 font-semibold leading-4"
          >
            {label}
          </label>
          {required ? <Asterisk /> : null}
        </div>
      ) : null}
      <div className="flex items-center">
        <Field
          render={({ field }: any) => (
            <Component
              {...field}
              {...inputProps}
              value={value || (getValue ? getValue(values) : field.value[name])}
              id={name}
              ref={ref}
              checked={
                ['checkbox', 'radio'].includes(type)
                  ? value || (getValue ? getValue(values) : field.value[name])
                  : undefined
              }
              className={[
                inputClass,
                'border border-[#c5c5c5]',
                type === 'date' ? 'z-10 mr-[-40px] bg-transparent' : '',
                hideIcon ? 'mr-[-16px] mt-0' : '',
              ].join(' ')}
              placeholder={placeholder}
              type={type}
              name={name}
              onChange={e => {
                try {
                  const forceValue = inputProps?.onChange?.(e)
                  const newE = { ...e }
                  newE.target.value =
                    typeof forceValue === 'undefined'
                      ? e.target.value
                      : forceValue
                  field.onChange(newE)
                } catch (error) {
                  ///
                }
              }}
              onBlur={e => {
                field.onBlur(e)
                const forceValue = inputProps?.onBlur?.(e)
                if (!forceValue) return
                const newE = { ...e }
                newE.target.value = forceValue
                field.onChange(newE)
              }}
            />
          )}
        />
        {type === 'date' && !hideIcon && <Calendar />}
      </div>
      {withCharCount && (
        <div className="text-black-3A float-right mt-2 text-xs font-semibold leading-4">
          {(values[name] || '').length}/{inputProps.maxLength}
        </div>
      )}
      <ErrorMessage
        className=" text-foot mt-3 font-medium leading-5 text-[#E41515]"
        name={name.toString()}
        component="div"
      />
    </div>
  )
}
