import React, { DetailedHTMLProps } from 'react'
import { useVonage } from '../../context/vonage'

declare global {
  interface Window {
    VonageDialer: any
  }
}

// require the SDK when window is available
if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('@vgip/vonage-dialer-sdk')
}

interface VonageCallButtonProps
  extends DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  phoneNumber: string
  children?: React.ReactNode
  className?: string
  clientName?: string
  onCallStart?: () => void
  onCallAnswer?: () => void
  onCallEnd?: () => void
  setRefId?: (refId: string) => void
}

const VonageCallButton = ({
  phoneNumber,
  children,
  className,
  clientName,
  onCallEnd,
  onCallStart,
  onCallAnswer,
  setRefId,
  ...props
}: VonageCallButtonProps) => {
  // show the vonage contactpad only when button is clicked and hide it when the call is ended

  const { setShowContactPad } = useVonage()

  const handleClick = () => {
    setShowContactPad(true)
    window.VonageDialer.init({}, (dialer: any) => {
      dialer.setOnDialerEvent((event: any) => {
        switch (event.type) {
          case 'CALL_START': {
            if (onCallStart) {
              onCallStart()
            }
            dialer.setInteractionContact(event.data.id, {
              provider: 'acme',
              id: '123',
              label: clientName,
              type: 'contact',
              // typeLabel: 'Contact'
              // phoneType: 'Mobile'
            })
            break
          }
          case 'CALL_ANSWER': {
            console.log(event) // <- carries the assigned contact object
            if (onCallAnswer) {
              onCallAnswer()
            }
            break
          }
          case 'CALL_END': {
            console.log(event) // <- carries the assigned contact object
            if (onCallEnd) {
              onCallEnd()
            }
            if (setRefId) {
              setRefId(event.data.id)
            }
            setShowContactPad(false)
            break
          }
          default: {
            console.log('Unhandled event', event)
            setShowContactPad(false)
          }
        }

        //   const logDiv = document.createElement('div')
        //   logDiv.innerHTML = '<b>Event:</b> ' + JSON.stringify(event)
        //   logDiv.style.paddingBottom = '10px'
        //   document.body.appendChild(logDiv)
      })
    })
    window.VonageDialer.placeCall(phoneNumber)
  }

  return (
    <div>
      <button
        type="button"
        className={className}
        onClick={handleClick}
        {...props}
      >
        {children}
      </button>
    </div>
  )
}

export default VonageCallButton
