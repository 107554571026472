import { SearchIcon } from '@heroicons/react/outline'
import { ChangeEvent, KeyboardEvent } from 'react'

interface SearchBarProps {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
  label?: string
  placeholder?: string
  className?: string
  iconClassName?: string
  value?: string
}

const SearchBar = ({
  onChange,
  label,
  placeholder,
  iconClassName,
  onKeyDown,
  value,
  className,
}: SearchBarProps) => (
  <div
    className={`mr-4 flex flex-col  md:flex-row md:items-center md:space-x-4 ${className}`}
  >
    {label && (
      <label htmlFor={label?.toLowerCase().replace(' ', '_')}>{label}</label>
    )}
    <div
      className={`flex w-fit items-center justify-between rounded-lg border-[1px] border-gray-300 bg-[#FDFDFD] px-2 md:w-72 ${className}`}
    >
      <input
        type="text"
        value={value}
        placeholder={placeholder || 'Search'}
        id={label?.toLowerCase().replace(' ', '_')}
        className=" border-transparent  bg-[#FDFDFD] text-black placeholder:text-xs placeholder:text-[#A4A4A4]
        focus:border-transparent focus:ring-0"
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      <div>
        <SearchIcon
          className={`mr-2 h-5 w-5 text-[#3F3E3E] ${iconClassName}`}
        />
      </div>
    </div>
  </div>
)

export default SearchBar
