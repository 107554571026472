import { createFetcher, ROUTES } from '../api'
import { ClassClassification, Proof } from '../interfaces'
import { RenewalTrademarkDetails } from './renewal'

export const fetchRenewalPrices = createFetcher<RenewalPrice[]>({
  url: ROUTES.RENEWAL.PRICES,
  method: 'GET',
})

export interface RenewalOrder {
  order_item_id: string
  created_at: number
  updated_on: number
  renewal_deadline: number
  order_status: number
  prices_id: string
  serial_number: string
  classes: number
  proof?: any
  deliverables?: any
  tm_incontestability_id?: any
  tm_ownership_transfer_id?: any
  is_renewal_admin_classifications: boolean
  is_grace: boolean
  is_rapid_processing: boolean
  is_section8_filed: boolean
  is_submitted_for_processing: boolean
  class_classifications: ClassClassification[]
}

export interface RenewalPrice {
  id: string
  created_at: number
  updated_at: number
  country_code: string
  country_name: string
  region: string
  flag_url: string
  currency: string
  show_order?: number
  renewal_prices?: RenewalPrices
  renewal_section8_prices?: RenewalSection8Prices
  in_contestability_prof_fee: number
  in_contestability_govt_fee: number
  ot_prof_fee: number
  ot_gov_fee: number
}

export interface RenewalPrices {
  id: string
  number_of_class: number
  renewal_fee: number
  renewal_govt_fee: number
  renewal_add_class_govt_fee: number
  renewal_grace_fee: number
  renewal_grace_govt_fee: number
  renewal_add_class_grace_govt_fee: number
  renewal_rapid_processing_fee: number
  renewal_time: number
  renewal_min_processing_time: number
  renewal_grace_time: number
  renewal_processing_time: number
}

export interface RenewalSection8Prices {
  id: string
  number_of_class: number
  renewal_fee: number
  renewal_govt_fee: number
  renewal_add_class_govt_fee: number
  renewal_grace_fee: number
  renewal_grace_govt_fee: number
  renewal_add_class_grace_govt_fee: number
  renewal_rapid_processing_fee: number
  renewal_time: number
  renewal_min_processing_time: number
  renewal_grace_time: number
  renewal_processing_time: number
}
