/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/return-await */
import { ChevronUpIcon, DownloadIcon } from '@heroicons/react/solid'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import { useAsyncMemo, useBrand } from 'hooks'
import {
  ComponentProps,
  DetailedHTMLProps,
  ReactNode,
  useMemo,
  useState,
} from 'react'
import { jsonToCsv } from 'utils'
import Loading from './Loading'
import { Omit } from './interfaces'

type ItemMethod<Data, States, Return> = (
  item: Data,
  idx: number,
  states: States | Record<string, any>,
) => Return

interface ActionProps<Data, States>
  extends Omit<ComponentProps<'svg'>, 'onClick'> {
  icon: (props: ComponentProps<'svg'>) => JSX.Element
  onClick?: ItemMethod<Data, States, any | Promise<any>>
}

export interface Field<Data, States, Translated> {
  title: string | JSX.Element
  subTitle?: string | JSX.Element
  total?: (idx: number) => ReactNode | null
  lastLine?: string | ReactNode
  render: (
    item: Data,
    idx: number,
    states: States | Record<string, any>,
    translated: Translated | undefined,
  ) => ReactNode | null
  print?: (
    item: Data,
    idx: number,
    states: States | Record<string, any>,
  ) => string | number
  key: keyof Data | string
  props?: DetailedHTMLProps<
    React.TdHTMLAttributes<HTMLTableDataCellElement>,
    HTMLTableDataCellElement
  >
  onSort?: (currentOrder: 'asc' | 'desc') => void
}

type Props<Data, States, Translated> = {
  variant?: 1 | 2 | 3
  data: Data[]
  multiSelect?: boolean
  titleClassName?: string
  fields: Field<Data, States, Translated>[]
  states?: States
  action?: ItemMethod<Data, States, ActionProps<Data, States> | undefined>
  translator?: (item: any) => Translated | Promise<Translated>
  loading?: boolean
  nameOfFile?: string
  getDrop?: (item: Data, colspan: number, idx: number) => ReactNode | null
} & (
  | {
      selectable?: false
      setSelectedRows?: undefined
      selectedRows?: undefined
      identifier?: undefined
      getCheckboxProps?: undefined
    }
  | {
      selectable: true
      setSelectedRows: (value: React.SetStateAction<string[]>) => void
      selectedRows: string[]
      identifier: (item: Data) => string
      getCheckboxProps?: ItemMethod<Data, States, CheckboxProps>
    }
)

type RowProps<Data, States, Translated> = {
  variant?: 1 | 2 | 3
  action:
    | ItemMethod<Data, States, ActionProps<Data, States> | undefined>
    | undefined
  item: Data
  idx: number
  multiSelect?: boolean
  states: States | undefined
  fields: Field<Data, States, Translated>[]
  translator: ((item: any) => Translated | Promise<Translated>) | undefined
  getCheckboxProps?: ItemMethod<Data, States, CheckboxProps>
} & (
  | {
      selectable?: false
    }
  | {
      selectable: true
      setSelectedRows: (value: React.SetStateAction<string[]>) => void
      selectedRows: string[]
      identifier: (item: Data) => string
    }
)

const Row = <Data, States, Translated>({
  action,
  item,
  idx,
  states,
  fields,
  translator,
  variant,
  multiSelect,
  ...props
}: RowProps<Data, States, Translated>) => {
  const ActionItem = action?.(item, idx, states || {})
  const [translated] = useAsyncMemo(
    async () => await translator?.(item),
    [item],
  )
  const { brand } = useBrand()
  const id = props.selectable && props.identifier?.(item)
  return (
    <tr key={idx.toString()}>
      {props.selectable && id && (
        <td
          className={[
            variant === 1 || variant === 2
              ? 'border-b border-r-2 border-[#DFDCF3] p-4 text-center text-sm font-medium'
              : 'p-4 text-sm font-medium',
            idx % 2
              ? brand === 'tm'
                ? variant === 2
                  ? 'bg-[#f9f8ff]'
                  : variant === 3
                  ? 'bg-[#f9f9f9]'
                  : 'bg-[#f9f8ff]'
                : 'bg-[#F6F6F6]'
              : 'bg-white',
            props.selectedRows.includes(id) ? 'bg-[#F8FBFF]' : '',
          ].join(' ')}
        >
          <Checkbox
            checked={props.selectedRows.includes(id)}
            onChange={() => {
              if (multiSelect)
                props.setSelectedRows(p =>
                  props.selectedRows.includes(id)
                    ? p.filter(i => i !== id)
                    : [...p, id],
                )
              else
                props.setSelectedRows(() =>
                  props.selectedRows.includes(id) ? [] : [id],
                )
            }}
            {...props.getCheckboxProps?.(item, idx, states || {})}
          />
        </td>
      )}
      {fields.map(field => (
        <td
          {...field.props}
          className={[
            variant === 1 || variant === 2
              ? 'border-b border-r-2 border-[#DFDCF3] p-4 text-center text-sm font-medium'
              : 'p-4 text-sm font-medium',
            idx % 2
              ? brand === 'tm'
                ? variant === 2
                  ? 'bg-[#f9f8ff]'
                  : variant === 3
                  ? 'bg-[#f9f9f9]'
                  : 'bg-[#f9f8ff]'
                : 'bg-[#F6F6F6]'
              : 'bg-white',
            props.selectable && id && props.selectedRows.includes(id)
              ? 'bg-[#F8FBFF]'
              : '',
            field.props?.className,
          ].join(' ')}
          key={field.key.toString()}
        >
          {field.render(item, idx, states || {}, translated)}
        </td>
      ))}

      {ActionItem && (
        <td className="relative h-[100%] w-0 p-0">
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <ActionItem.icon
            {...action}
            className={[
              'absolute top-[50%] left-4 h-5 w-5 translate-y-[-50%]',
              ActionItem.className,
            ].join(' ')}
            onClick={() => ActionItem.onClick?.(item, idx, states || {})}
          />
        </td>
      )}
    </tr>
  )
}

export const Table = <Data extends Record<string, any>, States, Translated>({
  variant = 1,
  fields,
  data,
  titleClassName,
  states,
  action,
  translator,
  loading,
  nameOfFile,
  multiSelect = true,
  getDrop,
  ...props
}: Props<Data, States, Translated>) => {
  const hasTotal = useMemo(() => fields.some(field => field.total), [fields])
  const hasLastLine = useMemo(
    () => fields.some(field => field.lastLine),
    [fields],
  )

  const [sortOrders, setSortOrders] = useState<Record<string, 'asc' | 'desc'>>(
    {},
  )

  const dowloadCsv = async () => {
    const csv = jsonToCsv(
      data.map((item, idx) =>
        fields.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.key]: curr.print?.(item, idx, states || {}),
          }),
          {},
        ),
      ),
      // {
      //   placeTop: [
      //     fields.reduce(
      //       (acc, curr) => ({ ...acc, [curr.key]: curr.total?.toString() }),
      //       {},
      //     ),
      //   ],
      // },
    )
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `${nameOfFile || 'data'}.csv`
    a.click()
    URL.revokeObjectURL(url)
  }

  const { brand } = useBrand()

  return (
    <>
      {nameOfFile && (
        <div className="flex w-full justify-end">
          {variant === 3 ? (
            <button
              type="button"
              onClick={() => dowloadCsv()}
              className=" flex items-center gap-2 px-4 py-2 text-sm font-semibold  text-[#F55431]"
            >
              <DownloadIcon className="h-4 w-4" /> <span>Export Table</span>
            </button>
          ) : (
            <button
              type="button"
              onClick={() => dowloadCsv()}
              className="bg-primary-100 text-primary-500 -mt-12 px-4 py-2 text-sm  font-semibold"
            >
              Download as .csv
            </button>
          )}
        </div>
      )}
      <div
        className={[
          'overflow-x-auto overflow-y-hidden rounded-xl border',
          variant === 1 ? 'mt-8' : '',
          variant === 2 ? 'mt-8 border-[#ACA5D6]' : '',
          variant === 3 ? 'mt-8 border-none' : '',
        ].join(' ')}
      >
        <table className="w-[100%] border-collapse">
          <thead className="rounded-t-xl">
            <tr
              className={[
                '',
                brand === 'tm'
                  ? [
                      variant === 1 ? 'bg-primary-100' : '',
                      variant === 2 ? 'bg-[#695F9D]' : '',
                      variant === 3 ? 'bg-white' : '',
                    ].join(' ')
                  : 'bg-black-1A',
              ].join(' ')}
            >
              {props.selectable && (
                <th
                  className={[
                    variant === 2 ? 'border-r-2 border-[#ACA5D6]' : '',
                    'px-4 py-4 text-left',
                  ].join(' ')}
                >
                  <Checkbox
                    checked={props.selectedRows.length === data.length}
                    indeterminate={
                      props.selectedRows.length > 0 &&
                      props.selectedRows.length < data.length
                    }
                    onChange={e => {
                      if (props.selectable)
                        props.setSelectedRows(p => {
                          if (e.target.checked && props.selectable) {
                            if (p.length === data.length) return p
                            if (p.length > 0) return []
                            return data.map(i => props.identifier(i))
                          }
                          return []
                        })
                    }}
                  />
                </th>
              )}
              {fields.map(i => (
                <th
                  key={i.key.toString()}
                  className={[
                    variant === 2 ? 'border-r-2 border-[#ACA5D6]' : '',
                  ].join(' ')}
                >
                  <div
                    className={[
                      'px-4 py-4 text-left',
                      brand === 'tm'
                        ? [
                            variant === 1 ? 'text-primary text-cap' : '',
                            variant === 2 ? 'text-sm text-white' : '',
                          ].join(' ')
                        : 'text-white',
                      titleClassName,
                    ].join(' ')}
                  >
                    <div className="flex items-center justify-between">
                      <p
                        className={`${
                          variant === 3
                            ? 'text-left font-semibold'
                            : 'text-center'
                        }`}
                      >
                        {i.title}
                      </p>
                      {i.onSort && (
                        <button
                          type="button"
                          onClick={() => {
                            const key = String(i.key) // convert keyof Data to string
                            const currentSortOrder = sortOrders[key] || 'asc' // get current sort order or default to 'asc'
                            const newSortOrder =
                              currentSortOrder === 'asc' ? 'desc' : 'asc'

                            // set new sort order
                            setSortOrders(prevState => ({
                              ...prevState,
                              [key]: newSortOrder,
                            }))

                            // invoke onSort with new sort order
                            i.onSort?.(newSortOrder)
                          }}
                          className="hover:bg-adminBlue-100 rounded-full p-2"
                        >
                          <ChevronUpIcon
                            className={[
                              sortOrders[String(i.key)] === 'asc'
                                ? 'rotate-180'
                                : 'rotate-0', // convert keyof Data to string
                              'h-5 w-5',
                            ].join(' ')}
                          />
                        </button>
                      )}
                    </div>
                    <p className="text-left font-thin">{i.subTitle}</p>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          {hasTotal && (
            <thead className=" rounded-t-xl">
              <tr className="bg-adminBlue-100">
                {fields.map((i, idx) => (
                  <th key={i.key.toString()}>
                    <div className="text-adminBlue text-cap px-4 py-4 text-left">
                      <p className="text-center">{i.total?.(idx)}</p>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
          )}{' '}
          <tbody className={`${variant === 3 ? 'border-t-[0.8px]' : ''}`}>
            {loading ? (
              <tr>
                <td colSpan={fields.length}>
                  <div className="flex items-center justify-center p-5">
                    <Loading />
                  </div>
                </td>
              </tr>
            ) : (
              data.map((item, idx) => (
                <>
                  <Row
                    action={action}
                    item={item}
                    idx={idx}
                    states={states}
                    fields={fields}
                    variant={variant}
                    translator={translator}
                    multiSelect={multiSelect}
                    {...props}
                  />
                  {getDrop?.(item, fields.length, idx)}
                </>
              ))
            )}
            {hasLastLine && (
              <tr className="">
                {props.selectable && <div />}
                {fields.map(i => (
                  <th key={i.key.toString()}>
                    <div className=" px-4 py-4 text-left">{i.lastLine}</div>
                  </th>
                ))}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}
