import { createFetcher, ROUTES } from '../api'

export const fetchTMOwnership = createFetcher<
  TMOWNERSHIP,
  any,
  { order_item_id: string }
>({
  url: ROUTES.TMOWNERSHIP.GET_ONE,
  withTokens: true,
})

export const fetchOwnershipPostPayment = createFetcher({
  url: ROUTES.TMOWNERSHIP.POSTPAYMENT,
  withTokens: true,
})

export const fetchOwnershipUpdateMatter = createFetcher({
  url: ROUTES.TMOWNERSHIP.UPDATE_MATTER,
  withTokens: true,
})

export interface TMOWNERSHIP {
  order_item_id: string
  serial_number: string
  tm_ownership_transfer_entities: [TRANSFER_ENTITY]
}

export interface TRANSFER_ENTITY {
  id: string
  tm_ownership_transfer_id: string
  entity_type: string
  entity_name: string
  state_of_formation: string
  authorised_person_name: string
  street: string
  country: string
  city: string
  state: string
  zip: string
  is_saved: boolean
  is_modified: boolean
  is_new: boolean
}

export interface OwnershipPostpaymentItem {
  order_item_id: string
  created_at: number
  updated_on: number
  order_status: number
  prices_id: string
  proof?: any
  serial_number: string
  is_admin_add_owner_req: boolean
  is_submitted_for_processing: boolean
  order_id: string
  matter_id: number
  full_name: string
  email: string
  phone: string
  order_item: OwnershipOrderItem
}

export interface OwnershipOrderItem {
  id: string
  user_id: string
  order_id: string
  added_at: number
  quantity: number
  billable_amount: number
  billable_amount_paid: number
  billable_govt_amount: number
  billable_govt_amount_paid: number
  item_type: string
  order_item_status: number
  payment_status: number
  prices_id: string
  pricing_ref: OwnershipPricingRef
  is_offline_order: boolean
  offline_order_rep?: any
  offline_order_created_by?: any
  discount: number
}
export interface OwnershipPricingRef {
  ot_gov_fee: number
  ot_prof_fee: number
}
