import React, { FC } from 'react'

// eslint-disable-next-line import/no-extraneous-dependencies
import { Temporal } from '@js-temporal/polyfill'

interface Props {
  children: string | number
  className?: string
}
export const Deadline: FC<Props> = ({ children, className }) => {
  const integer =
    (typeof children === 'string'
      ? Number.isNaN(Number(children))
        ? Number(children)
        : 0
      : Number.isNaN(children)
      ? 0
      : children) || 0
  const instant = Temporal.Instant.fromEpochSeconds(integer)
  const now = Temporal.Now.instant()
  const diff = now.until(instant)

  const days = diff.total({
    unit: 'days',
    relativeTo: Temporal.Now.plainDateISO(),
  })

  if (children === 0) {
    return (
      <div className={className || 'flex items-center justify-center'}>
        <div className=" inline-block h-2 w-2 rounded-full bg-[#F64B4B]" />
        <p className="ml-2 text-[#F64B4B]">NA</p>
      </div>
    )
  }

  if (days < 0) {
    return (
      <div className={className || 'flex items-center justify-center'}>
        <div className=" inline-block h-2 w-2 rounded-full bg-[#F64B4B]" />
        <p className="ml-2 text-[#F64B4B]">Passed</p>
      </div>
    )
  }

  if (days > 0 && days <= 7) {
    return (
      <div className={className || 'flex items-center justify-center'}>
        <div className=" inline-block h-2 w-2 rounded-full bg-[#F64B4B]" />
        <p className="ml-2 text-[#F64B4B]">
          {Math.round(days)} {days <= 1 && days >= 0 ? 'day' : 'days'} left
        </p>
      </div>
    )
  }

  if (days > 7 && days < 30) {
    return (
      <div className={className || 'flex items-center justify-center'}>
        <div className=" inline-block h-2 w-2 rounded-full bg-[#FC9B29]" />
        <p className="ml-2 text-[#FC9B29]">{Math.round(days)} days left</p>
      </div>
    )
  }

  if (days > 30 && days < 365) {
    return (
      <div className={className || 'flex items-center justify-center'}>
        <div className=" inline-block h-2 w-2 rounded-full bg-[#128807]" />
        <p className="ml-2 text-[#128807]">{Math.round(days)} days left</p>
      </div>
    )
  }

  return null
}
