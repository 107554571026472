interface Link {
  href: string
  label: string
  description?: string
}

export const allLinks: Link[] = [
  {
    description: 'Add ons',
    href: '/tm/addons',
    label: 'Addons',
  },
  {
    description: 'All Clients',
    href: '/tm/clients',
    label: 'All Clients',
  },
  {
    description: 'App Dev',
    href: '/tm/addons/app_dev',
    label: 'App Dev',
  },
  {
    description: 'Apparels',
    href: '/tm/addons/apparel',
    label: 'Apparels',
  },
  {
    description: 'Attorney Consultation',
    href: '/tm/addons/attorney_consultation',
    label: 'Attorney Consultation',
  },
  {
    description: 'Attorneys',
    href: '/tm/attorneys',
    label: 'Attorneys',
  },
  {
    description: 'Border Protection',
    href: '/tm/addons/border_protection',
    label: 'Border Protection',
  },
  {
    description: 'Client Management',
    href: '/tm/client_management',
    label: 'Client Management',
  },
  {
    description: 'Client tagging',
    href: '/tm/client_tagging',
    label: 'Client tagging',
  },
  {
    description: 'Coupons',
    href: '/tm/coupons',
    label: 'Coupons',
  },
  {
    description: 'Cron Dashboard',
    href: '/tm/cron_dashboard',
    label: 'Cron Dashboard',
  },
  {
    description: 'Digital Marketing',
    href: '/tm/addons/digital_marketing',
    label: 'Digital Marketing',
  },
  {
    description: 'Domain',
    href: '/tm/addons/domain',
    label: 'Domain',
  },
  {
    description: 'Incontestability',
    href: '/tm/addons/incontestability',
    label: 'Incontestability',
  },
  {
    description: 'Incorporate Business',
    href: '/tm/addons/incd_business',
    label: 'Incorporate Business',
  },
  {
    description: 'Insurance',
    href: '/tm/addons/insurance',
    label: 'Insurance',
  },
  {
    description: 'International Filing',
    href: '/tm/int_filing',
    label: 'International Filing',
  },
  {
    description: 'International TM Registration',
    href: '/tm/addons/international_tm_reg',
    label: 'International TM Registration',
  },
  {
    description: 'Leaderboard',
    href: '/tm/leaderboard',
    label: 'Leaderboard',
  },
  {
    description: 'Leads',
    href: '/tm/leads',
    label: 'Leads',
  },
  {
    description: 'Legal Forms',
    href: '/tm/addons/legal_forms',
    label: 'Legal Forms',
  },
  {
    description: 'Logo Design',
    href: '/tm/addons/logo_design',
    label: 'Logo Design',
  },
  {
    description: 'Content Manager',
    href: '/tm/content',
    label: 'Content Manager',
  },
  {
    description: 'Matter Admin',
    href: '/tm',
    label: 'Matter Admin',
  },
  {
    description: 'Matter size',
    href: '/tm/operations?type=matter',
    label: 'Matter size',
  },
  {
    description: 'Monitoring',
    href: '/tm/addons/monitoring',
    label: 'Monitoring',
  },
  {
    description: 'Offline Orders',
    href: '/tm/offline',
    label: 'Offline Orders',
  },
  {
    description: 'Paid Ads',
    href: '/tm/addons/paid_ads',
    label: 'Paid Ads',
  },
  {
    description: 'Patent',
    href: '/tm/addons/patent',
    label: 'Patent',
  },
  {
    description: 'Priority',
    href: '/tm/addons/priority',
    label: 'Priority',
  },
  {
    description: 'Privacy Guard',
    href: '/tm/addons/privacy_guard',
    label: 'Privacy Guard',
  },
  {
    description: 'Refunds',
    href: '/tm/operations?type=refunds',
    label: 'Refunds',
  },
  {
    description: 'Social Media',
    href: '/tm/addons/social_media',
    label: 'Social Media',
  },
  {
    description: 'Subscription Management',
    href: '/tm/operations?type=subscriptions',
    label: 'Subscription Management',
  },
  {
    description: 'Team Members',
    href: '/tm/teams/team_members',
    label: 'Team Members',
  },
  {
    description: 'Teams',
    href: '/tm/teams',
    label: 'Teams',
  },
  {
    description: 'US Filing',
    href: '/tm/us_filing',
    label: 'US Filing',
  },
  {
    description: 'Url management',
    href: '/tm/url_management',
    label: 'Url management',
  },
]
