import { createFetcher, PE_ROUTES } from '../../api'
import { Order } from './orders'

export const fetchPeClients = createFetcher<ClientsPe[]>({
  url: PE_ROUTES.CLIENTS.GET_ALL,
  withTokens: true,
})

export const fetchPeClient = createFetcher<ClientsPe>({
  url: PE_ROUTES.CLIENTS.GET_ONE,
  withTokens: true,
})

export const fetchPeClientOrders = createFetcher<{ orders: Order[] }>({
  url: PE_ROUTES.CLIENTS.GET_ALL_ORDERS,
  withTokens: true,
})

export const fetchPeClientTransactions = createFetcher<TransactionPe[]>({
  url: PE_ROUTES.CLIENTS.GET_ALL_TRANSACTIONS,
  withTokens: true,
})

export interface ClientsPe {
  id: string
  created_at: number
  address: string
  updated_on: number
  uid: string
  full_name: string
  email: string
  phone: string
  address_id?: string
  avatar: string
  last_logged_in: number
}

export interface TransactionPe {
  id: string
  matter_id: number
  created_at: string
  order_status: number
  payment_status: number
  payed_on: number
  billable_amount: number
  billable_amount_paid: number
  billable_govt_amount: number
  billable_govt_amount_paid: number
  item_info: ItemInfo[]
  transaction_info: TransactionInfo
  specimen_urls: any
}

export interface ItemInfo {
  id: string
  user_id: string
  order_id: string
  added_at: number
  billable_amount: number
  billable_amount_paid: number
  billable_amount_refunded: number
  billable_govt_amount: number
  billable_govt_amount_paid: number
  billable_govt_amount_refunded: number
  patent_type: string
  patent_application_type: string
  order_item_status: number
  payment_status: number
  prices_id: any
  pricing_ref: any
  is_offline_order: boolean
  offline_order_rep: any
  offline_order_created_by: any
  assignee_id: any
  attorney_order_disclosure: string
  attorney_order_drafting: string
  metadata: PatentMetadata
  specimens: any
  order_countries: PatentOrderCountry[]
}

export interface PatentMetadata {
  order_item_id: string
  name: string
  invention_description: string
  patent_application_type: string
  attorney_meet_slot: string
  want_attorney_review: boolean
  priority: boolean
  priority_application_numbers: any
  offering_date: number
  application_date: number
  publication_date: number
  main_problem: string
  solution: string
  previous_solution: string
  invention_details: string
  entity_status: string
}

export interface PatentOrderCountry {
  country_code: string
  country_name: string
}

export interface TransactionInfo {
  transaction: {
    id: string
    transaction_hash: string
    created_at: number
    updated_on: number
    transaction_type: string
    amount: number
    status: number
    address_id: string
    metadata: any
    order_id: string
  }
}
