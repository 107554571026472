import { createFetcher, ROUTES } from '../api'
import { ClassClassification, UsTmProcessing } from '../interfaces'

export const fetchTMOrder = createFetcher<
  TMOrder,
  any,
  { order_item_id: string }
>({
  url: ROUTES.TMORDER.GET_ONE,
  withTokens: true,
})

export const fetchTMOrderUpdateMatter = createFetcher({
  url: ROUTES.TMORDER.UPDATE_MATTER,
  withTokens: true,
  method: 'PATCH',
})

export interface TMOrder extends outDatedTMOrder {
  order_item_id: string
  created_at: number
  is_name_protected: boolean
  mark_name: string
  is_slogan_protected: boolean
  name_description: string
  slogan_description: string
  slogan: string
  description: string
  updated_on: number
  classes: number
  prices_id: string
  is_logo_protected: boolean
  search_terms: string[]
  type_of_protection: string
  protection_term: string
  logo_url: string
  logo_description: string
  is_sound_protected: boolean
  sound_url: string
  is_attorney_review_requested?: boolean
  class_classifications: ClassClassification[]
  package: string
  is_assigned: boolean
}

export interface outDatedTMOrder {
  is_trademark_already_using: boolean
  is_good_protected: boolean
  website: string
  is_step_1_completed: boolean
  is_step_2_completed: boolean
  is_step_3_completed: boolean
  is_step_4_completed: boolean
  is_service_protected: boolean
  good_description: string
  service_description: string
  tm_order_entity_info: EntityInfo
}

export interface EntityInfo {
  authorised_person_name: string
  city: string
  entity_name: string
  entity_type: string
  state: string
  state_of_formation: string
  street_address: string
  tm_order_id: string
  zip: string
}
