import { useRef, useEffect } from 'react'

export const CHECKED = 1
export const UNCHECKED = 2
export const INDETERMINATE = -1

interface Props {
  value?: number | string | boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  checked?: boolean
}

/**
 *
 * @param {number} value - 1 for checked, 2 for unchecked, -1 for indeterminate
 * @param {function} onChange - onChange function
 * @param {string} className - className
 * @returns checkbox
 */

const CheckboxV2 = ({ value, onChange, className, checked }: Props) => {
  const checkRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (checkRef.current) {
      checkRef.current.indeterminate = value === INDETERMINATE

      checkRef.current.checked = value === CHECKED
      checkRef.current.indeterminate = value === INDETERMINATE
    }
  }, [value])

  return (
    <input
      type="checkbox"
      ref={checkRef}
      checked={checked}
      onChange={onChange}
      className={['form-check-input', className].join(' ')}
    />
  )
}

export default CheckboxV2
