import {
  BreakingNewsInterface,
  SearchData,
} from '../../../apps/admin-side/interfaces/Search'
import { createFetcher, ROUTES } from '../api'
import { AddonDataItem } from '../interfaces'
import { TrademarkDetails } from './renewal'

export const fetchTrademarkBySerialNo = createFetcher<
  TrademarkDetails,
  any,
  { country: string; serialNumber: string }
>({
  url: ROUTES.SEARCH.TRADEMARK_BY_SERIAL,
  method: 'GET',
})

export const fetchSearchStep = createFetcher<AddonDataItem[]>({
  url: ROUTES.SEARCH.FETCH_STEP,
  method: 'GET',
  withTokens: true,
})

export const fetchSearchMoveStep = createFetcher<null>({
  url: ROUTES.SEARCH.MOVE_STEP,
  method: 'PATCH',
  withTokens: true,
})

export const fetchSearchUpdateMatter = createFetcher({
  url: ROUTES.SEARCH.UPDATE_MATTER,
  withTokens: true,
})

export const fetchBasicSearchData = createFetcher<SearchData>({
  url: ROUTES.SEARCH.BASIC_SEARCH,
})

export const fetchArrayOfVisibities = createFetcher<Visibility[]>({
  url: ROUTES.SEARCH.ARRAY_OF_VISIBILITIES,
  withTokens: true,
})

export const fetchSearchToggleVisibility = createFetcher({
  url: ROUTES.SEARCH.TOGGLE_VISIBILITY,
  withTokens: true,
})

export const fetchSearchTTAB = createFetcher<TTABBODY>({
  url: ROUTES.SEARCH.TTAB_SEARCH,
  withTokens: true,
})

export const fetchSearchProblematicClients = createFetcher<ProblematicClient[]>(
  {
    url: ROUTES.SEARCH.PROBLEMATIC_CLIENT_SEARCH,
    withTokens: true,
  },
)

export const fetchSearchProblematicClientsByUserId = createFetcher<
  ProblematicClient,
  any,
  { user_id: string }
>({
  url: ROUTES.SEARCH.PROBLEMATIC_CLIENT_SEARCH_BY_USER_ID,
  withTokens: true,
})

export const fetchUnfiledMattersSearch = createFetcher<UnfiledMatterSearch[]>({
  url: ROUTES.SEARCH.UNFILED_MATTERS,
  withTokens: true,
})

export const fetchCronDashboard = createFetcher<
  CronDashboardBody,
  any,
  any,
  {
    page: number
    country: string
  }
>({
  url: ROUTES.DASHBOARD.GET_FILES,
  withTokens: true,
})

export const fetchBasicElasticSearch = createFetcher<
  BasicElasticSearchResponse,
  SearchQueries
>({
  method: 'POST',
  url: ROUTES.SEARCH.BASIC_ELASTIC_SEARCH,
})

export interface SearchQueries {
  input_query: string
  date_query: boolean
  registration_date: any[]
  filing_date: number[]
  status_date: any[]
  owners: any[]
  attorneys: any[]
  law_firms: any[]
  status: string[]
  classes: string[]
  page: number
  is_search_report: boolean
  search_report: string[]
}

export interface BasicElasticSearchResponse {
  data: BreakingNewsInterface[]
  facets: ElasticFacet[]
  total_found: number
  total_pages: number
}
export interface ElasticFacet {
  field: string
  buckets: Bucket[]
}

export interface Bucket {
  doc_count: number
  key: string
}

export interface CronDashboardBody {
  files: File[]
  next_page: boolean
}

export interface File {
  ID: string
  created_at: string
  updated_at: string
  deleted_at: any
  step1_Download: boolean
  step1_process: boolean
  step1_done: boolean
  step2_Download: boolean
  step2_process: boolean
  step2_done: boolean
  file_name: string
  s3_uri: string
  country: string
}

export interface ProblematicClient {
  id: string
  user_id: string
  client_name: string
  client_email: string
  client_mark: string
  matter_type: string
  serial_number: string
  notes: string
  added_by: string
  created_at: number
}

interface Visibility {
  serial_number: number
  is_visible: boolean
}

export interface TTABBODY {
  data: TTABSearch[]
  facets: any
  total_found: number
  total_pages: number
}

export interface TTABSearch {
  _version_: number
  def_id: string[]
  defendant: string[]
  defendant_text: string[]
  filing_date: number[]
  id: string
  mark_texts?: string[]
  plaintiff: string[]
  plaintiff_id: string[]
  plaintiff_text: string[]
  registration_numbers: string[]
  serial_numbers: string[]
  serial_numbers_int: number[]
  status_code: number[]
  status_update_date: number[]
  type_code: string[]
}

export interface UnfiledMatterSearch {
  order_item_id: string
  created_at: number
  updated_on: number
  classes: number
  prices_id: string
  is_name_protected: boolean
  mark_name: string
  logo_description: string
  is_sound_protected: boolean
  sound_url: string
  is_attorney_review_requested?: boolean
  class_classifications: UnfiledClassClassification[]
  package: string
  is_assigned: boolean
  search_terms?: string[]
  us_tm_processing: UnfiledUsTmProcessing
  description?: string
}

export interface UnfiledClassClassification {
  id: string
  tm_order_id: string
  trademark_classification_class_id: string
  description: string
  is_being_used_to_sell: boolean
  first_use_date_com: number
  first_use_date_any: number
  proof: any
  is_filed_in_any_country: boolean
  application_number: string
  filing_country: string
  date_of_filing: number
  is_saved: boolean
  is_attorney_recommended: boolean
}

export interface UnfiledUsTmProcessing {
  tm_order_id: string
  created_at: number
  classes_paid_count: number
  total_classes: number
  attorney_id: string
}
