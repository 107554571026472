// eslint-disable-next-line import/no-extraneous-dependencies
import { Price } from 'api'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'

interface Props {
  suggestions: string[]
  className: string
  formikName?: string
}
const Pricing = () => ({
  SearchServiceName: '',
  Search: '',
  SelectRegion: {} as Price,
  Currency: [{ curr: 'US Dollars ($)' }],
  CurrencySelected: '',
})

type PricingType = ReturnType<typeof Pricing>

export const AutoComplete = ({ suggestions, className, formikName }: Props) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([])
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [input, setInput] = useState('')
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setFilteredSuggestions(
      suggestions.filter(
        suggestion =>
          suggestion.toLowerCase().indexOf(input.toLowerCase()) > -1,
      ),
    )
  }, [suggestions, input])

  const { setFieldValue } = useFormikContext<PricingType>()

  const onChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    // Filter our suggestions that don't contain the user's input
    // const unLinked = suggestions.filter(
    //   suggestion =>
    //     suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
    // )
    if (formikName) {
      setFieldValue('Search', e.target.value)
    }

    setInput(e.target.value)
    // setFilteredSuggestions(unLinked)
    setActiveSuggestionIndex(0)
    setShowSuggestions(true)
    setOpen(true)
  }

  const onClick: React.MouseEventHandler<HTMLAnchorElement> = e => {
    const inputClick = e.target as HTMLElement
    // setFilteredSuggestions([])
    setInput(inputClick.innerText)
    setActiveSuggestionIndex(0)
    setShowSuggestions(false)
    setOpen(false)
    if (formikName) setFieldValue(formikName, inputClick.innerText)
  }

  const onKeyDown = (key: any) => {
    /**
     * The first condition checks if the user’s input matches keyCode 13,
     * the enter key. If it does, it runs the setInput method to add the user’s
     * input and close the suggestions list.
     */

    if (key.keyCode === 13 || key.keyCode === 9) {
      setInput(filteredSuggestions[activeSuggestionIndex])
      setFilteredSuggestions([])
      if (formikName)
        setFieldValue(formikName, filteredSuggestions[activeSuggestionIndex])
      setOpen(false)
    }

    if (key.keyCode === 38) {
      if (activeSuggestionIndex === 0) {
        return
      }
      setActiveSuggestionIndex(activeSuggestionIndex - 1)
    }
    if (key.keyCode === 40) {
      if (activeSuggestionIndex + 1 === filteredSuggestions.length) {
        return
      }
      setActiveSuggestionIndex(activeSuggestionIndex + 1)
    }
  }

  const SuggestionsListComponent = React.memo(() =>
    filteredSuggestions.length ? (
      <ul className="mt-0 w-[150%]  list-none overflow-y-auto rounded-lg border border-gray-300 bg-white pl-0 shadow-md">
        {filteredSuggestions.map((suggestion, index) => (
          <a href="#" onClick={onClick}>
            <li
              className={`${
                index === activeSuggestionIndex
                  ? 'bg-primary hover:bg-primary-300 cursor-pointer p-4 font-semibold text-white'
                  : 'border-gray-300 p-4 text-black last:border-b-2'
              }`}
              key={suggestion}
            >
              {suggestion}
            </li>
          </a>
        ))}
      </ul>
    ) : (
      <div className="p-2 text-gray-500">
        <em>No suggestions, you&apos;re on your own!</em>
      </div>
    ),
  )

  return (
    <div className="relative flex flex-col justify-center">
      <input
        type="text"
        onFocus={() => setOpen(true)}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={input}
        className={`rounded-lg border border-gray-300 p-2 ${className}`}
      />
      <div className="absolute top-14 ">
        {showSuggestions && input && open && <SuggestionsListComponent />}
      </div>
    </div>
  )
}
