import { datadogRum } from '@datadog/browser-rum'
import { createTheme, ThemeProvider } from '@mui/material'
import tailwindConfig from 'config/tailwind.config'
import { useBrand } from 'hooks'
import { GetServerSideProps } from 'next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import 'nprogress/nprogress.css'
import { FC, useCallback } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import 'ui/tailwind.css'
import ErrorBoundary from '../components/ErrorBoundary'
import Layout from '../components/Layout'
import { isLocal } from '../constants/env'
import { AccessProvider } from '../context/access'
import { ConstantsProvider } from '../context/constants'
import { PeConstantsProvider } from '../context/peConstants'
import { UserProvider } from '../context/user'
import { VonageProvider } from '../context/vonage'
import '../styles/globals.css'

toast.configure({ theme: 'colored' })

const TopProgressBar = dynamic(() => import('../components/TopProgressBar'), {
  ssr: false,
})

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { brand } = useBrand()
  const Providers: FC = useCallback(
    ({ children }) =>
      brand === 'tm' ? (
        <ConstantsProvider>{children}</ConstantsProvider>
      ) : (
        <PeConstantsProvider>{children}</PeConstantsProvider>
      ),
    [brand],
  )

  const theme = createTheme({
    palette: {
      primary: {
        main: tailwindConfig.theme.extend.colors.primary.DEFAULT,
      },
      secondary: {
        main: tailwindConfig.theme.extend.colors.secondary.DEFAULT,
      },
    },
    typography: {
      fontFamily: tailwindConfig.theme.fontFamily.sans[0],
    },
  })

  return (
    <Providers>
      <ThemeProvider theme={theme}>
        <VonageProvider>
          <UserProvider>
            <AccessProvider>
              <Layout>
                <TopProgressBar />
                <ErrorBoundary>
                  <Component {...pageProps} />
                </ErrorBoundary>
              </Layout>
            </AccessProvider>
          </UserProvider>
        </VonageProvider>
      </ThemeProvider>
    </Providers>
  )
}

export default MyApp

export const getServerSideProps: GetServerSideProps = async context => ({
  props: context.query,
})

const initDatadog = () => {
  // Initialize the Datadog RUM module
  if (isLocal) return
  const sampleRate = process.env.NEXT_PUBLIC_ENV === 'staging' ? 20 : 70
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID as string,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
    site: 'us5.datadoghq.com',
    service: 'tm-admin',
    env: process.env.NEXT_DEV_ENV,
    sessionSampleRate: sampleRate,
    premiumSampleRate: sampleRate,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    enableExperimentalFeatures: ['clickmap'],
    // Add any additional configuration options here.
    allowedTracingUrls: [
      // IMAGE DOMAINS
      'staging-api.trademarkia.app',
      'api.trademarkia.com',
      'staging-api.trademarkia.app',
      // API DOMAINS
      /https:\/\/.*\.trademarkia\..+\/.*/,
    ],
  })

  datadogRum.startSessionReplayRecording()
}

initDatadog()
