import { createFetcher, ROUTES } from '../api'

export const fetchAllManagedClients = createFetcher<ManagedClient[]>({
  url: ROUTES.CLIENT_MANAGEMENT.GET_ALL,
  withTokens: true,
})

export const fetchAllDashboardManagers = createFetcher<DashboardAdmin[]>({
  url: ROUTES.CLIENT_MANAGEMENT.GET_DASHBOARD_ADMINS,
  withTokens: true,
})
export const fetchDashboardMyManager = createFetcher<DashboardAdmin, any>({
  url: ROUTES.CLIENT_MANAGEMENT.GET_MY_DASHBOARD,
  withTokens: true,
})

export const fetchTempClaimClient = createFetcher<
  any,
  any,
  { user_id: string }
>({
  url: ROUTES.CLIENT_MANAGEMENT.TEMP_CLAIM,
  method: 'PATCH',
  withTokens: true,
})

export const fetchUpdateClient = createFetcher<
  {
    is_dismissed: boolean
    is_unresponsive: boolean
  },
  any,
  { user_id: string }
>({
  url: ROUTES.CLIENT_MANAGEMENT.UPDATE_CLIENT,
  method: 'PATCH',
  withTokens: true,
})

export const fetchPhoneCheckIn = createFetcher<any, any, { user_id: string }>({
  url: ROUTES.CLIENT_MANAGEMENT.PHONE_CHECK_IN,
  withTokens: true,
})

export const fetchCheckInClient = createFetcher<
  any,
  any,
  any,
  {
    admin_email: string
    user_email: string
    ref_id: string
  }
>({
  url: ROUTES.CLIENT_MANAGEMENT.CHECK_IN,
  withTokens: true,
})

export interface ManagedClient {
  additional_emails: string[]
  user_id: string
  full_name: string
  email: string
  phone: string
  country_code: string
  created_at: number
  updated_on: number
  is_agent_account: boolean
  previously_claimed_by_id: any
  temp_claim_id: string
  last_check_in_at: number
  total_orders: number
  total_revenue: number
  classifications: any[]
  is_dismissed: boolean
  is_un_responsive: boolean
  admin_id: string
  admin_name: string
  check_in_count: number
}

export interface DashboardAdmin {
  admin_id: string
  client_count: number
  admin_full_name: string
  total_check_ins: number
  total_expiring: number
}
