// this is the vonage context file, with this you can call the vonage api from anywhere in the app

import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

declare global {
  interface Window {
    VonageDialer: any
  }
}

// require the SDK when window is available
if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('@vgip/vonage-dialer-sdk')
}

interface Props {
  showContactPad: boolean
  setShowContactPad: (show: boolean) => void
  vonageLoaded: boolean
}

export const VonageContext = createContext<Props>({
  showContactPad: false,
  setShowContactPad: () => {},
  vonageLoaded: false,
})

export const useVonage = () => useContext(VonageContext)

export const VonageProvider: FC = ({ children, ...props }) => {
  const [vonageLoaded, setVonageLoaded] = useState(false)
  const [showContactPad, setShowContactPad] = useState(false)

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     setVonageDialer(window.VonageDialer)
  //   }
  // }, [])

  useEffect(() => {
    const script = document.createElement('script')
    script.src =
      'https://apps.gunify-dev.vonage.com/cti/common/vonage.dialer.sdk.js'
    script.type = 'text/javascript'
    script.async = true
    document.body.appendChild(script)
    script.onload = () => {
      setVonageLoaded(true)
    }
  }, [])

  const value = useMemo(
    () => ({
      showContactPad,
      setShowContactPad,
      vonageLoaded,
    }),
    [showContactPad, setShowContactPad, vonageLoaded],
  )

  return (
    <VonageContext.Provider value={value} {...props}>
      <>
        {children}
        <div
          className="vonage-dialer-container"
          style={{
            width: '280px',
            height: '460px',
            position: 'absolute',
            bottom: '0px',
            opacity: showContactPad ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
            pointerEvents: showContactPad ? 'all' : 'none',
            zIndex: 9999,
          }}
        />
      </>
    </VonageContext.Provider>
  )
}
