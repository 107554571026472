import { createFetcher, ROUTES } from '../api'

export const fetchCouponsByAdmin = createFetcher<Coupon[], any>({
  url: ROUTES.COUPONS.GET_BY_ADMIN,
  withTokens: true,
})

export const fetchCreateCoupon = createFetcher<null, CouponQuery>({
  url: ROUTES.COUPONS.CREATE,
  method: 'POST',
  withTokens: true,
})

export const fetchUpdateCoupon = createFetcher<null, CouponQuery>({
  url: ROUTES.COUPONS.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

export const fetchApplyCoupon = createFetcher<
  null,
  {
    order_id: string
    coupon_code: string
  }
>({
  url: ROUTES.COUPONS.APPLY,
  method: 'POST',
})

export interface CouponQuery {
  coupon_code: string
  expiry_date: number
  order_item_type: string[]
  coupon_type: number
  fixed_discount?: number
  percentage_diccount?: number
  minimum_order_amount: number
  is_active: boolean
  max_use_count: number
  is_valid_all_countries: boolean
  countries?: string[]
  maximum_discount?: number
  description?: string
}

export interface Coupon {
  id: string
  admin_id: string
  coupon_code: string
  expiry_date: number
  description: string
  order_item_type: string[]
  coupon_type: number
  fixed_discount: number
  percentage_discount: number
  minimum_order_amount: number
  maximum_discount: number
  is_active: boolean
  max_use_count: number
  is_valid_all_countries: boolean
  countries?: any
  created_at: number
  updated_at: number
}
