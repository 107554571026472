import { ComponentProps, Dispatch, SetStateAction } from 'react'
import { accessibleOnClick } from 'utils'

interface Tab {
  icon: (props: ComponentProps<'svg'>) => JSX.Element
  title: string
}

interface Props {
  tabs: Tab[]
  current: string
  setCurrent: Dispatch<SetStateAction<string>>
}

export const Tabs = ({ tabs, current, setCurrent }: Props) => (
  // const parentRef = useRef<HTMLDivElement>(null)
  <div className="relative flex items-center">
    {tabs.map(({ icon: Icon, title }) => (
      <div
        className={[
          'text-h5 mr-7 flex cursor-pointer items-center font-bold',
          current === title ? 'text-primary' : 'text-gray-400',
        ].join(' ')}
        key={title}
        {...accessibleOnClick(() => setCurrent(title))}
      >
        <Icon className="mr-2 h-5 w-5" />
        <p>{title}</p>
      </div>
    ))}
    <div className="absolute bottom-0 h-1" />
  </div>
)
