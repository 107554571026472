import { createFetcher, ROUTES } from '../api'
import { Matter } from './matters'

export const fetchUsAttorneys = createFetcher<Attorneys[]>({
  url: ROUTES.ATTORNEYS.GET_ALL,
  withTokens: true,
})

export const fetchUsAttorney = createFetcher<Attorneys>({
  url: ROUTES.ATTORNEYS.GET_ONE,
  withTokens: true,
})

export const createUsAttorney = createFetcher<Attorneys>({
  url: ROUTES.ATTORNEYS.CREATE,
  withTokens: true,
  method: 'POST',
})

export const updateUsAttorney = createFetcher<Attorneys>({
  url: ROUTES.ATTORNEYS.UPDATE,
  withTokens: true,
  method: 'PATCH',
})
export const disableUsAttorney = createFetcher<Attorneys>({
  url: ROUTES.ATTORNEYS.DISABLE,
  withTokens: true,
  method: 'PATCH',
})

/**
 * @description for uploading new image send full_name, ext, file in form-data
 * @description for updating attorney image send id, file in form-data - here id is attorney id
 */

export const fetchUploadImageUsAttorney = createFetcher<
  { key: string; url: string },
  FormData
>({
  url: ROUTES.UPLOADER.UPLOAD_ATTORNEY_PHOTO,
  method: 'POST',
  isFormData: true,
  withTokens: true,
})

export interface Attorneys {
  admin_id: string
  admin: any
  full_name: string
  photo: string
  phone: string
  email: string
  address_id: string
  address: AttroneyAddress
  qualifications: string
  university: string
  languages: string[]
  practice_statement: string
  practice_area: string[]
  licensed_state: string[]
  priority: number
  rating: number
  is_disabled: boolean
}

export interface AttroneyAddress {
  id: string
  line_1: string
  line_2: string
  city: string
  state: string
  country: string
  postal_code: string
}
