import Image from 'next/image'
import React, { FC, SVGProps } from 'react'

interface Props {
  icon?: string | FC<SVGProps<SVGSVGElement>>
  title?: string
  desc?: string
  onlyDesc?: boolean
}

export const HoverInfo = ({ icon: Icon = '', title, desc, onlyDesc }: Props) =>
  !onlyDesc ? (
    <div className="border-primary grid w-[450px] grid-cols-7 rounded-lg border bg-[#F9F7FF] p-6">
      <div className=" relative  col-span-2 my-auto  mx-3 h-16 w-16">
        {typeof Icon === 'string' ? (
          <Image unoptimized 
            objectFit="contain"
            layout="fill"
            src={Icon}
            alt="trademarkia logo"
          />
        ) : (
          <Icon fill="rgb(113 97 197)" height={80} width={80} />
        )}
      </div>
      <div className=" text-primary col-span-5 my-auto text-sm">
        <span className="font-bold">{title}</span> {desc}
      </div>
    </div>
  ) : (
    <div className="border-primary grid w-[550px] rounded-lg border bg-[#F9F7FF] p-6">
      <div className=" text-primary my-auto mb-2 text-sm font-semibold">
        {title}
      </div>
      <div className=" text-primary my-auto text-sm font-medium">{desc}</div>
    </div>
  )
