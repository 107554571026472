import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { fetchProfile, updateGmailAccess } from 'api'
import { onAuthStateChanged, User } from 'firebase/auth'
import { toast } from 'react-toastify'
import { useAsyncMemo } from 'hooks'

import { auth, logout } from '../config/firebase'
import { Profile } from '../interfaces'

interface Props {
  user: User | null | undefined
  profile: Profile | null | undefined
}

const UserContext = createContext<Props>({
  user: undefined,
  profile: undefined,
})

export const useUser = () => useContext(UserContext)

export const UserProvider: FC = props => {
  const [user, setUser] = useState<User | null | undefined>(undefined)

  useEffect(() => {
    onAuthStateChanged(auth, u => setUser(u || null))
  }, [setUser])

  const [profile] = useAsyncMemo<Profile | undefined>(
    async () => {
      if (user === undefined) return undefined
      const idToken = await user?.getIdToken()
      const refreshToken = user?.refreshToken
      if (idToken && refreshToken && user.email)
        updateGmailAccess({
          access_token: idToken,
          email: user.email,
          refresh_token: refreshToken,
        })
      if (!idToken) return null
      const { body, error } = await fetchProfile({ id_token: idToken })
      const { msg } = error?.data || {}
      toast.error(msg === 'not found' ? 'You do not have admin access' : msg)
      if (error) logout()
      return body?.profile || null
    },
    [user],
    { defaultValue: undefined },
  )

  const value = useMemo(
    () => ({
      user,
      profile,
    }),
    [user, profile],
  )

  return <UserContext.Provider {...props} value={value} />
}
