import axios from 'axios'

export const getColorNames = async (hexes: string[]): Promise<ColorProps[]> => {
  const { data } = await axios.get('https://api.color.pizza/v1/', {
    params: { values: hexes.map(hex => hex.replace('#', '')).join(',') },
  })
  return data.colors
    .filter(
      (value: { name: any }, index: any, self: any[]) =>
        index === self.findIndex(t => t.name === value.name),
    )
    .map((c: ColorProps) => ({
      ...c,
      hex: c.requestedHex,
    }))
}

export interface ColorProps {
  name: string
  requestedHex: string
  hex: string
  hsl: { h: number; s: number; l: number }
}
