import { ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
  icon: (props: ComponentProps<'svg'>) => JSX.Element
  variant?: 'primary' | 'secondary'
  pointer?: boolean
}

const colors = {
  primary: 'text-primary',
  secondary: 'text-secondary',
}

export const Icon = ({
  icon: I,
  className,
  variant,
  pointer,
  ...props
}: Props) => (
  <I
    {...props}
    className={[
      'h-4 w-4',
      pointer ? 'cursor-pointer' : '',
      variant ? colors[variant] : '',
      className,
    ].join(' ')}
  />
)

