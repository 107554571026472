/* eslint-disable jsx-a11y/label-has-associated-control */
import { DetailedHTMLProps, InputHTMLAttributes } from 'react'
import { Icon } from './Icon'
import { ActionProps } from './interfaces'

type HTMLInput<Element> = DetailedHTMLProps<
  InputHTMLAttributes<Element>,
  Element
>

interface InputProps<Element = HTMLInputElement | HTMLTextAreaElement>
  extends HTMLInput<Element> {
  label?: string
  // eslint-disable-next-line react/no-unused-prop-types
  hint?: string
  error?: string
}

interface TextFieldProps extends InputProps {
  multiline?: boolean
  action1?: ActionProps
  action2?: ActionProps
  variant?: 1 | 2
}

export const TextField = ({
  label,
  placeholder,
  multiline,
  error,
  hint,
  value,
  onChange,
  action1,
  action2,
  type,
  variant = 1,
  className,
}: TextFieldProps) => {
  const Inp = multiline ? 'textarea' : 'input'
  return (
    <div className={['mb-3 flex flex-col', className].join(' ')}>
      {label && (
        <label htmlFor="label" className="text-h5 font-bold">
          {label}
        </label>
      )}
      <div
        className={[
          'border-1 my-1 flex items-center rounded-md border',
          error ? 'border-error' : 'border-grey-300',
          variant === 2 ? 'bg-white' : '',
        ].join(' ')}
      >
        {action1 && (
          <Icon
            {...action1}
            className={['ml-3', action1.className].join(' ')}
          />
        )}
        <Inp
          placeholder={placeholder}
          className={[
            'text-p2 flex-1 border border-[#c5c5c5] bg-transparent px-3 py-2 focus:outline-none',
            multiline ? 'h-7' : '',
          ].join(' ')}
          type={type}
          value={value}
          onChange={onChange}
        />
        {action2 && (
          <Icon
            {...action2}
            className={['mr-3', action2.className].join(' ')}
          />
        )}
      </div>
      {hint && !error && <p className="text-cap">{hint}</p>}
      {error && <p className="text-cap text-error">{error}</p>}
    </div>
  )
}

interface OptionProps {
  label?: string | number
  value: string | number
  disabled?: boolean
}

interface SelectProps extends InputProps<HTMLSelectElement> {
  options: (OptionProps | string)[]
  variant?: 1 | 2
  labelClassName?: string
  mainClassName?: string
  defaultValue?: string | number
  addEmpty?: boolean
}

export const Select = ({
  label,
  error,
  options,
  value,
  labelClassName,
  mainClassName,
  defaultValue,
  onChange,
  variant = 1,
  addEmpty,
}: SelectProps) => (
  <div className={['flex flex-col', mainClassName].join(' ')}>
    {label && (
      <label
        className={`mb-2 text-sm font-semibold ${labelClassName}`}
        htmlFor={label?.toLowerCase().replace(' ', '_')}
      >
        {label}
      </label>
    )}
    <div
      className={[
        'border-1 my-1 flex items-center rounded-md ',
        error ? 'border-error' : 'border-grey-300',
        variant === 2 ? 'bg-white' : 'border',
      ].join(' ')}
    >
      <select
        className="flex-1 cursor-pointer border-0  bg-transparent font-semibold text-[#4E4E4E] focus:border-0 focus:shadow-none"
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        name=""
        id=""
      >
        {addEmpty && (
          <option disabled value="">
            Select
          </option>
        )}
        {options.map(o =>
          typeof o === 'string' ? (
            <option value={o}>{o}</option>
          ) : (
            <option disabled={o.disabled} value={o.value} selected={o.disabled}>
              {o.label || o.value}
            </option>
          ),
        )}
      </select>
    </div>
  </div>
)
