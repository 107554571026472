import React, { FC } from 'react'

interface StatusCodes {
  Code: string
  Status: string
  Description: string
}

const data: StatusCodes[] = [
  {
    Code: '000',
    Status: 'Indifferent',
    Description: 'UNKNOWN',
  },
  {
    Code: '400',
    Status: 'Dead/Cancelled',
    Description: 'IR CANCELLED; APPLICATION PENDING TRANSFORMATION',
  },
  {
    Code: '401',
    Status: 'Dead/Cancelled',
    Description: 'IR CANCELLED - NO TRANSFORMATION FILED',
  },
  {
    Code: '402',
    Status: 'Dead/Abandoned',
    Description: 'IR CANCELLED; US APPLICATION ABANDONED',
  },
  {
    Code: '403',
    Status: 'Dead/Cancelled',
    Description: 'IR CANCELLED; REGISTRATION PENDING TRANSFORMATION',
  },
  {
    Code: '404',
    Status: 'Dead/Cancelled',
    Description: 'IR CANCELLED; US REGISTRATION CANCELLED',
  },
  {
    Code: '405',
    Status: 'Dead/Cancelled',
    Description: 'IR CANCELED; APPLICATION TRANSFRMED',
  },
  {
    Code: '406',
    Status: 'Dead/Cancelled',
    Description: 'IR CANCELLED; REGISTRATION TRANSFORMED',
  },
  {
    Code: '410',
    Status: 'Live/Pending',
    Description: 'IR RESTRICTED; US APP.  ABANDONED, PENDING TRANSFORMATION',
  },
  {
    Code: '411',
    Status: 'Dead/Abandoned',
    Description: 'IR RESTRICTED; US APP. ABANDONED; NO TRANSFORMATION FILED',
  },
  {
    Code: '412',
    Status: 'Dead/Abandoned',
    Description: 'IR RESTRICTED; US APPLICATION ABANDONED',
  },
  {
    Code: '413',
    Status: 'Live/Pending',
    Description: 'IR RESTRICTED; US REG. CANCELLED PEND TRANSFORMATION',
  },
  {
    Code: '414',
    Status: 'Dead/Cancelled',
    Description: 'IR RESTRICTED; US REG. CANCELLED; NO TRANSFORMATION FILED',
  },
  {
    Code: '415',
    Status: 'Dead/Cancelled',
    Description: 'IR RESTRICTED; US REGISTRATION CANCELLED',
  },
  {
    Code: '416',
    Status: 'Dead/Abandoned',
    Description: 'IR RESTRICTED; US APP ABANDONED; US APP TRANSFORMED',
  },
  {
    Code: '417',
    Status: 'Dead/Cancelled',
    Description: 'IR RESTRICTED; US REG CANCELLED; US REG TRANSFORMED',
  },
  {
    Code: '600',
    Status: 'Dead/Abandoned',
    Description: 'ABANDONED - INCOMPLETE RESPONSE',
  },
  {
    Code: '601',
    Status: 'Dead/Abandoned',
    Description: 'ABANDONED - EXPRESS',
  },
  {
    Code: '602',
    Status: 'Dead/Abandoned',
    Description: 'ABANDONED-FAILURE TO RESPOND OR LATE RESPONSE',
  },
  {
    Code: '603',
    Status: 'Dead/Abandoned',
    Description: 'ABANDONED - AFTER EX PARTE APPEAL',
  },
  {
    Code: '604',
    Status: 'Dead/Abandoned',
    Description: 'ABANDONED - AFTER INTER-PARTES DECISION',
  },
  {
    Code: '605',
    Status: 'Dead/Abandoned',
    Description: 'ABANDONED – EXPRESS AFTER PUB',
  },
  {
    Code: '606',
    Status: 'Dead/Abandoned',
    Description: 'ABANDONED - NO STATEMENT OF USE FILED',
  },
  {
    Code: '607',
    Status: 'Dead/Abandoned',
    Description: 'ABANDONED - DEFECTIVE STATEMENT OF USE',
  },
  {
    Code: '608',
    Status: 'Dead/Abandoned',
    Description: 'ABANDONED - AFTER PETITION DECISION',
  },
  {
    Code: '609',
    Status: 'Dead/Abandoned',
    Description: 'ABANDONED - DEFECTIVE DIVIDED APPLICATION',
  },
  {
    Code: '610',
    Status: 'Dead',
    Description: 'TERMINATED AFTER SANCTIONS',
  },
  {
    Code: '612',
    Status: 'Dead/Abandoned',
    Description: 'PETITION TO REVIVE-RECEIVED',
  },
  {
    Code: '614',
    Status: 'Dead/Abandoned',
    Description: 'ABANDONED PETITION TO REVIVE-DENIED',
  },
  {
    Code: '616',
    Status: 'Live/Pending',
    Description: 'REVIVED - AWAITING FURTHER ACTION',
  },
  {
    Code: '618',
    Status: 'Dead/Abandoned',
    Description: 'ABANDONED FILE - BACKFILE',
  },
  {
    Code: '620',
    Status: 'Live/Pending',
    Description:
      'BACKFILE APPLICATION ADDED TO DATA BASE - STATUS NOT RECORDED',
  },
  {
    Code: '622',
    Status: 'Indifferent',
    Description: 'MISASSIGNED SERIAL NUMBER',
  },
  {
    Code: '624',
    Status: 'Live/Registered',
    Description: 'REGISTERED - BACKFILE',
  },
  {
    Code: '625',
    Status: 'Live/Registered',
    Description: 'REGISTRATION ADDED TO THE DATA BASE-STATUS UNCLEAR',
  },
  {
    Code: '626',
    Status: 'Dead/Cancelled',
    Description: 'REGISTERED - BACKFILE CANCELLED OR EXPIRED',
  },
  {
    Code: '630',
    Status: 'Live/Pending',
    Description:
      'NEW APPLICATION - RECORD INITIALIZED NOT ASSIGNED TO EXAMINER',
  },
  {
    Code: '631',
    Status: 'Live/Pending',
    Description: 'NEW APPLICATION - DIVIDED - INITIAL PROCESSING',
  },
  {
    Code: '632',
    Status: 'Dead',
    Description: 'INFORMAL APPLICATION',
  },
  {
    Code: '638',
    Status: 'Live/Pending',
    Description: 'NEW APPLICATION - ASSIGNED TO EXAMINER',
  },
  {
    Code: '640',
    Status: 'Live/Pending',
    Description: 'NON-FINAL ACTION COUNTED - NOT MAILED',
  },
  {
    Code: '641',
    Status: 'Live/Pending',
    Description: 'NON-FINAL ACTION - MAILED',
  },
  {
    Code: '642',
    Status: 'Live/Pending',
    Description: 'INVENTORIED AS REJECTED',
  },
  {
    Code: '643',
    Status: 'Live/Pending',
    Description: 'PREVIOUS ACTION/ALLOWANCE COUNT WITHDRAWN',
  },
  {
    Code: '644',
    Status: 'Live/Pending',
    Description: 'FINAL REFUSAL COUNTED - NOT MAILED',
  },
  {
    Code: '645',
    Status: 'Live/Pending',
    Description: 'FINAL REFUSAL - MAILED',
  },
  {
    Code: '646',
    Status: 'Live/Pending',
    Description: 'EXAMINERS AMENDMENT COUNTED - NOT MAILED',
  },
  {
    Code: '647',
    Status: 'Live/Pending',
    Description: 'EXAMINERS AMENDMENT - MAILED',
  },
  {
    Code: '648',
    Status: 'Live/Pending',
    Description: 'ACTION CONTINUING FINAL COUNTED - NOT MAILED',
  },
  {
    Code: '649',
    Status: 'Live/Pending',
    Description: 'ACTION CONTINUING FINAL - MAILED',
  },
  {
    Code: '650',
    Status: 'Live/Pending',
    Description: 'SUSPENSION INQUIRY COUNTED - NOT MAILED',
  },
  {
    Code: '651',
    Status: 'Live/Pending',
    Description: 'SUSPENSION INQUIRY - MAILED',
  },
  {
    Code: '652',
    Status: 'Live/Pending',
    Description: 'SUSPENSION LETTER COUNTED - NOT MAILED',
  },
  {
    Code: '653',
    Status: 'Live/Pending',
    Description: 'SUSPENSION LETTER - MAILED',
  },
  {
    Code: '654',
    Status: 'Live/Pending',
    Description: 'REPORT COMPLETED SUSPENSION CHECK - CASE STILL SUSPENDED',
  },
  {
    Code: '655',
    Status: 'Live/Pending',
    Description: "EXAMINER'S AMENDMENT/PRIORITY ACTION COUNTED NOT MAILED",
  },
  {
    Code: '656',
    Status: 'Live/Pending',
    Description: "EXAMINER'S AMENDMENT/PRIORITY ACTION MAILED",
  },
  {
    Code: '657',
    Status: 'Live/Pending',
    Description: 'PRIORITY ACTION COUNTED NOT MAILED',
  },
  {
    Code: '658',
    Status: 'Live/Pending',
    Description: 'PRIORITY ACTION MAILED',
  },
  {
    Code: '659',
    Status: 'Live/Pending',
    Description: 'SUBSEQUENT FINAL REFUSAL COUNTED NOT MAILED',
  },
  {
    Code: '660',
    Status: 'Live/Pending',
    Description: 'SUBSEQUENT FINAL MAILED',
  },
  {
    Code: '661',
    Status: 'Live/Pending',
    Description: 'RESPONSE AFTER NON-FINAL ACTION - ENTERED',
  },
  {
    Code: '663',
    Status: 'Live/Pending',
    Description: 'RESPONSE AFTER FINAL REJECTION - ENTERED',
  },
  {
    Code: '664',
    Status: 'Live/Pending',
    Description: 'INVENTORIED AS AMENDED',
  },
  {
    Code: '665',
    Status: 'Live/Pending',
    Description: 'NOTICE OF UNRESPONSIVE AMENDMENT - COUNTED',
  },
  {
    Code: '666',
    Status: 'Live/Pending',
    Description: 'NOTICE OF UNRESPONSIVE AMENDMENT - MAILED',
  },
  {
    Code: '667',
    Status: 'Live/Pending',
    Description: 'REFUSAL WITHDRAWL LETTER - COUNTED',
  },
  {
    Code: '668',
    Status: 'Live/Pending',
    Description: 'REFUSAL WITHDRAWL LETTER - MAILED',
  },
  {
    Code: '672',
    Status: 'Live/Pending',
    Description: 'REINSTATED - AWAITING FURTHER ACTION',
  },
  {
    Code: '680',
    Status: 'Live/Pending',
    Description: 'APPROVED FOR PUBLICATION',
  },
  {
    Code: '681',
    Status: 'Live/Pending',
    Description: 'PUBLICATION/ISSUE REVIEW COMPLETE',
  },
  {
    Code: '682',
    Status: 'Live/Pending',
    Description: 'ADDITIONAL REVIEW COMPLETED – RETURNED TO PUB CYCLE',
  },
  {
    Code: '686',
    Status: 'Live/Pending',
    Description: 'PUBLISHED FOR OPPOSITION',
  },
  {
    Code: '688',
    Status: 'Live/Pending',
    Description: 'NOTICE OF ALLOWANCE - ISSUED',
  },
  {
    Code: '689',
    Status: 'Live/Pending',
    Description: 'NOTICE OF ALLOWANCE - WITHDRAWN',
  },
  {
    Code: '690',
    Status: 'Live/Pending',
    Description: 'NOTICE OF ALLOWANCE - CANCELLED',
  },
  {
    Code: '692',
    Status: 'Live/Pending',
    Description: 'WITHDRAWN BEFORE PUBLICATION',
  },
  {
    Code: '693',
    Status: 'Live/Pending',
    Description: 'WITHDRAWN FROM ISSUE-JURISDICTION RESTORED',
  },
  {
    Code: '694',
    Status: 'Live/Pending',
    Description: 'WITHDRAWN BEFORE ISSUE',
  },
  {
    Code: '700',
    Status: 'Live/Registered',
    Description: 'REGISTERED',
  },
  {
    Code: '701',
    Status: 'Live/Registered',
    Description: 'SECTION 8-ACCEPTED',
  },
  {
    Code: '702',
    Status: 'Live/Registered',
    Description: 'SECTION 8 & 15-ACCEPTED AND ACKNOWLEDGED',
  },
  {
    Code: '703',
    Status: 'Live/Registered',
    Description: 'SECTION 15-ACKNOWLEDGED',
  },
  {
    Code: '704',
    Status: 'Live/Registered',
    Description: 'PARTIAL SECTION 8 ACCEPTED',
  },
  {
    Code: '705',
    Status: 'Live/Registered',
    Description: 'PARTIAL SECTION 8 & 15 ACCEPTED AND ACKNOWLEDGED',
  },
  {
    Code: '706',
    Status: 'Live/Registered',
    Description: 'SECTION 71 ACCEPTED',
  },
  {
    Code: '707',
    Status: 'Live/Registered',
    Description: 'PARTIAL SECTION 71 ACCEPTED',
  },
  {
    Code: '708',
    Status: 'Live/Registered',
    Description: 'PARTIAL SECTION 71 & 15 ACCEPTED AND ACKNOWLEDGED',
  },
  {
    Code: '709',
    Status: 'Dead/Cancelled',
    Description: 'CANCELLED – SECTION 71',
  },
  {
    Code: '710',
    Status: 'Dead/Cancelled',
    Description: 'CANCELLED - SECTION 8',
  },
  {
    Code: '711',
    Status: 'Dead/Cancelled',
    Description: 'CANCELLED - SECTION 7',
  },
  {
    Code: '712',
    Status: 'Dead/Cancelled',
    Description: 'CANCELLED BY COURT ORDER (SECTION 37)',
  },
  {
    Code: '713',
    Status: 'Dead/Cancelled',
    Description: 'CANCELLED - SECTION 18',
  },
  {
    Code: '714',
    Status: 'Dead/Cancelled',
    Description: 'CANCELLED - SECTION 24',
  },
  {
    Code: '715',
    Status: 'Indifferent/Cancelled',
    Description: 'CANCELLED - RESTORED TO PENDENCY',
  },
  {
    Code: '717',
    Status: 'Live',
    Description: 'REGISTERED - AWAITING DIVISIONAL FEE',
  },
  {
    Code: '718',
    Status: 'Live/Pending',
    Description: 'REQUEST FOR FIRST EXTENSION - FILED',
  },
  {
    Code: '719',
    Status: 'Live/Pending',
    Description: 'REQUEST FOR SECOND EXTENSION - FILED',
  },
  {
    Code: '720',
    Status: 'Live/Pending',
    Description: 'REQUEST FOR THIRD EXTENSION - FILED',
  },
  {
    Code: '721',
    Status: 'Live/Pending',
    Description: 'REQUEST FOR FOURTH EXTENSION - FILED',
  },
  {
    Code: '722',
    Status: 'Live/Pending',
    Description: 'REQUEST FOR FIFTH EXTENSION - FILED',
  },
  {
    Code: '724',
    Status: 'Live/Pending',
    Description: 'EXTENSION REQUEST REFUSAL - COUNTED',
  },
  {
    Code: '725',
    Status: 'Live/Pending',
    Description: 'EXTENSION REQUEST REFUSAL - MAILED',
  },
  {
    Code: '730',
    Status: 'Live/Pending',
    Description: 'FIRST EXTENSION - GRANTED',
  },
  {
    Code: '731',
    Status: 'Live/Pending',
    Description: 'SECOND EXTENSION - GRANTED',
  },
  {
    Code: '732',
    Status: 'Live/Pending',
    Description: 'THIRD EXTENSION - GRANTED',
  },
  {
    Code: '733',
    Status: 'Live/Pending',
    Description: 'FOURTH EXTENSION - GRANTED',
  },
  {
    Code: '734',
    Status: 'Live/Pending',
    Description: 'FIFTH EXTENSION - GRANTED',
  },
  {
    Code: '739',
    Status: 'Live/Registered',
    Description: 'Section 71 & 15 – ACCEPTED AND ACKNOWLEDGED',
  },
  {
    Code: '740',
    Status: 'Live/Pending',
    Description: 'POST REGISTRATION PAPER FILED - ASSIGNED TO PARA-LEGAL',
  },
  {
    Code: '744',
    Status: 'Live/Pending',
    Description: 'STATEMENT OF USE - FILED',
  },
  {
    Code: '745',
    Status: 'Live/Pending',
    Description: 'STATEMENT OF USE - INFORMAL-LETTER MAILED',
  },
  {
    Code: '746',
    Status: 'Live/Pending',
    Description: 'STATEMENT OF USE - INFORMAL-RESPONSE ENTERED',
  },
  {
    Code: '748',
    Status: 'Live/Pending',
    Description: 'STATEMENT OF USE - TO EXAMINER',
  },
  {
    Code: '752',
    Status: 'Live/Pending',
    Description: 'SU - EXAMINER STATEMENT COUNTED - NOT MAILED',
  },
  {
    Code: '753',
    Status: 'Live/Pending',
    Description: 'SU - EXAMINER STATEMENT - MAILED',
  },
  {
    Code: '756',
    Status: 'Live/Pending',
    Description: 'EXAMINER STATEMENT COUNTED - NOT MAILED',
  },
  {
    Code: '757',
    Status: 'Live/Pending',
    Description: 'EXAMINER STATEMENT - MAILED',
  },
  {
    Code: '760',
    Status: 'Live/Pending',
    Description: 'EX PARTE APPEAL PENDING',
  },
  {
    Code: '762',
    Status: 'Live/Pending',
    Description: 'EX PARTE APPEAL TERMINATED',
  },
  {
    Code: '763',
    Status: 'Live/Pending',
    Description: 'EX PARTE APPEAL-REFUSAL AFFIRMED',
  },
  {
    Code: '764',
    Status: 'Live/Pending',
    Description: 'EX PARTE APPEAL DISMISSED AS MOOT',
  },
  {
    Code: '765',
    Status: 'Live/Pending',
    Description: 'CONCURRENT USE PROCEEDING TERMINATED-GRANTED',
  },
  {
    Code: '766',
    Status: 'Live/Pending',
    Description: 'CONCURRENT USE PROCEEDING TERMINATED-DENIED',
  },
  {
    Code: '771',
    Status: 'Live/Pending',
    Description: 'CONCURRENT USE PROCEEDING PENDING',
  },
  {
    Code: '772',
    Status: 'Live/Pending',
    Description: 'INTERFERENCE PROCEEDING PENDING',
  },
  {
    Code: '773',
    Status: 'Live/Pending',
    Description: 'EXTENSION OF TIME TO OPPOSE PROCESS - TERMINATED',
  },
  {
    Code: '774',
    Status: 'Live/Pending',
    Description: 'OPPOSITION PENDING',
  },
  {
    Code: '775',
    Status: 'Live/Pending',
    Description: 'OPPOSITION DISMISSED',
  },
  {
    Code: '777',
    Status: 'Live/Pending',
    Description: 'OPPOSITION TERMINATED-SEE TTAB RECORDS',
  },
  {
    Code: '778',
    Status: 'Live/Registered',
    Description: 'CANCELLATION DISMISSED',
  },
  {
    Code: '779',
    Status: 'Live/Pending',
    Description: 'OPPOSITION SUSTAINED',
  },
  {
    Code: '780',
    Status: 'Live/Registered',
    Description: 'CANCELLATION TERMINATED - SEE TTAB RECORDS',
  },
  {
    Code: '781',
    Status: 'Dead/Cancelled',
    Description: 'CANCELLED - SECTION 16A',
  },
  {
    Code: '782',
    Status: 'Dead/Cancelled',
    Description: 'CANCELLED - SECTION 16B',
  },
  {
    Code: '790',
    Status: 'Live/Registered',
    Description: 'CANCELLATION PENDING',
  },
  {
    Code: '794',
    Status: 'Live/Pending',
    Description: 'JURISDICTION RESTORED TO EXAMINING ATTORNEY',
  },
  {
    Code: '800',
    Status: 'Live/Registered',
    Description: 'REGISTERED AND RENEWED',
  },
  {
    Code: '801',
    Status: 'Live/Pending',
    Description: 'OPPOSITION PAPERS FILED',
  },
  {
    Code: '802',
    Status: 'Live/Pending',
    Description: 'REQUEST FOR EXTENSION OF TIME TO FILE OPPOSITION',
  },
  {
    Code: '803',
    Status: 'Live/Pending',
    Description: 'AMENDMENT AFTER PUBLICATION',
  },
  {
    Code: '804',
    Status: 'Live/Pending',
    Description: 'APPEAL RECEIVED AT TTAB',
  },
  {
    Code: '806',
    Status: 'Live/Pending',
    Description: 'SU - NON-FINAL ACTION COUNTED - NOT MAILED',
  },
  {
    Code: '807',
    Status: 'Live/Pending',
    Description: 'SU - NON-FINAL ACTION - MAILED',
  },
  {
    Code: '808',
    Status: 'Live/Pending',
    Description: 'SU - FINAL REFUSAL COUNTED - NOT MAILED',
  },
  {
    Code: '809',
    Status: 'Live/Pending',
    Description: 'SU - FINAL REFUSAL - MAILED',
  },
  {
    Code: '810',
    Status: 'Live/Pending',
    Description: "SU - EXAMINER'S AMENDMENT COUNTED - NOT MAILED",
  },
  {
    Code: '811',
    Status: 'Live/Pending',
    Description: "SU - EXAMINER'S AMENDMENT - MAILED",
  },
  {
    Code: '812',
    Status: 'Live/Pending',
    Description: 'SU - ACTION CONTINUING FINAL COUNTED - NOT MAILED',
  },
  {
    Code: '813',
    Status: 'Live/Pending',
    Description: 'SU - ACTION CONTINUING FINAL - MAILED',
  },
  {
    Code: '814',
    Status: 'Live/Pending',
    Description: 'SU - RESPONSE AFTER NON-FINAL ACTION - ENTERED',
  },
  {
    Code: '815',
    Status: 'Live/Pending',
    Description: 'SU - RESPONSE AFTER FINAL REJECTION - ENTERED',
  },
  {
    Code: '816',
    Status: 'Live/Pending',
    Description: 'SU - NOTICE OF UNRESPONSIVE AMENDMENT - COUNTED',
  },
  {
    Code: '817',
    Status: 'Live/Pending',
    Description: 'SU - NOTICE OF UNRESPONSIVE AMENDMENT - MAILED',
  },
  {
    Code: '818',
    Status: 'Live/Pending',
    Description: 'SU - STATEMENT OF USE ACCEPTED - APPROVED FOR REGISTRATION',
  },
  {
    Code: '819',
    Status: 'Live/Pending',
    Description: 'SU - REGISTRATION REVIEW COMPLETE',
  },
  {
    Code: '820',
    Status: 'Live/Pending',
    Description: "SU-EXAMINER'S AMENDMENT/PRIORITY ACTION COUNTED NOT MAILED",
  },
  {
    Code: '821',
    Status: 'Live/Pending',
    Description: "SU - EXAMINER'S AMENDMENT/PRIORITY ACTION MAILED",
  },
  {
    Code: '822',
    Status: 'Live/Pending',
    Description: 'SU - PRIORITY ACTION COUNTED NOT MAILED',
  },
  {
    Code: '823',
    Status: 'Live/Pending',
    Description: 'SU - PRIORITY ACTION MAILED',
  },
  {
    Code: '824',
    Status: 'Live/Pending',
    Description: 'SU - SUBSEQUENT FINAL REFUSAL WRITTEN',
  },
  {
    Code: '825',
    Status: 'Live/Pending',
    Description: 'SU - SUBSEQUENT FINAL MAILED',
  },
  {
    Code: '900',
    Status: 'Dead/Expired',
    Description: 'EXPIRED',
  },
  {
    Code: '901',
    Status: 'Dead',
    Description: 'DEAD INVENTORIED AS REJECTED',
  },
  {
    Code: '969',
    Status: 'Live/Pending',
    Description: 'NON REGISTRATION DATA',
  },
  {
    Code: '970',
    Status: 'Indifferent',
    Description: 'RECORD CREATED DUE TO ASSIGNMENT REQUEST',
  },
  {
    Code: '973',
    Status: 'Live/Pending',
    Description: 'SUSPENDED PENDING ADMINISTRATIVE REVIEW',
  },
]

export const statusCodes: Map<string, StatusCodes> = new Map(
  data.map((item: StatusCodes) => [item.Code, item]),
)

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  showDescription?: boolean
  descClassName?: string
  variant?: 1 | 2
}

interface StatusProp {
  bg: string
  text: string
}

const statusProps: Record<string, StatusProp> = {
  'Live/Registered': {
    bg: 'bg-[#11A33A]',
    text: 'text-[#11A33A]',
  },
  'Live/Pending': {
    bg: 'bg-tm-light-green',
    text: 'text-tm-light-green',
  },
  'Dead/Abandoned': {
    bg: 'bg-[#E85252]',
    text: 'text-[#E85252]',
  },
  Dead: {
    bg: 'bg-[#E85252]',
    text: 'text-[#E85252]',
  },
  'Dead/Expired': {
    bg: 'bg-[#E85252]',
    text: 'text-[#E85252]',
  },
  'Dead/Cancelled': {
    bg: 'bg-[#E85252]',
    text: 'text-[#E85252]',
  },
  'Indifferent/Cancelled': {
    bg: 'bg-[#EDAB2C]',
    text: 'text-[#EDAB2C]',
  },
  DEFAULT: {
    bg: 'bg-[#2D75E1]',
    text: 'text-[#2D75E1]',
  },
}

export const StatusText: FC<Props> = ({
  children,
  showDescription,
  className,
  descClassName,
  variant = 1,
  ...props
}) => {
  const status = statusCodes?.get(children?.toString() ?? '')
  if (!status) return null
  const { bg, text } = statusProps[status.Status] ?? statusProps.DEFAULT

  return (
    <>
      <div
        className={[
          'flex items-center',
          variant === 2 ? 'justify-center' : '',
        ].join(' ')}
      >
        <div
          className={[className, 'inline-block h-2 w-2 rounded-full', bg].join(
            ' ',
          )}
        />
        <span
          className={[
            descClassName,
            `status-live-registered ml-[7px] text-base font-bold leading-6`,
            text,
            variant === 2 ? 'text-center text-lg' : '',
          ].join(' ')}
          {...props}
        >
          {variant === 1 &&
            (showDescription ? status?.Description : status?.Status)}{' '}
          {variant === 2 && status?.Status}
          <sup className={`text-lg font-bold ${text}`}>®</sup>
        </span>
      </div>
      {variant === 2 && (
        <p className="my-1 text-center text-xs capitalize text-gray-600">
          {status?.Description.split('-')[1].toLowerCase()}
        </p>
      )}
    </>
  )
}

export default StatusText
