import { createFetcher, ROUTES } from '../api'

export const fetchUploadImage = createFetcher<
  { key: string; url: string },
  FormData,
  { ext: string }
>({
  url: ROUTES.UPLOADER.UPLOAD,
  method: 'POST',
  isFormData: true,
  withTokens: true,
})

export const fetchUploadAdminImage = createFetcher<
  { key: string; url: string },
  FormData,
  { ext: string }
>({
  url: ROUTES.UPLOADER.UPLOAD_ADMIN,
  method: 'POST',
  isFormData: true,
  withTokens: true,
})

export const fetchUploadSound = createFetcher<
  { key: string; url: string },
  FormData,
  { ext: string }
>({
  url: ROUTES.UPLOADER.UPLOAD_SOUND,
  method: 'POST',
  isFormData: true,
  withTokens: true,
})

export const fetchUploadDocument = createFetcher<
  { key: string; url: string },
  FormData,
  { ext: string }
>({
  url: ROUTES.UPLOADER.UPLOAD_DOCUMENT,
  method: 'POST',
  isFormData: true,
  withTokens: true,
})

export const fetchCreateDeliverable = createFetcher<any, CreateDeliverable>({
  url: ROUTES.DELIVERABLE.CREATE_DELIVERABLE,
  method: 'POST',
  withTokens: true,
})

export const fetchDeliverable = createFetcher<
  FetchDeliverable[],
  any,
  { order_item_id: string }
>({
  url: ROUTES.DELIVERABLE.GET_DELIVERABLE,
  method: 'GET',
  withTokens: true,
})

export const fetchDeleteDeliverable = createFetcher<any, any, { id: string }>({
  url: ROUTES.DELIVERABLE.DELETE_DELIVERABLE,
  method: 'DELETE',
  withTokens: true,
})

export interface CreateDeliverable {
  order_id: string
  order_item_id: string
  deliverables: Deliverable[]
}

export interface Deliverable {
  key: string
  name: string
  approval_status: number
}

export interface FetchDeliverable {
  id: string
  order_id: string
  order_item_id: string
  user_id: string
  key: string
  url: string
  name: string
  is_approved: number
}
