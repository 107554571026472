import { createFetcher, ROUTES } from '../api'
import { Proof } from '../interfaces'

export const fetchUsRenewalStep0 = createFetcher<Step0RenewalDataItem[]>({
  url: ROUTES.US_RENEWAL.STEP_0.GET,
  method: 'GET',
  withTokens: true,
})

export const fetchUsRenewalMoveStep = createFetcher<any, StepMoveRenewal>({
  url: ROUTES.US_RENEWAL.MOVE_STEP,
  method: 'POST',
  withTokens: true,
})

export const fetchRenewalTrademarkBySerialNo = createFetcher<
  RenewalTrademarkDetails,
  any,
  { country: string; serialNumber: string }
>({
  url: ROUTES.RENEWAL.TRADEMARK,
  method: 'GET',
})

// STEP 1
export const fetchUsRenewalStep1 = createFetcher<Step1RenewalDataItem[]>({
  url: ROUTES.US_RENEWAL.STEP_1.GET,
  method: 'GET',
  withTokens: true,
})

export const fetchRenewalStep1Update = createFetcher<
  null,
  {
    paralegal_id: string
    tm_renewal_id: string
    current_step: 1
  }
>({
  url: ROUTES.US_RENEWAL.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

// STEP 2
export const fetchUsRenewalStep2 = createFetcher<Step2RenewalDataItem[]>({
  url: ROUTES.US_RENEWAL.STEP_2.GET,
  method: 'GET',
  withTokens: true,
})

export const fetchRenewalStep2Update = createFetcher<
  null,
  {
    law_firm_matter_id: number
    tm_renewal_id: string
    current_step: 2
  }
>({
  url: ROUTES.US_RENEWAL.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

// STEP 3
export const fetchUsRenewalStep3 = createFetcher<Step3RenewalDataItem[]>({
  url: ROUTES.US_RENEWAL.STEP_3.GET,
  method: 'GET',
  withTokens: true,
})

export const fetchUsRenewalContactAttorney = createFetcher<
  Step3RenewalDataItem[]
>({
  url: ROUTES.US_RENEWAL.RENEWAL_ATTORNEY.GET,
  method: 'GET',
  withTokens: true,
})

export const fetchRenewalStep3Update = createFetcher<
  null,
  {
    is_approved_for_renewal: boolean
    is_email_generated?: boolean
    filing_form: FilingRenewalForm
    tm_renewal_id?: string
    current_step: 3
  }
>({
  url: ROUTES.US_RENEWAL.UPDATE,
  method: 'PATCH',
  withTokens: true,
})
// STEP 4
export const fetchUsRenewalStep4 = createFetcher<Step4RenewalDataItem[]>({
  url: ROUTES.US_RENEWAL.STEP_4.GET,
  method: 'GET',
  withTokens: true,
})

export interface StepMoveRenewal {
  tm_renewal_id: string
  next_step: number
}

export interface Step1RenewalDataItem {
  tm_renewal: TmRenewal
  tm_renewal_id: string
  current_step: number
  created_at: number
  updated_on: number
  renewal_deadline: number
  paralegal_id: any
  order_date: number
  matter_id: number
  order_item: RenewalOrderItem
}

export interface TmRenewal {
  order_item_id: string
  created_at: number
  updated_on: number
  renewal_deadline: number
  order_status: number
  prices_id: string
  serial_number: string
  classes: number
  proof: Proof[]
  deliverables: any
  tm_incontestability_id: any
  tm_ownership_transfer_id: any
  is_renewal_admin_classifications: boolean
  is_grace: boolean
  is_rapid_processing: boolean
  is_section8_filed: boolean
  is_submitted_for_processing: boolean
}

interface PricingRef {
  renewal_fee: number
  renewal_time: number
  renewal_govt_fee: number
  renewal_grace_fee: number
  renewal_grace_time: number
  renewal_grace_govt_fee: number
  renewal_processing_time: number
  renewal_add_class_govt_fee: number
  renewal_min_processing_time: number
  renewal_rapid_processing_fee: number
  renewal_add_class_grace_govt_fee: number
}

export interface RenewalTrademarkDetails {
  date: number
  trademark: TrademarkDetails
  type: string
}

export interface TrademarkDetails {
  serial_number: number
  registration_number: number
  registration_date: number
  filing_date: number
  status_code: number
  status_date: number
  transaction_date: number
  mark_identification: string
  mark_drawing_code: string
  published_opposition_date: number
  abandonment_date: number
  cancellation_code: string
  cancellation_date: number
  renewal_date: number
  docket_number: string
  attorney_name: string
  company_id: number
  goods_and_service: string
  primary_code: string
  mark_identification_cn: string
  goods_and_service_cn: string
  owner_phone: string
  correspondent_phone: string
  correspondent_primary_email: string
  correspondent_secondary_email: string
  is_visible: boolean
  trademark_application_statement: TrademarkApplicationStatement[]
  trademark_classification: TrademarkClassification[]
  trademark_correspondent: TrademarkCorrespondent
  trademark_design_search: any[]
  trademark_owner: TrademarkOwner[]
  trademark_section_info: TrademarkSectionInfo
  trademark_prosecution_history: TrademarkProsecutionHistory[]
  trademark_owner_contact?: any
}
export interface TrademarkApplicationStatement {
  id: string
  trademark_id: number
  type_code: string
  type: number
  file_text: string
}

export interface TrademarkClassification {
  id: string
  trademark_id: number
  international_code_total: number
  us_code_total: number
  us_code_list: string[]
  status_code: string
  first_use_anywhere_date: number
  first_use_commerce_date: number
  primary_code: string
}

export interface TrademarkCorrespondent {
  id: string
  trademark_id: number
  created_on: number
  address_1: string
  address_2: string
  address_3: string
  address_4: string
  address_5: string
  is_visible: boolean
}

export interface TrademarkOwner {
  id: string
  trademark_id: number
  party_type: string
  party_name: string
  entry_number: number
  nationality_country: string
  legal_entity_type_code: number
  address_1: string
  city: string
  state: string
  country: string
  post_code: string
}

export interface TrademarkSectionInfo {
  trademark_id: number
  trademark_in: boolean
  collective_trademark_in: boolean
  service_mark_in: boolean
  collective_service_mark_in: boolean
  certification_mark_in: boolean
  foreign_priority_in: boolean
  renewal_filed_in: boolean
  section_12_c_in: boolean
  section_2_f_in: boolean
  section_2_f_in_part_in: boolean
  section_8_filed_in: boolean
  section_8_partial_accept_in: boolean
  section_8_accepted_in: boolean
  section_15_acknowledged_in: boolean
  section_15_filed_in: boolean
  intent_to_use_in: boolean
  intent_to_use_current_in: boolean
  filed_as_use_application_in: boolean
  amended_to_use_application_in: boolean
  use_application_currently_in: boolean
  filing_basis_filed_as_44_d_in: boolean
  amended_to_44_d_application_in: boolean
  filing_basis_current_44_d_in: boolean
  filing_basis_filed_as_44_e_in: boolean
  filing_basis_current_44_e_in: boolean
  amended_to_44_e_application_in: boolean
  without_basis_currently_in: boolean
  filing_current_no_basis_in: boolean
  filing_basis_filed_as_66_a_in: boolean
  filing_basis_current_66_a_in: boolean
  supplemental_register_in: boolean
}

export interface TrademarkProsecutionHistory {
  ID: string
  trademark_id: number
  code: string
  type: string
  description_text: string
  date: number
  sequence_number: number
}

export interface Step2RenewalDataItem {
  tm_renewal: TmRenewal
  tm_renewal_id: string
  current_step: number
  created_at: number
  updated_on: number
  renewal_deadline: number
  paralegal_id: any
  law_firm_matter_id: number
  law_firm_matter: any
  paralegal_name: string
  order_date: number
  matter_id: number
  order_item: RenewalOrderItem
}

export interface Step3RenewalDataItem {
  tm_renewal: TmRenewal
  tm_renewal_id: string
  current_step: number
  created_at: number
  updated_on: number
  renewal_deadline: number
  paralegal_id: any
  law_firm_matter_id: number
  law_firm_matter: any
  paralegal_name: string
  is_approved_for_renewal: boolean
  is_email_generated: boolean
  filing_form: FilingRenewalForm
  order_date: number
  matter_id: number
  order_item: RenewalOrderItem
}

export interface FilingRenewalForm {
  classes: number
  serial_number: string
  class_classifications: ClassClassificationRenewal[]
}

export interface ClassClassificationRenewal {
  id: string
  proof: Proof[]
  is_saved: boolean
  description: string
  tm_renewals_id: string
  first_use_date_any: number
  first_use_date_com: number
  is_conflict_raised: boolean
  is_being_used_to_sell: boolean
  trademark_classification_class_id: string
}

export interface Step4RenewalDataItem {
  tm_renewal: TmRenewal
  tm_renewal_id: string
  current_step: number
  created_at: number
  updated_on: number
  renewal_deadline: number
  paralegal_id: any
  law_firm_matter_id: number
  law_firm_matter: any
  paralegal_name: string
  is_approved_for_renewal: boolean
  is_email_generated: boolean
  filing_form: FilingRenewalForm
  order_date: number
  matter_id: number
  order_item: RenewalOrderItem
}

export interface Step0RenewalDataItem {
  order_item_id: string
  created_at: number
  updated_on: number
  renewal_deadline: number
  order_status: number
  prices_id: string
  serial_number: string
  classes: number
  proof: any
  tm_incontestability_id: any
  tm_ownership_transfer_id: any
  is_renewal_admin_classifications: boolean
  is_grace: boolean
  is_rapid_processing: boolean
  is_section8_filed: boolean
  is_submitted_for_processing: boolean
  order_id: string
  matter_id: number
  full_name: string
  email: string
  phone: string
  order_item: RenewalOrderItem
}

export interface RenewalOrderItem {
  id: string
  user_id: string
  order_id: string
  added_at: number
  quantity: number
  billable_amount: number
  billable_amount_paid: number
  billable_govt_amount: number
  billable_govt_amount_paid: number
  item_type: string
  order_item_status: number
  payment_status: number
  prices_id: string
  pricing_ref: PricingRef
  is_offline_order: boolean
  offline_order_rep: any
  offline_order_created_by: any
  discount: any
}
