import { createFetcher, ROUTES } from '../api'
import { ProofType } from '../interfaces'
// import { OrderItemOwnership } from './matters'
import { Step2RenewalDataItem } from './renewal'

export const fetchUsSouMoveStep = createFetcher<any, StepMoveSou>({
  url: ROUTES.US_SOU.MOVE_STEP,
  method: 'POST',
  withTokens: true,
})

export const fetchSOUOrderUpdateMatter = createFetcher({
  url: ROUTES.US_SOU.UPDATE_MATTER,
  withTokens: true,
  method: 'PATCH',
})

export const fetchSouPostpayment = createFetcher({
  url: ROUTES.US_SOU.POSTPAYMENT,
  withTokens: true,
})

/**********************
 *
 *  Step 1 fetch (GET & UPDATE)
 *
 **********************/

export const fetchUsSouStep1 = createFetcher<Step1SouDataItem[]>({
  url: ROUTES.US_SOU.STEP_1.GET,
  method: 'GET',
  withTokens: true,
})

export const fetchSouStep1Update = createFetcher<
  null,
  {
    paralegal_id: string
    sou_id: string
    current_step: 1
  }
>({
  url: ROUTES.US_SOU.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

/**********************
 *
 *  Step 2 fetch (GET & UPDATE)
 *
 **********************/

export const fetchUsSouStep2 = createFetcher<Step2SouDataItem[]>({
  url: ROUTES.US_SOU.STEP_2.GET,
  method: 'GET',
  withTokens: true,
})

export const fetchSouStep2Update = createFetcher<
  null,
  {
    law_firm_matter_id: string
    sou_id: string
    current_step: 2
  }
>({
  url: ROUTES.US_SOU.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

/**********************
 *
 *  Step 3 fetch (GET & UPDATE)
 *
 **********************/

export const fetchUsSouStep3 = createFetcher<Step3SouDataItem[]>({
  url: ROUTES.US_SOU.STEP_3.GET,
  method: 'GET',
  withTokens: true,
})

export const fetchSouStep3Update = createFetcher<
  null,
  {
    is_approved_for_sou: boolean
    is_email_generated?: boolean
    sou_id?: string
    current_step: 3
    filing_report: FilingReportSou
  }
>({
  url: ROUTES.US_SOU.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

export const fetchUsSouContactAttorney = createFetcher<Step3SouDataItem[]>({
  url: ROUTES.US_SOU.SOU_ATTORNEY.GET,
  method: 'GET',
  withTokens: true,
})

/**********************
 *
 *  Step 4 fetch (GET & UPDATE)
 *
 **********************/

export const fetchUsSouStep4 = createFetcher<Step4SouDataItem[]>({
  url: ROUTES.US_SOU.STEP_4.GET,
  method: 'GET',
  withTokens: true,
})

/**********************
 *
 *  Step Move (POST)
 *
 **********************/

export interface StepMoveSou {
  sou_id: string
  next_step: number
}

export interface SouPostPaymentDataItm {
  order_item_id: string
  created_at: number
  updated_on: number
  order_status: number
  deadline: number
  prices_id: string
  intent_to_use: boolean
  serial_number: string
  proof: ProofSou[]
  intent_to_use_description: string
  is_submitted_for_processing: boolean
  order_id: string
  matter_id: number
  full_name: string
  email: string
  phone: string
  order_item: OrderItemSou
}

/**********************
 *
 *  Step 1 Interfaces
 *
 **********************/

export interface Step1SouDataItem {
  sou: Sou
  sou_id: string
  current_step: number
  created_at: number
  updated_on: number
  paralegal_id: any
  order_date: number
  matter_id: number
  order_item: OrderItemSou
}

export interface Sou {
  order_item_id: string
  created_at: number
  updated_on: number
  order_status: number
  deadline: number
  prices_id: string
  intent_to_use: boolean
  serial_number: string
  first_use_date: any
  first_use_public_date: any
  proof: ProofSou
  intent_to_use_description: string
  deliverables: any
  is_submitted_for_processing: boolean
}

export interface ProofSou {
  classes: Class[]
}

export interface Class {
  proof: Proof2[]
  is_saved: boolean
  first_use_date_any: number
  first_use_date_com: number
  is_being_used_to_sell: boolean
  trademark_classification_class_id: string
}

export interface Proof2 {
  other: string
  specimen: string
  proof_type: number
  website_specimen: string
}

export interface OrderItemSou {
  id: string
  user_id: string
  order_id: string
  added_at: number
  quantity: number
  billable_amount: number
  billable_amount_paid: number
  billable_govt_amount: number
  billable_govt_amount_paid: number
  item_type: string
  order_item_status: number
  payment_status: number
  prices_id: string
  pricing_ref: PricingRefSou
  is_offline_order: boolean
  offline_order_rep: any
  offline_order_created_by: any
}

export interface PricingRefSou {
  sou_ext_fee: number
  sou_ext_gov_fee: number
}

/**********************
 *
 *  Step 2 Interfaces
 *
 **********************/

export interface Step2SouDataItem {
  sou: Sou
  sou_id: string
  current_step: number
  created_at: number
  updated_on: number
  paralegal_id: string
  law_firm_matter_id: number
  law_firm_matter: LawFirmMatterSou
  paralegal_name: string
  order_date: number
  matter_id: number
  order_item: OrderItemSou
}
export interface LawFirmMatterSou {
  id: number
  created_at: number
  updated_on: number
  order_item_id: string
  order_item_type: string
  is_assigned: boolean
  law_firm_id: string
  attorney_id: string
  admin_us_tm_processing_id: any
  assigned_name: string
  prices_id: string
  admin_int_tm_processing_id: any
  matter_status: number
}

/**********************
 *
 *  Step 3 Interfaces
 *
 **********************/

export interface Step3SouDataItem {
  sou: Sou
  sou_id: string
  current_step: number
  created_at: number
  updated_on: number
  paralegal_id: string
  law_firm_matter_id: number
  law_firm_matter: LawFirmMatterSou
  paralegal_name: string
  is_approved_for_sou: boolean
  is_email_generated: boolean
  filing_report: FilingReportSou
  order_date: number
  matter_id: number
  order_item: OrderItemSou
}

export interface FilingReportSou {
  proof: Proof3
}

export interface Proof3 {
  classes: Class2[]
}

export interface Class2 {
  proof: Proof4[]
  is_saved: boolean
  first_use_date_any: number
  first_use_date_com: number
  is_being_used_to_sell: boolean
  trademark_classification_class_id: string
  description: string
}

export interface Proof4 {
  other: string
  specimen: string
  proof_type: ProofType
  website_specimen: string
}
/**********************
 *
 *  Step 4 Interfaces
 *
 **********************/

export interface Step4SouDataItem {
  sou: Sou
  sou_id: string
  current_step: number
  created_at: number
  updated_on: number
  paralegal_id: string
  law_firm_matter_id: number
  law_firm_matter: LawFirmMatterSou
  paralegal_name: string
  is_approved_for_sou: boolean
  is_email_generated: boolean
  filing_report: FilingReportSou
  order_date: number
  matter_id: number
  order_item: OrderItemSou
}
