import { Fetcher, Options } from 'api'
import { useAsyncMemo, useQueryState } from 'hooks'
import { DispatchSetQuery } from 'interfaces'
import { useEffect, useRef, useState } from 'react'

export interface PaginationProps<Query = any, Res = unknown> {
  activePage: number
  totalPages: number
  jumpTo: DispatchSetQuery<number>
  refresh: (
    props?:
      | ({
          urlParams?: Query | undefined
        } & Options<any, Query>)
      | undefined,
  ) => Promise<void>
  refetch: (
    props?:
      | ({
          urlParams?: Query | undefined
        } & Options<any, Query>)
      | undefined,
  ) => Promise<void>
  loading: boolean
  response?: Res
}

interface Props<Item, Res, Req, Query>
  extends Omit<Options<any, Query>, 'data'> {
  fetcher: Fetcher<Res, Req, Query>
  deps?: any[]
  resetDeps?: any[]
  urlParams?: Query
  getArray?: (res: Res) => Item[]
  defaultPage?: number
  pageKey?: string
  isVisible?: boolean
  perPage?: number
  data?:
    | Options<any, Query>['data']
    | ((props: { page: number }) => Options<any, Query>['data'])
}

export const usePagination = <
  Res = any,
  Item = Res extends (infer I)[] ? I : unknown,
  Req = any,
  Query = any,
>({
  fetcher,
  deps = [],
  resetDeps = [],
  urlParams,
  getArray = res => res as unknown as Item[],
  defaultPage = 1,
  pageKey = 'page',
  isVisible = true,
  perPage = 10,
  ...options
}: Props<Item, Res, Req, Query>): [Item[], PaginationProps<Query, Res>] => {
  const firstLoad = useRef<boolean>(true)
  const [totalPages, setTotalPages] = useState(Infinity)
  const [response, setResponse] = useState<Res | undefined>(undefined)
  const [activePage, { set: setActivePage, isReady }] = useQueryState<number>(
    pageKey,
    defaultPage,
  )
  const controller = useRef<AbortController | null>(null)
  const [data, , refresh, { loading }] = useAsyncMemo<
    Item[],
    Item[],
    { urlParams?: Query } & Options<any, Query>
  >(
    async props => {
      if (controller.current) controller.current.abort()
      controller.current = new AbortController()
      if (!isReady || !fetcher || !isVisible) return [] as Item[]
      const { body } = await fetcher(
        {} as Req,
        {
          ...options,
          data:
            typeof options.data === 'function'
              ? options.data({ page: activePage })
              : options.data,
          signal: controller.current?.signal,
          params: {
            page: activePage,
            ...options.params,
            ...(props?.params || {}),
          },
        },
        {
          ...(urlParams || {}),
          ...(props?.urlParams || {}),
        } as Query,
      )
      setResponse(body)
      if (!body) {
        setTotalPages(activePage - 1)
        return [] as Item[]
      }
      const arr = getArray(body)
      if (!(arr instanceof Array)) {
        setTotalPages(0)
        return [] as Item[]
      }
      if (arr && arr.length === 0) setTotalPages(activePage - 1)
      else if (arr && arr.length < perPage) setTotalPages(activePage)
      else setTotalPages(Infinity)
      return arr as Item[]
    },
    [activePage, isVisible, isReady, ...deps],
    {
      defaultValue: [] as Item[],
      destructor: () => controller.current?.abort(),
    },
  )

  useEffect(() => {
    if (!isReady) return
    if (activePage > totalPages)
      setActivePage(totalPages > 0 ? totalPages : defaultPage)
  }, [activePage, totalPages])

  const reset = () => {
    if (!isReady || firstLoad.current) {
      firstLoad.current = false
      return
    }
    setActivePage(defaultPage)
  }

  useEffect(() => {
    reset()
  }, [...resetDeps, firstLoad.current, isVisible])

  return [
    data,
    {
      activePage,
      totalPages,
      jumpTo: setActivePage,
      refresh: async props => {
        reset()
        return await refresh(props)
      },
      refetch: async props => {
        return await refresh(props)
      },
      loading,
      response,
    },
  ]
}
