import { createFetcher, ROUTES } from '../api'
import { Admin } from '../interfaces'

export const fetchAdmins = createFetcher<Admin[]>({
  url: ROUTES.ADMINS.GET_ALL,
  withTokens: true,
})

export const fetchAdminsByTeamId = createFetcher<Admin[]>({
  url: ROUTES.ADMINS.GET_BY_TEAM_ID,
  withTokens: true,
})
export const fetchRoles = createFetcher<Role[]>({
  url: ROUTES.ADMINS.GET_ROLES,
  withTokens: true,
})
export const fetchTeams = createFetcher<Team[]>({
  url: ROUTES.ADMINS.GET_TEAMS,
  withTokens: true,
})

export const fetchCreateAdmin = createFetcher<
  any,
  {
    full_name: string
    email: string
    phone: string
    permissions: string[]
    address: {
      line_1: string
      line_2: string
      city: string
      state: string
      country: string
      postal_code: string
    }
    teams?: string[]
    is_attorney?: boolean
    photo?: string
    qualifications?: string
    university?: string
    languages?: string[]
    practice_statement?: string
    practice_area?: string[]
    licensed_state?: {
      country: string
      states: string[]
    }[]
    priority?: number
    rating?: number
    experience_start_date?: number
  }
>({
  url: ROUTES.ADMINS.CREATE,
  method: 'POST',
  withTokens: true,
})

export const fetchUpdateAdminStatus = createFetcher<
  any,
  {
    admin_id: string
    is_disabled: boolean
    disable_reason: string
  }
>({
  url: ROUTES.ADMINS.UPDATE_STATUS,
  method: 'PUT',
  withTokens: true,
})

export const fetchCreateTeam = createFetcher<
  any,
  {
    name: string
    is_role: boolean
    permissions: string[]
  }
>({
  url: ROUTES.ADMINS.CREATE_TEAM,
  method: 'POST',
  withTokens: true,
})

export const fetchLinkAdminToTeam = createFetcher<
  any,
  {
    admin_ids: string[]
    team_id: string
  }
>({
  url: ROUTES.ADMINS.LINK_ADMIN_TO_TEAM,
  method: 'POST',
  withTokens: true,
})

export const fetchRemoveAdminFromTeam = createFetcher<
  any,
  {
    admin_id: string
    team_id: string
  }
>({
  url: ROUTES.ADMINS.REMOVE_ADMIN_FROM_TEAM,
  method: 'DELETE',
  withTokens: true,
})

export const fetchGroups = createFetcher<AdminGroup[]>({
  url: ROUTES.ADMINS.GET_GROUPS,
  withTokens: true,
})
export const fetchCreateGroup = createFetcher<
  AdminGroup,
  { name: string; permissions: string[] }
>({
  url: ROUTES.ADMINS.CREATE_GROUP,
  method: 'POST',
  withTokens: true,
})
export const fetchEditGroup = createFetcher<
  null,
  { name: string; permissions: string[]; id: string }
>({
  url: ROUTES.ADMINS.UPDATE_GROUP,
  method: 'PUT',
  withTokens: true,
})

export const fetchLinkGroup = createFetcher<
  null,
  { email: string; group_id: string }
>({
  url: ROUTES.ADMINS.ADD_TO_GROUP,
  method: 'PATCH',
  withTokens: true,
})

export const fetchAddPermissionsToAdmin = createFetcher<
  null,
  { email: string; permissions: string[] }
>({
  url: ROUTES.ADMINS.ADD_PERMISSIONS_TO_ADMIN,
  method: 'PUT',
  withTokens: true,
})

export const fetchParalegals = createFetcher<Paralegal[]>({
  url: ROUTES.ADMINS.GET_ALL_PARALEGALS,
  withTokens: true,
})

export interface Paralegal {
  admin_id: string
  admin: Admin
  division: string
  division_position: number
  prices_id: string
}

export interface AdminGroup {
  id: string
  name: string
  permissions: string[] | null
  admins: Admin | null
}

export interface Role {
  id: string
  name: string
  is_role: true
  permissions: string[]
}
export interface Team {
  id: string
  name: string
  is_role: false
  permissions: []
}
