import { Field, FormikValues } from 'formik'
import Image from 'next/image'
import React, { FC } from 'react'
import { SelectField } from './SelectField'

const regions = [
  { id: 1, region: 'Africa' },
  { id: 2, region: 'America' },
  { id: 3, region: 'Asia' },
  { id: 4, region: 'Europe' },
  { id: 5, region: 'Middle East' },
  { id: 6, region: 'Oceania' },
]

interface Props {
  values: FormikValues
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void
  inputClass: string
  downIconClass: string
  selectContainerClass: string
  hideDescription?: boolean
}

export const CountrySelect: FC<Props> = ({
  downIconClass,
  inputClass,
  selectContainerClass,
  setFieldValue,
  values,
  hideDescription,
}) => (
  <div className="mt-4 max-w-[540px] rounded-lg bg-[#FAFAFA] pb-2">
    <div className="sm:bg-primary-600  rounded-t-lg ">
      <div className=" flex flex-wrap px-6 pt-6">
        <label className="mr-5 mb-5 flex items-center" htmlFor="SelectRegion">
          <Field
            type="checkbox"
            checked={values.SelectRegion.includes('Africa')}
            name="SelectRegion"
            value="Africa"
            className="bg-primary-600 rounded-sm border-2 border-white"
          />
          <p className="ml-2 text-white">Africa</p>
        </label>
        <label className="mr-5 mb-5 flex items-center" htmlFor="SelectRegion">
          <Field
            type="checkbox"
            checked={values.SelectRegion.includes('America')}
            name="SelectRegion"
            value="America"
            className="bg-primary-600 rounded-sm border-2 border-white"
          />
          <p className="ml-2 text-white">America</p>
        </label>
        <label className="mr-5 mb-5 flex items-center" htmlFor="SelectRegion">
          <Field
            type="checkbox"
            checked={values.SelectRegion.includes('Asia')}
            name="SelectRegion"
            value="Asia"
            className="bg-primary-600 rounded-sm border-2 border-white"
          />
          <p className="ml-2 text-white">Asia</p>
        </label>
        <label className="mr-5 mb-5 flex items-center" htmlFor="SelectRegion">
          <Field
            type="checkbox"
            checked={values.SelectRegion.includes('Europe')}
            name="SelectRegion"
            value="Europe"
            className="bg-primary-600 rounded-sm border-2 border-white"
          />
          <p className="ml-2 text-white">Europe</p>
        </label>
        <label className="mr-5 mb-5 flex items-center" htmlFor="SelectRegion">
          <Field
            type="checkbox"
            checked={values.SelectRegion.includes('Middle East')}
            name="SelectRegion"
            value="Middle East"
            className="bg-primary-600 rounded-sm border-2 border-white"
          />
          <p className="ml-2 text-white">Middle East</p>
        </label>
        <label className="mr-5 mb-5 flex items-center" htmlFor="SelectRegion">
          <Field
            type="checkbox"
            checked={values.SelectRegion.includes('Oceania')}
            name="SelectRegion"
            value="Oceania"
            className="bg-primary-600 rounded-sm border-2 border-white"
          />
          <p className="ml-2 text-white">Oceania</p>
        </label>
      </div>
      <div className="grid-cols-7 sm:grid sm:gap-3 sm:px-6 sm:py-5">
        <SelectField
          downIconClass={downIconClass}
          inputClass={inputClass}
          placeholder="Select Region"
          type="region"
          options={regions}
          SelectRegion={values.SelectRegion}
          selectContainerClass={selectContainerClass}
        />
        <div className="relative mt-4 sm:col-span-4 sm:mt-0">
          <Field
            className="text-h5 w-full rounded-lg py-2 leading-5 placeholder:text-[#7D7D7D] "
            placeholder="Search country"
            type="text"
            name="CountrySearch"
          />
          <div className="absolute right-3 top-[5px] h-7 w-7">
            <div className="relative h-7 w-7">
              <Image unoptimized 
                layout="fill"
                src="/Fields/Search.svg"
                alt="search country"
                objectFit="cover"
                objectPosition="center"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative mt-6 h-[500px] overflow-scroll overflow-x-auto bg-[#FAFAFA] px-6 pt-4">
        {values.SelectedCountries.filter(
          (data: any) =>
            (values.SelectRegion?.length
              ? values.SelectRegion?.toString()
                  .toLowerCase()
                  .includes(data.region?.toLowerCase())
              : true) &&
            data.country_name
              .toLowerCase()
              .includes(values.CountrySearch?.toString().toLowerCase()),
        ).map((data: any) => (
          <div
            onKeyPress={() => {}}
            role="button"
            tabIndex={0}
            onClick={() => {
              const tempSelectedCountries = values.SelectedCountries.filter(
                (country: any) => data.id !== country.id,
              )
              setFieldValue('SelectedCountries', [...tempSelectedCountries])

              // setFieldValue(
              //   'SearchCountries',
              //   values.SelectedCountries.filter(
              //     (country: any) => country.id !== data.id,
              //   ),
              // )
            }}
            key={data.id}
            className="border-primary text-primary bg-primary-100 mt-3 flex items-center rounded-lg border py-3 px-5"
          >
            <div className="relative h-[18px] w-[18px]">
              <Image unoptimized 
                layout="fill"
                src="/Fields/CountySelected.svg"
                objectFit="cover"
                objectPosition="center"
              />
            </div>
            <div className="relative mx-2 h-4 w-7 ">
              <Image unoptimized 
                layout="fill"
                src={`/Flags/${data.country_code.toLowerCase()}.svg`}
                alt="search country"
                objectFit="cover"
                objectPosition="center"
              />
            </div>
            <div>
              <div className="text-foot leading-5">{data.country_name}</div>
              <div className="text-cap leading-5">{data.region}</div>
            </div>
            <div className="flex-1" />
            <div className="">${data.standard_cost}.00*&nbsp;only</div>
          </div>
        ))}
        {values.AllCountries.filter((data: any) =>
          (values.SelectRegion?.length
            ? values.SelectRegion?.toString()
                .toLowerCase()
                .includes(data.region?.toLowerCase())
            : true) && values.CountrySearch
            ? data.country_name
                .toLowerCase()
                .includes(values.CountrySearch?.toString().toLowerCase())
            : true &&
              !values.SelectedCountries.find((sc: any) => sc.id === data.id),
        ).map((data: any) => (
          <div
            onKeyPress={() => {}}
            role="button"
            tabIndex={0}
            onClick={() => {
              setFieldValue('SelectedCountries', [
                ...values.SelectedCountries,
                data,
              ])
            }}
            key={data.id}
            className="mt-3 flex items-center rounded-lg border border-transparent bg-[#F2F2F2] py-3 px-5"
          >
            <div className="relative h-[18px] w-[18px]">
              <Image unoptimized 
                layout="fill"
                src="/Fields/CountyUnSelected.svg"
                objectFit="cover"
                objectPosition="center"
              />
            </div>
            <div className="relative mx-2 h-4 w-7 ">
              <Image unoptimized 
                layout="fill"
                src={`/Flags/${data.country_code.toLowerCase()}.svg`}
                alt="search country"
                objectFit="cover"
                objectPosition="center"
              />
            </div>
            <div>
              <div className="text-foot leading-5">{data.country_name}</div>
              <div className="text-cap leading-5">{data.region}</div>
            </div>
            <div className="flex-1" />
            <div className="">${data.standard_cost}.00*&nbsp;only</div>
          </div>
        ))}
      </div>
    </div>
    {!hideDescription && (
      <div className="mx-6 mb-2 font-normal leading-6">
        <div className=" mt-4  text-[#616061]">
          *Government and class fees will be charged extra
        </div>
        <div className="mt-2  text-[#616061]">
          **The fee you see on the table is the trademarkia filing fee per
          class. There will be extra government fees and additional class fees
          too. Check the cart for more details
        </div>
      </div>
    )}
  </div>
)
