import { Dispatch, SetStateAction, useCallback } from 'react'
import { useLocalStorage } from './useLocalStorage'

export interface CacheListProps<T> {
  append: (item: T) => void
  filter: (cb: (value: T, index: number, array: T[]) => any) => void
  set: Dispatch<SetStateAction<T[]>>
  get: (cb: (value: T, index: number, array: T[]) => any) => T | undefined
  edit: (finder: (value: T, index: number, array: T[]) => any, item: T) => void
}
export const useCacheList = <T>(localKey: string): [T[], CacheListProps<T>] => {
  const [list, setList] = useLocalStorage<T[]>(localKey, [])
  const append = (item: T) =>
    setList(p => [...p.slice(p.length - 6, p.length), item])
  const filter = (cb: (value: T, index: number, array: T[]) => any) =>
    setList(p => p.filter(cb))
  const get = useCallback(
    (cb: (value: T, index: number, array: T[]) => any) => list.find(cb),
    [list],
  )
  const edit = (
    finder: (value: T, index: number, array: T[]) => any,
    item: T,
  ) => {
    if (get(finder))
      setList(p =>
        p.map((value, index, array) =>
          finder(value, index, array) ? item : value,
        ),
      )
    else append(item)
  }

  return [list, { append, filter, set: setList, get, edit }]
}
