import { createFetcher, ROUTES } from '../api'
import { AddonDataItem } from '../interfaces'

export const fetchIncontestabilityStep = createFetcher<AddonDataItem[]>({
  url: ROUTES.INCONTESTABILTITY.FETCH_STEP,
  method: 'GET',
  withTokens: true,
})

export const fetchIncontestabilityMoveStep = createFetcher<null>({
  url: ROUTES.INCONTESTABILTITY.MOVE_STEP,
  method: 'PATCH',
  withTokens: true,
})
