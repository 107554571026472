import { useState } from 'react'

export const useFocus = <Shape = any>(): [
  Record<keyof Shape, boolean>,
  (key: keyof Shape) => {
    onFocus: () => any
    onBlur: () => any
  },
] => {
  const [isFocused, setIsFocused] = useState({} as Record<keyof Shape, boolean>)
  const focusProps = (key: keyof Shape) => ({
    onFocus: () => setIsFocused(p => ({ ...p, [key]: true })),
    onBlur: () => setIsFocused(p => ({ ...p, [key]: false })),
  })
  return [isFocused, focusProps]
}
