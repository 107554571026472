export const getSubscriptionPeriod = (period: number) => {
  if (period === 1) {
    return '/month'
  } else if (period === 6) {
    return '/half year'
  } else if (period === 3) {
    return '/quarter'
  } else if (period === 12) {
    return '/year'
  } else if (period === 24) {
    return '/2 years'
  } else if (period === 36) {
    return '/3 years'
  } else {
    return ''
  }
}
