import { useRouter } from 'next/router'
import { useMemo } from 'react'

export interface Colors {
  primary: string
  primarylight: string
  secondary: string
  white: '#ffffff'
}
export type Brand = 'pe' | 'tm'

export const useBrand = (): { brand: Brand; colors: Colors } => {
  const { pathname } = useRouter()
  const brand = useMemo(
    () => (pathname.startsWith('/pe') ? 'pe' : 'tm'),
    [pathname],
  )
  const colors: Colors = useMemo(
    () => ({
      primary: brand === 'tm' ? '#7161C5' : '#09ABAB',
      secondary: brand === 'tm' ? '#F55431' : '#FA6464',
      primarylight: brand === 'tm' ? '#ECE9F9' : '#E2F5F5',
      white: '#ffffff',
    }),
    [brand],
  )
  return { brand, colors }
}
