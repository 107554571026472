/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// autocomplete input for global kbar search to suggest different routes in the site with animation and beautiful UI

import { useRouter } from 'next/router'
import { KeyboardEventHandler, useEffect, useMemo, useState } from 'react'

// features:
// auto filter suggestions with description to different part of the website
// have a recent search section in the bottom of the modal

interface Suggestions {
  href: string
  label: string
  description?: string
}

interface AutoCompleteProps {
  open: boolean
  onClose: () => void
  onSearch: (query: string) => void
  suggestions: Suggestions[]
}

const AutoComplete = ({
  open,
  onClose,
  onSearch,
  suggestions,
}: AutoCompleteProps) => {
  const [query, setQuery] = useState('')

  const { push } = useRouter()

  // filter suggestions based on the query
  const filteredSuggestions = useMemo(
    () =>
      suggestions.filter(suggestion =>
        suggestion.label.toLowerCase().includes(query.toLowerCase()),
      ),
    [query, suggestions],
  )

  const [activeSuggestion, setActiveSuggestion] = useState(
    filteredSuggestions[0],
  )

  // recent searches state which will have initial value from local storage and check if local storage is available or not
  const [recentSearches, setRecentSearches] = useState(() =>
    typeof window !== 'undefined'
      ? JSON.parse(localStorage.getItem('recentSearches') || '[]')
      : [],
  )
  //   when modal opens put focus on the input field
  useEffect(() => {
    if (open) {
      const input = document.getElementById('global-search-input')
      input?.focus()
    }
  }, [open])

  // handlekey down event for arrow up and down to change active suggestion and enter to select suggestion and esc to close modal and clear query and save selected suggestion to recent search in local storage
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.key === 'Escape') {
      onClose()
      setQuery('')
    }
    if (e.key === 'ArrowDown') {
      const index = filteredSuggestions.findIndex(
        suggestion => suggestion.label === activeSuggestion?.label,
      )
      if (index < filteredSuggestions.length - 1) {
        setActiveSuggestion(filteredSuggestions[index + 1])
      }
    }
    if (e.key === 'ArrowUp') {
      const index = filteredSuggestions.findIndex(
        suggestion => suggestion.label === activeSuggestion?.label,
      )
      if (index > 0) {
        setActiveSuggestion(filteredSuggestions[index - 1])
      }
    }
    if (e.key === 'Enter') {
      e.preventDefault()
      push(activeSuggestion.href)
      onClose()
      setQuery('')
      setRecentSearches((prev: Suggestions[]) => {
        const index = prev.findIndex(
          suggestion => suggestion.label === activeSuggestion.label,
        )
        if (index === -1) {
          return [activeSuggestion, ...prev]
        }
        return prev
      })

      if (typeof window !== 'undefined') {
        localStorage.setItem('recentSearches', JSON.stringify(recentSearches))
      }
    }
    // save recent searches to local storage
    localStorage.setItem('recentSearches', JSON.stringify(recentSearches))
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
    setActiveSuggestion(filteredSuggestions[0])
    onSearch(e.target.value)
  }

  useEffect(() => {
    localStorage.setItem('recentSearches', JSON.stringify(recentSearches))
  }, [recentSearches])

  return (
    <div
      className={`fixed inset-0 z-50 bg-black bg-opacity-50 transition-opacity ${
        open ? 'opacity-100' : 'pointer-events-none opacity-0'
      }`}
      onKeyDown={open ? handleKeyDown : undefined}
      onClick={onClose}
    >
      <div className="fixed inset-0 flex items-center justify-center">
        <div
          onClick={e => e.stopPropagation()}
          className="w-full max-w-md rounded-md bg-white p-4 shadow-md"
        >
          <form className="w-full ">
            <div className="flex items-center justify-between">
              <input
                id="global-search-input"
                type="text"
                autoComplete="off"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                value={query}
                onChange={handleSearch}
                className="w-full rounded-md border border-gray-300 p-2 focus:border-blue-500 focus:outline-none"
                placeholder="Search..."
              />
            </div>

            <div className="mt-4">
              {filteredSuggestions.slice(0, 5).map(suggestion => (
                <div
                  key={suggestion.label}
                  className={`flex cursor-pointer items-center justify-between rounded-md p-2 transition-colors hover:bg-gray-100 ${
                    activeSuggestion?.label === suggestion.label
                      ? 'bg-gray-100'
                      : ''
                  }`}
                  onClick={() => {
                    push(suggestion.href)
                    onClose()
                    setQuery('')
                    setRecentSearches((prev: Suggestions[]) => {
                      const selectedSuggestion = prev.find(
                        recentSearch => recentSearch.label === suggestion.label,
                      )
                      if (selectedSuggestion) {
                        return prev
                      }

                      return [suggestion, ...prev]
                    })
                  }}
                >
                  <div className="flex flex-col">
                    <span
                      className="text-sm font-medium text-gray-900"
                      dangerouslySetInnerHTML={{
                        __html: suggestion.label.replace(
                          new RegExp(query, 'gi'),
                          match => `<span class="text-primary">${match}</span>`,
                        ),
                      }}
                    />

                    <span className="text-xs text-gray-500">
                      {suggestion.description}
                    </span>
                    <span className="text-xs text-gray-500">
                      {suggestion.href}
                    </span>
                  </div>
                  <svg
                    className="h-4 w-4 text-gray-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              ))}
            </div>
            <div className="mt-2">
              {/* recent searches that are highlighted on hover and can be controlled with arrow keys */}
              {recentSearches.length > 0 && (
                <div className="text-sm text-gray-500">Recent Searches</div>
              )}
              {recentSearches.slice(0, 4).map((recentSearch: Suggestions) => (
                <a
                  // key={recentSearch.label}
                  // href={recentSearch.href}
                  className="block p-2 hover:bg-gray-100"
                >
                  <div className="flex items-center">
                    <div className="flex-1">
                      <div className="font-medium">{recentSearch.label}</div>
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        // remove recent search from local storage, update local storage and force update to re render
                        const updatedRecentSearches = recentSearches.filter(
                          (search: Suggestions) =>
                            search.label !== recentSearch.label,
                        )
                        setRecentSearches(updatedRecentSearches)
                        localStorage.setItem(
                          'recentSearches',
                          JSON.stringify(updatedRecentSearches),
                        )

                        // force update to re render
                        setRecentSearches((prev: Suggestions[]) => [...prev])
                      }}
                    >
                      <svg
                        className="h-4 w-4 text-gray-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </a>
              ))}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AutoComplete
