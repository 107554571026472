import { sentencize, stringify } from 'utils'

export const DynamicGrid = <Item,>({ item }: { item: Item }) => (
  <>
    {Object.entries(item ?? {}).map(([key, value]) =>
      typeof value === 'object' ? (
        Object.entries(value ?? {}).length ? (
          <div className="my-2 rounded border bg-[#0000000a] p-2">
            <p className="text-primary text-xl font-bold">{sentencize(key)}</p>
            <div>
              <DynamicGrid item={value} />
            </div>
          </div>
        ) : null
      ) : (
        <div className="flex items-center justify-between ">
          <pre className="mr-10 font-bold">{sentencize(key)}:</pre>
          <pre>
            {typeof value === 'string' && !value.includes('@') ? (
              sentencize(stringify(key, value))
            ) : value === undefined ? (
              <span className="opacity-50">NA</span>
            ) : (
              stringify(key, value)
            )}
          </pre>
        </div>
      ),
    )}
  </>
)

interface Props<Item> {
  data: Item[]
}

export const DynamicGridList = <Item,>({ data }: Props<Item>) => {
  console.log(data)

  return (
    <div>
      {Object.keys(data[0] as any).length !== 0
        ? data.map(item => (
            <div className="mt-10 flex max-h-[1000px] flex-col flex-wrap gap-5 overflow-y-auto bg-[#3838380a] p-5 shadow">
              <DynamicGrid item={item} />
            </div>
          ))
        : null}
    </div>
  )
}
