import { RenewalTrademarkDetails } from 'api'
import Image from 'next/image'
import { useMemo } from 'react'
import { formatDate } from 'utils'
import { Deadline } from './Deadline'
// eslint-disable-next-line import/no-named-as-default
import StatusText from './StatusText'

interface Props {
  trademarkDetails: RenewalTrademarkDetails
  renewal?: boolean
}

export const TrademarkInfoCard = ({ trademarkDetails, renewal }: Props) => {
  const ownerType30 = useMemo(
    () =>
      trademarkDetails.trademark?.trademark_owner.find(
        owner => owner.party_type === '30',
      ),
    [trademarkDetails.trademark.trademark_owner],
  )

  return trademarkDetails ? (
    <div className="grid w-full grid-cols-2 gap-10 rounded-md border-[1px] px-8 py-10 ">
      <div className="flex flex-col items-center justify-center">
        <div className="relative flex h-full w-full items-center justify-center rounded-lg border-2">
          {
            <Image unoptimized 
              //   loader={() => imageUrl!}
              src={`https://usmark.s3.amazonaws.com/${trademarkDetails?.trademark?.serial_number}`}
              alt={trademarkDetails?.trademark?.mark_identification}
              layout="fill"
              objectFit="contain"
            />
          }
        </div>
        <div className="mt-6 text-xl font-bold leading-7 text-[#3F3F3F]">
          {trademarkDetails.trademark?.mark_identification}
        </div>
        <div className="flex flex-col items-center justify-center">
          <StatusText>
            {trademarkDetails.trademark?.status_code.toString()}
          </StatusText>
          <p>
            on{' '}
            {formatDate((trademarkDetails?.trademark.status_date || 0) * 1000)}{' '}
          </p>
        </div>
      </div>
      <div>
        <div>
          <p className="text-sm font-medium text-[#F6300A] ">Owned By</p>
          <div className="mt-2 ">
            <p className="text-sm font-semibold leading-5 text-[#636363]">
              {ownerType30?.party_name ||
                trademarkDetails.trademark?.trademark_owner[0]?.party_name}
            </p>
            <p className="text-sm font-medium leading-5 text-[#636363]">
              {ownerType30?.address_1 ||
                trademarkDetails.trademark?.trademark_owner[0]?.address_1}
            </p>
            <p className="inline text-sm font-medium leading-5 text-[#636363]">
              {ownerType30?.city ||
                trademarkDetails.trademark?.trademark_owner[0]?.city}{' '}
            </p>
            <p className="inline text-sm font-medium leading-5 text-[#636363]">
              {ownerType30?.state ||
                trademarkDetails.trademark?.trademark_owner[0]?.state}{' '}
            </p>
            <p className="inline text-sm font-medium leading-5 text-[#636363]">
              {ownerType30?.post_code ||
                trademarkDetails.trademark?.trademark_owner[0]?.post_code}
            </p>
          </div>
        </div>
        <div className="mt-6">
          <p className="text-sm font-medium text-[#F6300A] ">
            Legal Correspondent
          </p>
          <div className="mt-2 ">
            <p className="text-sm font-semibold leading-5 text-[#636363]">
              {trademarkDetails.trademark?.trademark_correspondent?.address_1}
            </p>
            <p className="text-sm font-medium leading-5 text-[#636363]">
              {trademarkDetails.trademark?.trademark_correspondent?.address_2}
            </p>
            <p className="inline text-sm font-medium leading-5 text-[#636363]">
              {trademarkDetails.trademark?.trademark_correspondent?.address_3}
            </p>
            <p className="inline text-sm font-medium leading-5 text-[#636363]">
              {trademarkDetails.trademark?.trademark_correspondent?.address_4}
            </p>
            <p className="inline text-sm font-medium leading-5 text-[#636363]">
              {trademarkDetails.trademark?.trademark_correspondent?.address_5}
            </p>
          </div>
        </div>
        {renewal && (
          <div className="mt-6">
            <p className="text-sm font-medium text-[#F6300A] ">
              Trademark Dates
            </p>
            <div className="mt-2 flex flex-col items-start">
              <p className="text-sm font-medium leading-5 text-[#636363]">
                Registered on{' '}
                <span>
                  {' '}
                  {formatDate(trademarkDetails.trademark.filing_date * 1000, {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    // eslint-disable-next-line react/no-unstable-nested-components
                    formatBy: ({ date, month, year, sup }) => (
                      <span>
                        {date}
                        <sup>{sup}</sup> {month} {year}
                      </span>
                    ),
                    addnth: true,
                  })}
                </span>
              </p>
              {trademarkDetails.date !== 0 &&
                trademarkDetails.type === 'renewal' && (
                  <div>
                    <p className="text-sm font-medium leading-5 text-[#636363]">
                      Expires on{' '}
                      <span>
                        {' '}
                        {formatDate(trademarkDetails.date * 1000, {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric',
                          // eslint-disable-next-line react/no-unstable-nested-components
                          formatBy: ({ date, month, year, sup }) => (
                            <span>
                              {date}
                              <sup>{sup}</sup> {month} {year}
                            </span>
                          ),
                          addnth: true,
                        })}
                      </span>
                    </p>
                    <div className="float-left">
                      <Deadline>{trademarkDetails.date}</Deadline>
                    </div>
                  </div>
                )}

              {/* <p className=" text-sm font-medium leading-5 text-[#636363]">
                {trademarkDetails.trademark?.trademark_section_info
                  .section_8_accepted_in
                  ? `Trademark Renewal for ${values.StatementsOld.length}  ${
                      values.StatementsOld.length === 1 ? 'class' : 'classes'
                    } in Section 8`
                  : `Trademark Renewal for ${values.StatementsOld.length} ${
                      values.StatementsOld.length === 1 ? 'class' : 'classes'
                    } in Section 8 + Section 9`}
              </p> */}
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <p>loading...</p>
  )
}
