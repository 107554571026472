// A Floating Action Button (FAB) performs the primary, or most common, action on a screen.

// A floating action button appears in front of all screen content, typically as a circular shape with an icon in its center. FABs come in two types: regular, and extended.

// Only use a FAB if it is the most suitable way to present a screen's primary action. Only one component is recommended per screen to represent the most common action.

interface FloatButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  // The icon to display in the button. This can be a React element, or a string.
  icon: React.ReactNode
  // The label to display in the button. This is only used for extended FABs.
  label?: string
  // The variant to use.
  variant?: 'regular' | 'extended'
  // The color of the button. It supports those theme colors that make sense for this component.
  color?: 'default' | 'inherit' | 'primary' | 'secondary'
  // If true, the button will be disabled.
  disabled?: boolean
  // If true, the button will take up the full width of its container.
  fullWidth?: boolean
  // The size of the button.
  size?: 'small' | 'medium' | 'large'
  fixed?: boolean
}

const FloatButton = ({
  icon,
  label,
  variant = 'regular',
  color = 'primary',
  disabled = false,
  fullWidth = false,
  size = 'medium',
  fixed = false,
  ...props
}: FloatButtonProps) => (
  <button
    type="button"
    className={`${
      variant === 'regular'
        ? 'rounded-full p-4 shadow-lg'
        : 'flex items-center justify-between rounded-md px-4 py-2'
    } ${
      color === 'default'
        ? 'bg-[#313131] text-white'
        : color === 'inherit'
        ? 'bg-transparent text-gray-700'
        : color === 'primary'
        ? 'bg-blue-500 text-white'
        : 'bg-green-500 text-white'
    }
    ${fixed ? 'fixed bottom-8 right-8' : ''}
     ${
       disabled
         ? 'cursor-not-allowed'
         : 'hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
     } ${fullWidth ? 'w-full' : size === 'small' ? 'h-12 w-12' : 'h-16 w-16'}`}
    disabled={disabled}
    {...props}
  >
    {variant === 'regular' ? (
      icon
    ) : (
      <>
        {icon}
        <span className="ml-2">{label}</span>
      </>
    )}
  </button>
)

export default FloatButton
