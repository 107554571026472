import { createFetcher, ROUTES } from '../api'
import { FetchOrder } from './orders'

export const fetchCreateOwnershipOfflineOrder = createFetcher<FetchOrder, any>({
  url: ROUTES.OFFLINE.CREATE,
  method: 'POST',
  withTokens: true,
})

export const fetchCreateSocialOfflineOrder = createFetcher<any, any>({
  url: ROUTES.OFFLINE.CREATE,
  method: 'POST',
  withTokens: true,
})

export const fetchCreateRenewalOfflineOrder = createFetcher<any, any>({
  url: ROUTES.OFFLINE.CREATE,
  method: 'POST',
  withTokens: true,
})

export const fetchCreateOfficeOfflineOrder = createFetcher<
  any,
  {
    order_id: string
    item_type: 'office_action'
    office_action: {
      prices_id: string
      serial_number: string
      confusion_likelihood: number
      gs_description: number
      notice_of_disclaimer: number
      generic_mark: number
    }
  }
>({
  url: ROUTES.OFFLINE.CREATE,
  method: 'POST',
  withTokens: true,
})

export const fetchCreateTrademarkOfflineOrder = createFetcher<
  any,
  {
    order_id: string
    item_type: 'trademark'
    offline_order_rep: string
    trademark_information: {
      is_name_protected: boolean
      mark_name: string
      insurance_type: string
      privacy_guard_type: string
      prices_id: string[]
      is_slogan_protected: boolean
      is_sound_protected: boolean
      sound_url: string
      slogan: string
      is_logo_protected: boolean
      logo_url: string
      is_colour_protected: boolean
      is_attorney_review_requested: boolean
      is_trademark_already_using: boolean
      is_good_protected: boolean
      is_service_protected: boolean
      good_classifications: string[]
      service_classifications: string[]
      priority_type: number
    }
  }
>({
  url: ROUTES.OFFLINE.CREATE,
  method: 'POST',
  withTokens: true,
})

export const fetchCreateSearchOfflineOrder = createFetcher<
  any,
  {
    order_id: string
    item_type: 'search'
    tm_search: {
      prices_id: string
      tm_order_id: string
    }[]
  }
>({
  url: ROUTES.OFFLINE.CREATE,
  method: 'POST',
  withTokens: true,
})
