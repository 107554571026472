import React, { FC, useState } from 'react'
import { accessibleOnClick } from 'utils'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  maxLength?: number
}

interface SectionProps {
  data?: string[]
}
export const ReadMore: FC<Props & SectionProps> = ({
  data,
  children,
  className,
  maxLength = 150,
  ...props
}) => {
  const text = children as string | undefined
  const [limit, setLimit] = useState(maxLength)
  const [isReadMore, setIsReadMore] = useState(true)
  const toggleReadMore = () => {
    if (limit < (text?.length || 0)) {
      setIsReadMore(true)
      setLimit(p => p + maxLength)
    } else {
      setIsReadMore(false)
      setLimit(maxLength)
    }
  }
  return (
    <div {...props}>
      {isReadMore
        ? text?.toString().slice(0, limit)
        : text
            ?.toString()
            ?.split('\n')
            .map(item => <p className="first-letter:capitalize">{item}</p>)}
      <span
        {...accessibleOnClick(toggleReadMore)}
        className="read-or-hide text-primary ml-2 cursor-pointer"
      >
        {(text?.toString().length || 0) > limit
          ? limit < (text?.length || 0)
            ? 'Read More'
            : 'Read Less'
          : ''}
      </span>
    </div>
  )
}
