import { OrderItemPaymentStatus, OrderStatus, Proof } from '../interfaces'
import { createFetcher, ROUTES } from '../api'
import { PricingRefOwnership } from './ownership'
import { PricingRefSou } from './sou'
import { Transaction } from './clients'

export const fetchMatters = createFetcher<Matter[]>({
  url: ROUTES.MATTERS.GET_ALL,
  withTokens: true,
})
export const fetchMatter = createFetcher<
  MatterDetails,
  any,
  { matter_id: string }
>({
  url: ROUTES.MATTERS.GET_ONE,
  withTokens: true,
})

export const fetchUpdateMatterClientDetails = createFetcher<
  { message: string; success: boolean },
  UpdateMatterClientBody
>({
  method: 'PUT',
  url: ROUTES.MATTERS.UPDATE_CLIENT,
  withTokens: true,
})

export const fetchMattersAttorney = createFetcher<Matter[]>({
  url: ROUTES.MATTERS.GET_ATTORNEY,
  withTokens: true,
})

export const fetchMatterReport = createFetcher<MatterReport[]>({
  url: ROUTES.MATTERS.GET_REPORT,
  withTokens: true,
})

export const fetchSubscriptionsByOrder = createFetcher<
  SubscriptionBody[],
  any,
  any,
  { order_id: string }
>({
  url: ROUTES.MATTERS.GET_SUBSCRIPTIONS_BY_ORDER,
  withTokens: true,
})

export const fetchMatterTransactionsByOrderId = createFetcher<
  {
    transactions: Transaction[]
  },
  any,
  { order_id: string }
>({
  url: ROUTES.MATTERS.GET_TRANSACTIONS_BY_ORDER_ID,
  withTokens: true,
})

export interface Subscription {}

export interface SubscriptionBody {
  subscription_id: string
  is_active: boolean
  created_at: number
  updated_at: number
  order_id: string
  order_item_id: string
  order: SubscriptionOrder
  order_item: SubscriptionOrderItem
  subscription_amount: number
  subscription_payment_period: number
  plan_id: string
  status: string
  billing_day_of_month: number
  next_billing_period_amount: number
  next_billing_date: number
  status_history: StatusHistory[]
  transactions: any
  trial_period: number
  billing_period_end_date?: number
  billing_period_start_date?: number
  current_billing_cycle: number
}

export interface SubscriptionOrder {
  id: string
  matter_id: any
  user_id: any
  created_at: number
  updated_on: number
  payed_on: number
  order_status: number
  payment_status: number
  address_id: any
  email: string
  phone: string
  full_name: string
  billable_amount: number
  billable_amount_paid: number
  billable_amount_refunded: number
  coupon_id: any
  coupon_code: string
  total_discount: number
  is_current_cart: boolean
  transactions: any
  order_items: any
  IsFromOldDB: boolean
}

export interface SubscriptionOrderItem {
  id: string
  user_id: any
  order_id: string
  added_at: number
  quantity: number
  billable_amount: number
  billable_amount_paid: number
  billable_amount_refunded: number
  subscription_amount: number
  subscription_payment_period: number
  discount: number
  item_type: string
  order_item_status: number
  payment_status: number
  prices_id: any
  group_order_item_id: string
  pricing_ref: any
  is_offline_order: boolean
  offline_order_rep: any
  offline_order_created_by: any
  is_govt_fee_generated: boolean
  transaction_id: any
  refund_id: any
}

export interface StatusHistory {
  price: number
  status: string
  subscription_source: string
}

export interface MatterReport {
  date: number
  total_matters: number
  offline_total: number
  us_attorney_offline: number
  online_total: number
  total_govt_fee: number
}

export interface Address {
  id: string
  line1: string
  line2: string
  city: string
  state: string
  country: string
  postal_code: string
  created_at: null | number
  updated_on: null | number
  deleted_at: null | number
}

export interface UpdateMatterClientBody {
  id: string
  full_name: string
  email: string
  phone: string
}

export interface Matter<Type extends keyof DynamicOrderItemDetails = 'any'> {
  id: string
  matter_id: number
  user_id: string
  user_revenue: number
  client_relation_id: string
  created_at: number
  updated_on: number
  payed_on: number
  order_status: OrderStatus
  payment_status: OrderItemPaymentStatus
  total_discount: number
  nick_name: string
  address_id: null | string
  email: string
  phone: string
  coupon_code: null | string
  full_name: string
  billable_amount: number
  billable_amount_paid: number
  billable_amount_refunded: number
  billable_govt_amount: number
  billable_govt_amount_paid: number
  billable_govt_amount_refunded: number
  billable_govt_amount_paid_direct: number
  is_current_cart: boolean
  transactions: null | any[]
  order_items: null | OrderItem<Type>[]
  items: string[]
  utm_params: UtmParams
}

export interface UtmParams {
  order_id: string
  utm_source: string
  utm_medium: string
  utm_campaign: string
  utm_term: string
  utm_content: string
}

export interface MatterDetails<Type extends keyof DynamicOrderItemDetails = any>
  extends Matter<Type> {
  user: User
  address: null | Address
}

export interface User {
  id: string
  created_at: number
  updated_on: number
  client_relation_id: string
  uid: string
  full_name: string
  email: string
  phone: string
  address_id: string
  payment_random_id: any
  avatar: string
  last_logged_in: number
  email_subscription: boolean
  country_code: string
  revenue: number
  total_matters: number
  additional_emails: any
}

export interface PricingRef {
  filing_fee: number
  standard_cost: number
  filling_fee_second_class: number
  standard_cost_additional_class: number
  filing_govt_fee_additional_class: number
}

export type Services =
  | 'trademark_order'
  | 'ownership_transfer'
  | 'video_production'
  | 'renewal'
  | 'search'
  | 'office_action'
  | 'sou'
  | 'in_contestability'
  | 'border_protection'
  | 'revival'
  | 'govt_fee'
  | 'priority'
  | 'social_media'
  | 'attorney_consultation'
  | 'patent'
  | 'domain'
  | 'app_dev'
  | 'legal_forms'
  | 'logo_design'
  | 'monitoring'
  | 'insurance'
  | 'digital_marketing'
  | 'privacy_guard'
  | 'incd_business'
  | 'apparel'
  | 'international_tm_reg'
  | 'paid_ads'
  // renewal add ons
  | 'renewal_ownership_update'
  | 'renewal_assurance'
  | 'incontestability'
  | 'border_protection'
  | 'piracy_report'
  | 'renewal_delete_class'
  | 'renewal_new_product'
  | 'renewal_post_filing_actions'
  | 'trademark_plaque'

export interface DynamicOrderItemDetails {
  any: {
    Metadata: any
    PricingRef: any
  }
  Trademark: {
    Metadata: USTMMetadata
    PricingRef: PricingRef
  }
  Renewal: {
    Metadata: RenewalMetadata
    PricingRef: any
  }
  Search: {
    Metadata: SearchMetadata
    PricingRef: any
  }
  Priority: {
    Metadata: PriorityMetadata
    PricingRef: any
  }
  OfficeAction: {
    Metadata: OfficeMetadata
    PricingRef: any
  }
  SocialMedia: {
    Metadata: SocialMetadata
    PricingRef: any
  }
  OwnershipTransfer: {
    Metadata: OwnershipMetadata
    PricingRef: PricingRefOwnership
  }
  SOU: {
    Metadata: SOUMetadata
    PricingRef: PricingRefSou
  }
  Revival: {
    Metadata: RevivalMetadata
    PricingRef: PricingRefRevival
  }
  GovtFee: {
    Metadata: GovtFeeMetadata
    PricingRef: {
      base_fee: number
    }
  }
  InContestability: {
    Metadata: InContestabilityMetadata
    PricingRef: {
      base_fee: number
    }
  }
  BorderProtection: {
    Metadata: BorderProtectionMetadata
    PricingRef: {
      base_fee: number
    }
  }
  Insurance: {
    Metadata: InsuranceMetadata
    PricingRef: {
      base_fee: number
    }
  }
  PrivacyGuard: {
    Metadata: PrivacyGuardMetadata
    PricingRef: {
      base_fee: number
    }
  }
  DigitalMarketing: {
    Metadata: DigitalMarketingMetadata
    PricingRef: {
      base_fee: number
    }
  }
  AttorneyConsultation: {
    Metadata: AttorneyConsultationMetadata
    PricingRef: {
      base_fee: number
    }
  }
  Patent: {
    Metadata: PatentMetadata
    PricingRef: {
      base_fee: number
    }
  }
  Domain: {
    Metadata: DomainMetadata
    PricingRef: {
      base_fee: number
    }
  }
  AppDev: {
    Metadata: AppDevMetadata
    PricingRef: {
      base_fee: number
    }
  }
  LegalForms: {
    Metadata: LegalFormsMetadata
    PricingRef: {
      base_fee: number
    }
  }
  LogoDesign: {
    Metadata: LogoDesignMetadata
    PricingRef: {
      base_fee: number
    }
  }
  Monitoring: {
    Metadata: MonitoringMetadata[]
    PricingRef: {
      base_fee: number
    }
  }
  IncdBusiness: {
    Metadata: IncdBusinessMetadata
    PricingRef: {
      base_fee: number
    }
  }
  Apparel: {
    Metadata: ApparelMetadata
    PricingRef: {
      base_fee: number
    }
  }
  InternationalTmReg: {
    Metadata: InternationalTmRegMetadata
    PricingRef: {
      base_fee: number
    }
  }
  Old: {
    Metadata: any
    PricingRef: any
  }
}

export interface OrderItem<Type extends keyof DynamicOrderItemDetails = any> {
  id: string
  user_id: string
  order_id: string
  payed_on: number
  added_at: number
  quantity: number
  refund_id: null | string
  is_govt_fee_generated: boolean
  is_offline_order: boolean
  offline_order_created_by: string
  offline_order_rep: string
  discount: number
  govt_fee: number
  billable_amount: number
  billable_amount_paid: number
  billable_amount_refunded: number
  item_type: Services
  group_order_item_id: string
  subscription_amount: number
  subscription_payment_period: number
  order_item_status: number
  payment_status: number
  prices_id: string
  metadata: DynamicOrderItemDetails[Type]['Metadata']
  pricing_ref: DynamicOrderItemDetails[Type]['PricingRef']
}

// METADATA
export interface USTMMetadata {
  order_item_id: string
  created_at: number
  updated_on: number
  order_status: number
  classes: number
  prices_id: string
  is_name_protected: boolean
  mark_name: string
  is_slogan_protected: boolean
  slogan: string
  is_logo_protected: boolean
  logo_url: string
  is_colour_protected: boolean
  logo_description: string
  is_trademark_already_using: boolean
  is_step1_completed: boolean
  is_step2_completed: boolean
  is_attorney_review_requested: boolean
  is_step3_completed: boolean
  website: string
  insurance_type: string
  is_step4_completed: boolean
  priority_type: string
  is_submitted_for_processing: boolean
  search_terms: any
  is_sound_protected: boolean
  sound_url: string
  privacy_guard_type: string
  description: string
  is_classifications_confirmed: boolean
  is_good_protected: any
  is_service_protected: any
  good_description: any
  service_description: any
  is_good_classifications_confirmed: any
  is_service_classifications_confirmed: any
  package: string
}

export interface RenewalMetadata {
  order_item_id: string
  created_at: number
  updated_on: number
  renewal_deadline: number
  order_status: number
  prices_id: string
  serial_number: string
  classes: number
  proof?: Proof[]
  deliverables?: any
  tm_incontestability_id?: any
  tm_ownership_transfer_id?: any
  is_renewal_admin_classifications: boolean
  is_grace: boolean
  is_rapid_processing: boolean
  is_section8_filed: boolean
  is_submitted_for_processing: boolean
}

export interface SearchMetadata {
  order_item_id: string
  created_at: number
  updated_on: number
  order_status: number
  prices_id: string
  tm_order_id: string
  search_document: any[]
  search_type: number
  current_step: number
}

export interface RevivalMetadata {
  order_item_id: string
  created_at: number
  updated_on: number
  order_status: number
  revival_type: number
  sou_id: string
  office_actions_id: any
  current_step: number
}

export interface PricingRefRevival {
  revival_govt_fee: number
  revival_prof_fee: number
}

export interface SOUMetadata {
  order_item_id: string
  created_at: number
  updated_on: number
  order_status: number
  deadline: number
  prices_id: string
  intent_to_use: boolean
  serial_number: string
  first_use_date: any
  first_use_public_date: any
  proof: RevivalProof[]
  intent_to_use_description: string
  deliverables: any
  is_submitted_for_processing: boolean
  is_revival_order: boolean
}

export interface RevivalProof {
  proof: Proof[]
  is_saved: boolean
  description: string
  first_use_date_any: number
  first_use_date_com: number
  is_being_used_to_sell: boolean
  trademark_classification_class_id: string
}

export interface PriorityMetadata {}

export interface OfficeMetadata {
  order_item_id: string
  created_at: number
  updated_on: number
  order_status: number
  deadline: number
  prices_id: string
  serial_number: string
  confusion_likelihood: string
  office_actions_admin_id: any
  deliverables: any
  gs_description: string
  notice_of_disclaimer: string
  generic_mark: string
  tags: any
  is_submitted_for_processing: boolean
  is_revival_order: boolean
}

export interface GovtFeeMetadata {
  order_item_id: string
  created_at: number
  updated_on: number
  ref_order_item_id: string
}

export interface SocialMetadata {
  order_item_id: string
  created_at: number
  updated_on: number
  order_status: OrderStatus
  pack: '28p' | '50p' | '100p'
  username: string
  username_summary: string
  description: string
}

export interface OwnershipMetadata {
  order_item_id: string
  created_at: number
  updated_on: number
  order_status: number
  prices_id: string
  proof: any
  serial_number: string
  is_admin_add_owner_req: boolean
  is_submitted_for_processing: boolean
  correspondent_name: any
  correspondent_country: any
  correspondent_city: any
  correspondent_state: any
  correspondent_zip: any
  deliverables: any
}

export interface InContestabilityMetadata {
  order_item_id: string
  created_at: number
  updated_on: number
  order_status: number
  classes: number
  serial_number: string
  current_step: number
}

export interface BorderProtectionMetadata {
  order_item_id: string
  created_at: number
  updated_on: number
  order_status: number
  classes: number
  serial_number: string
  current_step: number
}

export interface InsuranceMetadata {
  order_item_id: string
  created_at: number
  updated_on: number
  trademark_insurance_type: string
  trademark_order_id: string
  serial_number: string
}

export interface PrivacyGuardMetadata {
  order_item_id: string
  created_at: number
  updated_on: number
  privacy_type: string
  trademark_order_id: string
}

export interface DigitalMarketingMetadata {
  order_item_id: string
  created_at: number
  updated_on: number
  marketing_type: string
}
/**
 * @param type 1 for free - 15 mins, 2 for $99 - 30 mins and 3 for $199 - 60 mins
 *
 */
export interface AttorneyConsultationMetadata {
  order_item_id: string
  attorney_id: string
  type: 1 | 2 | 3
  created_at: number
  updated_on: number
}

export interface PatentMetadata {
  created_at: number
  order_item_id: string
  updated_on: number
}
export interface DomainMetadata {
  created_at: number
  order_item_id: string
  updated_on: number
}
export interface AppDevMetadata {
  created_at: number
  order_item_id: string
  updated_on: number
}
export interface LegalFormsMetadata {
  created_at: number
  order_item_id: string
  updated_on: number
}
export interface LogoDesignMetadata {
  created_at: number
  order_item_id: string
  updated_on: number
}
export interface MonitoringMetadata {
  created_at: number
  order_item_id: string
  updated_on: number
}
export interface IncdBusinessMetadata {
  created_at: number
  order_item_id: string
  updated_on: number
}
export interface ApparelMetadata {
  created_at: number
  order_item_id: string
  updated_on: number
}
export interface InternationalTmRegMetadata {
  created_at: number
  order_item_id: string
  updated_on: number
}
