import axios from 'axios'
import { adminRoute, createFetcher, ROUTES } from '../api'

// Tries to fiqgure out all unassigned matters and assigns it automatically, it does not matter where you run it, it should just run often. best to put in dashboard.
export const fetchUnassignedCount = createFetcher<{ count: string }>({
  url: ROUTES.US_FILING_NEW.GET_UNASSIGNED_COUNT,
  withTokens: true,
})

// Get the dashboard of all teams of every attorney
export const fetchUsFilingDashboard = createFetcher<UsFilingDashboard[]>({
  url: ROUTES.US_FILING_NEW.GET_US_FILING_DASHBOARD,
  withTokens: true,
})

// Get all the attorney orders by team id for steps 1,2,3,4 - supports filters for matter_id, email, attorney_id (required)
export const fetchUsFilingAttorneyOrders = createFetcher<
  UsFilingAttorneyOrder[]
>({
  url: ROUTES.US_FILING_NEW.GET_US_FILING_ATTORNEY_ORDERS,
  withTokens: true,
})

// get all the attorney orders by team id for steps 5,6,7 - supports filters for matter_id, email, attorney_id (optional)
export const fetchPostAdvisoryUsFilingAttorneyOrders = createFetcher<
  UsFilingAttorneyOrder[]
>({
  url: ROUTES.US_FILING_NEW.GET_POST_ADVISORY_US_FILING_ATTORNEY_ORDERS,
  withTokens: true,
})

// GET details of each orderItem - steps tracker, search report, diligence report, final conflict report, etc
export const fetchTmProcessing = createFetcher<
  UsFilingTmProcessingOrder,
  any,
  {
    tm_order_id: string
  }
>({
  url: ROUTES.US_FILING_NEW.GET_US_FILING_TM_PROCESSING_DETAILS,
  withTokens: true,
})

// GET details of each Order - steps tracker, search report, diligence report, final conflict report, etc, there will be multiple order items
export const fetchTmProcessingByOrder = createFetcher<
  UsFilingTmProcessing[],
  any,
  {
    order_id: string
  }
>({
  url: ROUTES.US_FILING_NEW.GET_US_FILING_TM_PROCESSING_DETAILS_BY_ORDER,
  withTokens: true,
})

// Update the search report, add search elemetns, mark as complete etc
export const fetchUpdateSearchReport = createFetcher<
  {
    message: string //update response
  },
  SearchReportUpdateBody, // body to send
  {
    tm_order_id: string // url params
  }
>({
  url: ROUTES.US_FILING_NEW.UPDATE_SEARCH_REPORT,
  method: 'PATCH',
  withTokens: true,
})

export const fetchUpdateDiligenceReport = createFetcher<
  {
    message: string //update response
  },
  DiligenceReportBody, // body to send
  {
    tm_order_id: string // url params
  }
>({
  url: ROUTES.US_FILING_NEW.UPDATE_DILIGENCE_REPORT,
  method: 'PATCH',
  withTokens: true,
})

export const fetchUpdateConflictCheckUpdate = createFetcher<
  {
    message: string //update response
  },
  ConflictCheckBody, // body to send
  {
    tm_order_id: string // url params
  }
>({
  url: ROUTES.US_FILING_NEW.UPDATE_CONFLICT_CHECK_REPORT,
  method: 'PATCH',
  withTokens: true,
})

// Update the filing report, add classes, trademark items, entities, mark as complete etc
export const fetchUpdateFilingReport = createFetcher<
  {
    message: string //update response
  },
  FilingDetailsBody, // body to send
  {
    tm_order_id: string // url params
  }
>({
  url: ROUTES.US_FILING_NEW.UPDATE_FILING_REPORT,
  method: 'PATCH',
  withTokens: true,
})

// update the siganture status and signature  link
export const fetchUpdateSignature = createFetcher<
  {
    message: string //update response
  },
  {
    is_complete: boolean
    signature_link?: string
    verify: boolean
  }, // body to send
  {
    tm_order_id: string // url params
  }
>({
  url: ROUTES.US_FILING_NEW.UPDATE_SIGNATURE,
  method: 'PATCH',
  withTokens: true,
})

// update or add the serial number for the final filing of the trademark order
export const fetchUpdateFinalFiling = createFetcher<
  {
    message: string //update response
  },
  {
    serial_number: number
  }, // body to send
  {
    tm_order_id: string // url params
  }
>({
  url: ROUTES.US_FILING_NEW.UPDATE_FINAL_FILING,
  method: 'PATCH',
  withTokens: true,
})

// upload specimens related to US Filing orders
export const fetchUploadSpecimen = createFetcher<
  {
    key: string
    url: string
  },
  FormData
>({
  url: ROUTES.US_FILING_NEW.UPLOAD_SPECIMEN,
  method: 'POST',
  withTokens: true,
  isFormData: true,
})

// upload email html files related to US Filing orders
export const uploadAdvisoryEmail = createFetcher<
  { key: string; url: string },
  FormData
>({
  url: ROUTES.US_FILING_NEW.UPLOAD_ADVISORY_EMAIL,
  method: 'POST',
  isFormData: true,
  withTokens: true,
})

export const uploadSignatureEmail = createFetcher<
  { key: string; url: string },
  FormData
>({
  url: ROUTES.US_FILING_NEW.UPLOAD_SIGNATURE_EMAIL,
  method: 'POST',
  isFormData: true,
  withTokens: true,
})

// update the draft advisory step 4
export const updateAdvisoryEmail = createFetcher<
  { message: string },
  {
    draft_advisory: {
      email_s3_key: string
      assessments: string[]
    }
    is_complete: boolean
  },
  { tm_order_id: string }
>({
  url: ROUTES.US_FILING_NEW.UPDATE_ADVISORY_EMAIL,
  method: 'PATCH',
  withTokens: true,
})

// update the draft advisory step 4
export const fetchDismissUsFilingOrder = createFetcher<
  { message: string },
  {
    reasons: string[]
  },
  { action: 'retain' | 'dismiss' },
  { id: string; current_step: number }
>({
  url: ROUTES.US_FILING_NEW.DIMISSAL_ACTION,
  method: 'PATCH',
  withTokens: true,
})

export const fetchEmail = async ({
  tm_order_id,
  type,
}: {
  tm_order_id: string
  type: 'advisory_email' | 'signature_email'
}): Promise<{ data?: string; error?: any }> => {
  try {
    const { data } = await axios.get(adminRoute(`/us/${type}/${tm_order_id}`), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    console.log({ data })
    const res = await axios.post('/api/proxy', {
      url: data.body,
    })
    return { data: res.data }
  } catch (error) {
    return { error }
  }
}

export interface FilingDetailsBody {
  is_filing_report_done: boolean
  filing_details: FilingDetails
}

export interface FilingDetails {
  trademark_item_type: string
  trademark_item: string
  description: string
  classes: FilingClasses[]
  entities: OwnerEntity[]
}

export interface FilingClasses {
  class_classification_id: string
  description: string
  links: Link[]
  images: string[]
  is_intent_to_use: boolean
}

export interface ConflictCheckBody {
  adverse_party: AdverseParty
  unfiled_matters: {
    unfiled_matters: string[]
    is_unfiled_complete: boolean
  }
  is_complete: boolean
}
export interface DiligenceReportBody {
  specimen_research?: SpecimenResearch
  ownership_research: OwnershipResearch
  is_complete: boolean
  steps_tracker_message: string
}

export interface SearchReportUpdateBody {
  search_elements: SearchElement[]
  watch_report?: WatchReport
  is_complete: boolean
}

export interface WatchReport {
  regex: string[]
  class_codes: string[]
}
export interface SearchElement {
  serial_number: string
  similarity_type: number
}

export interface UsFilingTmProcessingOrder {
  matter: UsFilingTmProcessing
  order_id: string
}

export interface UsFilingTmProcessing {
  tm_order_id: string
  tm_order: TmOrderDetails
  admin_us_tm_detailed: AdminUsTmDetailed
  created_at: number
  is_multi_application_matter: boolean
  classes_paid_count: number
  total_classes: number
  current_step: number
  pre_advisory_at: number
  attorney_id: string
  isFilingDone: boolean
  serialNumber: number
}

export interface TmOrderDetails {
  description: string
  order_item_id: string
  created_at: number
  updated_on: number
  classes: number
  prices_id: string
  is_name_protected: boolean
  mark_name: string
  logo_description: string
  is_sound_protected: boolean
  is_logo_protected: boolean
  logo_url: string
  is_slogan_protected: boolean
  slogan: string
  sound_url: string
  is_attorney_review_requested: boolean
  class_classifications: any
  package: string
  is_assigned: boolean
}

export interface AdminUsTmDetailed {
  admin_us_tm_processing_id: string
  adminUsTmProcessing: UsFilingTmProcessing
  is_search_done: boolean
  search_report: SearchReport[]
  serial_number: number
  is_diligence_done: boolean
  watch_report: WatchReport
  diligence_report: DiligenceReport
  is_draft_done: boolean
  is_final_conflict_done: boolean
  final_conflict_report: FinalConflictReport
  is_filing_report_done: boolean
  filing_details: FilingDetails
  is_signature_done: boolean
  signature_time: number
  signature_link: string
  steps_tracker: StepsTracker[]
  draft_report?: DraftReport
}

export interface FinalConflictReport {
  adverse_party: AdverseParty
  unfiled_matters: {
    unfiled_matters: string[]
    is_unfiled_complete: boolean
  }
  is_complete?: boolean
}

export interface AdverseParty {
  is_cleared: boolean
  ttab_conflicts: TtabConflict[]
  problematic_clients: string[]
}

export interface TtabConflict {
  serial_number: string
  notes?: string
}

export interface DiligenceReport {
  specimen_research: SpecimenResearch
  ownership_research: OwnershipResearch
  draft_advisory: DraftAdvisory
  is_complete?: boolean
}

export interface DraftAdvisory {
  email_s3_key: string
  assesments: string[]
  is_complete?: boolean
}
export interface SpecimenResearch {
  notes?: string
  links?: Link[]
  images: string[]
  is_complete?: boolean
}

export interface Link {
  link: string
  link_type: string
}

export interface OwnershipResearch {
  notes?: string
  entities: OwnerEntity[]
  is_complete: boolean
  social_links: Link[]
}

export interface OwnerEntity {
  city: string
  email: string
  state: string
  position: string
  zip_code: string
  is_primary: boolean
  citizenship: string
  entity_name?: string
  entity_type: string
  street_address: string
  name_of_applicant: string
  state_of_formation?: string
}

export interface StepsTracker {
  time: number
  action: string
  is_comp: boolean
  admin_id: string
}

export interface SearchReport {
  serial_number: string
  similarity_type: number
}

export interface DraftReport {
  email_s3_key?: string
}

export interface UsFilingDashboard {
  attorney_id: string
  timely: number
  late: number
  post_advisory: number
  rating: number
}

export interface UsFilingAttorneyOrder {
  matter_id: number
  order_id: string
  tm_order_id: string
  client_name: string
  client_email: string
  user_id: string
  classes: number
  is_multi_application_matter: boolean
  prices_id: string
  is_name_protected?: boolean
  is_slogan_protected?: boolean
  is_sound_protected?: boolean
  slogan?: string
  is_diligence_done: boolean
  is_draft_done: boolean
  is_final_conflict_done: boolean
  is_signature_done: boolean
  is_search_done?: boolean
  sound_url?: string
  mark_name?: string
  billable_amount: number
  is_filing_report_done: boolean
  serial_number?: number
  billable_amount_paid: number
  package?: string
  assigned_on: number
  paid_on: number
  client_phone?: string
  attorney_id?: string
  current_step: number
  description?: string
  legal_assistant_id?: string
  signature_time?: number
  signature_link?: string
  is_logo_protected?: boolean
  logo_url?: string
  website?: string
  steps_tracker: StepsTracker[]
}
