import { createFetcher, ROUTES } from '../api'
import { Transaction } from './clients'

export const fetchSubscriptionDashboard = createFetcher<
  SubscriptionDashboard[]
>({
  url: ROUTES.SUBSCRIPTIONS.GET_DASHBOARD,
  withTokens: true,
})

export const fetchSubscriptionMatterReport = createFetcher<
  {
    date: number
    sale_value: number
  }[]
>({
  url: ROUTES.SUBSCRIPTIONS.GET_MATTER_REPORT,
  withTokens: true,
})

export const fetchTransactionsByDate = createFetcher<
  TransactionsByDate[],
  any,
  any,
  { from_date: number; to_date: number }
>({
  url: ROUTES.MATTERS.GET_TRANSACTIONS_BY_DATE,
  withTokens: true,
})

export const fetchSubscriptionPrediction = createFetcher<
  {
    date: number
    sale_amount: number
  }[]
>({
  url: ROUTES.SUBSCRIPTIONS.GET_PREDICTION,
  withTokens: true,
})

export const fetchCancelSubscription = createFetcher<
  { amount_paid: number },
  any,
  { subscription_id: string },
  { cancel_refund: boolean | undefined }
>({
  method: 'DELETE',
  url: ROUTES.SUBSCRIPTIONS.GET_CANCEL_SUBSCRIPTION,
  withTokens: true,
})

export interface SubscriptionDashboard {
  plan_id: string
  active: number
  canceled: number
  past_due: number
}

export interface TransactionsByDate extends Transaction {
  email: string
  full_name: string
  matter_id: number
  user_id: string
  phone: string
}
