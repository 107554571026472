import React, { FC } from 'react'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  innerClassName?: string
}

export const Section: FC<Props> = ({
  children,
  className,
  innerClassName,
  ...props
}) => (
  <div
    {...props}
    className={['flex items-center justify-center', className].join(' ')}
  >
    {/* w-[100%] max-w-[90%] md:max-w-[80%] old class will have to revert */}
    <div
      className={['w-[100%] max-w-[90%] md:max-w-[80%]', innerClassName].join(
        ' ',
      )}
    >
      {children}
    </div>
  </div>
)
