import { CSSProperties, FC, SVGProps } from 'react'

export * from './404'
export * from './Accordion'
export * from './AddButton'
export * from './Asterisk'
export * from './BottomSheet'
export * from './Chips'
export * from './Deadline'
export * from './Fields/AutoComplete'
export * from './Fields/AutoCompletePlain'
export * from './Fields/SearchInput'
export * from './Fields/CardNumberField'
export * from './Fields/Checkbox'
export * from './Fields/CountrySelect'
export * from './Fields/DocInput'
export * from './Fields/Dropdown'
export * from './Fields/CheckBoxV2'
export * from './Fields/DropMultiSelect'
export * from './Fields/FileInput'
export * from './Fields/InputWithError'
export * from './Fields/MultiSelect'
export * from './Fields/Otp'
export * from './Fields/PhoneField'
export * from './Fields/Radio'
export * from './Fields/SelectField'
export * from './HoverInfo'
export * from './Switch'
export * from './Icon'
export * from './InfoCircle'
export * from './Inputs'
export * from './Pagination'
export * from './ReadMore'
export * from './Section'
export * from './StatusText'
export * from './Table'
export * from './DynamicTable'
export * from './DynamicGrid'
export * from './Tabs'
export * from './TrademarkInfoCard'
export * from './TipTap'
export * from './Collapsible'

export const lottieStyle: CSSProperties = {
  position: 'absolute',
  top: '0',
  left: '0',
  bottom: '0',
  right: '0',
  scale: 1.01,
  zIndex: 200,
}

export const NameIco: FC<SVGProps<SVGSVGElement>> = ({ fill, ...props }) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="none"
    stroke={fill}
  >
    <path
      d="M6.402 16.646h5.82M9.313 14.438v-3.864M6.984 8.366l2.328 2.208 2.329-2.208"
      strokeWidth={0.873}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.726 12.162a5.579 5.579 0 0 1-1.64-1.916 5.279 5.279 0 0 1-.594-2.401c-.017-2.992 2.526-5.48 5.68-5.55a6.05 6.05 0 0 1 3.431.965 5.57 5.57 0 0 1 2.187 2.687 5.251 5.251 0 0 1 .116 3.393 5.517 5.517 0 0 1-1.999 2.815 1.697 1.697 0 0 0-.502.583 1.606 1.606 0 0 0-.182.732v.416a.538.538 0 0 1-.17.39.598.598 0 0 1-.412.162H6.984a.598.598 0 0 1-.411-.162.538.538 0 0 1-.17-.39v-.416c-.001-.253-.063-.502-.18-.728a1.685 1.685 0 0 0-.497-.58v0Z"
      strokeWidth={1.164}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const LogoIco: FC<SVGProps<SVGSVGElement>> = ({ fill, ...props }) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="none"
    stroke={fill}
  >
    <path
      d="m2.125 12.172 6.375 3.72 6.375-3.72"
      strokeWidth={1.063}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.125 8.985 8.5 12.704l6.375-3.72"
      strokeWidth={1.063}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.125 5.797 8.5 9.517l6.375-3.72L8.5 2.08 2.125 5.797Z"
      strokeWidth={1.063}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const SloganIco: FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="#000"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9.45 2.833c-.16.455-.242.934-.242 1.417H3.542v9.916h9.916V8.5c.483 0 .962-.081 1.417-.242v6.617a.708.708 0 0 1-.708.708H2.833a.708.708 0 0 1-.708-.708V3.54a.708.708 0 0 1 .708-.708H9.45Zm4.008 2.833a1.417 1.417 0 1 0 0-2.833 1.417 1.417 0 0 0 0 2.833Zm0 1.417a2.833 2.833 0 1 1 0-5.666 2.833 2.833 0 0 1 0 5.666Z" />
  </svg>
)
export const GoodsIco = () => (
  <svg width={20} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 1.75v10.5h12V1.75H2ZM1.25.25h13.5a.75.75 0 0 1 .75.75v12a.75.75 0 0 1-.75.75H1.25A.75.75 0 0 1 .5 13V1a.75.75 0 0 1 .75-.75Zm2.25 3h2.25v7.5H3.5v-7.5Zm3 0H8v7.5H6.5v-7.5Zm2.25 0h.75v7.5h-.75v-7.5Zm1.5 0h2.25v7.5h-2.25v-7.5Z"
      fill="#000"
    />
  </svg>
)
export const ServicesIco = () => (
  <svg width={20} height={18} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3 9.2h-1.853a5.413 5.413 0 0 1-.689 1.64l1.31 1.31-1.786 1.785-1.309-1.31a5.462 5.462 0 0 1-1.623.672v1.853H6.8v-1.853a5.463 5.463 0 0 1-1.624-.671l-1.309 1.309-1.802-1.802 1.31-1.31A5.463 5.463 0 0 1 2.702 9.2H.85V6.676h1.844a5.536 5.536 0 0 1 .68-1.65L2.065 3.719 3.85 1.933l1.31 1.309a5.505 5.505 0 0 1 1.64-.689V.7h2.55v1.853c.578.136 1.13.366 1.623.672l1.31-1.31 1.801 1.803-1.309 1.309c.306.501.544 1.054.68 1.649H15.3V9.2Zm-7.225 1.275a2.547 2.547 0 0 0 2.55-2.55 2.547 2.547 0 0 0-2.55-2.55 2.547 2.547 0 0 0-2.55 2.55 2.547 2.547 0 0 0 2.55 2.55Z"
      fill="#000"
    />
  </svg>
)
