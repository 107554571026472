export interface OfficeActionsDetails {
  label: string
  explanation: string
  response: string
  complexity: number
  slug: string
  isHeading?: false
  subLabel?: string
}

interface SubHeading {
  label: string
  slug: string
  isHeading: true
  subTags: Omit<OfficeActionsDetails, 'label'>[]
}

export const officeActionsList: (OfficeActionsDetails | SubHeading)[] = [
  {
    isHeading: true,
    label: 'Likelyhood of Confusion',
    slug: 'likelyhood-of-confusion',
    subTags: [
      {
        subLabel: 'ONE Mark',
        complexity: 1,
        explanation:
          'The Examiner finds that your mark could cause consumer confusion with other registered marks.',
        response:
          "Submit a response with comprehensive legal arguments based on law and facts to counter the Examiner's arguments.",
        slug: 'likelyhood-of-confusion-one',
      },
      {
        subLabel: 'Multiple Marks',
        complexity: 2,
        explanation:
          'The Examiner finds that your mark could cause consumer confusion with other registered marks.',
        response:
          "Submit a response with comprehensive legal arguments based on law and facts to counter the Examiner's arguments.",
        slug: 'likelyhood-of-confusion-multiple',
      },
    ],
  },
  {
    label: 'Disclaimer',
    explanation:
      'The Examiner recommends disclaiming a word or words in your mark.',
    response:
      "Submit a response to disclaim the words if the Examiner's recommendation is appropriate, or argue against it if the recommendation is inappropriate.",
    complexity: 1,
    slug: 'disclaimer',
  },
  {
    label: 'Amending the identification of goods and services',
    explanation:
      'The Examiner proposes ways to amend the identification of goods and services by adding, deleting, or changing the descriptions, answering specific questions, or filling in the blanks.',
    response:
      "Submit a response to provide additional information, accept or reject the Examiner's proposals and offer counter proposals if necessary.",
    complexity: 2,
    slug: 'amending_identification',
  },
  {
    label: 'Description of the mark ',
    explanation:
      'The Examiner recommends certain wordings in the description or color of the mark. ',
    response:
      "The applicant may adopt the Examiner's suggestions or submit counter proposals.",
    complexity: 1,
    slug: 'mark_description',
  },
  {
    label: 'Translations',
    explanation:
      'The Examiner requires the translation of certain words or phrases in the mark. ',
    response:
      "The applicant may adopt the Examiner's suggestions, provide translation or submit counter proposals",
    complexity: 1,
    slug: 'translations',
  },
  {
    label: 'Consent statement from a living individual',
    explanation:
      "If a mark contains a living individual's real name, pseudonym, stage name, or nickname, a statement of consent from the individual is required.",
    response:
      'Draft and submit a properly executed statement of consent from the living individual.',
    complexity: 1,
    slug: 'consent_statement',
  },
  {
    label: 'Amending the classes',
    explanation: 'The Examiner proposes to add, delete, or change classes.',
    response:
      "Submit a response to provide additional information, accept or reject the Examiner's proposals and offer counter proposals if necessary. Note: adding additional classes will result in additional legal and government fees.",
    complexity: 2,
    slug: 'amending_classes',
  },
  {
    label: 'Supplemental register advisory',
    slug: 'supplemental_register_advisory',
    isHeading: true,
    subTags: [
      {
        subLabel: 'Simple',
        complexity: 1,
        explanation:
          'The Examiner finds that the mark is too generic or descriptive to be registered on the Principal Register. Therefore the Examiner proposes to amend the submission to register the mark in the Supplemental Register.',
        response:
          "Submit a response to provide additional information and/or arguments, accept or reject the Examiner's proposals.",
        slug: 'supplemental_register_advisory_simple',
      },
      {
        subLabel: 'Complex',
        complexity: 2,
        explanation:
          'The Examiner finds that the mark is too generic or descriptive to be registered on the Principal Register. Therefore the Examiner proposes to amend the submission to register the mark in the Supplemental Register.',
        response:
          "Submit a response to provide additional information and/or arguments, accept or reject the Examiner's proposals.",
        slug: 'supplemental_register_advisory_complex',
      },
    ],
  },
  {
    label: 'Additional information',
    explanation:
      "The Examiner requests additional information to help him or her to better understand the applicant's product, market, or the meaning of the mark.",
    response:
      "Respond to the examiner's questions strategically to prevent limiting the scope of your trademark's goods and services and to prevent a final refusal.",
    complexity: 2,
    slug: 'additional_information',
  },
  {
    label: 'Specimen refusal',
    explanation:
      'The Examiner refuses the submitted specimen because it has no direct association with the mark.',
    response:
      'The applicant needs to submit a qualified specimen to prevent a final refusal.',
    complexity: 2,
    slug: 'specimen_refusal',
  },
  {
    label: 'Geographically descriptive',
    explanation:
      'The Examiner finds that your mark primarily describes a geographical location associated with the goods or services provided.',
    response:
      "Submit a response with comprehensive legal arguments based on law and facts to counter the Examiner's arguments.",
    complexity: 5,
    slug: 'geographically_descriptive',
  },
  {
    label: 'Merely descriptive',
    explanation:
      'The Examiner finds that your mark merely describes the ingredients, functions, or purposes of the goods or services provided.',
    response:
      "Submit a response with comprehensive legal arguments based on law and facts to counter the Examiner's arguments.",
    complexity: 5,
    slug: 'merely_descriptive',
  },
  {
    label: 'Federal Food, Drug, and Cosmetic Act (FDCA) Refusal',
    explanation:
      "To qualify for federal trademark mark registration, the use of a mark in commerce must be lawful. The Examiner finds that the applicant's use violates the Federal Food, Drug, and Cosmetic Act (“FDCA”).",
    response:
      "The applicant may adopt the Examiner's suggestions or submit counter proposals and must respond strategically to prevent a final refusal.",
    complexity: 5,
    slug: 'federal_food_drug_cosmetic_act_refusal',
  },
  {
    label: 'CSA Refusal',
    explanation:
      "To qualify for federal trademark mark registration, the use of a mark in commerce must be lawful. The Examiner finds that the applicant's use violates the Controlled Substance Act (“CSA”).",
    response:
      "The applicant may adopt the Examiner's suggestions or submit counter proposals and respond strategically to the refusal to prevent a final refusal.",
    complexity: 5,
    slug: 'csa_refusal',
  },
  {
    label: 'Failure to Function as a Trademark – Social or Political Message',
    explanation:
      'The Examiner finds that the mark is mainly used to convey social or political messages, therefore fails to function as a source indicator.',
    response:
      "Submit a response with comprehensive legal arguments based on law and facts to counter the Examiner's arguments.",
    complexity: 5,
    slug: 'failure_to_function_as_a_trademark_social_or_political_message',
  },
  {
    label: 'Ornamental refusal',
    explanation:
      'The Examiner finds the specimen of the mark merely serves a decorative function, not as a source indicator.',
    response:
      'The applicant needs to submit a qualified specimen to prevent a final refusal.',
    complexity: 5,
    slug: 'ornamental_refusal',
  },
  {
    label: 'Surname refusal',
    explanation:
      'The Examiner finds that the mark is primarily just a “surname”, i.e., a family name or last name',
    response:
      "Submit a response with comprehensive legal arguments based on law and facts to counter the Examiner's arguments.",
    complexity: 5,
    slug: 'surname_refusal',
  },
  {
    label: 'Generic Advisory Rejections',
    explanation:
      '“cannot acquire distinctiveness” and thus is not entitled to registration on either the Principal or Supplemental Register under any circumstances.”',
    response:
      'Generic Advisory Rejections of a Trademark comprised of a generic term(s) combined with a non-source-identifying term and without any brand value, identity or trademark, or service mark significance',
    complexity: 7,
    slug: 'generic_advisory_rejections',
  },
  {
    isHeading: true,
    label: 'Suspended due to prior-pending applications',
    slug: 'suspended_due_to_prior_pending_applications',
    subTags: [
      {
        complexity: 1,
        explanation:
          'The Examiner finds that your mark is confusingly similar to one or more currently pending applications filed before you. Your application will be suspended, waiting for the result of the prior applications.',
        response:
          'No action is needed if you are OK with waiting for the result of the prior applications. If you want to get out of the suspension status now, you need to submit a response with comprehensive legal arguments based on law and facts to argue that your mark is not similar to the prior applications.',
        slug: 'suspended_due_to_prior_pending_applications_one',
        subLabel: 'ONE Mark',
      },
      {
        complexity: 2,
        explanation:
          'The Examiner finds that your mark is confusingly similar to one or more currently pending applications filed before you. Your application will be suspended, waiting for the result of the prior applications.',
        response:
          'No action is needed if you are OK with waiting for the result of the prior applications. If you want to get out of the suspension status now, you need to submit a response with comprehensive legal arguments based on law and facts to argue that your mark is not similar to the prior applications.',
        slug: 'suspended_due_to_prior_pending_applications_multiple',
        subLabel: 'MULTIPLE Mark',
      },
    ],
  },
  {
    isHeading: true,
    label: 'Quantity of Attachments',
    slug: 'quantity_of_attachments',
    subTags: [
      {
        complexity: 5,
        explanation:
          'An OA will have attachments that may include cited websites and registrations. This may result in more effort for the OAR.',
        response:
          "Submit a detailed response with comprehensive legal arguments based on law and facts to counter the Examiner's arguments cited on each of the documents attached in the OA",
        slug: 'quantity_of_attachments_under_20',
        subLabel: 'Under 20',
      },
    ],
  },
  {
    isHeading: true,
    label: '# of pages',
    slug: 'no_of_pages',
    subTags: [
      {
        complexity: 9,
        explanation: 'Additional charge if over 100 pages. See this example',
        response:
          "Submit a detailed response with comprehensive legal arguments based on law and facts to counter the Examiner's arguments cited on each of the documents attached in the OA",
        slug: 'no_of_pages_under_20',
        subLabel: 'Under 20',
      },
    ],
  },
  {
    isHeading: true,
    label: '# of exhibits',
    slug: 'no_of_exhibits',
    subTags: [
      {
        complexity: 8,
        explanation: 'Additional charge if over 40 exhibits. See this example.',
        response:
          "Submit a detailed response with comprehensive legal arguments based on law and facts to counter the Examiner's arguments cited on each of the documents attached in the OA",
        slug: 'no_of_exhibits_under_20',
        subLabel: 'Under 20',
      },
    ],
  },
  {
    isHeading: true,
    label: '# of questions need to be answered',
    slug: 'no_of_questions_need_to_be_answered',
    subTags: [
      {
        complexity: 7,
        explanation:
          'Additional charge if over 20 questions need to be answered. See this example.',
        response:
          "Submit a detailed response with comprehensive legal arguments based on law and facts to counter the Examiner's arguments cited on each of the questions in the OA",
        slug: 'no_of_questions_need_to_be_answered_under_20',
        subLabel: 'Under 20',
      },
    ],
  },
  {
    label: 'Submit Additional proof of use',
    explanation:
      'USPTO requests the applicant to submit additional proof of use of the registered mark or delete goods and/or services  ',
    response:
      'Submit proof of use of the registered mark for the additional goods and/or services per class specified OR delete audited (and all other) goods and/or services for which proof of use of the registered mark cannot be provided',
    complexity: 0,
    slug: 'submit_additional_proof_of_use',
  },
  {
    label: 'Submit a statement verifying the proof of use.',
    explanation:
      'USPTO requests the applicant to submit a statement verifying the proof of use',
    response:
      'The applicant needs to submit additional proof of use of the registered mark submit a statement verifying the proof of use.',
    complexity: 0,
    slug: 'submit_statement_verifying_the_proof_of_use',
  },
  {
    label: "Owner/holder must provide owner's/holder's domicile address",
    explanation:
      "All post registration filings must include the owner's/holder's domicile street  address",
    response:
      "The post-registration filing lists the owner/holder as a juristic entity and specifies the owner's/holder's domicile as a post office box instead of a street address.",
    complexity: 0,
    slug: 'owner_holder_must_provide_owners_holders_domicile_address',
  },
  {
    label: 'Deficiency Surcharge Fee',
    explanation: 'The owner must submit the required deficiency surcharge. ',
    response: 'NA',
    complexity: 0,
    slug: 'deficiency_surcharge_fee',
  },
  {
    label: 'Good Faith Statement Is Required',
    explanation: 'USPTO requests the applicant to submit Good Faith Statement',
    response: 'NA',
    complexity: 0,
    slug: 'good_faith_statement_is_required',
  },
  {
    label: 'Specimen Omits Url/Date Accessed.',
    explanation:
      'USPTO requests the applicant to submit a specimen with URL and date of access.',
    response: 'NA',
    complexity: 0,
    slug: 'specimen_omits_url_date_accessed',
  },
  {
    label: 'Specimen Deficiency',
    explanation:
      'The specimen submitted to support the Section 8 Affidavit is unacceptable because it does not show a direct association between the registered mark and the services specified in the registration.',
    response:
      'The applicant needs to submit additional proof of use of the registered mark submit a statement verifying the proof of use.',
    complexity: 0,
    slug: 'specimen_deficiency',
  },
  {
    label: 'Other issues',
    slug: 'other_issues',
    isHeading: true,
    subTags: [
      {
        subLabel: 'Simple',
        complexity: 1,
        explanation: '“Explain”',
        response: 'NA',
        slug: 'other_issues_simple',
      },
      {
        subLabel: 'Complex',
        complexity: 2,
        explanation: '“Explain”',
        response: 'NA',
        slug: 'other_issues_complex',
      },
    ],
  },
]

export const officeActionsDetails = officeActionsList.reduce(
  (acc, curr) => [
    ...acc,
    ...(curr.isHeading
      ? curr.subTags.map(sub => ({ ...sub, label: curr.label }))
      : [curr]),
  ],
  [] as OfficeActionsDetails[],
)
