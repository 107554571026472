/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-lone-blocks */
// this is the global search component that opens when ctrl +k or cmd + k is pressed. it is a modal that opens up and allows you to search for anything in the app. it is a global search component that is used in the adminv3 app.

import { useEffect, useState } from 'react'
import { allLinks } from '../../Data/Sitemap'
import AutoComplete from './AutoComplete'

interface GlobalSearchProps {
  onSearch?: (query: string) => void
}

// the following component will listen for the keydown event and if the key pressed is ctrl+k or cmd+k then it will open the global search modal. it will also close the modal if the escape key is pressed.

const GlobalSearch = ({ onSearch }: GlobalSearchProps) => {
  const [showSearch, setShowSearch] = useState(false)

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setShowSearch(true)
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [])

  return (
    <div className="flex items-center">
      <AutoComplete
        open={showSearch}
        onClose={() => setShowSearch(false)}
        onSearch={onSearch || (() => {})}
        suggestions={allLinks}
      />
    </div>
  )
}

export default GlobalSearch
