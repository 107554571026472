import { initializeApp } from 'firebase/app'
import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
} from 'firebase/firestore'
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  list,
  ref,
  uploadBytes,
} from 'firebase/storage'
import { toast } from 'react-toastify'

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_apiKey,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_authDomain,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_projectId,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_storageBucket,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_messagingSenderId,
  appId: process.env.NEXT_PUBLIC_FIREBASE_appId,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_measurementId,
}

const app = initializeApp(firebaseConfig)
export default app

const provider = new GoogleAuthProvider()

provider.addScope('https://www.googleapis.com/auth/gmail.compose')

export const auth = getAuth()
export const db = getFirestore(app)
export const storage = getStorage(app)

export const loginWithGoogle = (): Promise<{
  success: boolean
  [key: string]: any
}> =>
  signInWithPopup(auth, provider)
    .then(result => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result)
      if (typeof window !== 'undefined')
        window.localStorage.setItem(
          'access_token',
          JSON.stringify(credential?.accessToken),
        )
      return { success: true }
      // ...
    })
    .catch(error => {
      console.log(error)
      return { success: false, ...error }
    })

export const loginWithEmail = (
  {
    email,
    password,
    redirected,
  }: { email: string; password: string; redirected?: boolean },
  callback?: () => void,
) =>
  signInWithEmailAndPassword(auth, email, password)
    .then(userCredential => {
      // Signed in
      const { user } = userCredential
      if (typeof window !== 'undefined')
        window.localStorage.setItem(
          'access_token',
          // @ts-ignore
          JSON.stringify(userCredential.user.accessToken),
        )
      // ...
      toast.success(`Logged In as ${email}`)
      callback?.()
      return user
    })
    .catch(({ code }) => {
      switch (code) {
        case 'auth/user-not-found':
          toast.error(`Email ID not found`)
          break
        case 'auth/wrong-password':
          toast.error(
            redirected
              ? `User with email ${email} already exists and you entered the wrong password`
              : `Incorrect password for ${email}`,
          )
          break
        case 'auth/invalid-email':
          toast.error('Email is invalid')
          break
        default:
          toast.error(code)
      }
    })

export const createUser = ({
  email,
  password,
}: {
  email: string
  password: string
}) =>
  createUserWithEmailAndPassword(auth, email, password)
    .then(userCredential => {
      // Signed in
      const { user } = userCredential
      toast.success(`New user created ${email}`)
      return user
      // ...
    })
    .catch(({ code }) => {
      switch (code) {
        case 'auth/weak-password':
          toast.error('Password must be atleast 6 characters long')
          break
        case 'auth/email-already-in-use':
          loginWithEmail({ email, password, redirected: true })
          break
        default:
          toast.error(code)
      }
    })

export const logout = () => {
  signOut(auth)
  localStorage.removeItem('sessions_id')
  localStorage.removeItem('sessions_token')
}

export const resetLink = (email: string) =>
  sendPasswordResetEmail(auth, email)
    .then(() => {
      // Password reset email sent!
      toast.success(`Password reset email send to ${email}`)
    })
    .catch(({ code }) => {
      switch (code) {
        case 'auth/invalid-email':
          toast.error('Invalid Email')
          break
        case 'auth/user-not-found':
          toast.error(`Email not found`)
          break
        default:
          toast.error(code)
      }
    })

export const resetPassword = (
  code: string,
  newPassword: string,
  cb: () => void,
) =>
  confirmPasswordReset(auth, code, newPassword)
    .then(() => {
      toast.success('Password Reset Successful')
      cb?.()
    })
    .catch(error => {
      toast.error(error.code)
    })

export const getContent = async (props?: {
  country?: string
  language?: string
}) => {
  try {
    const contentRef = doc(
      db,
      'data',
      'content',
      props?.country || 'default',
      props?.language || 'default',
    )
    const content = await getDoc(contentRef)

    return { content: content.data(), error: null }
  } catch (error) {
    return { error, content: null }
  }
}

export const setContent = async (
  content: any,
  props?: {
    country?: string
    language?: string
  },
) => {
  console.log({ content, props })
  try {
    const contentRef = doc(
      db,
      'data',
      'content',
      props?.country || 'default',
      props?.language || 'default',
    )
    const res = await setDoc(contentRef, content)
    return { error: null, res }
  } catch (error) {
    console.log({ error })
    return { error }
  }
}

export const setInternationalCountries = async (countries: string[]) => {
  try {
    const countriesRef = doc(db, 'data', 'countries')
    const res = await setDoc(countriesRef, { countries })
    return { error: null, res }
  } catch (error) {
    console.log({ error })
    return { error }
  }
}

export const getInternationalCountries = async (): Promise<string[]> => {
  try {
    const countriesRef = doc(db, 'data', 'countries')
    const countries = await getDoc(countriesRef)
    return countries?.data()?.countries || []
  } catch (error) {
    console.log({ error })
    return []
  }
}

export const getCountryLanguages = async (
  country: string,
): Promise<string[]> => {
  try {
    const languagesRef = collection(db, 'data', 'content', country)
    const languages = await getDocs(languagesRef)
    return languages?.docs.map(d => d.id).filter(l => l !== '_metadata') || []
  } catch (error) {
    console.log({ error })
    return []
  }
}

export const uploadImage = async (
  file: File,
): Promise<{ error: any; url: string }> => {
  try {
    const storageRef = ref(storage, `images/${file.name}`)
    await uploadBytes(storageRef, file)
    const url = await getDownloadURL(storageRef)
    return { error: null, url }
  } catch (error) {
    console.log({ error })
    return { error, url: '' }
  }
}

export const fetchImages = async (
  pageToken?: string,
): Promise<{
  urls: string[]
  pageToken?: string
}> => {
  try {
    const listRef = ref(storage, 'images')
    const res = await list(listRef, { maxResults: 10, pageToken })
    const urls = await Promise.all(res.items.map(getDownloadURL))
    return { urls, pageToken: res.nextPageToken }
  } catch (error) {
    console.log({ error })
    return { urls: [] }
  }
}

export const deleteImage = async (url: string): Promise<{ error: any }> => {
  try {
    const storageRef = ref(storage, url)
    await deleteObject(storageRef)
    return { error: null }
  } catch (error) {
    console.log({ error })
    return { error }
  }
}

export interface CountryMetadata {
  deploy_hook: string
}

export const changeCountryMetadata = async (
  country: string,
  metadata: CountryMetadata,
): Promise<{ error: any }> => {
  try {
    const metadataRef = doc(db, 'data', 'content', country, '_metadata')
    await setDoc(metadataRef, metadata)
    return { error: null }
  } catch (error) {
    console.log({ error })
    return { error }
  }
}

export const getCountryMetadata = async (
  country: string,
): Promise<{ error: any; metadata: CountryMetadata | null }> => {
  try {
    const metadataRef = doc(db, 'data', 'content', country, '_metadata')
    const metadata = await getDoc(metadataRef)
    console.log({ metadata, country }, metadata.data())
    return { error: null, metadata: metadata.data() as CountryMetadata }
  } catch (error) {
    console.log({ error })
    return { error, metadata: null }
  }
}

export type MatchType = 'starts_with' | 'exact' | 'includes'
export interface PageUrl {
  match_type: MatchType
  value: string
  id: number
}

export interface AccessGroup {
  name: string
  access_pages: number[]
  admins: string[]
}

export const fetchRestrictedPages = async () => {
  try {
    const docRef = doc(db, 'admin', 'restricted_pages')
    const data = await getDoc(docRef)
    return { error: null, data: data.data()?.data as PageUrl[] }
  } catch (error) {
    console.log({ error })
    return { error, data: null }
  }
}
export const fetchAccessGroups = async () => {
  try {
    const docRef = doc(db, 'admin', 'access_groups')
    const data = await getDoc(docRef)
    return { error: null, data: data.data()?.data as AccessGroup[] }
  } catch (error) {
    console.log({ error })
    return { error, data: null }
  }
}
export const changeRestrictedPages = async (data: PageUrl[]) => {
  try {
    const docRef = doc(db, 'admin', 'restricted_pages')
    await setDoc(docRef, { data })
    return { error: null }
  } catch (error) {
    console.log({ error })
    return { error }
  }
}
export const changeAccessGroups = async (data: AccessGroup[]) => {
  try {
    const docRef = doc(db, 'admin', 'access_groups')
    await setDoc(docRef, { data })
    return { error: null }
  } catch (error) {
    console.log({ error })
    return { error }
  }
}

export const getIndiaLanguages = async () => {
  try {
    const collectionRef = collection(db, 'india')
    const docs = await getDocs(collectionRef)
    return {
      data: [
        'default',
        ...docs.docs.map(({ id }) => id).filter(id => id !== 'default'),
      ],
    }
  } catch (error) {
    console.log({ error })
    return { error, data: [] as string[] }
  }
}

export const saveIndiaContent = async (language: string, content: any) => {
  try {
    const docRef = doc(db, 'india', language)
    await setDoc(docRef, content)
    return { error: null }
  } catch (error) {
    console.log({ error })
    return { error }
  }
}

export const getIndiaContent = async (language: string) => {
  try {
    const docRef = doc(db, 'india', language)
    const data = await getDoc(docRef)
    return { error: null, data: data.data() }
  } catch (error) {
    console.log({ error })
    return { error, data: null }
  }
}
