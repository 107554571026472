import { CameraIcon } from '@heroicons/react/outline'
import { adminImageRoute, fetchUploadAdminImage, Profile } from 'api'
import Image from 'next/image'
import { useState } from 'react'
import { toast } from 'react-toastify'
import Button from '../Button'
import Modal from '../Modal'

interface UserDetailsInterface {
  profile: Profile | null | undefined
  isOpen: boolean
  onClose: () => void
}

const EditProfileModal = ({
  profile,
  isOpen,
  onClose,
}: UserDetailsInterface) => {
  const [isHover, setIsHover] = useState(false)
  const [imageError, setImageError] = useState(false)

  const [image, setImage] = useState<string>(
    adminImageRoute(profile ? profile.id : ''),
  )
  return (
    <Modal variant={3} title="Edit Profile" isOpen={isOpen} onClose={onClose}>
      <div className="p-12">
        <div className="grid grid-cols-8 gap-4">
          <div className="col-span-3 flex flex-col items-center">
            <h1 className="text-sm font-semibold">Upload Profile Picture</h1>
            {!imageError ? (
              <div
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                className="relative mt-6 flex h-[162px] w-[162px] cursor-pointer items-center justify-center rounded-full bg-[#F2F2F2] transition-all duration-300 ease-in-out"
              >
                <Image
                  unoptimized
                  src={image}
                  alt={profile?.full_name}
                  width={162}
                  height={162}
                  objectFit="cover"
                  onError={() => {
                    setImageError(true)
                  }}
                  className="rounded-full"
                />
                <div
                  className={`bg-black-37 absolute top-0 left-0 flex h-full w-full items-center justify-center rounded-full transition-all duration-300 ease-in-out
                     ${isHover ? 'opacity-100' : 'opacity-0'}`}
                >
                  <input
                    type="file"
                    className="absolute top-0 left-0 h-full w-full cursor-pointer opacity-0"
                    onChange={async e => {
                      const file = e.currentTarget.files?.[0]
                      if (!file) {
                        toast.error('Select file')
                        return
                      }
                      const formData = new FormData()
                      formData.append('file', file)
                      const ext = file.name.split('.').pop() || ''
                      const { body, error } = await fetchUploadAdminImage(
                        formData,
                        {},
                        { ext },
                      )
                      if (error || !body) return
                      setImageError(false)
                      setImage(body.url)
                    }}
                  />
                  <div className="flex items-center justify-center">
                    <CameraIcon className="h-6 w-6 text-white" />
                    <h1 className="ml-2 text-sm font-semibold text-white">
                      Change
                    </h1>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div
                  className={`relative mt-6 flex h-[162px] w-[162px] cursor-pointer items-center justify-center rounded-full bg-[#F2F2F2] transition-all duration-300 ease-in-out ${
                    isHover ? 'opacity-70' : ''
                  }`}
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                >
                  <h1 className="text-[64px] font-semibold">
                    {profile?.full_name[0] || '-'}
                  </h1>

                  <div
                    className={`bg-black-37 absolute top-0 left-0 flex h-full w-full items-center justify-center rounded-full transition-all duration-300 ease-in-out
                     ${isHover ? 'opacity-100' : 'opacity-0'}`}
                  >
                    <input
                      type="file"
                      className="absolute top-0 left-0 h-full w-full cursor-pointer opacity-0"
                      onChange={async e => {
                        const file = e.currentTarget.files?.[0]
                        if (!file) {
                          toast.error('Select file')
                          return
                        }
                        const formData = new FormData()
                        formData.append('file', file)
                        const ext = file.name.split('.').pop() || ''
                        const { body, error } = await fetchUploadAdminImage(
                          formData,
                          {},
                          { ext },
                        )
                        if (error || !body) return
                        setImageError(false)
                        setImage(body.url)
                      }}
                    />
                    <Button
                      className="text-[16px] font-semibold text-[#FFFFFF]"
                      onClick={() => {}}
                    >
                      Upload
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-span-5 ml-6 flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <p className="font-semibold">Name</p>
              <p className="text-sm font-semibold text-[#626262]">
                {profile?.full_name || 'Not Available'}
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <p className="font-semibold">Email</p>
              <p className="text-sm font-semibold text-[#626262]">
                {profile?.email || 'Not Available'}
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <p className="font-semibold">Phone Number</p>
              <p className="text-sm font-semibold text-[#626262]">
                {profile?.phone || 'Not Available'}
              </p>
            </div>
          </div>{' '}
        </div>
        {/* <pre className="text-xs font-semibold text-[#626262]">
                      {JSON.stringify(profile?, null, 2)}
                    </pre> */}
        <div className="mt-6 flex w-full justify-end">
          <Button
            className=" w-[50%]"
            variant="primary"
            type="submit"
            onClick={() => {
              onClose()
            }}
            disabled={
              profile?.full_name === ''
              // ||
              // (userDetails.full_name !== '' &&
              //   profile?.permissions === userDetails.permissions)
            }
          >
            Save Changes
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default EditProfileModal
