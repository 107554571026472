import { createFetcher, ROUTES } from '../api'
import { DynamicOrderItemDetails, OrderItem } from './matters'

export const fetchOrder = createFetcher<FetchOrder, any, { id: string }>({
  url: ROUTES.ORDERS.GET_ORDER,
  withTokens: true,
})

export const fetchOrderItem = createFetcher<
  FetchOrder,
  any,
  { order_id: string; order_item_id: string }
>({
  url: ROUTES.ORDERS.GET_ORDER_ITEM,
  method: 'GET',
  withTokens: true,
})

export const fetchDeleteOrderItem = createFetcher<
  FetchCart,
  any,
  { order_id: string; order_item_id: string }
>({
  url: ROUTES.CART.DELETE,
  method: 'DELETE',
})

export interface FetchOrder<
  Type extends keyof DynamicOrderItemDetails = 'any',
> {
  order: Order<Type>
  order_items: OrderItem<Type>[]
  order_item: OrderItem<Type>
}

export interface Order<Type extends keyof DynamicOrderItemDetails = 'any'> {
  id: string
  matter_id: number
  user_id: string
  created_at: number
  updated_on: number
  payed_on: number
  order_status: number
  payment_status: number
  address_id: any
  email: string
  phone: string
  full_name: string
  billable_amount: number
  billable_amount_paid: number
  billable_amount_refunded: number
  coupon_id: string
  coupon_code: string
  total_discount: number
  is_current_cart: boolean
  transactions: any
  IsFromOldDB: boolean
  order_items?: OrderItem<Type>[] | null
}

export interface FetchCart {
  order: Order
  order_items: OrderItem[]
  order_item: OrderItem
}
