import { useState, useRef, useEffect } from 'react'

export const useDeferredValue = <T>(
  value: T,
  { timeout: timeoutMs = 200 }: { timeout?: number } = {},
): T => {
  const [deferredValue, setDeferredValue] = useState(value)
  const timeout = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (timeout.current !== null) clearTimeout(timeout.current)
    timeout.current = setTimeout(() => setDeferredValue(value), timeoutMs)
    return () => {
      if (timeout.current !== null) clearTimeout(timeout.current)
    }
  }, [value, timeoutMs])

  return deferredValue
}
