export const duplicate = <T>(data: T): T => JSON.parse(JSON.stringify(data))

export const filterRepetition = <T>(
  data: T[],
  repeater: (item: T) => string | number | symbol,
): T[] =>
  Object.values(
    data.reduce(
      (acc, curr) => ({
        ...acc,
        [repeater(curr)]: curr,
      }),
      {} as Record<keyof T, T>,
    ),
  )

export const removeKeys = (obj: any, keys: string[]) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    if (keys.includes(key)) return acc
    return {
      ...acc,
      [key]: value,
    }
  }, {})

export const compareObjs = <T>(obj1: T, obj2: T): boolean => {
  if (typeof obj1 === 'object' && typeof obj2 === 'object') {
    return Object.keys({ ...(obj1 as any), ...(obj2 as any) }).every(key =>
      compareObjs((obj1 as any)[key], (obj2 as any)[key]),
    )
  }
  return obj1 === obj2
}
