module.exports = {
  theme: {
    ...this.theme,
    borderWidth: {
      DEFAULT: '1px',
      0: '0',
      2: '2px',
      3: '3px',
      4: '4px',
      6: '6px',
      8: '8px',
    },
    screens: {
      sm: '415px',
      tab: '550px',
      md: '835px',
      nav: '952px',
      lg: '1025px',
      xlg: '1149px',
      // xl: '1441px',
      xxl: '1149px',
    },
    fontFamily: {
      sans: ['Gilroy'],
    },

    extend: {
      fontSize: {
        cap: '0.75rem', // Caption
        foot: '0.875rem', // Footer
        h5: '1rem', // Heading 05
        p1: '1.125rem', // Paragraph 01
        h4: '1.25rem', // Heading 04
        h3: '1.5rem', // Heading 03
        h2s: '1.75rem', // Heading 03
        h1s: '2rem', // Heading 01 (small)
        h2: '2.125rem', // Heading 02
        h1: '3rem', // Heading 01
        d2: '3.75', // Display 02
        d1: '4.5rem', // Display 01
      },
      colors: {
        primary: {
          100: '#ECE9F9',
          200: '#A397E3',
          300: '#8774E4',
          400: '#604CC5',
          500: '#4E3D9F',
          600: '#574C8E',
          DEFAULT: '#1874FD',
        },
        secondary: {
          100: '#FFF7F5',
          200: '#FFE7E1',
          300: '#F16A21',
          DEFAULT: '#F55431',
        },
        tertiary: {
          DEFAULT: '#F6696B',
        },
        adminBlue:{
          100: '#E8F0F9',
          500: '#3B86F6',
          DEFAULT: '#1874FD',
        },
        error: '#E41515',

        success: '#178747',
        warning: '#DF693C',
        red: { star: '#F50202', required: '#E41515' },
        grey: {
          100: '#F2F2F2',
          200: '#E0E0E0',
          300: '#BDBDBD',
          400: '#828282',
          600: '#333333',
        },
        dark: '#121212',
        light: '#FFFFFF',
        accent: {
          100: '#A57BDB',
          200: '#64B677',
          300: '#F7C245',
          400: '#EF7F5A',
          500: '#EEF0FC',
        },
        black: {
          '1C': '#1C1C1C',
          '1A': '#1A1A1A',
          '3A': '#3A3A3A',
          37: '#373737',
          65: '#656565',
          DEFAULT: '#000000',
        },
        transparent: 'transparent',
        'pe-primary': { 100: '#E2F5F5', DEFAULT: '#09ABAB' },
        'pe-secondary': { 100: '#FFF3F3', DEFAULT: '#FA6464' },
      },
    },
  },
  plugins: [
    // require('@tailwindcss/typography'),
    require('@tailwindcss/forms'),
    // require('@tailwindcss/line-clamp'),
    // require('@tailwindcss/aspect-ratio'),
  ],
}
