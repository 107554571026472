/* eslint-disable react/no-unstable-nested-components */
import { fetchCountries, PePrice } from 'api/pe'
import { useAsyncMemo } from 'hooks'
import Image from 'next/image'
import { createContext, FC, useContext, useMemo } from 'react'

interface Props {
  countries: Country[]
  prices?: PePrice[]
}

export interface Country {
  name: string
  code: string
  image: string
  id: string
  Flag: FC<{ width?: number; height?: number }>
}

export const ConstantsContext = createContext<Props>({
  countries: [],
})

export const useConstants = () => useContext(ConstantsContext)

export const PeConstantsProvider: FC = props => {
  const [prices] = useAsyncMemo<PePrice[], undefined>(
    async () => {
      const { body } = await fetchCountries()
      return body
    },
    [],
    { storeLocal: 'peprices', disableRefetch: true },
  )
  const countries = useMemo<Country[]>(
    () =>
      prices
        ? prices.map(c => ({
            code: c.country_code,
            name: c.country_name,
            image: `/Flags/${c.country_code?.toLowerCase()}.svg`,
            id: c.id,
            Flag: flagProps => (
              <Image unoptimized 
                width={30}
                height={20}
                objectFit="contain"
                src={`/Flags/${c.country_code?.toLowerCase()}.svg`}
                {...flagProps}
              />
            ),
          }))
        : [],
    [prices],
  )
  const value = useMemo<Props>(
    () => ({ countries, prices }),
    [countries, prices],
  )
  return <ConstantsContext.Provider value={value} {...props} />
}
