import { createFetcher, ROUTES } from '../api'
import { Address, Matter } from './matters'

export const fetchClients = createFetcher<Clients[]>({
  url: ROUTES.CLIENTS.GET_ALL,
  withTokens: true,
})

export const fetchClient = createFetcher<Clients>({
  url: ROUTES.CLIENTS.GET_ONE,
  withTokens: true,
})

export const fetchClientOrders = createFetcher<Matter<any>[]>({
  url: ROUTES.CLIENTS.GET_ALL_ORDERS,
  withTokens: true,
})

export const fetchClientTransactions = createFetcher<Transaction[]>({
  url: ROUTES.CLIENTS.GET_ALL_TRANSACTIONS,
  withTokens: true,
})

export const fetchTaggedClients = createFetcher<TaggedClients[]>({
  url: ROUTES.CLIENTS.GET_ALL_TAGGED_CLIENTS,
  withTokens: true,
})

export const fetchOneTaggedClient = createFetcher<TaggedClients>({
  url: ROUTES.CLIENTS.GET_ONE_TAGGED_CLIENT,
  withTokens: true,
})

export const fetchTagClient = createFetcher<{}, PostTaggedClients>({
  method: 'POST',
  url: ROUTES.CLIENTS.TAG_CLIENT,
  withTokens: true,
})

export const fetchUpdateClientDetails = createFetcher<
  { message: string; success: boolean },
  UpdateClientBody
>({
  method: 'PUT',
  url: ROUTES.CLIENTS.UPDATE_CLIENT,
  withTokens: true,
})

export const fetchMoveOrders = createFetcher<{}, MoveOrdersBody>({
  method: 'PATCH',
  url: ROUTES.CLIENTS.MOVE_ORDERS,
  withTokens: true,
})

export interface UpdateClientBody {
  id: string
  full_name: string
  email: string
  phone: string
}
export interface MoveOrdersBody {
  from_user_id: string
  to_user_id: string
  order_ids: string[]
}

export interface Clients {
  id: string
  created_at: number
  updated_on: number
  uid: string
  full_name: string
  email: string
  phone: string
  address: ClientAddress
  address_id: string
  additional_emails: string[]
  country_code: string
  avatar: string
  last_logged_in: number
  wallet: any
  revenue: number
  total_matters: number
  client_relation_id: string
  email_subscription: boolean
}

export interface ClientAddress {
  id: string
  line_1: string
  line_2: string
  city: string
  state: string
  country: string
  postal_code: string
}

export interface TaggedClients {
  user_id: string
  order_id: string
  tagged_by: any
  client_name: string
  email: string
  tagged_by_name: string
  total_money_spent: number
  website_url: string
  last_tag: number
  last_matter: number
  website_url_proof: string
  business_details: string
  reason: string
  business_maturity: string
  referral_network: string[]
  services?: TaggedServices
}

export interface PostTaggedClients {
  user_id: string
  website_url: string
  website_url_proof: string
  business_details: string
  reason: string
  business_maturity: string
  referral_network: string[]
  services?: any
}

export interface TaggedServices {
  others?: Others
  design_patents?: DesignPatents
  utility_patents?: UtilityPatents
  website?: Website
  contracts?: Contracts
  copyright?: Copyright
  litigation?: Litigation
  usMarkName?: UsMarkName
  influential?: Influential
  terms_of_use?: TermsOfUse
  incorporation?: Incorporation
  privacy_policy?: PrivacyPolicy
  remove_all_tags?: RemoveAllTags
  foreign_trademark?: ForeignTrademark
  us_logo_trademark?: UsLogoTrademark[]
  right_to_publicity?: RightToPublicity
}

export interface Others {
  description: string
}

export interface DesignPatents {
  description: string
}
export interface UtilityPatents {
  description: string
}

export interface Website {
  types: string[]
}

export interface Contracts {
  description: string
}

export interface Copyright {
  description: string
}

export interface Litigation {
  description: string
}

export interface UsMarkName {
  description: string
}

export interface Influential {
  description: string
}

export interface TermsOfUse {
  description: string
}

export interface Incorporation {
  description: string
}

export interface PrivacyPolicy {
  description: string
}

export interface RemoveAllTags {
  description: string
}

export interface ForeignTrademark {
  mark_name: string
  selected_countries: string[]
}

export interface UsLogoTrademark {
  key: string
  url: string
  WebsiteUrl: string
  description: string
}

export interface RightToPublicity {
  description: string
}

export interface Transaction {
  id: string
  transaction_ref_id: string
  created_at: number
  updated_on: number
  payment_gateway_name: string
  payment_instrument_type: string
  amount: number
  total_amount_refunded: number
  status: number
  address_id: any
  metadata: CardMetadata & PayPalMetadata
  payment_method_token: string
  transaction_division: string
  subscriptions_id: any
  order_id: string
}

export interface CardMetadata {
  last_4: string
  card_type: string
  issuing_bank: string
  cardholder_name: string
  expiration_date: string
  country_of_issuance: string
}

export interface PayPalMetadata {
  payer_id: string
  capture_id: string
  payment_id: string
  payer_email: string
  payer_last_name: string
  authorization_id: string
  payer_first_name: string
  billing_agreement_id: string
}
