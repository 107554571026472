import React from 'react'

interface Props {
  checked: boolean | undefined
  onChange: (checked: boolean) => void
  disabled?: boolean
  label?: string
  labelPosition?: 'left' | 'right'
  size?: 'small' | 'medium'
}

const Switch = ({
  checked,
  onChange,
  disabled,
  label,
  labelPosition = 'right',
  size = 'medium',
}: Props) => (
  <div className="flex items-center">
    {labelPosition === 'left' && label && (
      <label
        htmlFor="toggle"
        className="mr-2 text-sm font-medium text-gray-700"
      >
        {label}
      </label>
    )}
    <div className="relative inline-flex flex-shrink-0">
      <input
        type="checkbox"
        name="toggle"
        id="toggle"
        className={`cursor-pointer opacity-0 ${
          size === 'small' ? 'h-3 w-6' : 'h-5 w-9'
        }`}
        checked={checked}
        onChange={e => onChange(e.target.checked)}
        disabled={disabled}
      />
      <div
        className={`${
          size === 'small' ? 'h-4 w-10' : 'h-6 w-12'
        } pointer-events-none absolute top-0 bottom-0 left-0 right-0 cursor-pointer rounded-full bg-gray-200 transition-colors duration-200 ease-in-out ${
          disabled ? 'cursor-not-allowed' : ''
        }`}
      >
        <div
          className={`${
            size === 'small' ? 'h-4 w-4' : 'h-6 w-6'
          } transform rounded-full transition-all duration-200 ease-in-out ${
            checked ? 'translate-x-full bg-blue-600' : 'bg-white shadow-lg'
          } ${disabled ? 'cursor-not-allowed' : ''}`}
        />
      </div>
    </div>
    {labelPosition === 'right' && label && (
      <label
        htmlFor="toggle"
        className="ml-2 text-sm font-medium text-gray-700"
      >
        {label}
      </label>
    )}
  </div>
)

export default Switch
