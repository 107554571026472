import { Temporal } from '@js-temporal/polyfill'
import { RenewalTrademarkDetails } from 'api'

/**
 *
 * @param renewalTrademarkDetails
 * @returns status of renewal, e.g. 'Revival', 'Abandoned', 'Waiting', 'Countdown', 'Expired'
 *
 */

export const renewalStatus = (
  renewalTrademarkDetails: RenewalTrademarkDetails,
) => {
  const instant = Temporal.Instant.fromEpochSeconds(
    renewalTrademarkDetails?.date || 0,
  )
  const now = Temporal.Now.instant()
  const diff = now.until(instant)
  const year = diff.total({
    unit: 'year',
    relativeTo: Temporal.Now.plainDateISO(),
  })
  const months = diff.total({
    unit: 'month',
    relativeTo: Temporal.Now.plainDateISO(),
  })
  const days = diff.total({
    unit: 'days',
    relativeTo: Temporal.Now.plainDateISO(),
  })

  const status =
    renewalTrademarkDetails?.type === 'revive1' ||
    renewalTrademarkDetails?.type === 'revive2'
      ? 'Revival'
      : renewalTrademarkDetails?.type === 'abandoned'
      ? 'Abandoned'
      : renewalTrademarkDetails?.type === 'non_registered'
      ? 'NotRegistered'
      : year > 1.5
      ? 'Waiting'
      : year > 1
      ? 'Countdown'
      : year > -0.5
      ? 'Renewal'
      : 'Expired'

  return {
    status,
    year,
    months,
    days,
  }
}
