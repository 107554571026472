import { XIcon } from '@heroicons/react/outline'
import { Field, useFormikContext } from 'formik'
import { useOutsideClick } from 'hooks'
import { FC, ReactNode, useMemo, useState } from 'react'

interface Option {
  label?: string | ReactNode
  value: string | number
}

interface Props {
  options: (Option | string)[]
  name: string
  variant?: 1 | 2
}

export const MultiSelect: FC<Props> = ({
  options: ogOptions,
  name,
  variant = 1,
}) => {
  const options: Option[] = ogOptions.map(option =>
    typeof option === 'string' ? { value: option } : option,
  )
  const [dropOpen, setDropOpen] = useState(false)
  const ref = useOutsideClick(() => {
    if (dropOpen) setDropOpen(false)
  }, [dropOpen])
  const [search, setSearch] = useState('')
  const { values, setFieldValue } = useFormikContext<any>()
  const current: Option[] = useMemo(
    () => options.filter(i => values[name]?.includes(i.value.toString())),
    [options, values[name]],
  )
  return (
    <div ref={ref}>
      {variant === 2 && (
        <input
          onClick={() => setDropOpen(true)}
          className="w-full rounded border p-2"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      )}
      <div
        className={[
          'flex',
          variant === 1 ? 'flex-wrap' : '',
          variant === 2 && dropOpen
            ? 'selectDropdown max-h-[400px] flex-col overflow-y-scroll p-5 shadow-2xl'
            : '',
        ].join(' ')}
        role="group"
        aria-labelledby="checkbox-group"
      >
        {(variant !== 2 || dropOpen) &&
          options
            .filter(o =>
              ((typeof o.label === 'string' ? o.label : '') + o.value)
                .toLowerCase()
                .includes(search.toLowerCase()),
            )
            .map(({ value, label }) => (
              <label className="mr-5 mb-5 flex items-center" htmlFor={name}>
                <Field type="checkbox" name={name} value={value.toString()} />
                <p className="ml-2">{label || value}</p>
              </label>
            ))}
      </div>
      {variant === 2 && (
        <div className="mt-2 flex flex-wrap">
          {current.map(i => (
            <div className="mr-2 mt-2 flex items-center rounded border p-3">
              <p>{i.label || i.value}</p>
              <XIcon
                onClick={() =>
                  setFieldValue(
                    name,
                    values[name]?.filter((str: string) => str !== i.value),
                  )
                }
                className="ml-2 h-5 w-5 cursor-pointer"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
