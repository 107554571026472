export const tiptapCss = `
<style>
.editor {
    padding: 20px;
  }
  .editor * + * {
    margin-top: 0.75em;
  }
  .editor ul,
  .editor ol {
    padding: 0 1rem;
  }
  .editor h1,
  .editor h2,
  .editor h3,
  .editor h4,
  .editor h5,
  .editor h6 {
    line-height: 1.1;
  }
  .editor code {
    background-color: rgba(97, 97, 97, 0.1);
    color: #616161;
  }
  .editor pre {
    background: #0d0d0d;
    color: #fff;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
  }
  .editor pre code {
    color: inherit;
    padding: 0;
    background: none;
    font-size: 0.8rem;
  }
  .editor img {
    max-width: 100%;
    height: auto;
  }
  .editor blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(13, 13, 13, 0.1);
  }
  .editor hr {
    border: none;
    border-top: 2px solid rgba(13, 13, 13, 0.1);
    margin: 2rem 0;
  }
  
  .editor table {
    width: 100%;
  }
  .editor td,
  .editor th {
    border: 1px solid gray;
  }
  
  .editor ul {
    list-style: initial;
  }
  .editor ol {
    list-style: decimal;
  }
  
  .editor :is(h1, h2, h3, h4, h5, h6, p) {
    line-height: 180%;
  }
  
  .editor p {
    font-size: 1rem;
  }
  .editor h1 {
    font-size: 2rem;
  }
  .editor h2 {
    font-size: 1.5rem;
  }
  .editor h3 {
    font-size: 1.25rem;
  }
  .editor h4 {
    font-size: 1.125rem;
  }
  .editor h5 {
    font-size: 1rem;
  }
  .editor h6 {
    font-size: 0.875rem;
  }
  .editor a {
    color: rgb(0, 153, 255);
    cursor: pointer;
  }
  .editor mark {
    background-color: #f55431;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
  }
  .editor a mark {
    color: #fff;
    text-decoration: unset;
  }
  </style>
  `
