import Link from 'next/link'
import React, { Fragment } from 'react'
import { AddOnIcon, AddOnsInfo } from '../../../Data/addon'

const ItemList = ({ items }: { items: AddOnIcon[] }) => {
  const halfLength = Math.ceil(items.length / 2)
  const firstHalf = items.slice(0, halfLength)
  const secondHalf = items.slice(halfLength)

  return (
    <div className="flex  gap-4">
      <div className="w-1/2 p-2">
        {firstHalf.map(item => {
          const Icon = item.icon ?? Fragment
          return (
            <Link passHref href={`/tm/addons/${item.name}`}>
              <a>
                <div
                  key={item.name}
                  className="my-[8px] flex  cursor-pointer items-center gap-4 rounded-md p-2 hover:bg-gray-100"
                >
                  <div className="flex h-11 w-11 items-center justify-center rounded-md bg-[#F8F8F8]">
                    <Icon />
                  </div>
                  <div className="text-sm font-semibold">{item.label}</div>
                </div>
              </a>
            </Link>
          )
        })}
      </div>
      <div className="w-1/2 p-2">
        {secondHalf.map(item => {
          const Icon = item.icon ?? Fragment
          return (
            <Link passHref href={`/tm/addons/${item.name}`}>
              <a>
                <div
                  key={item.name}
                  className="my-[8px] flex  cursor-pointer items-center gap-4 rounded-md p-2 hover:bg-gray-100"
                >
                  <div className="flex h-11 w-11 items-center justify-center rounded-md bg-[#F8F8F8]">
                    <Icon />
                  </div>
                  <div className="text-sm font-semibold">{item.label}</div>
                </div>
              </a>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

const AddonsDropdown = () => (
  <div className="flex max-h-[500px] gap-4 overflow-y-auto">
    <div className="col-span-2 w-full">
      <div className="flex items-center justify-between">
        <h1 className="font-semibold">Cobra Add-ons</h1>
      </div>
      <div className="my-3 h-px w-full bg-gray-200" />
      {ItemList({
        items: AddOnsInfo.filter(item => item.category === 'trademark'),
      })}
    </div>
    <div className="col-span-1 w-full">
      <h1 className="font-semibold">Renewal Add-ons</h1>
      <div className="my-3 h-px w-full bg-gray-200" />
      <div className="p-2">
        {AddOnsInfo.filter(item => item.category === 'renewal').map(item => {
          const Icon = item.icon ?? Fragment
          return (
            <Link passHref href={`/tm/addons/${item.name}`}>
              <a>
                <div
                  key={item.name}
                  className="my-[8px] flex  cursor-pointer items-center gap-4 rounded-md p-2 hover:bg-gray-100"
                >
                  <div className="flex h-11 w-11 items-center justify-center rounded-md bg-[#F8F8F8]">
                    <Icon />
                  </div>
                  <div className="text-sm font-semibold">{item.label}</div>
                </div>
              </a>
            </Link>
          )
        })}
      </div>
    </div>
  </div>
)

export default AddonsDropdown
