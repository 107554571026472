import { createFetcher, ROUTES } from '../api'
// import { OrderItemOwnership } from './matters'
import { Step2RenewalDataItem } from './renewal'

export const fetchUsOwnershipMoveStep = createFetcher<any, StepMoveOwnership>({
  url: ROUTES.US_OWNERSHIP.MOVE_STEP,
  method: 'POST',
  withTokens: true,
})

/**********************
 *
 *  Step 1 fetch (GET & UPDATE)
 *
 **********************/

export const fetchUsOwnershipStep1 = createFetcher<Step1OwnershipDataItem[]>({
  url: ROUTES.US_OWNERSHIP.STEP_1.GET,
  method: 'GET',
  withTokens: true,
})

export const fetchOwnershipStep1Update = createFetcher<
  null,
  {
    paralegal_id: string
    tm_ownership_transfer_id: string
    current_step: 1
  }
>({
  url: ROUTES.US_OWNERSHIP.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

/**********************
 *
 *  Step 2 fetch (GET & UPDATE)
 *
 **********************/

export const fetchUsOwnershipStep2 = createFetcher<Step2OwnershipDataItem[]>({
  url: ROUTES.US_OWNERSHIP.STEP_2.GET,
  method: 'GET',
  withTokens: true,
})

export const fetchOwnershipStep2Update = createFetcher<
  null,
  {
    law_firm_matter_id: string
    tm_ownership_transfer_id: string
    current_step: 2
  }
>({
  url: ROUTES.US_OWNERSHIP.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

/**********************
 *
 *  Step 3 fetch (GET & UPDATE)
 *
 **********************/

export const fetchUsOwnershipStep3 = createFetcher<Step3OwnershipDataItem[]>({
  url: ROUTES.US_OWNERSHIP.STEP_3.GET,
  method: 'GET',
  withTokens: true,
})

export const fetchOwnershipStep3Update = createFetcher<
  null,
  {
    is_approved_for_transfer: boolean
    is_email_generated?: boolean
    tm_ownership_transfer_id?: string
    current_step: 3
    filing_form: FilingFormOwnership
  }
>({
  url: ROUTES.US_OWNERSHIP.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

export const fetchUsOwnershipContactAttorney = createFetcher<
  Step3OwnershipDataItem[]
>({
  url: ROUTES.US_OWNERSHIP.OWNERSHIP_ATTORNEY.GET,
  method: 'GET',
  withTokens: true,
})

/**********************
 *
 *  Step 4 fetch (GET & UPDATE)
 *
 **********************/

export const fetchUsOwnershipStep4 = createFetcher<Step4OwnershipDataItem[]>({
  url: ROUTES.US_OWNERSHIP.STEP_4.GET,
  method: 'GET',
  withTokens: true,
})

/**********************
 *
 *  Step Move (POST)
 *
 **********************/

export interface StepMoveOwnership {
  tm_ownership_transfer_id: string
  next_step: number
}

/**********************
 *
 *  Step 1 Interfaces
 *
 **********************/

export interface Step1OwnershipDataItem {
  tm_ownership: TmOwnership
  tm_ownership_transfer_id: string
  current_step: number
  created_at: number
  updated_on: number
  paralegal_id: any
  order_date: number
  matter_id: number
  order_item: OrderItemOwnership
}

export interface TmOwnership {
  order_item_id: string
  created_at: number
  updated_on: number
  order_status: number
  prices_id: string
  proof: any
  serial_number: string
  is_admin_add_owner_req: boolean
  is_submitted_for_processing: boolean
  correspondent_name: any
  correspondent_country: any
  correspondent_city: any
  correspondent_state: any
  correspondent_zip: any
  deliverables: any
}

/**********************
 *
 *  Step 2 Interfaces
 *
 **********************/

export interface Step2OwnershipDataItem {
  tm_ownership: TmOwnership
  tm_ownership_transfer_id: string
  current_step: number
  created_at: number
  updated_on: number
  paralegal_id: any
  law_firm_matter_id: number
  law_firm_matter: any
  paralegal_name: string
  order_date: number
  matter_id: number
  order_item: OrderItemOwnership
}

export interface OrderItemOwnership {
  id: string
  user_id: string
  order_id: string
  added_at: number
  quantity: number
  billable_amount: number
  billable_amount_paid: number
  billable_govt_amount: number
  billable_govt_amount_paid: number
  item_type: string
  order_item_status: number
  payment_status: number
  prices_id: string
  pricing_ref: PricingRefOwnership
  is_offline_order: boolean
  offline_order_rep: any
  offline_order_created_by: any
}

export interface PricingRefOwnership {
  ot_gov_fee: number
  ot_prof_fee: number
}

/**********************
 *
 *  Step 3 Interfaces
 *
 **********************/

export interface Step3OwnershipDataItem {
  tm_ownership: TmOwnership
  tm_ownership_transfer_id: string
  current_step: number
  created_at: number
  updated_on: number
  renewal_deadline: number
  paralegal_id: any
  law_firm_matter_id: number
  law_firm_matter: any
  paralegal_name: string
  is_approved_for_transfer: boolean
  is_email_generated: boolean
  filing_form: FilingFormOwnership
  order_date: number
  matter_id: number
  order_item: OrderItemOwnership
}

export interface FilingFormOwnership {
  entities: Entity[]
  serial_number: string
}

export interface Entity {
  id: string
  zip: string
  city: string
  state: string
  is_new: boolean
  street: string
  country: string
  is_saved: boolean
  entity_name: string
  entity_type: string
  is_modified: boolean
  state_of_formation: string
  authorised_person_name: string
  tm_ownership_transfer_id: string
}

/**********************
 *
 *  Step 4 Interfaces
 *
 **********************/

export interface Step4OwnershipDataItem {
  tm_ownership: TmOwnership
  tm_ownership_transfer_id: string
  current_step: number
  created_at: number
  updated_on: number
  renewal_deadline: number
  paralegal_id: string
  law_firm_matter_id: number
  law_firm_matter: LawFirmMatterOwnership
  paralegal_name: string
  is_approved_for_transfer: boolean
  is_email_generated: boolean
  filing_form: FilingFormOwnership
  order_date: number
  matter_id: number
  order_item: OrderItemOwnership
}

export interface LawFirmMatterOwnership {
  id: number
  created_at: number
  updated_on: number
  order_item_id: string
  order_item_type: string
  is_assigned: boolean
  law_firm_id: string
  attorney_id: string
  admin_us_tm_processing_id: any
  assigned_name: string
  prices_id: string
  admin_int_tm_processing_id: any
  matter_status: number
}
