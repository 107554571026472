import { createFetcher, ROUTES } from '../api'
import { AddonDataItem } from '../interfaces'

export const fetchBorderProtectionStep = createFetcher<AddonDataItem[]>({
  url: ROUTES.BORDER_PROTECTION.FETCH_STEP,
  method: 'GET',
  withTokens: true,
})

export const fetchBorderProtectionMoveStep = createFetcher<null>({
  url: ROUTES.BORDER_PROTECTION.MOVE_STEP,
  method: 'PATCH',
  withTokens: true,
})
