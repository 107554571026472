import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'

export type Initializer<T> = T extends any ? T | ((prev: T) => T) : never
export type DispatchSetQuery<T> = (
  v: Initializer<T>,
  options?: Options<T>,
) => void
interface SetQueryProps<T, IsReady extends boolean = boolean> {
  set: DispatchSetQuery<T>
  isReady: IsReady
}

export type Ret<T> = [T, SetQueryProps<T>]

interface Options<T> {
  modifier?: (prev: T, next: T) => T
  extendQuery?: Record<string, any>
  isPush?: boolean
  spread?: boolean
}

export const useQueryState = <T>(
  key: string,
  defaultValue: T,
  {
    modifier = (prev, next) => next,
    extendQuery = {},
    isPush = false,
    spread,
  }: Options<T> = {},
): Ret<T> => {
  const toSpread = spread && typeof defaultValue === 'object'
  const { query, isReady, pathname, replace, push } = useRouter()
  const q = toSpread ? query : query[key]
  const isString = typeof defaultValue === 'string'

  const value: T = useMemo(() => {
    try {
      if (!isReady) return defaultValue
      const val = isString
        ? (q as unknown as T)
        : toSpread
        ? ({
            ...defaultValue,
            ...(Object.entries(q as object).reduce(
              (acc, [key, value]) => ({
                ...acc,
                [key]: JSON.parse(value),
              }),
              {},
            ) as object),
          } as T)
        : (JSON.parse(q as string) as T)
      return val ?? defaultValue
    } catch (error) {
      return defaultValue
    }
  }, [isReady, q, isString])

  const set: DispatchSetQuery<T> = (v, options) => {
    try {
      if (!isReady) return
      const val = (options?.modifier ?? modifier)(
        value,
        typeof v === 'function' ? v(value) : v,
      )

      const redirect =
        typeof options?.isPush === 'boolean'
          ? options.isPush
            ? push
            : replace
          : isPush
          ? push
          : replace

      redirect(
        {
          pathname,
          query: {
            ...query,
            ...(toSpread
              ? {
                  ...Object.entries(val as unknown as object).reduce(
                    (acc, [key, value]) => ({
                      ...acc,
                      [key]: JSON.stringify(value),
                    }),
                    {},
                  ),
                }
              : { [key]: isString ? val : JSON.stringify(val) }),
            ...extendQuery,
            ...(options?.extendQuery ?? []),
          },
        },
        undefined,
        { scroll: false },
      )
    } catch (error) {}
  }

  useEffect(() => {
    if (!isReady) return
    set(modifier(defaultValue, value) as Initializer<T>, {})
  }, [isReady])

  return [value, { set, isReady }] as Ret<T>
}
