import { doc, getDoc, setDoc, Timestamp } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { db } from '../../config/firebase'
import { useUser } from '../../context/user'
import { version as appVersion } from '../../package.json'
import ChangelogModal from './ChangeLogModal'

interface ChangelogData {
  name: string
  changes: {
    text: string
    description: string
  }[]
}

export interface ChangelogSection {
  version: string
  date: Timestamp
  data: ChangelogData[]
}

// Define the collection and document names
const ADMIN_COLLECTION_NAME = 'admin'
const CHANGELOG_DOCUMENT_NAME = 'changelog'
const VERSION_COLLECTION_NAME = 'versions'
const USERS_METADATA_COLLECTION_NAME = 'users-metadata'

// Function to fetch the changelog data from Firebase
async function fetchChangelogData(versionNumber: string) {
  const changelogDoc = await getDoc(
    doc(
      db,
      ADMIN_COLLECTION_NAME,
      CHANGELOG_DOCUMENT_NAME,
      VERSION_COLLECTION_NAME,
      versionNumber,
    ),
  )
  if (changelogDoc.exists()) {
    return changelogDoc.data() as ChangelogSection
  }
  return null
}

// Function to update the last used version for a user
async function updateLastUsedVersion(userEmail: string, versionNumber: string) {
  const usersMetadataRef = doc(
    db,
    ADMIN_COLLECTION_NAME,
    CHANGELOG_DOCUMENT_NAME,
    USERS_METADATA_COLLECTION_NAME,
    userEmail,
  )
  await setDoc(usersMetadataRef, { last_used_version: versionNumber })
}

// Function to check if a user has seen a particular version of the changelog
async function hasUserSeenVersion(userEmail: string, versionNumber: string) {
  const usersMetadataRef = doc(
    db,
    ADMIN_COLLECTION_NAME,
    CHANGELOG_DOCUMENT_NAME,
    USERS_METADATA_COLLECTION_NAME,
    userEmail,
  )
  const usersMetadataDoc = await getDoc(usersMetadataRef)
  if (usersMetadataDoc.exists()) {
    const usersMetadata = usersMetadataDoc.data()
    if (usersMetadata?.last_used_version === versionNumber) {
      return true
    }
  }
  return false
}

const ChangeLogContainer = () => {
  const [changelogData, setChangelogData] = useState<ChangelogSection | null>(
    null,
  )

  const [isChangeLogModalOpen, setIsChangeLogModalOpen] = useState(false)

  const { profile } = useUser()

  const currentAppVersion = appVersion

  useEffect(() => {
    // Function to display the changelog popup if necessary
    if (!profile?.email) {
      return
    }
    async function displayChangelogPopup(
      userEmail: string,
      versionNumber: string,
    ) {
      const hasSeenChangelog = await hasUserSeenVersion(
        userEmail,
        versionNumber,
      )
      if (!hasSeenChangelog) {
        const data = await fetchChangelogData(versionNumber)
        if (data) {
          setChangelogData(data)
          setIsChangeLogModalOpen(true)
        }
      }
    }
    displayChangelogPopup(profile?.email || '', currentAppVersion)
  }, [profile?.email])

  if (!changelogData) {
    return null
  }

  return (
    <ChangelogModal
      isOpen={isChangeLogModalOpen}
      onClose={() => setIsChangeLogModalOpen(false)}
      changelogSections={changelogData}
      onSubmit={() => {
        setIsChangeLogModalOpen(false)
        updateLastUsedVersion(profile?.email || '', currentAppVersion)
      }}
    />
  )
}

export default ChangeLogContainer
