import { createFetcher, ROUTES } from '../api'

export const fetchGovtFee = createFetcher<
  GovernmentReport[],
  any,
  { order_id: string }
>({
  url: ROUTES.GOVT_FEES.FETCH_BY_ID,
  method: 'GET',
  withTokens: true,
})
export const fetchGenerateGovtFee = createFetcher<{}, GenerateGovtFee>({
  url: ROUTES.GOVT_FEES.FETCH_GENERATE_GOVT_FEE,
  method: 'POST',
  withTokens: true,
})

interface GenerateGovtFee {
  order_id: string
  order_item_govt_fees: GovernmentReport[]
}

export interface GovernmentReport {
  order_item_id: string
  govt_fee: number
}
