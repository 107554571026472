import axios, { AxiosRequestConfig } from 'axios'
import { Omit } from 'ui/interfaces'
import { ifSpreadObject } from 'utils'

export const SEARCH_HOST = process.env.NEXT_PUBLIC_SEARCH_HOST
export const API_HOST = process.env.NEXT_PUBLIC_API_HOST
export const ADMIN_HOST = process.env.NEXT_PUBLIC_ADMIN_HOST
export const UPLOADER_HOST = process.env.NEXT_PUBLIC_UPLOADER_HOST
export const STATIC_HOST = 'https://static.trademarkia.com'
export const TMO_HOST = process.env.NEXT_PUBLIC_TMO_HOST
export const PE_ADMIN_HOST = process.env.NEXT_PUBLIC_PE_ADMIN_HOST
export const NEXT_PUBLIC_PE_API_HOST = process.env.NEXT_PUBLIC_PE_API_HOST
export const INTERNATIONAL_PREVIEW_HOST =
  process.env.NEXT_PUBLIC_INTERNATIONAL_PREVIEW_HOST

type AxiosCustomConfig<Req> = Omit<AxiosRequestConfig<Req>, 'url' | 'params'>

export interface Options<Req, Query> extends AxiosCustomConfig<Req> {
  url?: string
  params?: Query
}

export type Fetcher<Res, Req = any, Params = any, Query = any> = (
  body?: Req,
  options?: Options<Req, Query>,
  urlParams?: Params,
) => Promise<ResWrapper<Res>>

// (body?: Req, options?: Options<Req>, urlParams?: Query) => Promise<ResWrapper<Res>>
// body?: Req, options?: Options<Req>, urlParams?: Query) => Promise<ResWrapper<Res>

export interface DefaultOptions<Query = {}>
  extends Omit<AxiosRequestConfig, 'url'> {
  url: string | ((param: Query) => string)

  getTokens?: boolean
  withTokens?: boolean

  getPeTokens?: boolean
  withPeTokens?: boolean

  isFormData?: boolean
}

export interface ResWrapper<T> {
  body?: T
  msg?: string
  error?: any
}

export const createFetcher =
  <Res = any, Req = any, Params = any, Query = any>({
    url,
    getTokens,
    withTokens,
    isFormData,
    getPeTokens,
    withPeTokens,
    ...defaultOptions
  }: DefaultOptions<Params>): Fetcher<Res, Req, Params, Query> =>
  async (
    body?: Req,
    options: Options<Req, Query> = {},
    urlParams?: Params,
  ): Promise<ResWrapper<Res>> => {
    try {
      const res = await axios({
        headers: {
          'Content-Type': 'application/json',
          ...ifSpreadObject(!!withTokens, {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }),
          ...ifSpreadObject(!!withPeTokens, {
            Authorization: `Bearer ${localStorage.getItem('peToken')}`,
          }),
        },
        url:
          typeof url === 'function'
            ? url(urlParams || ({} as Params))
            : url || '',
        ...defaultOptions,
        ...options,
        data: isFormData
          ? body
          : {
              ...defaultOptions.data,
              ...options.data,
              ...body,
            },
        params: {
          ...defaultOptions.params,
          ...options.params,
        },
      })
      if (getTokens) localStorage.setItem('token', res.data.body.token)
      if (getPeTokens) localStorage.setItem('peToken', res.data.body.peToken) // TODO : change target
      return { ...res.data }
    } catch (error) {
      return { error: error.response ? error.response : error }
    }
  }

export const searchRoute = (path: string) => `${SEARCH_HOST}${path}`
export const apiRoute = (path: string) => `${API_HOST}${path}`
export const adminRoute = (path: string) => `${ADMIN_HOST}${path}`
export const uploaderRoute = (path: string) => `${UPLOADER_HOST}${path}`
export const staticRoute = (path: string) => `${STATIC_HOST}${path}`
export const tmoRoute = (path: string) => `${TMO_HOST}${path}`
export const peAdminRoute = (path: string) => `${PE_ADMIN_HOST}${path}`
export const peRoute = (path: string) => `${NEXT_PUBLIC_PE_API_HOST}${path}`
export const internationalPreviewRoute = (path: string) =>
  `${INTERNATIONAL_PREVIEW_HOST}${path}`
export const tsdrRoute = (serial_number: string | number) =>
  `https://tsdr.uspto.gov/#caseNumber=${serial_number}&caseSearchType=US_APPLICATION&caseType=DEFAULT&searchType=statusSearch`

export const imgRoute = (path: string) =>
  path?.startsWith('http') ? path : `${API_HOST}/media/${path}`
export const specimenRoute = (path: string) =>
  path?.startsWith('http') ? path : `${API_HOST}/media/${path}`
export const uploaderImgRoute = (path: string) =>
  path?.startsWith('http') ? path : `${UPLOADER_HOST}/media/${path}`
export const soundRoute = (path: string) =>
  path?.startsWith('http') ? path : `${API_HOST}/media/${path}`
export const adminImageRoute = (admin_id: string) =>
  admin_id?.startsWith('http')
    ? admin_id
    : `${API_HOST}/media/admins/profile/${admin_id}`

export const peImgRoute = (path: string) =>
  `${NEXT_PUBLIC_PE_API_HOST}/images/${path}`
export const trademarkImage = (serialNumber: string | number) =>
  staticRoute(`/images/${serialNumber}`)

export const ROUTES = {
  SEARCH: {
    TRADEMARK: (p: { country: string }) => searchRoute(`/${p.country}/search`),
    TRADEMARK_BY_SERIAL: (p: { country: string; serialNumber: string }) =>
      searchRoute(`/${p.country}/serial_number/${p.serialNumber}`),
    FETCH_STEP: adminRoute('/addon/fetch/search'),
    BASIC_SEARCH: (p: { country: string }) =>
      searchRoute(`/basic/${p.country}`),
    MOVE_STEP: adminRoute('/addon/move/search'),
    UPDATE_MATTER: adminRoute('/order/update/search'),
    ARRAY_OF_VISIBILITIES: searchRoute('/admin/fetch_array_visibilities'),
    TOGGLE_VISIBILITY: (p: { sn: string }) =>
      searchRoute(`/admin/toggle_us_mark_visibility/${p.sn}`),
    TTAB_SEARCH: searchRoute('/basic/us/ttab'),
    PROBLEMATIC_CLIENT_SEARCH: adminRoute('/problematic_clients/fetch'),
    PROBLEMATIC_CLIENT_SEARCH_BY_USER_ID: (p: { user_id: string }) =>
      adminRoute(`/problematic_clients/fetch/${p.user_id}`),
    UNFILED_MATTERS: adminRoute('/us/fetch/unfiled'),
    BASIC_ELASTIC_SEARCH: searchRoute('/v2/us'),
  },
  GOVT_FEES: {
    FETCH_BY_ID: (p: { order_id: string }) =>
      adminRoute(`/govt_fee/fetch/${p.order_id}`),
    FETCH_GENERATE_GOVT_FEE: adminRoute('/govt_fee/generate'),
  },
  INCONTESTABILTITY: {
    FETCH_STEP: adminRoute('/addon/fetch/incontestability'),
    MOVE_STEP: adminRoute('/addon/move/incontestability'),
  },
  BORDER_PROTECTION: {
    FETCH_STEP: adminRoute('/addon/fetch/borders'),
    MOVE_STEP: adminRoute('/addon/move/borders'),
  },
  AUTH: {
    LOGIN: adminRoute('/login'),
    UPDATE_GMAIL_ACCESS: adminRoute('/admin/gmail/update'),
  },
  CART: {
    DELETE: (P: { order_id: string; order_item_id: string }) =>
      apiRoute(`/cart/delete/${P.order_id}/${P.order_item_id}`),
  },

  STEP0: {
    RENEWAL: adminRoute('/renewal/postpayment/fetch'),
  },
  RENEWAL: {
    TRADEMARK: (p: { country: string; serialNumber: string }) =>
      apiRoute(`/renewal/${p.country}/serial_number/${p.serialNumber}`),
    PRICES: apiRoute('/prices/renewal'),
  },
  US_RENEWAL: {
    UPDATE: adminRoute('/renewal/update'),
    MOVE_STEP: adminRoute('/renewal/move/step'),
    STEP_0: {
      GET: adminRoute('/renewal/postpayment/fetch'),
    },
    STEP_1: {
      GET: adminRoute('/renewal/step1/fetch'),
    },
    STEP_2: {
      GET: adminRoute('/renewal/step2/fetch'),
    },
    STEP_3: {
      GET: adminRoute('/renewal/step3/fetch'),
    },
    STEP_4: {
      GET: adminRoute('/renewal/step4/fetch'),
    },
    RENEWAL_ATTORNEY: {
      GET: adminRoute('/portal/attorney/renewal/us'),
    },
  },
  SOCIAL: {
    UPDATE_MATTER: adminRoute('/order/update/social_media'),
  },
  US_OWNERSHIP: {
    UPDATE: adminRoute('/ownership/update'),
    MOVE_STEP: adminRoute('/ownership/move/step'),
    STEP_1: {
      GET: adminRoute('/ownership/step1/fetch'),
    },
    STEP_2: {
      GET: adminRoute('/ownership/step2/fetch'),
    },
    STEP_3: {
      GET: adminRoute('/ownership/step3/fetch'),
    },
    STEP_4: {
      GET: adminRoute('/ownership/step4/fetch'),
    },
    OWNERSHIP_ATTORNEY: {
      GET: adminRoute('/portal/attorney/ownership_transfer/us'),
    },
  },
  US_SOU: {
    UPDATE: adminRoute('/sou/update'),
    MOVE_STEP: adminRoute('/sou/move/step'),
    POSTPAYMENT: adminRoute('/sou/postpayment/fetch'),
    STEP_1: {
      GET: adminRoute('/sou/step1/fetch'),
    },
    STEP_2: {
      GET: adminRoute('/sou/step2/fetch'),
    },
    STEP_3: {
      GET: adminRoute('/sou/step3/fetch'),
    },
    STEP_4: {
      GET: adminRoute('/sou/step4/fetch'),
    },
    SOU_ATTORNEY: {
      GET: adminRoute('/portal/attorney/sou/us'),
    },
    UPDATE_MATTER: adminRoute('/order/update/sou'),
  },
  OFFICEACTIONS: {
    PRICES: apiRoute('/prices/office_action'),
    GET_ONE: apiRoute('/office_actions/fetch'),
    SUBMIT: apiRoute('/office_actions/submit'),
    UPDATE_TAGS: adminRoute('/office_action/update/tags'),
    UPDATE: adminRoute('/office_action/update'),
    MOVE_STEP: adminRoute('/office_action/move/step'),
    STEP_1: {
      GET: adminRoute('/office_action/step1/fetch'),
    },
    STEP_2: {
      GET: adminRoute('/office_action/step2/fetch'),
    },
    STEP_3: {
      GET: adminRoute('/office_action/step3/fetch'),
    },
    STEP_4: {
      GET: adminRoute('/office_action/step4/fetch'),
    },
    STEP_5: {
      GET: adminRoute('/office_action/step5/fetch'),
    },
    OFFICEACTIONS_ATTORNEY: {
      GET: adminRoute('/portal/attorney/office_action/us'),
    },
  },
  US_FILING: {
    UPDATE: adminRoute('/us/update'),
    MOVE_STEP: adminRoute('/us/move/step'),
    POSTPAYMENT: adminRoute('/us/postpayment/fetch'),
    STEP1: {
      GET: adminRoute('/us/step1/fetch'),
      ADD: adminRoute('/us/step1/assign/classes'),
      REMOVE: adminRoute('/us/step1/remove/classes'),
      UDPATE: adminRoute('/us/step1/update/classes'),
    },
    STEP2: {
      GET: adminRoute('/us/step2/fetch'),
    },
    STEP2_2: {
      GET: adminRoute('/us/step8/fetch'),
    },
    STEP3: {
      GET: adminRoute('/us/step3/fetch'),
    },
    STEP4: {
      GET: adminRoute('/us/step4/fetch'),
    },
    STEP5: {
      GET: adminRoute('/us/step5/fetch'),
    },
    STEP6: {
      GET: adminRoute('/us/step6/fetch'),
    },
    STEP7: {
      GET: adminRoute('/us/step7/fetch'),
    },
    TTABS: {
      GET: searchRoute('/ttabus/search'),
    },
    LEGAL_FIRMS: {
      GET: adminRoute('/lawfirm/fetch/country'),
      GET_ATTORNEYS: adminRoute('/lawfirm/fetch/attorney'),
      CREATE_MATTER: adminRoute('/lawfirm/matter/create'),
    },
    EARN_ATTORNEY: {
      GET: adminRoute('/portal/attorney/tmorder/us/earn'),
    },
    APPROVE_ATTORNEY: {
      GET: adminRoute('/portal/attorney/tmorder/us/approve'),
    },
  },
  INT_FILING: {
    UPDATE: adminRoute('/int/update'),
    MOVE_STEP: adminRoute('/int/move/step'),
    POSTPAYMENT: adminRoute('/int/postpayment/fetch'),
    STEP1: {
      GET: adminRoute('/int/step1/fetch'),
      ADD: adminRoute('/int/step1/assign/classes'),
      REMOVE: adminRoute('/int/step1/remove/classes'),
      UDPATE: adminRoute('/int/step1/update/classes'),
    },
    STEP2: {
      GET: adminRoute('/int/step2/fetch'),
    },
    STEP3: {
      GET: adminRoute('/int/step3/fetch'),
    },
    STEP4: {
      GET: adminRoute('/int/step4/fetch'),
    },
    STEP5: {
      GET: adminRoute('/int/step5/fetch'),
    },
    STEP6: {
      GET: adminRoute('/int/step6/fetch'),
    },
    EARN_ATTORNEY: {
      GET: adminRoute('/portal/attorney/tmorder/int/earn'),
    },
    APPROVE_ATTORNEY: {
      GET: adminRoute('/portal/attorney/tmorder/int/approve'),
    },
  },
  US_FILING_NEW: {
    GET_UNASSIGNED_COUNT: adminRoute('/us/unassigned_count/fetch'),
    GET_US_FILING_DASHBOARD: adminRoute('/us/dashboard'),
    GET_US_FILING_ATTORNEY_ORDERS: adminRoute('/us/attorney/orders/fetch'),
    GET_POST_ADVISORY_US_FILING_ATTORNEY_ORDERS: adminRoute(
      '/us/post_advisory/matters/fetch',
    ),
    UPLOAD_SPECIMEN: uploaderRoute('/uploader/admin/specimen'),
    GET_US_FILING_TM_PROCESSING_DETAILS: (p: { tm_order_id: string }) =>
      adminRoute(`/us/fetch/detailed/${p.tm_order_id}`),
    GET_US_FILING_TM_PROCESSING_DETAILS_BY_ORDER: (p: { order_id: string }) =>
      adminRoute(`/us/fetch/order/detailed/${p.order_id}`),
    UPDATE_SEARCH_REPORT: (p: { tm_order_id: string }) =>
      adminRoute(`/us/update/search_report/${p.tm_order_id}`),
    UPDATE_DILIGENCE_REPORT: (p: { tm_order_id: string }) =>
      adminRoute(`/us/update/diligence_report/${p.tm_order_id}`),
    UPDATE_CONFLICT_CHECK_REPORT: (p: { tm_order_id: string }) =>
      adminRoute(`/us/update/conflict_check/${p.tm_order_id}`),
    UPDATE_FILING_REPORT: (p: { tm_order_id: string }) =>
      adminRoute(`/us/update/filing_report/${p.tm_order_id}`),
    UPDATE_SIGNATURE: (p: { tm_order_id: string }) =>
      adminRoute(`/us/update/signature/${p.tm_order_id}`),
    UPDATE_FINAL_FILING: (p: { tm_order_id: string }) =>
      adminRoute(`/us/update/filing/${p.tm_order_id}`),
    UPDATE_ADVISORY_EMAIL: (p: { tm_order_id: string }) =>
      adminRoute(`/us/update/draft_advisory/${p.tm_order_id}`),
    DIMISSAL_ACTION: (p: { action: 'dismiss' | 'retain' }) =>
      adminRoute(`/us/dismissal_action/${p.action}`),
    UPLOAD_ADVISORY_EMAIL: uploaderRoute(`/uploader/admin/advisory_emails`),
    UPLOAD_SIGNATURE_EMAIL: uploaderRoute(`/uploader/admin/signature_email`),
  },
  CONSTANTS: {
    COUNTRIES: apiRoute('/prices/tm_reg'),
    CLASSIFICATIONS: apiRoute('/trademark_classifications'),
    PROBLAMATIC_CLIENTS: adminRoute('/us/problematic/fetch'),
    UNFILED_MATTERS: adminRoute('/us/unfiled/fetch'),
  },
  ORDERS: {
    GET_ORDER: (p: { id: string }) => apiRoute(`/cart/fetch/${p.id}`),
    GET_ORDER_ITEM: (p: { order_id: string; order_item_id: string }) =>
      apiRoute(`/cart/order_item/fetch/${p.order_id}/${p.order_item_id}`),
  },
  ADMINS: {
    GET_ALL: adminRoute('/admins/fetch'),
    GET_ROLES: adminRoute('/roles/fetch'),
    GET_TEAMS: adminRoute('/teams/fetch'),
    CREATE: adminRoute('/admins/create'),
    UPDATE_STATUS: adminRoute('/admins/status/update'),
    CREATE_TEAM: adminRoute('/team/create'),
    LINK_ADMIN_TO_TEAM: adminRoute('/team/join'),
    REMOVE_ADMIN_FROM_TEAM: adminRoute('/team/remove'),
    ADD_PERMISSIONS_TO_ADMIN: adminRoute('/admins/permission/update'),
    GET_BY_TEAM_ID: adminRoute('/team/members'),
    ADD_TO_GROUP: adminRoute('/admins/group/link'),
    ADD_PERMISSIONS: adminRoute('/admins/permissions/add'),
    GET_GROUPS: adminRoute('/groups/fetch'),
    CREATE_GROUP: adminRoute('/group/create'),
    UPDATE_GROUP: adminRoute('/group/update'),
    GET_ALL_PARALEGALS: adminRoute('/paralegal/all'),
    GET_ONE: (p: { attorney_id: string }) =>
      apiRoute(`/attorney/fetch/${p.attorney_id}`),
  },
  ADDONS: {
    STATS: adminRoute('/addon/stats/tm_addons'),
    GET_ALL: adminRoute('/addon/fetch/tm_addons'),
    PROCESS: adminRoute('/addon/processing/tm_addons'),
  },
  LEGAL_FORMS: {
    PROCESS: adminRoute('/legal_forms/process'),
    GET_ALL: adminRoute('/legal_forms/fetch'),
    GET_GROUPED_DOCS: adminRoute('/legal_forms/fetch/group'),
  },
  REFUNDS: {
    // 0 = pending, 1 = approved but not refunded yet, 2 = processing, 3 = rejected - Params
    GET_ALL: adminRoute('/refunds/fetch'),
    CREATE_REFUND: adminRoute('/refunds/create'),
    PATCH_REFUND: adminRoute('/refunds/action'),
  },
  MATTERS: {
    GET_ALL: adminRoute('/matters/'),
    GET_ONE: (p: { matter_id: string }) =>
      adminRoute(`/matters/fetch/${p.matter_id}`),
    GET_ATTORNEY: adminRoute('/portal/attorney/fetch'),
    GET_REPORT: adminRoute('/matters/matter_report'),
    GET_SUBSCRIPTIONS_BY_ORDER: adminRoute('/subscriptions/fetch_by_order'),
    UPDATE_CLIENT: adminRoute('/users/update'),
    GET_TRANSACTIONS_BY_ORDER_ID: (p: { order_id: string }) =>
      adminRoute(`/matters/transactions/${p.order_id}`),
    GET_TRANSACTIONS_BY_DATE: adminRoute('/matters/transactions_by_date'),
  },
  DASHBOARD: {
    GET_FILES: searchRoute('/dashboard/files'),
  },
  SUBSCRIPTIONS: {
    GET_DASHBOARD: adminRoute('/subscriptions/dashboard/fetch'),
    GET_MATTER_REPORT: adminRoute('/subscriptions/matter_report/fetch'),
    GET_PREDICTION: adminRoute('/subscriptions/predict'),
    GET_CANCEL_SUBSCRIPTION: (p: { subscription_id: string }) =>
      adminRoute(`/subscriptions/cancel/${p.subscription_id}`),
  },
  POSTFILING: {
    GET_ALL: searchRoute('/postfiling/'),
    SEARCH: adminRoute('/post_filing/search'),
    GET_ATTORNEYS: searchRoute('/postfiling/attorneys'),
    TRADEMARK_ENTRY: adminRoute('/post_filing/trademark_entry'),
    TENTATIVELY_CLAIM: (p: { id: string }) =>
      adminRoute(`/post_filing/temp_claim/${p.id}`),
    CHECK_IN: (p: { type_of_check_in: string }) =>
      adminRoute(`/post_filing/check_in/${p.type_of_check_in}`),
    UPDATE_MATTERS: (p: { id: string }) =>
      adminRoute(`/post_filing/update/${p.id}`),
    MATTERS_BY_ID: (p: { id: string }) =>
      adminRoute(`/post_filing/fetch/${p.id}`),
  },
  SPREADSHEETS: {
    CREATE_SPREADSHEET: adminRoute('/spreadsheets/create'),
    GET_BY_ADMIN_ID: (p: { admin_id: string }) =>
      adminRoute(`/spreadsheets/fetch/${p.admin_id}`),
    GET_BY_ADMIN_ID_AND_TYPE: (p: {
      admin_id: string
      spreadsheet_type: string
    }) => adminRoute(`/spreadsheets/fetch/${p.admin_id}/${p.spreadsheet_type}`),
    DELETE_SPREADSHEET: (p: { admin_id: string; spreadsheet_type: string }) =>
      adminRoute(`/spreadsheets/delete/${p.admin_id}/${p.spreadsheet_type}`),
  },
  CLIENTS: {
    GET_ALL: adminRoute('/users/'),
    GET_ONE: (p: { user_id: string }) => adminRoute(`/users/${p.user_id}`),
    GET_ALL_ORDERS: (p: { user_id: string }) =>
      adminRoute(`/users/${p.user_id}/orders`),
    GET_ALL_TRANSACTIONS: (p: { user_id: string }) =>
      adminRoute(`/users/${p.user_id}/transactions`),
    GET_ALL_TAGGED_CLIENTS: adminRoute('/client_tagging/fetch'),
    GET_ONE_TAGGED_CLIENT: (p: { user_id: string }) =>
      adminRoute(`/client_tagging/fetch/${p.user_id}`),
    TAG_CLIENT: adminRoute('/client_tagging/tag'),
    UPDATE_CLIENT: adminRoute('/users/update'),
    MOVE_ORDERS: adminRoute('/users/move_orders'),
  },
  CLIENT_MANAGEMENT: {
    GET_ALL: adminRoute('/client_management/search'),
    GET_DASHBOARD_ADMINS: adminRoute('/client_management/dashboard'),
    GET_MY_DASHBOARD: adminRoute('/client_management/my/dashboard'),
    TEMP_CLAIM: (p: { user_id: string }) =>
      adminRoute(`/client_management/tmp_claim/${p.user_id}`),
    UPDATE_CLIENT: (p: { user_id: string }) =>
      adminRoute(`/client_management/update/${p.user_id}`),
    PHONE_CHECK_IN: (p: { user_id: string }) =>
      adminRoute(`/client_management/phone/check_in/${p.user_id}`),
    CHECK_IN: adminRoute(`/client_management/check_in`),
  },
  ATTORNEYS: {
    GET_ALL: adminRoute('/attorney/fetch'),
    GET_ONE: (p: { attorney_id: string }) =>
      adminRoute(`/attorney/fetch/${p.attorney_id}`),
    CREATE: adminRoute('/attorney/create'),
    UPDATE: adminRoute('/attorney/update'),
    DISABLE: (p: { attorney_id: string }) =>
      adminRoute(`/attorney/disable/${p.attorney_id}`),
  },
  UPLOADER: {
    UPLOAD: (p: { ext: string }) =>
      uploaderRoute(`/uploader/logo?ext=${p.ext}`),
    UPLOAD_ADMIN: uploaderRoute(`/uploader/admin`),
    UPLOAD_SOUND: (p: { ext: string }) =>
      uploaderRoute(`/uploader/sound?ext=${p.ext}`),
    UPLOAD_DOCUMENT: (p: { ext: string }) =>
      adminRoute(`/deliverable/upload?ext=${p.ext}`),
    UPLOAD_ATTORNEY_PHOTO: adminRoute('/attorney/photo'),
  },
  DELIVERABLE: {
    CREATE_DELIVERABLE: adminRoute('/deliverable/create'),

    GET_DELIVERABLE: (p: { order_item_id: string }) =>
      adminRoute(`/deliverable/fetch/${p.order_item_id}`),
    DELETE_DELIVERABLE: (p: { id: string }) =>
      adminRoute(`/deliverable/${p.id}`),
  },
  OFFLINE: {
    CREATE: adminRoute('/offline/order/item/create'),
  },
  LEADS: {
    UNCLAIMED: adminRoute('/leads/unclaimed'),
    CLAIMED: adminRoute('/leads/claimed'),
    DISMISSED: adminRoute('/leads/dismissed'),
    OTHER: adminRoute('/leads/other'),
    TENTATIVELY_CLAIM: adminRoute('/leads/tentative_claim'),
    LEADERBOARD: adminRoute('/leads/leaderboard'),
    GET_ONE: adminRoute('/leads/fetch/lead_details'),
    CLAIM: adminRoute('/leads/claim'),
    DISMISS_LEAD: adminRoute(`/leads/`),
    CONTACT_LEAD: adminRoute(`/leads/contact_count`),
    FETCH_CONTACT_HISTORY: adminRoute('/leads/fetch/contact'),
    BCC_EMAIL: (p: { order_id: string }) =>
      adminRoute(`/leads/bcc_email/${p.order_id}`),
  },
  CLIENT_RELATIONS: {
    GET_ONE: (p: { admin_id: string }) =>
      adminRoute(`/client_relations/${p.admin_id}`),
  },
  ATTORNEY: {
    GET_ALL: apiRoute('/attorney/fetch'),
    GET_ONE: (p: { attorney_id: string }) =>
      apiRoute(`/attorney/fetch/${p.attorney_id}`),
    CREATE: adminRoute('/attorney/create'),
    UPDATE: adminRoute('/attorney/update'),
    PHOTO: adminRoute('/attorney/photo'),
    DISABLE: (p: { attorney_id: string }) =>
      adminRoute(`/attorney/disable/${p.attorney_id}`),
  },
  TMORDER: {
    GET_ONE: (p: { order_item_id: string }) =>
      adminRoute(`/order/fetch/tmorder/${p.order_item_id}`),
    UPDATE_MATTER: adminRoute('/order/update/tmorder'),
  },
  TMRENEWAL: {
    GET_ONE: (p: { order_item_id: string }) =>
      adminRoute(`/order/fetch/renewal/${p.order_item_id}`),
  },
  TMOWNERSHIP: {
    GET_ONE: (p: { order_item_id: string }) =>
      adminRoute(`/order/fetch/ownershiptransfer/${p.order_item_id}`),
    POSTPAYMENT: adminRoute('/ownership/postpayment/fetch'),
    UPDATE_MATTER: adminRoute('/order/update/ownershiptransfer'),
  },
  COUPONS: {
    GET_BY_ADMIN: adminRoute('/coupon/fetch'),
    UPDATE: adminRoute('/coupon/update'),
    CREATE: adminRoute('/coupon/create'),
    APPLY: apiRoute('/cart/coupon/apply'),
  },
}

export const PE_ROUTES = {
  ORDERS: {
    GET: peAdminRoute('/admin/orders'),
    GET_ORDER_ITEM: (p: { order_id: string; order_item_id: string }) =>
      peAdminRoute(
        `/admin/orders/order-items?order_id=${p.order_id}&order_item_id=${p.order_item_id}`,
      ),
  },
  CLIENTS: {
    GET_ALL: peAdminRoute('/admin/users/'),
    GET_ONE: (p: { user_id: string }) =>
      peAdminRoute(`/admin/users/${p.user_id}`),
    GET_ALL_TRANSACTIONS: (p: { user_id: string }) =>
      peAdminRoute(`/admin/users/${p.user_id}/transactions`),
    GET_ALL_ORDERS: (p: { user_id: string }) => peAdminRoute(`/admin/orders`),
  },
  CONSTANTS: {
    COUNTRIES: peRoute('/prices'),
    // CLASSIFICATIONS: apiRoute('/trademark_classifications/'),
    // PROBLAMATIC_CLIENTS: adminRoute('/us/problematic/fetch'),
    // UNFILED_MATTERS: adminRoute('/us/unfiled/fetch'),
  },
}
