import { createFetcher, ROUTES } from '../api'
import { Admin } from '../interfaces'
import { PricingRef } from './matters'

export const fetchCountries = createFetcher<Price[]>({
  url: ROUTES.CONSTANTS.COUNTRIES,
})
export const fetchClassifications = createFetcher<Classification[]>({
  url: ROUTES.CONSTANTS.CLASSIFICATIONS,
})
export const fetchTtabs = createFetcher<{ data: TTAB[] }>({
  url: ROUTES.US_FILING.TTABS.GET,
  withTokens: true,
  method: 'POST',
})
export const fetchUnfiledMatters = createFetcher<UnfiledMatter[]>({
  url: ROUTES.CONSTANTS.UNFILED_MATTERS,
  withTokens: true,
})
export const fetchProblamaticClients = createFetcher<ProbamaticClient[]>({
  url: ROUTES.CONSTANTS.PROBLAMATIC_CLIENTS,
  withTokens: true,
})

export const fetchLegalFirms = createFetcher<LegalFirm[]>({
  url: ROUTES.US_FILING.LEGAL_FIRMS.GET,
  withTokens: true,
})
export const fetchAttorneys = createFetcher<Attorney[]>({
  url: ROUTES.US_FILING.LEGAL_FIRMS.GET_ATTORNEYS,
  withTokens: true,
})
export const fetchCreateLawMatter = createFetcher<
  LawMatter,
  {
    law_firm_id: string
    order_item_id: string
    law_firm_attorney_id: string
    order_item_type: string
    prices_id: string
  }
>({
  url: ROUTES.US_FILING.LEGAL_FIRMS.CREATE_MATTER,
  method: 'POST',
  withTokens: true,
})

export interface LawMatter {
  id: number
  created_at: number
  updated_on: number
  order_item_id: string
  order_item?: any
  order_item_type: string
  is_assigned: boolean
  law_firm_id: string
  attorney_id: string
  admin_us_tm_processing_id?: any
}

export interface Attorney {
  admin_id: string
  admin: Admin
  law_firm_id: string
}

export interface LegalFirm {
  id: string
  created_at: number
  updated_on: number
  name: string
  prices_id: string
}

export interface ProsecutionHistory {
  date: string
  due_date: string
  history_text: string
}

export interface TTAB {
  _version_: number
  def_id: string[]
  defendant: string[]
  filing_date: number[]
  id: string
  mark_texts: string[]
  plaintiff: string[]
  plaintiff_id: string[]
  registration_numbers: string[]
  serial_numbers: string[]
  status_code: number[]
  status_update_date: number[]
  type_code: string[]
}

// {
//   id: string
//   type: string // matter type
//   number: string
//   filing_date: string
//   status: string
//   status_date: string
//   general_contact_number: string
//   defendant_name: string
//   defendant_correspondence: string
//   defendant_serial_number: string
//   defendant_application_status: string
//   defendant_mark: string
//   potential_opposers: any[]
//   prosecution_history: ProsecutionHistory[]
//   interlocutory_attorney: string
//   paralegal_name: string
//   plaintiff_name: string
//   plaintiff_correspondence: string
// }

export interface Price {
  id: string
  created_at: number
  updated_at: number
  country_code: string
  country_name: string
  region: string
  flag_url: string
  currency: string
  standard_cost: number
  standard_cost_additional_class: number
  filing_fee: number
  filing_govt_fee_additional_class: number
  number_of_class: number
  search: number
  search_govt_fee: number
  need_poa: boolean
  need_publication: boolean
  need_registration: boolean
  federal_search: number
  attorney_search: number
}

export interface Classification {
  id: string
  class_number: number
  class_name: string
  class_type: number
}

export interface UnfiledMatter {
  created_at: number
  order_date: number
  current_step: number
  matter_id: number
  order_item: {
    id: string
    user_id: string
    order_id: string
    added_at: number
    quantity: number
    billable_amount: number
    billable_amount_paid: number
    billable_govt_amount: number
    billable_govt_amount_paid: number
    item_type: string
    order_item_status: number
    payment_status: number
    prices_id: string
    pricing_ref: PricingRef
    is_offline_order: boolean
    offline_order_rep?: any
    offline_order_created_by?: any
    discount?: any
  }
  tm_order: {
    order_item_id: string
    created_at: number
    updated_on: number
    order_status: number
    classes: number
    prices_id: string
    is_name_protected: boolean
    mark_name: string
    is_slogan_protected: boolean
    slogan: string
    is_logo_protected: boolean
    logo_url: string
    is_colour_protected: boolean
    logo_description: string
    is_trademark_already_using: boolean
    is_good_protected: boolean
    is_service_protected: boolean
    good_description: string
    service_description: string
    is_step1_completed: boolean
    is_step2_completed: boolean
    is_good_classifications_confirmed: boolean
    is_service_classifications_confirmed: boolean
    is_attorney_review_requested: boolean
    is_step3_completed: boolean
    is_step4_completed: boolean
    priority_type: number
    is_submitted_for_processing: boolean
  }
  tm_order_id: string
  law_firm_matter_id?: any
  working_attorney_id?: any
  attorney_name: string
  working_attorney_name: string
  serial_number: string
}

export interface ProbamaticClient {
  id: string
  client_name: string
  client_mark: string
  matter_type: string
  serial_number: string
  notes: string
  added_by: string
}
