/* eslint-disable react/no-unstable-nested-components */
import { TrashIcon } from '@heroicons/react/outline'
import {
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatStrikethrough,
  ImageRounded,
  InsertPageBreak,
  LinkRounded,
  RedoRounded,
  UndoRounded,
} from '@mui/icons-material'
import Highlight from '@tiptap/extension-highlight'
import ImageExt from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import { Editor, EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { fetchUploadImage } from 'api'

import { FC, useCallback, useState } from 'react'
import {
  AddColumnLeft,
  AddColumnRight,
  AddRowBottom,
  AddRowTop,
  DeleteColumn,
  DeleteRow,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  T,
  TableIcon,
} from './assets'

// import { fetchUploadImage } from 'api'
import Modal from './Modal'
import classes from './tiptap.module.css'

export * from './tiptapCss'

const MenuBar: FC<{ editor: Editor | null }> = ({ editor }) => {
  const [openImageUploadModal, setOpenImageUploadModal] = useState(false)
  const [imgUrl, setImgUrl] = useState('')
  const [imgLoading, setImgLoading] = useState(false)
  const setLink = useCallback(() => {
    const previousUrl = editor?.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor?.chain().focus().extendMarkRange('link').unsetLink().run()

      return
    }

    // update link
    editor?.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
  }, [editor])

  if (!editor) {
    return null
  }

  const addImage = (url: string) =>
    editor.chain().focus().setImage({ src: url }).run()

  // prettier-ignore
  const actions = [
    { name:'bold', call: () => editor.chain().focus().toggleBold().run(), Icon: FormatBold },
    { name:'italic', call: () => editor.chain().focus().toggleItalic().run(), Icon: FormatItalic },
    { name:'strike', call: () => editor.chain().focus().toggleStrike().run(), Icon: FormatStrikethrough },

    { name:'bullet list', call: () => editor.chain().focus().toggleBulletList().run(), Icon: FormatListBulleted },
    { name:'ordered list', call: () => editor.chain().focus().toggleOrderedList().run(), Icon: FormatListNumbered },

    { name:'paragraph', call: () => editor.chain().focus().setParagraph().run(), Icon: T },
    { name:'h1', call: () => editor.chain().focus().toggleHeading({ level: 1 }).run(), Icon: H1 },
    { name:'h2', call: () => editor.chain().focus().toggleHeading({ level: 2 }).run(), Icon: H2 },
    { name:'h3', call: () => editor.chain().focus().toggleHeading({ level: 3 }).run(), Icon: H3 },
    { name:'h4', call: () => editor.chain().focus().toggleHeading({ level: 4 }).run(), Icon: H4 },
    { name:'h5', call: () => editor.chain().focus().toggleHeading({ level: 5 }).run(), Icon: H5 },
    { name:'h6', call: () => editor.chain().focus().toggleHeading({ level: 6 }).run(), Icon: H6 },
    
    // { name:'horizontal rule', call: () => editor.chain().focus().setHorizontalRule().run(), HorizontalRule },
    { name:'hard break', call: () => editor.chain().focus().setHardBreak().run(), Icon: InsertPageBreak },

    { name:'insertTable', call: () => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run(), Icon: TableIcon },
    { name:'addColumnBefore', call: () => editor.chain().focus().addColumnBefore().run(), disabled: !editor.can().addColumnBefore(), Icon: AddColumnLeft },
    { name:'addColumnAfter', call: () => editor.chain().focus().addColumnAfter().run(), disabled: !editor.can().addColumnAfter(), Icon: AddColumnRight },
    { name:'deleteColumn', call: () => editor.chain().focus().deleteColumn().run(), disabled: !editor.can().deleteColumn(), Icon:DeleteColumn },
    { name:'addRowBefore', call: () => editor.chain().focus().addRowBefore().run(), disabled: !editor.can().addRowBefore(), Icon: AddRowTop },
    { name:'addRowAfter', call: () => editor.chain().focus().addRowAfter().run(), disabled: !editor.can().addRowAfter(), Icon: AddRowBottom },
    { name:'deleteRow', call: () => editor.chain().focus().deleteRow().run(), disabled: !editor.can().deleteRow(), Icon: DeleteRow },
    { name:'deleteTable', call: () => editor.chain().focus().deleteTable().run(), disabled: !editor.can().deleteTable(), Icon: ()=><TrashIcon className='h-5 w-5 top-1 left-1'/> },
    
    { name:'setLink', call: setLink, Icon: LinkRounded },
    { name:'addImage', call: ()=>setOpenImageUploadModal(true), Icon: ImageRounded },
    
    { name:'undo', call: () => editor.chain().focus().undo().run(), Icon: UndoRounded },
    { name:'redo', call: () => editor.chain().focus().redo().run(), Icon: RedoRounded },
    { name:'redo', call: () => editor.chain().focus().setHighlight().run(), Icon: RedoRounded },
  ]

  return (
    <>
      <div className="flex flex-wrap p-2">
        {actions.map(({ name, call, disabled, Icon }) => (
          <button
            title={name}
            className={[
              'relative m-1 rounded border p-1 text-xs',
              disabled ? 'opacity-50' : '',
            ].join(' ')}
            type="button"
            key={name}
            onClick={call}
            disabled={disabled}
          >
            {Icon ? <Icon /> : name}
          </button>
        ))}
      </div>

      {openImageUploadModal && (
        <Modal isOpen onClose={() => setOpenImageUploadModal(false)}>
          <div className="w-[700px] max-w-[100vw]">
            <input
              type="text"
              value={imgUrl}
              onChange={e => setImgUrl(e.target.value)}
              className="mb-2 w-full"
            />
            {imgLoading ? (
              <p>Loading...</p>
            ) : (
              imgUrl && (
                <img
                  className="h-[400px] w-full object-contain"
                  src={imgUrl}
                  alt=""
                />
              )
            )}
            <input
              className="mt-5"
              onChange={async e => {
                setImgLoading(true)
                const file = e.currentTarget?.files?.[0]
                console.log({ file })
                if (!file) {
                  setImgLoading(false)
                  return
                }
                const formData = new FormData()
                formData.append('file', file)
                const ext = file.name.split('.').pop()
                if (ext) formData.append('ext', ext)

                const f = file
                if (f) {
                  const reader = new FileReader()
                  try {
                    const { body, error } = await fetchUploadImage(
                      formData,
                      {},
                      ext ? { ext } : undefined,
                    )
                    if (error || !body) return
                    setImgUrl(body.url)
                  } catch (error) {
                    ///
                  } finally {
                    setImgLoading(false)
                  }

                  reader.readAsBinaryString(f)
                }
              }}
              type="file"
            />
            <br />
            <button
              className="bg-primary mt-5 rounded px-4 py-2 font-bold text-white"
              type="button"
              onClick={() => {
                addImage(imgUrl)
                setOpenImageUploadModal(false)
                setImgUrl('')
              }}
            >
              Insert Image
            </button>
          </div>
        </Modal>
      )}
    </>
  )
}

interface Props {
  setHtml: (v: string) => void
  template?: string
  edit: boolean
  className?: string
}

export const TipTap: FC<Props> = ({ edit, setHtml, template, className }) => {
  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        Table,
        TableCell,
        TableHeader,
        TableRow,
        Link,
        ImageExt,
        Highlight,
      ],
      content: template,
      onUpdate: props => setHtml(props.editor.getHTML()),
      editable: edit,
    },
    [edit],
  )

  return (
    <div className="border-black-1A rounded-lg border-2">
      {edit && <MenuBar editor={editor} />}
      <div
        className={[
          classes.editor,
          'max-h-[500px] overflow-auto border-t-2',
        ].join(' ')}
      >
        <EditorContent className={['', className].join(' ')} editor={editor} />
      </div>
    </div>
  )
}
