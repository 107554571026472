import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  collapsed?: boolean
  deps?: any[]
}

export const Collapsible: FC<Props> = ({
  collapsed,
  children,
  deps = [],
  className,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)
  useLayoutEffect(() => {
    setHeight(ref.current?.scrollHeight || 0)
  }, [ref.current?.clientHeight, children, ...deps])
  return (
    <div
      {...props}
      style={{
        height: collapsed ? 0 : height,
      }}
      className={['overflow-hidden transition-all', className].join(' ')}
    >
      <div className="w-full" ref={ref}>
        {children}
      </div>
    </div>
  )
}
