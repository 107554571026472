import {
  ChevronDownIcon,
  ChevronRightIcon,
  MenuIcon,
} from '@heroicons/react/solid'
import { Brand, useBrand, useOutsideClick } from 'hooks'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useState } from 'react'
import { Collapsible, Section } from 'ui'
import {
  NavAddons,
  NavAllClients,
  NavClientManagement,
  NavClientTagging,
  NavCoupons,
  NavLeaderboard,
  NavLeads,
  NavManageUrls,
  NavMatterSize,
  NavRefunds,
  NavSubscription,
  NavUsFiling,
  PeLogo,
  Logo as TmLogo,
} from 'ui/assets'
import { accessibleOnClick, ifSpreadArray } from 'utils'
import { logout } from '../config/firebase'
import { isLocal } from '../constants/env'
import CallDrawer from './Adminv3/CallDrawer'
import Drawer from './Adminv3/Drawer'
import GlobalSearch from './Adminv3/GlobalSearch'
import AddonsDropdown from './Adminv3/Header/AddonsDropdown'
import NetworkStatus from './Adminv3/NetworkStatus'
import ProfileDropdown from './ProfileDropdown'
import ChangeLogContainer from './VersionSystem/ChangeLogContainer'

interface NoDropLink {
  href: string
  label: string
}
interface DropLink extends NoDropLink {
  // category: string
}
interface BrandLink extends NoDropLink {
  dropLinks: DropLink[]
  custom?: boolean
}

interface BrandProps {
  name: string
  links: BrandLink[]
  noDropLinks: NoDropLink[]
  Logo: FC
}

const brands: Record<Brand, BrandProps> = {
  pe: {
    name: 'PatentExpress',
    Logo: PeLogo,
    links: [],
    noDropLinks: [
      { href: '/pe', label: 'Orders' },
      { href: '/pe/clients', label: 'Clients' },
      // { href: '/pe/services', label: 'Services' },
      // { href: '/pe/manage', label: 'Manage' },
    ],
  },
  tm: {
    name: 'Trademarkia',
    Logo: TmLogo,
    links: [
      {
        href: '/tm/us_filing',
        label: 'US Filing',
        dropLinks: [
          {
            label: 'US TM Registration',
            href: '/tm/us_filing',
          },
          {
            label: 'US TM renewal',
            href: '/tm/renewal/file',
          },
          {
            label: 'US Ownership Transfer',
            href: '/tm/ownership/file',
          },
          {
            label: 'US SOU Transfer',
            href: '/tm/sou/file',
          },
          {
            label: 'US Office Action',
            href: '/tm/office_action',
          },
        ],
      },
      {
        href: '/tm/int_filing',
        label: 'International Filing',
        dropLinks: [
          {
            label: 'International TM Registration',
            href: '/tm/int_filing',
          },
        ],
      },
      {
        href: '/tm/addons',
        label: 'Addons',
        dropLinks: [
          {
            label: 'Incontestability',
            href: '/tm/addons/incontestability',
          },
          {
            label: 'Search',
            href: '/tm/addons/search',
          },
          {
            label: 'Border Protection',
            href: '/tm/addons/border_protection',
          },
        ],
        custom: true,
      },
    ],
    noDropLinks: [
      { href: '/tm/leads', label: 'Leads' },
      { href: '/tm/attorneys', label: 'Attorneys' },
      { href: '/tm/clients', label: 'Clients' },
      { href: '/tm/coupons', label: 'Coupons' },
      { href: '/tm/client_tagging', label: 'Client Tagging' },
      { href: '/tm/leaderboard', label: 'Leaderboard' },
      { href: '/tm/offline', label: 'Offline Orders' },
      { href: '/tm/teams', label: 'Teams' },
      { href: '/tm/url_management', label: 'Manage URLs' },
      { href: '/tm/client_management', label: 'Client Management' },
      { href: '/tm/operations', label: 'Operations' },
    ],
  },
}

interface NewBrandProps {
  name: string
  Logo: FC
  links: {
    label: string
    href: string
    options?: Option[]
    isDropdown?: boolean
    multiOptions?: MultiOption[]
  }[]
}

interface MultiOption {
  label: string
  options: Option[]
}

interface Option {
  label: string
  href: string
  description: string
  icon?: JSX.Element
  subOption?: React.ReactNode
}

const newBrands: Record<Brand, NewBrandProps> = {
  pe: {
    name: 'PatentExpress',
    Logo: PeLogo,
    links: [
      {
        label: 'Orders',
        href: '/pe',
        options: [
          {
            label: 'All Orders',
            href: '/pe',
            description: 'View all orders.',
          },
        ],
        isDropdown: true,
      },
      {
        label: 'Clients',
        href: '/pe/clients',
        options: [
          {
            label: 'All Clients',
            href: '/pe/clients',
            description: 'View all clients.',
          },
        ],
        isDropdown: true,
      },
    ],
  },
  tm: {
    name: 'Trademarkia',
    Logo: TmLogo,
    links: [
      {
        label: 'Trademark Filing',
        isDropdown: true,
        href: '/tm/us_filing',
        options: [
          ...ifSpreadArray(isLocal, {
            label: 'US Filing',
            href: '/tm/us_filing',
            description: 'Manage trademarks filed in the U.S.',
            icon: <NavUsFiling />,
          }),
          {
            label: 'International Filing',
            href: '/tm/int_filing',
            description: 'Manage trademarks filed internationally.',
            icon: <NavUsFiling />,
          },
          {
            label: 'Add Ons',
            href: '/tm/addons',
            description: 'View and manage client add ons.',
            subOption: <AddonsDropdown />,
            icon: <NavAddons />,
          },
        ],
      },
      {
        label: 'Clients',
        isDropdown: true,
        href: '/tm/clients',
        options: [
          {
            label: 'All Clients',
            href: '/tm/clients',
            description: 'View all clients.',
            icon: <NavAllClients />,
          },
          {
            label: 'Client Management',
            href: '/tm/client_management',
            description: 'Manage your assigned clients.',
            icon: <NavClientManagement />,
          },
          {
            label: 'Leads',
            href: '/tm/leads',
            description: 'View all leads.',
            icon: <NavLeads />,
          },
          {
            label: 'Client Tagging',
            href: '/tm/client_tagging',
            description: 'Manage client tagging.',
            icon: <NavClientTagging />,
          },
          {
            label: 'Leaderboard',
            href: '/tm/leaderboard',
            description: 'View the leaderboard.',
            icon: <NavLeaderboard />,
          },
        ],
      },
      {
        label: 'Offline Orders',
        isDropdown: false,
        href: '/tm/offline',
      },
      {
        label: 'Operations',
        isDropdown: true,
        href: '/tm/operations',
        multiOptions: [
          {
            label: 'Matters',
            options: [
              {
                label: 'Matter size',
                href: '/tm/operations?type=matter',
                description: 'View all the matters of Trademarkia',
                icon: <NavMatterSize />,
              },
              {
                label: 'Subscription Management',
                href: '/tm/operations?type=subscriptions',
                description: 'Keep track of all client subscriptions',
                icon: <NavSubscription />,
              },
              {
                label: 'Refunds',
                href: '/tm/operations?type=refunds',
                description: 'Manage client refunds',
                icon: <NavRefunds />,
              },
            ],
          },
          {
            label: 'Process',
            options: [
              {
                label: 'Coupons',
                href: '/tm/coupons',
                description: 'Track all coupons for Trademarkia',
                icon: <NavCoupons />,
              },
              {
                label: 'Manage URLs',
                href: '/tm/url_management',
                description: 'URL Management for Trademarkia',
                icon: <NavManageUrls />,
              },
              // {
              //   label: 'Contact Management',
              //   href: '/tm/operations?type=contact_management',
              //   description: 'Emails, Phone numbers of all contacts',
              //   icon: <NavManageUrls />,
              // },
            ],
          },
        ],
      },
      {
        label: 'Teams',
        href: '/tm/teams',
        isDropdown: true,
        options: [
          {
            label: 'Teams',
            href: '/tm/teams',
            description: 'Manage all teams',
          },
        ],
      },
      // {
      //   label: 'Quoting Tool',
      //   href: '/tm/quoting_tool',
      //   isDropdown: false,
      // },
    ],
  },
}

const Header = () => {
  const [hideDropdown, setHideDropdown] = useState(false)
  const [hideSubDropdown, setHideSubDropdown] = useState(false)
  const router = useRouter()
  const { brand } = useBrand()
  const { name } = brands[brand]
  const { links: newLinks } = newBrands[brand]
  const [showDropdown, setShowDropdown] = useState('')
  const [showMenu, setShowMenu] = useState(false)
  const listRef = useOutsideClick(() => {
    if (showDropdown) setShowDropdown('')
  }, [showDropdown, router.pathname])

  const isLinkActive = (href: string) => {
    const [hrefPathName, hrefQueryString] = href.split('?')
    const hrefQueries = hrefQueryString
      ? hrefQueryString.split('&').map(i => i.split('='))
      : []

    return (
      hrefPathName === router.pathname &&
      hrefQueries.every(([key, value]) => router.query[key] === value)
    )
  }

  return (
    <div
      className="sticky top-0"
      style={{
        zIndex: 10,
      }}
    >
      <Section
        innerClassName="flex justify-between items-center nav:items-center border-b-[1px]  top-0 max-h-[10%]  bg-white py-2   !max-w-[100%] px-8"
        // style={{
        //   boxShadow: `0px 4px 42px rgba(189, 189, 189, 0.25)`,
        // }}
      >
        <Link passHref href="/tm/">
          <div className="flex cursor-pointer items-center py-6">
            <div className="flex h-8 w-8 items-center justify-center">
              <div
                className="bg-white"
                {...accessibleOnClick((e: any) => {
                  e.preventDefault()
                  router.push(`/${brand}`)
                })}
              >
                {brand === 'tm' ? <TmLogo /> : <PeLogo />}
              </div>
            </div>
            <h1
              {...accessibleOnClick((e: any) => {
                e.preventDefault()
                router.push(`/${brand}`)
              })}
              className="text-h4 ml-2 font-bold text-gray-800"
            >
              {name}
              <span className="font-normal">Admin</span>
            </h1>
          </div>
        </Link>

        {/* // the nav items start from here */}

        <div
          ref={listRef}
          className={`no-scrollbar nav:flex hidden flex-1 flex-wrap items-center  px-20 ${
            brand === 'tm' ? 'justify-between' : 'justify-start'
          } `}
        >
          {newLinks.map(
            ({ isDropdown, label, options, multiOptions, href }) => {
              const existsInMultiOptions = multiOptions?.some(section =>
                section.options.some(o => isLinkActive(o.href)),
              )
              const existsInOptions = options?.find(o => isLinkActive(o.href))

              return (
                <li
                  key={label}
                  className={[
                    'group relative mx-2 flex cursor-pointer flex-col items-center  md:flex-row',
                  ].join(' ')}
                >
                  <div
                    className={[
                      `relative cursor-pointer whitespace-nowrap font-medium text-[#4E4E4E]`,
                      // ${
                      //   router.asPath.includes(
                      //     options?.find(o => o.href === getRoute(router))?.href,
                      //   ) &&
                      //   `${
                      //     brand === 'tm' ? 'text-primary' : 'text-pe-primary'
                      //   }
                      // options?.some(o => o.href === getRoute(router))
                      //   ? `${
                      //       brand === 'tm' ? 'text-primary' : 'text-pe-primary'
                      //     }  font-semibold`
                      //   : '',
                    ].join(' ')}
                  >
                    {isDropdown ? (
                      <>
                        <div
                          onMouseEnter={() => {
                            setHideDropdown(false)
                            setShowDropdown(label)
                          }}
                          className={`hover:text-primary flex items-center     ${
                            existsInOptions || existsInMultiOptions // TODO: fix this
                              ? `${
                                  brand === 'tm'
                                    ? 'text-primary'
                                    : 'text-pe-primary'
                                }  `
                              : ''
                          }
                        `}
                          {...accessibleOnClick(() => setHideDropdown(true))}
                        >
                          <p
                            className={` pointer-events-none  
                      
                          `}
                          >
                            {label}
                          </p>
                          <ChevronDownIcon
                            className={`ml-1 h-5 w-5 transition-transform duration-200 ease-in-out `}
                          />
                        </div>
                        {/*  DROP DOWN AREA STARTS */}
                        <div
                          className={[
                            'pointer-events-none absolute -left-40 top-full z-50 flex  -translate-y-3 opacity-0 transition-all duration-200 ease-in-out',
                            hideDropdown
                              ? ''
                              : 'lg:group-hover:pointer-events-auto lg:group-hover:translate-y-0 lg:group-hover:opacity-100',
                            showDropdown === href
                              ? 'pointer-events-auto translate-y-0 opacity-100 lg:pointer-events-none lg:translate-y-3 lg:opacity-0'
                              : '',
                          ].join(' ')}
                        >
                          <div
                            {...accessibleOnClick(() => setHideDropdown(true))}
                            className="flex rounded-lg bg-white p-7 shadow-lg"
                          >
                            {
                              // if multiOptions then show multiOptions
                              multiOptions ? (
                                <div className="flex">
                                  {multiOptions.map(
                                    ({ label: labal, options: optionss }) => (
                                      // two columns of options with their labels

                                      <div className="flex">
                                        {/* // suboption for multioption options */}
                                        <div
                                          className={[
                                            'pointer-events-none  z-40  -translate-y-3 opacity-0 transition-all duration-200 ease-in-out',
                                            hideSubDropdown
                                              ? ''
                                              : 'lg:group-hover:pointer-events-auto lg:group-hover:translate-y-0 lg:group-hover:opacity-100',
                                          ].join(' ')}
                                        >
                                          <div
                                            {...accessibleOnClick(() =>
                                              setHideSubDropdown(true),
                                            )}
                                            className=""
                                          >
                                            {
                                              optionss?.find(
                                                o => o.href === showDropdown,
                                              )?.subOption
                                            }
                                          </div>
                                        </div>
                                        <div
                                          key={labal}
                                          className="flex flex-col"
                                        >
                                          <p className="mb-2 text-lg font-semibold">
                                            {labal}
                                          </p>
                                          <div className="flex">
                                            <div>
                                              {optionss?.map(link => (
                                                <div
                                                  // if link has subOption then setHideSubDropdown(false)
                                                  onMouseEnter={() => {
                                                    if (link.subOption) {
                                                      setHideSubDropdown(false)
                                                      setShowDropdown(link.href)
                                                    } else {
                                                      setHideSubDropdown(true)
                                                      setShowDropdown('')
                                                    }
                                                  }}
                                                  key={link.href}
                                                  className="p-1"
                                                >
                                                  <ol>
                                                    <li
                                                      {...accessibleOnClick(
                                                        () => {
                                                          router.push(link.href)
                                                        },
                                                      )}
                                                      className={`rounded-xl p-4 font-semibold hover:bg-gray-100 ${
                                                        isLinkActive(link.href)
                                                          ? 'border-primary border-2'
                                                          : ''
                                                      }`}
                                                    >
                                                      <div className="flex items-center justify-between">
                                                        <div className="flex items-center">
                                                          {link.icon && (
                                                            <div className="mr-2">
                                                              {link.icon}
                                                            </div>
                                                          )}
                                                          <div>
                                                            <div className="flex flex-col">
                                                              <p className="text-sm font-semibold">
                                                                {link.label}
                                                              </p>
                                                              <p className="text-sm font-medium text-gray-500">
                                                                {
                                                                  link.description
                                                                }
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div>
                                                          {link.subOption ? (
                                                            <div className="">
                                                              <ChevronRightIcon className="h-6 w-6 text-gray-500" />
                                                            </div>
                                                          ) : null}
                                                        </div>
                                                      </div>
                                                    </li>
                                                  </ol>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ),
                                  )}
                                </div>
                              ) : (
                                // if not multiOptions then show options
                                <div>
                                  {options?.map(link => (
                                    <div
                                      // if link has subOption then setHideSubDropdown(false)
                                      onMouseEnter={() => {
                                        if (link.subOption) {
                                          setHideSubDropdown(false)
                                          setShowDropdown(link.href)
                                        } else {
                                          setHideSubDropdown(true)
                                          setShowDropdown('')
                                        }
                                      }}
                                      key={link.href}
                                      className="p-1"
                                    >
                                      <ol>
                                        <li
                                          {...accessibleOnClick(() => {
                                            router.push(link.href)
                                          })}
                                          className={`rounded-xl py-4 px-4 font-semibold hover:bg-gray-100 ${
                                            isLinkActive(link.href)
                                              ? 'border-primary border-2'
                                              : ''
                                          }`}
                                        >
                                          <div className="flex items-center justify-between">
                                            <div className="flex items-center">
                                              {link.icon && (
                                                <div className="mr-2">
                                                  {link.icon}
                                                </div>
                                              )}
                                              <div>
                                                <div className="flex flex-col">
                                                  <p className="text-sm font-semibold">
                                                    {link.label}
                                                  </p>
                                                  <p className="text-sm font-medium text-gray-500">
                                                    {link.description}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                            <div>
                                              {link.subOption ? (
                                                <div className="">
                                                  <ChevronRightIcon className="h-6 w-6 text-gray-500" />
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        </li>
                                      </ol>
                                    </div>
                                  ))}
                                </div>
                              )
                            }
                            {/* // this is the subOption area */}
                            <div
                              className={[
                                'pointer-events-none  z-40  -translate-y-3 opacity-0 transition-all duration-200 ease-in-out',
                                hideSubDropdown
                                  ? ''
                                  : 'lg:group-hover:pointer-events-auto lg:group-hover:translate-y-0 lg:group-hover:opacity-100',
                              ].join(' ')}
                            >
                              <div
                                {...accessibleOnClick(() =>
                                  setHideSubDropdown(true),
                                )}
                                className=""
                              >
                                {
                                  options?.find(o => o.href === showDropdown)
                                    ?.subOption
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*  DROP DOWN AREA ENDS */}
                      </>
                    ) : (
                      <Link passHref href={href} key={href}>
                        <li
                          {...accessibleOnClick(() => setShowDropdown(''))}
                          className="group flex cursor-pointer items-center "
                        >
                          <p
                            className={`hover:text-adminBlue cursor-pointer whitespace-nowrap ${
                              router.pathname === href &&
                              `${
                                brand === 'tm'
                                  ? 'text-adminBlue'
                                  : 'text-pe-primary'
                              }  font-semibold`
                            }`}
                          >
                            {label}
                          </p>
                        </li>
                      </Link>
                    )}
                  </div>
                </li>
              )
            },
          )}
        </div>
        {/* <button
          onClick={logout}
          type="button"
          // style={{ backgroundColor: colors.primary }}
          className="nav:flex bg-adminBlue mt-5 hidden rounded-lg px-4 py-2 font-semibold text-white"
        >
          LOGOUT
        </button> */}
        <div className="nav:block hidden">
          <ProfileDropdown />
        </div>
        <MenuIcon
          onClick={() => {
            setShowMenu(!showMenu)
          }}
          className="nav:hidden flex h-6 w-6"
        />
      </Section>

      {/* The mobile view navbar */}

      <Drawer
        title="Menu"
        onClose={() => {
          setShowMenu(false)
        }}
        anchor="right"
        open={showMenu}
      >
        <div className="h-px w-full bg-gray-100" />
        <div className="flex flex-col items-center justify-center gap-4">
          <div className="flex min-w-[500px] flex-col gap-12 p-8">
            {newLinks.map(
              ({ isDropdown, label, options, multiOptions, href }) => {
                const existsInMultiOptions = multiOptions?.some(section =>
                  section.options.some(o => isLinkActive(o.href)),
                )
                const existsInOptions = options?.find(o => isLinkActive(o.href))

                return (
                  <li
                    key={label}
                    className=" mx-2 flex cursor-pointer flex-col  items-center"
                  >
                    {isDropdown ? (
                      <>
                        <div
                          className={`hover:text-primary flex items-center     ${
                            existsInOptions || existsInMultiOptions // TODO: fix this
                              ? `${
                                  brand === 'tm'
                                    ? 'text-primary'
                                    : 'text-pe-primary'
                                }  `
                              : ''
                          }
                        `}
                          {...accessibleOnClick(() => {
                            setShowDropdown(label === showDropdown ? '' : label)
                          })}
                        >
                          <p>{label}</p>
                          <ChevronDownIcon
                            className={`ml-1 h-5 w-5 transition-transform duration-200 ease-in-out `}
                          />
                        </div>
                        <Collapsible collapsed={showDropdown !== label}>
                          <div
                            className={[
                              ' -translate-y-3 opacity-0 transition-all duration-1000 ease-in-out',
                              showDropdown === label
                                ? 'flex translate-y-0 opacity-100'
                                : '',
                            ].join(' ')}
                          >
                            <div
                              {...accessibleOnClick(() =>
                                setHideDropdown(true),
                              )}
                              className="flex rounded-lg bg-white p-7 shadow-lg"
                            >
                              {
                                // if multiOptions then show multiOptions
                                multiOptions ? (
                                  <div className="flex flex-col">
                                    {multiOptions.map(
                                      ({ label: labal, options: optionss }) => (
                                        // two columns of options with their labels

                                        <div className="flex">
                                          {/* // suboption for multioption options */}
                                          <div
                                            className={[
                                              ' z-40  -translate-y-3 opacity-0 transition-all duration-200 ease-in-out',
                                              hideSubDropdown
                                                ? ''
                                                : 'lg:group-hover:pointer-events-auto lg:group-hover:translate-y-0 lg:group-hover:opacity-100',
                                            ].join(' ')}
                                          >
                                            <div
                                              {...accessibleOnClick(() =>
                                                setHideSubDropdown(true),
                                              )}
                                              className=""
                                            >
                                              {
                                                optionss?.find(
                                                  o => o.href === showDropdown,
                                                )?.subOption
                                              }
                                            </div>
                                          </div>
                                          <div
                                            key={labal}
                                            className="flex flex-col"
                                          >
                                            <p className="mb-2 text-lg font-semibold">
                                              {labal}
                                            </p>
                                            <div className="flex ">
                                              <div>
                                                {optionss?.map(link => (
                                                  <div
                                                    // if link has subOption then setHideSubDropdown(false)
                                                    onMouseEnter={() => {
                                                      if (link.subOption) {
                                                        setHideSubDropdown(
                                                          false,
                                                        )
                                                        setShowDropdown(
                                                          link.href,
                                                        )
                                                      } else {
                                                        setHideSubDropdown(true)
                                                        setShowDropdown('')
                                                      }
                                                    }}
                                                    key={link.href}
                                                    className="p-1"
                                                  >
                                                    <ol>
                                                      <li
                                                        {...accessibleOnClick(
                                                          () => {
                                                            router.push(
                                                              link.href,
                                                            )
                                                            setShowMenu(false)
                                                          },
                                                        )}
                                                        className={`rounded-xl p-4 font-semibold hover:bg-gray-100 ${
                                                          isLinkActive(
                                                            link.href,
                                                          )
                                                            ? 'border-primary border-2'
                                                            : ''
                                                        }`}
                                                      >
                                                        <div className="flex items-center justify-between">
                                                          <div className="flex items-center">
                                                            {link.icon && (
                                                              <div className="mr-2">
                                                                {link.icon}
                                                              </div>
                                                            )}
                                                            <div>
                                                              <div className="flex flex-col">
                                                                <p className="text-sm font-semibold">
                                                                  {link.label}
                                                                </p>
                                                                <p className="text-sm font-medium text-gray-500">
                                                                  {
                                                                    link.description
                                                                  }
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div>
                                                            {link.subOption ? (
                                                              <div className="">
                                                                <ChevronRightIcon className="h-6 w-6 text-gray-500" />
                                                              </div>
                                                            ) : null}
                                                          </div>
                                                        </div>
                                                      </li>
                                                    </ol>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ),
                                    )}
                                  </div>
                                ) : (
                                  // if not multiOptions then show options
                                  <div>
                                    {options?.map(link => (
                                      <div
                                        // if link has subOption then setHideSubDropdown(false)
                                        // onMouseEnter={() => {
                                        //   if (link.subOption) {
                                        //     setHideSubDropdown(false)
                                        //     setShowDropdown(link.href)
                                        //   } else {
                                        //     setHideSubDropdown(true)
                                        //     setShowDropdown('')
                                        //   }
                                        // }}
                                        key={link.href}
                                        className="p-1"
                                      >
                                        <ol>
                                          <li
                                            {...accessibleOnClick(() => {
                                              router.push(link.href)
                                              setShowMenu(false)
                                            })}
                                            className={`rounded-xl py-4 px-4 font-semibold hover:bg-gray-100 ${
                                              isLinkActive(link.href)
                                                ? 'border-primary border-2'
                                                : ''
                                            }`}
                                          >
                                            <div className="flex items-center justify-between">
                                              <div className="flex items-center">
                                                {link.icon && (
                                                  <div className="mr-2">
                                                    {link.icon}
                                                  </div>
                                                )}
                                                <div>
                                                  <div className="flex flex-col">
                                                    <p className="text-sm font-semibold">
                                                      {link.label}
                                                    </p>
                                                    <p className="text-sm font-medium text-gray-500">
                                                      {link.description}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div>
                                                {link.subOption ? (
                                                  <div className="">
                                                    <ChevronRightIcon className="h-6 w-6 text-gray-500" />
                                                  </div>
                                                ) : null}
                                              </div>
                                            </div>
                                          </li>
                                        </ol>
                                      </div>
                                    ))}
                                  </div>
                                )
                              }
                              {/* // this is the subOption area */}
                              <div
                                className={[
                                  'pointer-events-none  z-40  -translate-y-3 opacity-0 transition-all duration-200 ease-in-out',
                                  hideSubDropdown
                                    ? ''
                                    : 'lg:group-hover:pointer-events-auto lg:group-hover:translate-y-0 lg:group-hover:opacity-100',
                                ].join(' ')}
                              >
                                <div
                                  {...accessibleOnClick(() => {
                                    setHideSubDropdown(true)
                                    setShowMenu(false)
                                  })}
                                  className=""
                                >
                                  {
                                    options?.find(o => o.href === showDropdown)
                                      ?.subOption
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </Collapsible>
                        {/*  DROP DOWN AREA ENDS */}
                      </>
                    ) : (
                      <Link passHref href={href} key={href}>
                        <li
                          {...accessibleOnClick(() => {
                            setShowDropdown('')
                            setShowMenu(false)
                          })}
                          className="group flex cursor-pointer items-center "
                        >
                          <p
                            className={`hover:text-adminBlue cursor-pointer whitespace-nowrap ${
                              router.pathname === href &&
                              `${
                                brand === 'tm'
                                  ? 'text-adminBlue'
                                  : 'text-pe-primary'
                              }  font-semibold`
                            }`}
                          >
                            {label}
                          </p>
                        </li>
                      </Link>
                    )}
                  </li>
                )
              },
            )}
          </div>
          <div className="mb-6 flex items-center">
            <button
              type="button"
              onClick={logout}
              className="bg-primary rounded-md px-4 py-2 text-white"
            >
              Logout
            </button>
          </div>
        </div>
      </Drawer>

      <GlobalSearch />
      <NetworkStatus />
      <CallDrawer />
      <ChangeLogContainer />
    </div>
  )
}

export default Header
