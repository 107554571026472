/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { XCircleIcon, XIcon } from '@heroicons/react/solid'
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'
import { accessibleOnClick } from 'utils'
import { useEventListener } from 'hooks'

type Div = Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'title'
>

interface Props extends Div {
  isOpen?: boolean
  onClose?: () => void
  title?: ReactNode
  actions?: ReactNode
  variant?: 1 | 2 | 3
  fullHeight?: boolean
}

const Modal: FC<Props> = ({
  children,
  isOpen,
  onClose,
  title,
  className,
  actions,
  variant = 1,
  fullHeight,
  ...props
}) => {
  useEventListener('keyup', ({ key }) => key === 'Escape' && onClose?.())
  return isOpen ? (
    <>
      <div
        {...accessibleOnClick(onClose)}
        className="fixed left-0 right-0 top-0 bottom-0 z-20 bg-black opacity-50"
      />
      <div
        {...props}
        className={[
          'min-w-xl fixed left-[50%] top-[50%] z-30 max-h-[calc(100vh-32px)] max-w-[calc(100%-80px)] translate-x-[-50%] translate-y-[-50%] overflow-auto rounded-lg bg-white',
          className,
        ].join(' ')}
      >
        <div className={`sticky top-0 ${fullHeight ? 'h-full' : ''}`}>
          <div
            className={[
              'flex items-center justify-between',
              variant === 1 && 'p-8 pl-12',
              variant === 2 && 'bg-primary py-5 px-8 text-white',
              variant === 3 && 'bg-[#1E1E1E] py-5 px-8 text-white',
            ].join(' ')}
          >
            {typeof title !== 'object' ? (
              <h2
                className={`max-w-[320px] ${
                  variant === 3
                    ? 'font-semiboold text-lg'
                    : 'text-2xl font-bold'
                }  `}
              >
                {title}
              </h2>
            ) : (
              title
            )}
            <div className="flex items-center">
              <div>{actions}</div>
              <div className="cursor-pointer" {...accessibleOnClick(onClose)}>
                {variant === 3 ? (
                  <XIcon className="h-6 w-6" />
                ) : (
                  <XCircleIcon
                    className={[
                      'h-10 w-10',
                      variant === 1 && 'text-[#ADADAD]',
                      variant === 2 && 'text-white',
                    ].join(' ')}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={[variant === 1 && 'p-8 pl-12', 'h-full'].join(' ')}>
            {children}
          </div>
        </div>
      </div>
    </>
  ) : null
}

export default Modal
