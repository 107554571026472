import { DynamicOrderItemDetails, MatterDetails } from './services/matters'

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type valueof<T> = T[keyof T]
export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>
}

export type YesNoNotSure = 'Yes' | 'No' | 'NotSure' | ''

export const trademarkTypesByMarkDrawingCode = {
  '1000': 'Slogan',
  '2000': 'Logo',
  '3000': 'Logo',
  '4000': 'Name',
  '5000': 'Name',
}
export type MarkDrawingCode = keyof typeof trademarkTypesByMarkDrawingCode

export const orderItemPaymentStatus = {
  0: 'cart',
  1: 'created_payment',
  2: 'paid',
  3: 'partially_paid',
  4: 'refunded',
}
export type OrderItemPaymentStatus = keyof typeof orderItemPaymentStatus

export const orderStatus = {
  0: 'pending',
  1: 'RAPC Earned',
}
export type OrderStatus = keyof typeof orderStatus

export const priorityTypes = {
  1: 'Standard Processing',
  2: 'Second business day express procession',
  3: 'Priority rush processing (Next business day)',
}
export type PriorityType = keyof typeof priorityTypes

export const proofTypes = {
  1: 'Photograph of the product',
  2: 'Product labels and tags affixed to the product',
  3: 'Product Packaging',
  4: 'Webpage with the product',
  5: 'Instruction sheets and manuals that are included with the product',
  6: 'Others',
}
export type ProofType = keyof typeof proofTypes

export interface Admin {
  id: string
  created_at: number
  updated_on: number
  uid: string
  full_name: string
  is_disabled?: boolean
  disable_reason?: string
  email: string
  phone: string
  permissions: string[]
  admin_teams?: AdminTeam[]
  admin_roles?: AdminRole[]
  meeting_link?: string
  address: {
    id: string
    line1: string
    line2: string
    city: string
    state: string
    country: string
    postal_code: string
  }
}

export interface AdminTeam {
  admin_team_id: string
  name: string
}

export interface AdminRole {
  admin_team_id: string
  name: string
  permissions: string[]
}

export interface AttorneyDetails {
  admin_id: string
  admin: Admin
  full_name: string
  role: string
  photo: string
  phone: string
  email: string
  address_id: string
  address: {
    id: string
    line_1: string
    line_2: string
    city: string
    state: string
    country: string
    postal_code: string
  }
  qualifications: string
  university: string
  languages: string[]
  practice_statement: string
  practice_area: string[]
  licensed_state: {
    country: string
    states: string[]
  }[]
  priority: number
  rating: number
  is_disabled: boolean
  meeting_link?: string
  experience_start_date?: number
}

export interface FilingReport {
  prices_id: string
  created_at: number
  updated_on: number
  order_status: number
  order_item_id: string
  priority_type: PriorityType
  logo_description: string
  is_step_1_completed: boolean
  is_step_2_completed: boolean
  is_step_3_completed: boolean
  is_step_4_completed: boolean
  tm_order_entity_info: {
    zip: string
    city: string
    state: string
    entity_name: string
    entity_type: string
    street_address: string
    state_of_formation: string
    authorised_person_name: string
  }

  class_classifications: ClassClassification[]
  is_submitted_for_processing: boolean
}

export interface ClassClassification {
  id: string
  proof: Proof[]
  is_saved: boolean
  description: string
  tm_order_id: string
  date_of_filing: number
  filing_country: string
  application_number: string
  first_use_date_any: number
  first_use_date_com: number
  is_being_used_to_sell: boolean
  is_filed_in_any_country: boolean
  trademark_classification_class_id: string
}

export interface UsTmProcessing {
  tm_order_id: string
  created_at: number
  working_attorney_id: string
  law_firm_matter_id: number
  current_step: number
  is_black_list_checked: boolean
  is_unfiled_matters_checked: boolean
  is_slic_checked: boolean
  slic_document: SlicDocument
  is_knockout_searched: boolean
  knockout_document: KnockoutDocument
  filing_report: FilingReport
  step5_earn_status: string
  step6_approval_type: string
  step6_approval_status: string
  serial_number: string
}
export interface SlicDocument {
  design_code: string
  search_urls: string[]
  description_used: string
  breif_description: string
  slic_document_list: SlicDocumentList[]
}
export interface SlicDocumentList {
  conflict_type: string
  serial_number: string
}

export interface Proof {
  other: string
  specimen: string
  proof_type: ProofType
  website_specimen: string
}

// export interface OrderItem {
//   id: string
//   user_id: string
//   order_id: string
//   added_at: number
//   quantity: number
//   item_type: string
//   order_item_status: number
//   payment_status: number
//   is_offline_order: boolean
//   offline_order_rep?: any
//   offline_order_created_by?: any
//   prices_id: string
//   billable_amount: number
//   billable_amount_paid: number
//   pricing_ref: PricingRef
// }

export interface TmOrder {
  order_item_id: string
  created_at: number
  updated_on: number
  order_status: number
  classes: number
  prices_id: string
  is_name_protected: boolean
  mark_name: string
  is_slogan_protected: boolean
  slogan: string
  is_logo_protected: boolean
  logo_url: string
  is_colour_protected: boolean
  logo_description: string
  is_trademark_already_using: boolean
  is_good_protected: boolean
  is_service_protected: boolean
  good_description: string
  service_description: string
  is_step1_completed: boolean
  is_step2_completed: boolean
  is_good_classifications_confirmed: boolean
  is_service_classifications_confirmed: boolean
  is_attorney_review_requested: boolean
  is_step3_completed: boolean
  priority_type: PriorityType
  is_submitted_for_processing: boolean
  is_step4_completed: boolean
}

export interface KnockoutDocumentItem {
  serial_number: string
  similarity_type: string
}

export interface KnockoutDocument {
  design_code: string
  search_urls: string[]
  description_used: string
  breif_description: string
  knockout_document_list: KnockoutDocumentItem[]
}

export interface OfflineProps<Type extends keyof DynamicOrderItemDetails> {
  order: MatterDetails<Type>
  onClose: () => Promise<void>
}

export interface LocalOwnershipDetails {
  ownedBy: null | Record<'value', string>
  stateOfFormation: string
  order_id: string
  entityName: string
  authorisedPersonName: string
  authorisedPersonPosition: string
  country: string
  street: string
  city: string
  state: string
  zip: string
}

export type Partial<T> = { [P in keyof T]?: T[P] }

export interface AddonPricingRef {
  search: number
}

export interface AddonOrderItem {
  id: string
  user_id: string
  order_id: string
  added_at: number
  quantity: number
  billable_amount: number
  billable_amount_paid: number
  billable_govt_amount: number
  billable_govt_amount_paid: number
  item_type: string
  order_item_status: number
  payment_status: number
  prices_id: string
  pricing_ref: AddonPricingRef
  is_offline_order: boolean
  offline_order_rep?: any
  offline_order_created_by?: any
  discount: number
}

export interface AddonDataItem {
  order_item_id: string
  created_at: number
  updated_on: number
  order_status: number
  prices_id: string
  tm_order_id?: any
  search_type: number
  current_step: number
  order_id: string
  matter_id: number
  full_name: string
  email: string
  phone: string
  order_item: AddonOrderItem
  serial_number?: string
}
