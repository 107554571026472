import { createFetcher, ROUTES } from '../api'

export const fetchProfile = createFetcher<
  {
    permissions: any[]
    profile: Profile
    token: string
  },
  { id_token: string }
>({
  url: ROUTES.AUTH.LOGIN,
  method: 'POST',
  getTokens: true,
})

export const updateGmailAccess = createFetcher<
  any,
  {
    email: string
    access_token: string
    refresh_token: string
  }
>({
  url: ROUTES.AUTH.UPDATE_GMAIL_ACCESS,
  method: 'PUT',
  withTokens: true,
})

export interface Profile {
  id: string
  created_at: number
  updated_on: number
  uid: string
  full_name: string
  email: string
  phone: string
  address_id: string
  meeting_link: string
  permissions: string
  admin_group_id: string
  admin_group: {
    admins: null
    id: string
    name: string
    permissions: string[]
  } | null
}
