/* eslint-disable react/destructuring-assignment */
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import Loading from 'ui/Loading'
import { useAccess } from '../context/access'
import { useUser } from '../context/user'
import Restricted from './Adminv3/Restricted'
import Header from './Header'
import Sidebar from './Sidebar'

const ignoreHeader = ['/login']

const Layout: FC = props => {
  const { pathname, push } = useRouter()
  const { profile } = useUser()
  const unprotectedRoutes = ['/login']

  const isRouteUnProtected = unprotectedRoutes.includes(pathname)

  useEffect(() => {
    if (!isRouteUnProtected && profile === null) push('/login')
    if (isRouteUnProtected && profile) push('/')
  }, [profile, push, pathname])

  const [loading, setLoading] = useState(false)

  // DONT TOUCH THIS: the component was loading twice, until we did this, dont memoize this, we know how devs think.
  useEffect(() => setLoading(profile === undefined), [profile])

  const { accessAllowed } = useAccess()

  const children = accessAllowed ? props.children : <Restricted />

  return (
    <div className="h-screen max-h-[100vh] overflow-y-auto overflow-x-hidden pb-10">
      {!ignoreHeader.includes(pathname) && <Header />}
      {loading ? (
        <Loading />
      ) : profile ? (
        <>
          <Sidebar />
          <div className="">{children}</div>
        </>
      ) : isRouteUnProtected ? (
        children
      ) : null}
    </div>
  )
}

export default Layout
