import { sentencize } from './format'
import { dollarKeys, flattenObject, testRegexString } from './parse'

export const jsonToCsv = <T extends Object>(
  data: T[],
  { placeTop = [] }: { placeTop?: Record<string, string>[] } = {},
) => {
  console.log({ data })
  const flattenedData = data.map(data => flattenObject(data))
  type Key = keyof typeof flattenedData[number]
  const keys = flattenedData.reduce(
    (acc: Key[], curr) => [
      ...acc,
      ...(Object.keys(curr) as Key[]).filter(i => !acc.includes(i)),
    ],
    [] as Key[],
  )
  const array = [
    keys.map(key => {
      if (testRegexString(key, dollarKeys)) return `${sentencize(key)} ($)`
      return sentencize(key)
    }),
    ...placeTop.map(row => {
      console.log({ keys, row })
      return keys.map(key => row[key])
    }),
    ...flattenedData.map(row => keys.map(key => row[key])),
  ]
  return array
    .map(row => row.map(cell => JSON.stringify(cell)).join(','))
    .join('\n')
}
