import {
  FilingFormOwnership,
  FilingRenewalForm,
  FilingReportSou,
  LawFirmMatter,
  OrderItem,
  OrderItemOwnership,
  OrderItemSou,
  RenewalOrderItem,
  Step0RenewalDataItem,
  Step4OwnershipDataItem,
  Step4RenewalDataItem,
  Step4SouDataItem,
} from '../api'

interface FilingForm extends FilingRenewalForm {
  step5_earn_status?: string
}

export interface TranslatedType {
  matterId: number
  deadline?: number
  service?: any
  orderItem: any
  orderId: string
  lawFirmMatter?: LawFirmMatter
  filingFormOwnership?: FilingFormOwnership
  filingFormSou?: FilingReportSou
  filingFormRenewal?: FilingForm
  paralegalId?: string
  isApprovedForRenewal?: boolean
  isApprovedForTransfer?: boolean
  isApprovedForSou?: boolean
  isOwnershipEmailGenerated?: boolean
  isRenewalEmailGenerated?: boolean
  isSouEmailGenerated?: boolean
  serialNumber?: string
  isSection8?: boolean
  isGrace?: boolean
}

export const renewalItemTranslator = (
  item: Step4RenewalDataItem,
): TranslatedType => ({
  matterId: item.matter_id,
  orderId: item.order_item.id,
  deadline: item.renewal_deadline,
  service: item.tm_renewal,
  orderItem: item.order_item,
  lawFirmMatter: item.law_firm_matter,
  paralegalId: item.paralegal_id,
  isApprovedForRenewal: item.is_approved_for_renewal,
  isApprovedForTransfer: undefined,
  isApprovedForSou: undefined,
  filingFormRenewal: item.filing_form,
  filingFormOwnership: undefined,
  filingFormSou: undefined,
  isOwnershipEmailGenerated: undefined,
  isSouEmailGenerated: undefined,
  isRenewalEmailGenerated: item.is_email_generated,
})

export const renewalStep0Translator = (
  item: Step0RenewalDataItem,
): TranslatedType => ({
  matterId: item.matter_id,
  serialNumber: item.serial_number,
  deadline: item.renewal_deadline,
  orderItem: item.order_item,
  isSection8: item.is_section8_filed,
  isGrace: item.is_grace,
  orderId: item.order_id,
})

export const ownershipItemTranslator = (
  item: Step4OwnershipDataItem,
): TranslatedType => ({
  matterId: item.matter_id,
  orderId: item.order_item.id,
  service: item.tm_ownership,
  orderItem: item.order_item,
  lawFirmMatter: item.law_firm_matter,
  filingFormRenewal: undefined,
  filingFormOwnership: item.filing_form,
  filingFormSou: undefined,
  paralegalId: item.paralegal_id,
  isApprovedForRenewal: undefined,
  isApprovedForSou: undefined,
  isApprovedForTransfer: item.is_approved_for_transfer,
  isOwnershipEmailGenerated: item.is_email_generated,
  isSouEmailGenerated: undefined,
  isRenewalEmailGenerated: undefined,
})
export const souItemTranslator = (item: Step4SouDataItem): TranslatedType => ({
  matterId: item.matter_id,
  orderId: item.order_item.id,
  service: item.sou,
  orderItem: item.order_item,
  lawFirmMatter: item.law_firm_matter,
  filingFormRenewal: undefined,
  filingFormOwnership: undefined,
  filingFormSou: item.filing_report,
  paralegalId: item.paralegal_id,
  isApprovedForRenewal: undefined,
  isApprovedForTransfer: undefined,
  isApprovedForSou: item.is_approved_for_sou,
  isSouEmailGenerated: item.is_email_generated,
  isOwnershipEmailGenerated: undefined,
  isRenewalEmailGenerated: undefined,
})
