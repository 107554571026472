/* eslint-disable jsx-a11y/media-has-caption */
import { imgRoute, soundRoute } from 'api'
import { ErrorMessage, FormikTouched, FormikValues } from 'formik'
import Lottie from 'lottie-react'
import Image from 'next/image'
import { useState } from 'react'
import Dropzone, { FileWithPath } from 'react-dropzone'
import { accessibleOnClick } from 'utils'

import { ImgUpload, RoundCross } from '../assets'
import loader from '../Lottie/imageupload.json'

interface Props<Values extends {} = any> {
  setTouched: (
    touched: FormikTouched<FormikValues>,
    shouldValidate?: boolean | undefined,
  ) => void
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void
  name: string
  fetchUploadImage: any
  values: Values
  onUpload?: () => any
  label?: string
  extraFormData?: Record<string, any>
  addExtToForm?: boolean
  addExtToUrlParam?: boolean
  getImage?: (values: Values) => { key: string; url: string }
  fromUrl?: boolean
  sound?: boolean
}

export const FileInput = <Values extends {} = any>({
  setFieldValue,
  setTouched,
  fetchUploadImage,
  name,
  values,
  onUpload,
  label = 'Upload Logo File',
  addExtToForm = true,
  addExtToUrlParam = true,
  extraFormData,
  getImage,
  fromUrl,
  sound,
}: Props<Values>) => {
  const image = getImage
    ? getImage(values)
    : (values?.[name as keyof Values] as
        | { key: string; url: string }
        | undefined)
  const [errors, setErrors] = useState('')
  const [progressPercent, setProgressPercent] = useState(0)
  const [, setUploadStatus] = useState('')
  const [loading, setLoading] = useState(false)

  const onDropHandler = async (acceptedFiles: FileWithPath[]) => {
    if (!acceptedFiles[0]) return
    setLoading(true)
    const formData = new FormData()
    formData.append('file', acceptedFiles[0])

    const ext = acceptedFiles[0].name.split('.').pop()
    if (addExtToForm && ext) {
      formData.append('ext', ext)
    }
    if (extraFormData) {
      Object.keys(extraFormData).map(key =>
        formData.append(key, extraFormData[key]),
      )
    }
    setTouched({ name: true })
    const f = acceptedFiles[0]
    if (f) {
      const reader = new FileReader()
      try {
        const { body, error } = await fetchUploadImage(formData, {}, [
          addExtToUrlParam ? ext && ext : null,
        ])
        if (error || !body) return

        setFieldValue(name, body)
        onUpload?.()
      } catch (error) {
        ///
      } finally {
        setLoading(false)
      }

      const xhr = new XMLHttpRequest()
      xhr.upload.onprogress = (event: any) => {
        const percentage = Math.floor((event.loaded / event.total) * 100)

        setProgressPercent(percentage)
      }
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) return
        if (xhr.status !== 200) {
          setUploadStatus('error') // Handle error here
        }
        setUploadStatus('success') // Handle success here
      }
      xhr.open('POST', 'https://httpbin.org/post', true)
      xhr.send(formData)

      reader.readAsBinaryString(f)
    }
  }

  return (
    <div
      className={`relative ${
        (fromUrl ? image?.url : image?.key) ? 'flex' : ''
      }`}
      onKeyPress={() => {}}
      role="button"
      tabIndex={0}
      onBlur={() => {
        setTouched({ name: true })
      }}
    >
      {(fromUrl ? image?.url : image?.key) && (
        <div className="flex w-1/2 flex-col items-center justify-center">
          {(fromUrl ? image?.url : image?.key) ? (
            <div className=" rounded-lg border-[1px] border-[#604CC5] p-4">
              <div
                className="relative float-right h-5 w-5 cursor-pointer"
                {...accessibleOnClick(() => {
                  setFieldValue(name, null)
                })}
              >
                <RoundCross />
              </div>

              {progressPercent > 0 && progressPercent < 100 ? (
                <div>
                  <p>{progressPercent}%</p>
                  <div className="h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700">
                    <div
                      style={{ width: `${progressPercent}%` }}
                      className="h-2.5 rounded-full bg-blue-600"
                    />
                  </div>
                </div>
              ) : null}
              {!sound ? (
                fromUrl ? (
                  <img
                    src={image?.url}
                    className="mt-4"
                    width={230}
                    alt="logo"
                    height={230}
                  />
                ) : (
                  <Image
                    unoptimized
                    src={imgRoute(image?.key as string)}
                    className="mt-4"
                    objectFit="contain"
                    width={230}
                    height={230}
                  />
                )
              ) : (
                <audio
                  controls
                  src={fromUrl ? image?.url : soundRoute(image?.key as string)}
                />
              )}
            </div>
          ) : null}
        </div>
      )}

      <Dropzone
        noKeyboard
        onDrop={onDropHandler}
        accept="image/png, image/jpeg, audio/mpeg"
        multiple={false}
        minSize={0}
        maxSize={2621440} // 2.5 MB (1048576 * 2.5)
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragReject,
          fileRejections,
        }) => {
          fileRejections.forEach(file => {
            file.errors.forEach(err => {
              if (err.code === 'file-too-large') {
                setErrors(
                  `The file you tried to upload is too big. Max upload size is 2.5MB`,
                )
              }

              if (err.code === 'file-invalid-type') {
                console.log(err)

                setErrors(
                  `The file you tried to upload is of wrong format. Only .png and .jpg files are allowed`,
                )
              }
            })
          })

          return (
            <div
              {...getRootProps()}
              className={`rounded-lg border border-[#D3D3D3] bg-[#F9F9F9] px-4 pt-8 pb-6 ${
                (fromUrl ? image?.url : image?.key) ? 'w-1/2' : ''
              }`}
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <Lottie
                    loop
                    style={{ width: 200, height: 200 }}
                    animationData={loader}
                  />
                </div>
              ) : (
                <>
                  <div className="relative mx-auto h-12 w-12">
                    <ImgUpload />
                  </div>
                  <input {...getInputProps()} name={name} />
                  <div className="text-h5 mt-4 text-center leading-5">
                    {!isDragActive && (
                      <div>
                        {!(fromUrl ? image?.url : image?.key) ? (
                          <div>
                            <p>{label}</p> <p>or Drag &amp; Drop</p>
                          </div>
                        ) : (
                          <div>
                            <p className="mt-10 text-2xl">Re-upload/</p>
                            <p className="text-2xl ">change file</p>
                          </div>
                        )}
                      </div>
                    )}
                    {isDragActive && !isDragReject && (
                      <p>Drop it like it&apos;s hot! 🔥</p>
                    )}
                    {isDragReject && (
                      <p className="text-red-600">Unsupported file type :(</p>
                    )}
                    {!(fromUrl ? image?.url : image?.key) && (
                      <div className="mt-4 text-red-600">{errors}</div>
                    )}
                  </div>
                  {!(fromUrl ? image?.url : image?.key) && (
                    <div className="flex justify-between">
                      <div className="text-h5 mt-4 text-center leading-5">
                        JPEG, JPG or PNG
                      </div>
                      <div className="text-h5 mt-4 text-center leading-5">
                        Max Size 2.5MB
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )
        }}
      </Dropzone>
      <ErrorMessage
        className=" text-foot absolute  -bottom-[22px] left-1 font-medium leading-5 text-[#E41515]"
        name={name}
        component="div"
      />
    </div>
  )
}
