import { createFetcher, ROUTES } from '../api'

export const fetchPostfilingMatters = createFetcher<{
  data: PostfilingMatter[]
  facets: Facets
}>({
  url: ROUTES.POSTFILING.GET_ALL,
  withTokens: true,
})

export const fetchPostfilingSearchMatters = createFetcher<
  PostfilingSearchMatter[]
>({
  url: ROUTES.POSTFILING.SEARCH,
  withTokens: true,
})

export const fetchPostfilingMattersById = createFetcher<
  PostFilingOwnerMatters,
  any,
  { id: string }
>({
  url: ROUTES.POSTFILING.MATTERS_BY_ID,
  withTokens: true,
})

export const fetchTentativelyClaimPostfilingOwner = createFetcher<
  any,
  any,
  { id: string }
>({
  method: 'PATCH',
  url: ROUTES.POSTFILING.TENTATIVELY_CLAIM,
  withTokens: true,
})

export const fetchCheckInPostFilingMatter = createFetcher<
  any,
  any,
  { type_of_check_in: string },
  {
    post_filing_id: string
    check_in_by: string
    serial_numbers: number[]
    notes: string
    check_in_type: string
  }
>({
  method: 'PATCH',
  url: ROUTES.POSTFILING.CHECK_IN,
  withTokens: true,
})

export const fetchUpdatePostFilingMatters = createFetcher<
  any,
  {
    is_dismissed?: boolean
    tags?: any[]
    matter_item_tags?: {
      serial_number?: number
      tags?: any[]
    }[]
  },
  { id: string }
>({
  method: 'PATCH',
  url: ROUTES.POSTFILING.UPDATE_MATTERS,
  withTokens: true,
})

export const fetchPostfilingAttorneys = createFetcher<PostfilingAttorney>({
  url: ROUTES.POSTFILING.GET_ATTORNEYS,
  withTokens: true,
})

export const fetchPostfilingEntry = createFetcher<
  {
    serial_number: number
    user_email: string
  },
  {
    serial_number: number
    user_email: string
  }
>({
  method: 'POST',
  url: ROUTES.POSTFILING.TRADEMARK_ENTRY,
  withTokens: true,
})

export interface PostFilingOwnerMatters {
  id: number
  user_id: string
  admin_id: any
  admin: any
  last_check_in_at: number
  check_in_count: number
  last_claimed_by: any
  admin_claimed_by: any
  matter_types: string[]
  upcoming_deadline: number
  approx_revenue: number
  is_dismissed: boolean
  post_filing_matters: PostFilingMatter[]
  post_filing_check_ins: PostfilingCheckIn[]
}

export interface PostfilingCheckIn {
  id: number
  post_filing_id: number
  post_filing: any
  check_in_at: number
  check_in_by: string
  admin: any
  serial_numbers: string[]
  notes: string
  check_in_type: string
}

export interface PostFilingMatter {
  serial_number: number
  post_filing_id: number
  matter_type: string
  deadline: number
  aor: string
  approx_revenue: number
  tags: any
}

export interface PostfilingSearchMatter {
  user_id: string
  full_name: string
  email: string
  check_in_count: number
  phone: string
  id: string
  temp_claim_id: string
  matter_types: string[]
  last_check_in_at: number
  upcoming_deadline: number
  user_revenue: number
  is_dismissed: boolean
  admin_id: any
  approx_revenue: number
}

export interface PostfilingMatter {
  serial_number: number
  action_key: string
  registration_number: number
  registration_date: number
  filing_date: number
  status_code: number
  status_date: number
  transaction_date: number
  mark_identification: string
  mark_drawing_code: string
  published_opposition_date: number
  abandonment_date: number
  cancellation_code: string
  cancellation_date: number
  renewal_date: number
  docket_number: string
  attorney_name: string
  is_visible: boolean
  trademark_application_statement: any
  trademark_classification: any
  trademark_design_search: any
  trademark_owner: any
  trademark_prosecution_history: any
  trademark_correspondent: any
  trademark_owner_contact: any
  trademark_section_info: any
}

export interface PostfilingAttorney {
  facets: Facets
}

export interface Facets {
  facet_fields: FacetFields
  facet_heatmaps: FacetHeatmaps
  facet_intervals: FacetIntervals
  facet_queries: FacetQueries
  facet_ranges: FacetRanges
}

export interface FacetFields {
  attorney_name: any[]
}

export interface FacetHeatmaps {}

export interface FacetIntervals {}

export interface FacetQueries {}

export interface FacetRanges {}
