import { ChevronDownIcon } from '@heroicons/react/outline'
import { adminImageRoute } from 'api'
import { useClickAway } from 'hooks'
import Image from 'next/image'
import { useRef, useState } from 'react'
import { logout } from '../config/firebase'
import { useUser } from '../context/user'
import EditProfileModal from './Adminv3/EditProfileModal'

const ProfileDropdown = () => {
  const { profile } = useUser()
  const [isOpen, setIsOpen] = useState(false)
  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  useClickAway(dropdownRef, () => setIsOpen(false))
  const [imageError, setImageError] = useState(false)

  if (!profile) return null

  return (
    <div className="relative flex items-center gap-4 ">
      <div>
        {/* profile.photourl in a circle or grey circle wuth full_name initials */}
        {!imageError ? (
          <Image
            unoptimized
            src={adminImageRoute(profile?.id)}
            alt={profile?.full_name}
            width={50}
            objectFit="cover"
            onError={() => {
              setImageError(true)
            }}
            height={50}
            className="rounded-full"
          />
        ) : (
          <div className="flex h-10 w-10 items-center justify-center rounded-full border-[1px] border-[#DCDCDC] bg-[#F3F3F3] font-semibold text-black">
            {profile?.full_name[0]}
          </div>
        )}
      </div>
      <div>
        <div className="text-sm font-semibold">{profile?.full_name}</div>
        <div className="text-xs text-[#A3A3A3]">{profile?.email}</div>
      </div>
      <div>
        <ChevronDownIcon
          className={`h-5 w-5 cursor-pointer text-[#A3A3A3] ${
            isOpen ? 'rotate-180 transform' : ''
          }`}
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      {
        // dropdown
        isOpen && (
          <div
            ref={dropdownRef}
            className="absolute top-8 mt-10 flex w-56 flex-col rounded-md border-[1px] border-[#DCDCDC] bg-white py-4 shadow-lg"
          >
            <button
              type="button"
              className="cursor-pointer py-2 px-4 text-center text-sm font-semibold hover:bg-[#F3F3F3]"
              onClick={() => {
                setIsOpen(false)
                setIsEditProfileModalOpen(true)
              }}
            >
              Edit Profile
            </button>
            <button
              type="button"
              className="cursor-pointer py-2 px-4 text-center text-sm font-semibold hover:bg-[#F3F3F3]"
              onClick={logout}
            >
              Logout
            </button>
          </div>
        )
      }
      <EditProfileModal
        profile={profile}
        isOpen={isEditProfileModalOpen}
        onClose={() => setIsEditProfileModalOpen(false)}
      />
    </div>
  )
}

export default ProfileDropdown
