import { createFetcher, ROUTES } from '../api'

export const fetchUnclaimedLeads = createFetcher<UnclaimedLeads[], any>({
  url: ROUTES.LEADS.UNCLAIMED,
  withTokens: true,
})
export const fetchClaimedLeads = createFetcher<ClaimedLeads[], any>({
  url: ROUTES.LEADS.CLAIMED,
  withTokens: true,
})
export const fetchDismissedLeads = createFetcher<DismissedLeads[], any>({
  url: ROUTES.LEADS.DISMISSED,
  withTokens: true,
})
export const fetchOtherLeads = createFetcher<OtherLeads[], any>({
  url: ROUTES.LEADS.OTHER,
  withTokens: true,
})
export const fetchOneLead = createFetcher<Lead, any>({
  url: ROUTES.LEADS.GET_ONE,
  withTokens: true,
})
export const fetchClaimLead = createFetcher<any, any>({
  url: ROUTES.LEADS.CLAIM,
  withTokens: true,
  method: 'POST',
})

export const fetchTentativelyClaimLead = createFetcher<
  { bcc_email: string; claims: string; message: string },
  { order_id: string }
>({
  url: ROUTES.LEADS.TENTATIVELY_CLAIM,
  method: 'POST',
  withTokens: true,
})

export const fetchContactHisory = createFetcher<ContactHistory[], any>({
  url: ROUTES.LEADS.FETCH_CONTACT_HISTORY,
  withTokens: true,
})

export const fetchLeaderboard = createFetcher<
  Leaderboard,
  any,
  any,
  {
    start_time: number
    end_time: number
    lead_type?: string
  }
>({
  url: ROUTES.LEADS.LEADERBOARD,
  withTokens: true,
})

export const fetchDismissLead = createFetcher<
  any,
  { order_id: string; dismiss_reason: string }
>({
  url: ROUTES.LEADS.DISMISS_LEAD,
  method: 'DELETE',
  withTokens: true,
})

export const fetchContactLead = createFetcher<
  any,
  { order_id: string; reason: string }
>({
  url: ROUTES.LEADS.CONTACT_LEAD,
  method: 'POST',
  withTokens: true,
})

export const fetchBccEmail = createFetcher<string, any, { order_id: string }>({
  url: ROUTES.LEADS.BCC_EMAIL,
  withTokens: true,
})

export interface ClientRelation {
  admin_id: string
  practice_statement: string
  practice_area: string[]
  languages: string[]
  university: string
}

export interface Lead {
  is_name_protected: boolean
  is_sound_protected: boolean
  sound_url: string
  mark_name: string
  trademark_category: {
    type_of_protection: string
    protection_term: string
  }[]
  prices_id?: any
  is_slogan_protected: boolean
  slogan: string
  classifications: string[]
  is_logo_protected: boolean
  logo_url: string
  is_colour_protected?: any
  is_attorney_review_requested?: any
  website: string
  is_good_protected?: any
  is_service_protected?: any
  good_classifications?: string[]
  service_classifications?: string[]
  good_description?: string
  service_description?: string
  insurance_type: string
  priority_type: number
  client_name: string
  email: string
  phone: string
  client_relation: ClientRelation | null
}

export interface Leaderboard {
  admin_stats: AdminStat[]
  lifetime_unclaimed: number
  lifetime_claimed: number
  total_claimed: number
  total_unclaimed: number
}

export interface AdminStat {
  admin_id: string
  admin_name: string
  looms_made: number
  revenue: number
}

export interface UnclaimedLeads {
  time: number
  email: string
  service: string
  lead_name: string
  lead_status: string
  is_sound_protected: boolean
  sound_url: string
  is_name_protected: boolean
  type_of_protections: string[]
  protection_terms: string[]
  is_logo_protected: boolean
  is_slogan_protected: boolean
  mark_name: string
  phone: string
  logo_url: string
  slogan: string
  order_amount: number
  lead_type: string
  order_id: string
  claimed_by: any
  claimed_by_name: string
  tentative_claim_expiry_time: number
  website?: string
}

export interface ClaimedLeads {
  order_id: string
  time: number
  is_name_protected: boolean
  is_logo_protected: boolean
  is_slogan_protected: boolean
  is_sound_protected: boolean
  type_of_protections: string[]
  protection_terms: string[]
  sound_url: string
  mark_name: string
  logo_url: string
  slogan: string
  description: string
  lead_name: string
  phone: string
  email: string
  contact_count: number
  order_amount: number
  last_contacted_at: number
  website?: string
}

export interface DismissedLeads {
  order_id: string
  lead_name: string
  time: number
  email: string
  phone: string
  service: string
  dismiss_reason: string
}

export interface OtherLeads {
  id: string
  lead_name: string
  date_of_acquisition: number
  cr_manager: string
  email: string
  phone: string
  service: string
}

export interface ContactHistory {
  cr_name: string
  reason: string
  timestamp: number
}
