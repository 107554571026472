/* eslint-disable react/jsx-no-constructed-context-values */

import { useAsyncMemo } from 'hooks'
import { useRouter } from 'next/router'
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
} from 'react'
import {
  AccessGroup,
  fetchAccessGroups,
  fetchRestrictedPages,
  PageUrl,
} from '../config/firebase'
import { useUser } from './user'

const isRestricted = (pathname: string, { match_type, value }: PageUrl) => {
  switch (match_type) {
    case 'includes':
      return pathname.includes(value)
    case 'starts_with':
      return pathname.startsWith(value)
    default:
      return pathname === value
  }
}

const AccessContext = createContext({
  restrictedPages: [] as PageUrl[],
  setRestrictedPages: (() => {}) as Dispatch<SetStateAction<PageUrl[]>>,
  refreshRestrictedPages: async () => {},

  accessGroups: [] as AccessGroup[],
  setAccessGroups: (() => {}) as Dispatch<SetStateAction<AccessGroup[]>>,
  refreshAccessGroups: async () => {},

  accessAllowed: false,
})

export const useAccess = () => useContext(AccessContext)

export const AccessProvider: FC<{ children: ReactNode }> = props => {
  const [restrictedPages, setRestrictedPages, refreshRestrictedPages] =
    useAsyncMemo(
      async () => {
        const { data } = await fetchRestrictedPages()
        return data ?? []
      },
      [],
      { defaultValue: [] },
    )

  const [accessGroups, setAccessGroups, refreshAccessGroups] = useAsyncMemo(
    async () => {
      const { data } = await fetchAccessGroups()
      return data ?? []
    },
    [],
    { defaultValue: [] },
  )

  const { profile } = useUser()

  const { pathname } = useRouter()
  const accessAllowed = useMemo(() => {
    const accessRestricted = restrictedPages.find(page =>
      isRestricted(pathname, page),
    )
    if (!accessRestricted) return true
    if (!profile) return false

    const hasAccess = accessGroups.some(
      group =>
        group.access_pages.includes(accessRestricted.id) &&
        group.admins.includes(profile?.email),
    )

    return hasAccess
  }, [pathname, restrictedPages, accessGroups, profile])

  return (
    <AccessContext.Provider
      value={{
        restrictedPages,
        setRestrictedPages,
        refreshRestrictedPages,
        accessGroups,
        setAccessGroups,
        refreshAccessGroups,
        accessAllowed,
      }}
      {...props}
    />
  )
}
