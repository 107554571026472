import { createFetcher, ROUTES } from '../api'

export const fetchOfficeActionMoveStep = createFetcher({
  url: ROUTES.OFFICEACTIONS.MOVE_STEP,
  method: 'POST',
})

export const fetchOfficeActionUpdateTags = createFetcher<
  any,
  {
    office_action_id: string
    order_id: string
    tags: string[]
    price: number
  }
>({
  url: ROUTES.OFFICEACTIONS.UPDATE_TAGS,
  method: 'POST',
  withTokens: true,
})

export const fetchOfficeActionStep1 = createFetcher<
  OfficeActionStep1DataItem[]
>({
  url: ROUTES.OFFICEACTIONS.STEP_1.GET,
  withTokens: true,
})

export const fetchOfficeActionStep2 = createFetcher<
  OfficeActionStep2DataItem[]
>({
  url: ROUTES.OFFICEACTIONS.STEP_2.GET,
  withTokens: true,
})

export const fetchOfficeActionStep3 = createFetcher<
  OfficeActionStep3DataItem[]
>({
  url: ROUTES.OFFICEACTIONS.STEP_3.GET,
  withTokens: true,
})

export const fetchOfficeActionStep4 = createFetcher<
  OfficeActionStep4DataItem[]
>({
  url: ROUTES.OFFICEACTIONS.STEP_4.GET,
  withTokens: true,
})

export const fetchOfficeActionStep5 = createFetcher<
  OfficeActionStep5DataItem[]
>({
  url: ROUTES.OFFICEACTIONS.STEP_5.GET,
  withTokens: true,
})

export const fetchOfficeActionUpdate = createFetcher({
  url: ROUTES.OFFICEACTIONS.UPDATE,
  method: 'PATCH',
  withTokens: true,
})

export interface OfficeActionPricingRef {
  base_fee: number
}

export interface OfficeActionOrderItem {
  id: string
  user_id: string
  order_id: string
  added_at: number
  quantity: number
  billable_amount: number
  billable_amount_paid: number
  billable_govt_amount: number
  billable_govt_amount_paid: number
  item_type: string
  order_item_status: number
  payment_status: number
  prices_id: string
  pricing_ref: OfficeActionPricingRef
  is_offline_order: boolean
  offline_order_rep?: any
  offline_order_created_by?: any
  discount: number
}

export interface OfficeActionStep1DataItem {
  order_item_id: string
  order_id: string
  created_at: number
  updated_on: number
  order_status: number
  deadline: number
  prices_id: string
  serial_number: string
  confusion_likelihood: string
  gs_description: string
  notice_of_disclaimer: string
  generic_mark: string
  tags?: string[]
  office_action_admin_id?: any
  is_submitted_for_processing: boolean
  matter_id: number
  full_name: string
  email: string
  phone: string
  order_item: OfficeActionOrderItem
}

export interface OfficeActionStep2DataItem {
  office_action: OfficeAction
  office_action_id: string
  current_step: number
  created_at: number
  updated_on: number
  paralegal_id?: any
  paralegal_name: string
  order_contributor: string
  law_firm_matter_id: number
  law_firm_matter?: any
  order_date: number
  matter_id: number
  order_item: OfficeOrderItem
}

export interface OfficeActionStep3DataItem extends OfficeActionStep2DataItem {}

export interface OfficeActionStep4DataItem extends OfficeActionStep2DataItem {}

export interface OfficeActionStep5DataItem {}

export interface OfficeAction {
  order_item_id: string
  created_at: number
  updated_on: number
  order_status: number
  deadline: number
  prices_id: string
  serial_number: string
  confusion_likelihood: string
  office_actions_admin_id?: any
  deliverables?: any
  gs_description: string
  notice_of_disclaimer: string
  generic_mark: string
  tags: string[]
  is_submitted_for_processing: boolean
  is_revival_order: boolean
  question_id?: any
}

export interface OfficePricingRef {
  base_fee: number
}

export interface OfficeOrderItem {
  id: string
  user_id: string
  order_id: string
  added_at: number
  quantity: number
  billable_amount: number
  billable_amount_paid: number
  billable_govt_amount: number
  billable_govt_amount_paid: number
  item_type: string
  order_item_status: number
  payment_status: number
  prices_id: string
  pricing_ref: OfficePricingRef
  is_offline_order: boolean
  offline_order_rep?: any
  offline_order_created_by?: any
  discount: number
}
