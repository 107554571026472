import { ErrorMessage, useFormikContext } from 'formik'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import { accessibleOnClick } from 'utils'
import { SelectIco } from '../assets'

interface Props<T extends Record<string, any>> {
  placeholder?: string
  type?: string
  options: T[]

  formikValue: string
  inputClass?: string
  downIconClass?: string
  selectContainerClass?: string
  item?: keyof T
  image?: boolean
  noCircleIcon?: boolean
  label?: string
  onChange?: (value: T) => void
  dropUpward?: boolean
}

export const Dropdown = <T extends Record<string, any>>({
  placeholder,
  type,
  options,
  formikValue,
  inputClass,
  downIconClass,
  selectContainerClass,
  item = 'value',
  image,
  noCircleIcon,
  label,
  dropUpward,
  onChange,
}: Props<T>) => {
  const { values, setFieldValue } = useFormikContext<any>()
  const [showOptions, setShowOptions] = useState(false)
  const [input, setInput] = useState('')
  const handleClickOutside = (e: any) => {
    if (
      !e.target.classList.contains('select-input') &&
      !e.target.classList.contains('select-options') &&
      !e.target.classList.contains('select-option')
    ) {
      setShowOptions(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className={`relative  ${selectContainerClass}`}>
      <div className="pointer-events-none absolute right-3 top-3">
        <div
          className={`relative h-7 w-7 ${downIconClass} ${
            showOptions ? 'rotate-180' : ''
          }`}
        >
          {noCircleIcon ? (
            <Image unoptimized 
              layout="fill"
              src="/Fields/SelectIcoWithoutCircle.svg"
              alt="dropdown icon"
              objectFit="cover"
              objectPosition="center"
            />
          ) : (
            <SelectIco />
          )}
        </div>
      </div>

      <input
        className={`text-h5 select-input w-full cursor-pointer rounded-lg border border-[#c5c5c5] p-4 leading-5 placeholder:text-[#7D7D7D] ${inputClass}`}
        placeholder={placeholder}
        type="text"
        onClick={() => {
          setShowOptions(true)
          if (!showOptions) setInput('')
        }}
        value={
          type === 'region'
            ? formikValue
            : showOptions
            ? input
            : label
            ? values[formikValue]?.[label]
            : values[formikValue]?.[item] || ''
        }
        onChange={e => {
          if (type === 'region')
            setFieldValue(formikValue?.toString(), e.target.value)
          else setInput(e.target.value)
        }}
      />
      {showOptions ? (
        <div
          className={[
            'select-options  selectDropdown absolute z-[100] max-h-[300px] w-full overflow-y-auto rounded-lg  bg-white py-2 px-2 shadow-[1px_2px_10px_10px_rgba(0,0,0,0.35)]',
            dropUpward ? 'bottom-full mb-2' : 'top-full mt-2',
          ].join(' ')}
        >
          {options
            .filter((data: any) =>
              data?.[item]
                ?.toString()
                .toLowerCase()
                .includes(input.toLowerCase()),
            )

            .map((data: T | any) => (
              <div
                key={data.id}
                className="select-option flex-between flex cursor-pointer items-center py-2"
                {...accessibleOnClick(() => {
                  setFieldValue(formikValue?.toString(), data)
                  setShowOptions(false)
                  onChange?.(data)
                })}
              >
                {image ? (
                  <Image unoptimized 
                    layout="fixed"
                    src={`/Flags/${data.country_code.toLowerCase()}.svg`}
                    alt="trademarkia logo"
                    objectFit="contain"
                    objectPosition="center"
                    height={20}
                    width={20}
                  />
                ) : null}

                <p className="pointer-events-none ml-2">
                  {label ? data[label] : data[item]}
                </p>
              </div>
            ))}
        </div>
      ) : null}
      <ErrorMessage
        name={formikValue}
        component="div"
        className=" text-foot mt-3 font-medium leading-5 text-[#E41515]"
      />
    </div>
  )
}
