import {
  DependencyList,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { useAsyncEffect } from './useAsyncEffect'

export const useAsyncMemo = <T, D = null, Props = any>(
  fetcher: (props?: Props) => Promise<T | D> | T | D,
  deps: DependencyList = [],
  {
    defaultValue,
    storeLocal,
    storeSession,
    disableRefetch,
    destructor,
  }: {
    defaultValue?: D
    storeLocal?: string
    storeSession?: string
    disableRefetch?: boolean
    destructor?: () => void
  } = {},
): [
  T | D,
  Dispatch<SetStateAction<T | D>>,
  (props?: Props) => Promise<void>,
  { loading: boolean },
] => {
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState<T | D>(() => {
    if (storeSession) {
      try {
        if (typeof window !== 'undefined') {
          const item = window.sessionStorage.getItem(storeSession)
          return item ? JSON.parse(item) : defaultValue
        }
        return defaultValue
      } catch (error) {
        return defaultValue
      }
    } else if (storeLocal) {
      try {
        if (typeof window !== 'undefined') {
          const item = window.localStorage.getItem(storeLocal)
          return item ? JSON.parse(item) : defaultValue
        }
        return defaultValue
      } catch (error) {
        return defaultValue
      }
    } else {
      return defaultValue
    }
  })

  const getValue = async (props?: Props) => {
    if (
      disableRefetch &&
      JSON.stringify(value) !== JSON.stringify(defaultValue)
    )
      return
    setLoading(true)
    setValue(await fetcher(props))
    setLoading(false)
  }

  useAsyncEffect(getValue, deps, destructor)

  useEffect(() => {
    if (storeLocal) {
      try {
        if (typeof window !== 'undefined')
          window.localStorage.setItem(storeLocal, JSON.stringify(value))
      } catch (error) {
        ///
      }
    }
    if (storeSession) {
      try {
        if (typeof window !== 'undefined')
          window.sessionStorage.setItem(storeSession, JSON.stringify(value))
      } catch (error) {
        ///
      }
    }
  }, [value, storeLocal, storeSession])

  return [value, setValue, getValue, { loading }]
}

// const [data, setData, refetch] = useAsyncMemo(
//   async () => {
//     try {
//       const res = await fetch('api')
//       return res
//     } catch (error) {
//       return null
//     }
//   },
//   [],
//   {
//     defaultValue: undefined,
//     storeLocal: 'keyName',
//   },
// )
