import { fetchUploadDocument } from 'api'
import { ErrorMessage, FormikValues, useFormikContext } from 'formik'
import Lottie from 'lottie-react'
import Image from 'next/image'
import { useState } from 'react'
import Dropzone, { FileWithPath } from 'react-dropzone'

import { Upload } from '../assets'
import loader from '../Lottie/imageupload.json'
import { InputWithError } from './InputWithError'

interface Props {
  name: string
  deletedName: string
  orderId: string
  orderItemId: string
  onUpload?: () => any
}

export const DocInput = ({
  name,
  deletedName,
  onUpload,
  orderId,
  orderItemId,
}: Props) => {
  const [errors, setErrors] = useState('')
  const [progressPercent, setProgressPercent] = useState(0)
  const [, setUploadStatus] = useState('')
  const [loading, setLoading] = useState(false)

  const { values, setFieldValue, setTouched } = useFormikContext<FormikValues>()

  const onDropHandler = async (acceptedFiles: FileWithPath[]) => {
    if (!acceptedFiles[0]) return
    setLoading(true)
    const formData = new FormData()
    formData.append('order_id', orderId)
    formData.append('order_item_id', orderItemId)
    formData.append('file', acceptedFiles[0])

    const ext = acceptedFiles[0].name.split('.').pop() || ''
    setTouched({ name: true })
    const f = acceptedFiles[0]
    if (f) {
      const reader = new FileReader()
      try {
        const { body, error } = await fetchUploadDocument(formData, {}, { ext })
        if (error || !body) return

        setFieldValue(name, [...values[name], body])
        onUpload?.()
      } catch (error) {
        ///
      } finally {
        setLoading(false)
      }

      const xhr = new XMLHttpRequest()
      xhr.upload.onprogress = (event: any) => {
        const percentage = Math.floor((event.loaded / event.total) * 100)

        setProgressPercent(percentage)
      }
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) return
        if (xhr.status !== 200) {
          setUploadStatus('error') // Handle error here
        }
        setUploadStatus('success') // Handle success here
      }
      xhr.open('POST', 'https://httpbin.org/post', true)
      xhr.send(formData)

      reader.readAsBinaryString(f)
    }
  }

  return (
    <div
      className={`relative ${values[name]?.url ? 'flex' : ''}`}
      onKeyPress={() => {}}
      role="button"
      tabIndex={0}
      onBlur={() => {
        setTouched({ name: true })
      }}
    >
      <div className="flex flex-wrap justify-start pl-[107px]">
        {values[name].map(
          (
            item: { url: string | undefined; key: string | undefined },
            index: any,
          ) => (
            <div>
              {item?.url && (
                <div className="mb-6 flex w-1/3 items-center justify-center">
                  {item.url ? (
                    <div
                      className={`ml-6 mr-8 rounded-lg border-[2px] ${
                        !values[name][index]?.approvalStatus
                          ? 'border-primary'
                          : 'border-green-600'
                      } p-4`}
                    >
                      {progressPercent > 0 && progressPercent < 100 ? (
                        <div>
                          <p>{progressPercent}%</p>
                          <div className="h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700">
                            <div
                              style={{ width: `${progressPercent}%` }}
                              className="h-2.5 rounded-full bg-blue-600"
                            />
                          </div>
                        </div>
                      ) : null}
                      {item?.key?.split('.').pop() === 'pdf' ? (
                        <iframe
                          src={item.url}
                          style={{ height: '300px' }}
                          title={item.key}
                        />
                      ) : (
                        <div className="h-[300px]  w-[300px]">
                          <Image unoptimized 
                            src={item.url}
                            className="mt-4"
                            alt={item.key}
                            objectFit="contain"
                            height={300}
                            width={300}
                          />
                        </div>
                      )}

                      {/* <object
                      data={item.url}
                      type="application/pdf"
                      width="100%"
                      height="100%"
                    >
                      <p>
                        Alternative text - include a link{' '}
                        <a href={item.url}>to the PDF!</a>
                      </p>
                    </object> */}
                      <div>
                        <div className="flex items-center justify-between">
                          <button
                            type="button"
                            className="  bg-primary top-0 right-0 mt-4 mr-4 rounded-lg py-2 px-4 text-xs font-bold text-white shadow-md focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 dark:bg-red-600"
                            onClick={() => {
                              setFieldValue(
                                name,
                                values[name].filter(
                                  (i: any, idx: any) => idx !== index,
                                ),
                              )
                              setFieldValue(deletedName, [
                                ...values[deletedName],
                                item,
                              ])
                            }}
                          >
                            Remove
                          </button>
                          <div className="flex items-center space-x-4">
                            <p className="mt-2">Approve Document</p>
                            <InputWithError
                              name={`${name}[${index}].approvalStatus`}
                              inputClassName=" text-green-500 focus:ring-green-200"
                              type="checkbox"
                              checked={values[name][index]?.approvalStatus}
                            />
                          </div>
                        </div>
                        <InputWithError
                          placeholder="Description"
                          name={`${name}[${index}].name`}
                          type="text"
                          value={values[name][index]?.name}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          ),
        )}
      </div>

      <Dropzone
        noKeyboard
        onDrop={onDropHandler}
        accept="image/png, image/jpeg, application/pdf"
        minSize={0}
        maxSize={5242880} // 5 MB (1048576 * 5)
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragReject,
          fileRejections,
        }) => {
          fileRejections.forEach(file => {
            file.errors.forEach(err => {
              if (err.code === 'file-too-large') {
                setErrors(
                  `The file you tried to upload is too big. Max upload size is 5MB`,
                )
              }

              if (err.code === 'file-invalid-type') {
                setErrors(
                  `The file you tried to upload is of wrong format. Only .png, .jpg and pdf files are allowed`,
                )
              }
            })
          })

          return (
            <div
              {...getRootProps()}
              className={`rounded-lg border border-[#A397E3] px-4 pt-8 pb-6 ${
                values[name]?.url ? 'w-1/2' : ''
              }`}
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <Lottie
                    loop
                    style={{ width: 200, height: 200 }}
                    animationData={loader}
                  />
                </div>
              ) : (
                <>
                  <input {...getInputProps()} name={name} />
                  <div className="text-h5 mt-4 text-center leading-5">
                    {!isDragActive && (
                      <div>
                        {!values?.[name]?.url ? (
                          <div>
                            <p>Upload a document</p> <p>or Drag &amp; Drop</p>
                          </div>
                        ) : (
                          <div>
                            <p className="mt-10 text-2xl">Re-upload/</p>
                            <p className="text-2xl ">change file</p>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="relative mx-auto mt-4 h-12 w-12">
                      <Upload />
                    </div>
                    {isDragActive && !isDragReject && (
                      <p>Drop it like it&apos;s hot! 🔥</p>
                    )}
                    {isDragReject && (
                      <p className="text-red-600">Unsupported file type :(</p>
                    )}
                    {!values[name]?.url && (
                      <div className="mt-4 text-red-600">{errors}</div>
                    )}
                  </div>
                  {!values[name]?.url && (
                    <div className="flex justify-between">
                      <div className="text-h5 mt-4 text-center leading-5">
                        JPEG, JPG, PNG or PDF
                      </div>
                      <div className="text-h5 mt-4 text-center leading-5">
                        Max Size 5MB
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )
        }}
      </Dropzone>
      <ErrorMessage
        className=" text-foot absolute  -bottom-[22px] left-1 font-medium leading-5 text-[#E41515]"
        name={name}
        component="div"
      />
    </div>
  )
}
