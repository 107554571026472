/* eslint-disable react/button-has-type */
import { Button as MuiButton, ButtonProps } from '@mui/material'
import Lottie from 'lottie-react'
import { FC } from 'react'
import loadingWhite from '../lottie/loadingWhite.json'

interface Props extends Omit<ButtonProps, 'variant'> {
  loading?: boolean
  disabled?: boolean
  variant?: 'primary' | 'secondary'
  muiVariant?: ButtonProps['variant']
  styles?: React.CSSProperties
}

const Button: FC<Props> = ({
  type,
  className,
  loading,
  variant,
  disabled,
  onClick,
  muiVariant,
  children,
  styles,
  ...props
}) => (
  <MuiButton
    variant={muiVariant || 'contained'}
    onClick={onClick}
    disabled={disabled}
    type={type}
    disableElevation
    sx={{
      textTransform: 'none',
      borderRadius: '8px',
      padding: '0.5rem 1rem',
      ...styles,
    }}
    endIcon={loading && <Lottie animationData={loadingWhite} />}
    {...props}
  >
    {typeof children === 'string' ? (
      <p className={[loading ? 'mr-2' : ''].join(' ')}>{children}</p>
    ) : (
      children
    )}
  </MuiButton>
)

export default Button
